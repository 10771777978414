/* FRAMEWORK CSS DEPENDENCIES */

// Dark Theme
$dark-theme: #080811;//#{$dark-theme}
$dark-l-theme: #10101a;//#{$dark-l-theme}

.btn-large {
    height: 49px!important
  }

  .btn-disabled-light button:disabled,
  .btn-disabled-light button[disabled] {
    background-color: #d0d7df!important
  }

  .btn-disabled-dark button:disabled,
  .btn-disabled-dark button[disabled] {
    background-color: #1f3a55!important
  }

  .custom-input,
  .custom-input-append,
  .custom-input-field {
    background-color: transparent!important;
    font-weight: 600!important;
    font-size: 12px!important;
    height: 23px!important;
    border: 1px solid #d0d7df!important;
    border-radius: 8px!important
  }

  .custom-input-field {
    border-right: none!important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important
  }

  .custom-input-append {
    color: red;
    border-left: none!important;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important
  }

  .btn-xs {
    padding: .25rem .4rem!important;
    font-size: 12px!important;
    line-height: 1.1!important;
    border-radius: 8px!important
  }

  .padme {
    margin-top: 15px
  }

  .menu-icon {
    color: #6b7c93
  }

  .notification-light {
    background: #fff
  }

  .notification-dark,
  .notification-light {
    -webkit-box-shadow: 0 2px 19px rgba(82, 105, 141, .12), 0 2px 22px rgba(15, 89, 209, .12);
    box-shadow: 0 2px 19px rgba(82, 105, 141, .12), 0 2px 22px rgba(15, 89, 209, .12);
    border-radius: 10px
  }

  .notification-dark {
    background: #{$dark-theme}
  }

  .alerts-enter {
    opacity: 0;
    -webkit-transform: translateY(35px);
    transform: translateY(35px)
  }

  .alerts-enter-active {
    -webkit-transition: all 1s ease;
    transition: all 1s ease
  }

  .alerts-leave-to {
    opacity: 0;
    -webkit-transform: scale(.6);
    transform: scale(.6)
  }

  .alerts-leave-active {
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute
  }

  .alerts-move {
    -webkit-transition: all 1.3s ease;
    transition: all 1.3s ease
  }

  .base-modal {
    height: 90vh!important
  }

  @media (min-width:900px) and (max-width:50000px) {
    .base-modal {
      height: 60vh!important
    }
  }

  @media (min-width:500px) and (max-width:899px) {
    .base-modal {
      height: 70vh!important
    }
  }

  #navigation-top {
    margin-top: .2rem;
    margin-bottom: .6rem
  }

  #navigation-top>* {
    margin-left: .75rem
  }

  .border-bottom-light {
    border-bottom: 1px solid #e6ebf2
  }

  .border-bottom-dark {
    border-bottom: 1px solid #1f3a55
  }

  .overlap {
    margin-left: -10px
  }

  .badge-version {
    font-size: 12px!important;
    background-color: #e9f2fd!important
  }

  .overlap {
    margin-left: -10px
  }

  .custom-control-inline {
    margin-right: 0!important;
    margin-left: 1rem!important
  }

  .custom-control-label {
    display: -webkit-inline-box!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
  }

  .btn-xs {
    padding: .25rem .4rem!important;
    font-size: 12px!important;
    line-height: 1.1!important;
    border-radius: 8px!important
  }

  .inactive {
    pointer-events: none;
    cursor: default;
    color: #86b0f4;
    opacity: .6;
    font-size: 1rem
  }

  .active {
    cursor: pointer;
    color: #86b0f4;
    font-size: 1rem
  }

  .inactive {
    pointer-events: none;
    cursor: default;
    color: #86b0f4;
    opacity: .6;
    font-size: 1rem
  }

  .active {
    cursor: pointer;
    color: #86b0f4;
    font-size: 1rem
  }

  .popover {
    max-width: 300px
  }

  .beta {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .table-button,
  .table-button-small {
    font-size: 14px!important;
    font-weight: 500!important;
    width: 132px;
    padding: 9px 0 9px 0!important
  }

  .table-button-small {
    width: 50px
  }

  .table .table-row-active,
  .table .table-row:hover {
    background-color: #e6ebf2
  }

  .dark-table .table-row-active,
  .dark-table .table-row:hover {
    background-color: #1d3144
  }

  .collapsed-indicator {
    border-left: 2px solid #0f59d1;
    padding-left: 20px
  }

  .sub-content,
  .sub-content-dark {
    border: 1px solid #e6ebf2;
    border-radius: 8px
  }

  .sub-content-dark .sub-content-header,
  .sub-content .sub-content-header {
    font-size: 14px;
    font-weight: 500;
    color: #6b7c93;
    background-color: #f7f9fc;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #e6ebf2
  }

  .sub-content-dark .sub-content-body,
  .sub-content .sub-content-body {
    font-size: 14px;
    font-weight: 500;
    color: #{$dark-theme}
  }

  .sub-content-dark {
    border: 1px solid #1f3a55!important
  }

  .sub-content-dark .sub-content-header {
    color: #cdd1d6!important;
    background-color: #1f3a55!important;
    border-bottom: 1px solid #1f3a55!important
  }

  .sub-content-dark .sub-content-body {
    color: #cdd1d6!important
  }

  .claim-item {
    min-height: 70px;
    border-bottom: 1px solid #e6ebf2
  }

  .claim-item .time-left {
    min-width: 100px
  }

  .claim-item:last-of-type {
    border-bottom: none
  }

  .no-claim-results {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 25px 10px
  }

  #protected-summary {
    background: #e6ebf2
  }

  #protected-summary-dark {
    background: #{$dark-theme}
  }

  .seperator {
    margin-bottom: 1.1rem;
    border: 1px solid #97a5b8
  }

  #rewards-summary {
    background: #e6ebf2
  }

  #rewards-summary-dark {
    background: #{$dark-theme}
  }

  .clickable-expand {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 10px;
    margin: -10px
  }

  .fade-enter-active,
  .fade-leave-active {
    -webkit-transition: opacity .3s;
    transition: opacity .3s
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0
  }

  .overlap {
    margin-left: -10px
  }

  .remaining-time {
    border-radius: 8px;
    overflow: hidden;
    position: relative
  }

  .remaining-time.remaining-time {
    background: #96b8ef
  }

  .remaining-time .remaining-time__progress {
    background: #0f59d1
  }

  .remaining-time,
  .remaining-time__content,
  .remaining-time__progress {
    height: 24px
  }

  .remaining-time__content,
  .remaining-time__progress {
    position: absolute;
    top: 0;
    left: 0
  }

  .remaining-time__content {
    text-align: center;
    width: 100%;
    color: #fff;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .remaining-time__icon {
    margin-right: 6px
  }

  .remaining-time--warn.remaining-time {
    background: #fadaa6
  }

  .remaining-time--warn .remaining-time__progress {
    background: #f6ab30
  }

  .remaining-time--info.remaining-time {
    background: #88d5d5
  }

  .remaining-time--info .remaining-time__progress {
    background: #3ec8c8
  }

  .remaining-time--done .remaining-time__content,
  .remaining-time--done .remaining-time__progress {
    background: #f7f9fc;
    color: #{$dark-theme}
  }

  .remaining-time--unlock {
    border: 1px solid #d0d7df;
    background: transparent!important;
    font-size: 12px;
    font-weight: 400;
    padding: 8px!important;
    height: auto
  }

  .remaining-time--unlock .remaining-time__progress {
    display: none
  }

  .remaining-time--unlock .remaining-time__content {
    position: relative;
    color: #6b7c93;
    display: inline-block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 2px
  }

  .remaining-time--unlock .remaining-time__progress-text {
    padding-right: 8px;
    font-size: .7rem;
    color: #0f59d1;
    font-weight: 600;
    white-space: nowrap
  }

  @media screen and (max-width:576px) {
    .remaining-time--unlock .remaining-time__progress-text {
      font-size: 1rem
    }
  }

  .remaining-time--unlock .remaining-time__desc {
    padding-top: 4px;
    font-size: .5rem
  }

  @media screen and (min-width:576px) {
    .remaining-time--unlock .remaining-time__desc {
      display: block
    }
  }

  .progress-line-bar {
    border-radius: 0;
    overflow: hidden;
    position: relative;
    background: #e6ebf2
  }

  .progress-line-bar,
  .progress-line-bar__progress {
    height: 4px
  }

  .progress-line-bar__progress {
    background: #0f59d1
  }

  .progress-line-bar--info .progress-line-bar__progress {
    background: #3ec8c8
  }

  .progress-line-bar--warn .progress-line-bar__progress {
    background: #f6ab30
  }

  .progress-line-bar--error .progress-line-bar__progress {
    background: #de4a5c
  }

  .alert-over {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    border-radius: 8px!important;
    border: 1px solid #f6ab30!important;
    color: #f6ab30!important;
    background-color: #fff8ed!important
  }

  .balance {
    min-width: 225px
  }

  .input-currency {
    border-right: 0!important;
    position: relative
  }

  .input-currency__append {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    right: 0
  }

  .input-currency__img {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    border: 1px solid #e6ebf2;
    padding: 4px
  }

  .input-currency__input {
    background-image: none!important
  }

  .input-currency__input::-webkit-inner-spin-button,
  .input-currency__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
  }

  .input-currency__input[type=number] {
    -moz-appearance: textfield
  }

  .button-status--invalid {
    background: #de4a5c!important;
    border-color: transparent!important
  }

  .button-status--empty {
    background: #d0d7df!important;
    border-color: transparent!important
  }

  .balance {
    min-width: 205px;
    padding-left: 5px
  }

  .vote-head {
    font-size: 12px;
    font-weight: 500;
    padding: .75rem
  }

  .vote-head-light {
    background-color: #f7f9fc
  }

  .vote-head-dark {
    background-color: #1f3a55
  }

  .vote-head .colb {
    padding: .1rem .3rem;
    vertical-align: middle!important;
    margin-right: -15px
  }

  .table-body {
    display: table;
    vertical-align: middle
  }

  .table-body .colb {
    display: table-cell;
    font-size: 14px;
    font-weight: 500;
    padding: .75rem .3rem;
    color: #{$dark-theme};
    vertical-align: middle!important;
    margin-right: -15px
  }

  .table-body .divider {
    width: 100%;
    height: 1px
  }

  .table-body .divider-light {
    background: #e6ebf2
  }

  .table-body .divider-dark {
    background: #1f3a55
  }

  .button-progress {
    border-radius: 8px;
    padding: 8px;
    background: #f7f9fc;
    border: 1px solid #e6ebf2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: top;
    -ms-flex-align: top;
    align-items: top
  }

  .button-progress__radio {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    border: 1px solid #e6ebf2;
    margin-right: 8px;
    -webkit-box-shadow: inset 0 0 0 7px #fff, inset 0 0 0 7px #0f59d1;
    box-shadow: inset 0 0 0 7px #fff, inset 0 0 0 7px #0f59d1
  }

  .button-progress__radio--selected {
    border-color: #0f59d1;
    -webkit-box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 7px #0f59d1;
    box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 7px #0f59d1
  }

  .button-progress__content {
    line-height: 16px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
  }

  .modal-body {
    padding-top: 0!important
  }

  .error-icon svg {
    font-size: 84px;
    color: #de4a5c!important
  }

  #vote-details .voted-for {
    color: #3ec8c8
  }

  #vote-details .voted-against {
    color: #de4a5c
  }

  #vote-details .modal-header {
    border-bottom: 1px solid #e6ebf2
  }

  #vote-details .proposal-title-light {
    color: #{$dark-theme}
  }

  #vote-details .modal-body {
    padding-right: 0!important;
    padding-left: 0!important
  }

  #vote-details a {
    color: #0f59d1
  }

  #open-proposals .align-rows-cells td {
    height: 1px;
    padding-top: 24px!important;
    padding-bottom: 24px!important;
    vertical-align: top!important
  }

  #open-proposals tr:last-child,
  #open-proposals tr:last-child>td {
    border-bottom: none!important
  }

  @media (min-width:992px) {
    .container-border {
      border-left: 1px solid #e6ebf2
    }
  }

  .button-vote {
    border-color: transparent!important;
    outline: none!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important
  }

  .button-vote--for {
    background: #3ec8c8!important
  }

  .button-vote--against {
    background: #de4a5c!important
  }

  .square {
    display: inline-block;
    height: 4px;
    width: 4px;
    vertical-align: middle;
    margin-right: 8px
  }

  .square--for {
    background: #3ec8c8!important
  }

  .square--against {
    background: #de4a5c!important
  }

  .mini-pie-wrapper {
    height: 16px;
    width: 16px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
  }

  .voted-box {
    border: 1px solid #e6ebf2;
    background: #f7f9fc;
    padding: 4px 8px;
    border-radius: 8px
  }

  .voted-box__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 24px
  }

  .voted-box__text {
    color: #{$dark-theme}
  }

  .vote-chip {
    border-radius: 8px;
    border: 1px solid currentColor;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 60px;
    height: 24px
  }

  .vote-chip--for {
    color: #3ec8c8
  }

  .vote-chip--for:before {
    content: "For"
  }

  .vote-chip--against {
    color: #de4a5c
  }

  .vote-chip--against:before {
    content: "Against"
  }

  .votes-bar {
    height: 24px;
    border-radius: 8px;
    overflow: hidden;
    background: #de4a5c;
    position: relative;
    color: #fff;
    font-size: .65rem
  }

  .votes-bar__progress {
    background: #3ec8c8
  }

  .votes-bar__content,
  .votes-bar__progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px
  }

  .votes-bar__content {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 8px
  }

  .votes-bar--empty {
    border: 1px solid currentColor;
    background: transparent
  }

  .votes-bar--for {
    color: #3ec8c8
  }

  .votes-bar--against {
    color: #de4a5c
  }

  .btn-breakdown {
    font-size: .75rem;
    font-weight: 500
  }

  .tiny-text {
    font-size: 10px;
    line-height: 12px;
    color: #{$dark-theme}
  }

  .fix-a {
    vertical-align: middle;
    cursor: pointer;
    color: #0f59d1!important
  }

  .table .align-rows-cells>td {
    vertical-align: top!important
  }

  .fix-pie circle,
  .fix-pie text {
    display: none
  }

  .pie-wrapper {
    height: 24px;
    width: 24px;
    background: #de4a5c;
    border-radius: 12px
  }

  .result--for {
    color: #3ec8c8!important
  }

  .result--against {
    color: #de4a5c!important
  }

  .dark-table td.no-border,
  .table td.no-border {
    border-bottom: 0!important
  }

  .buttons-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .combo.combo--title {
    border-bottom-right-radius: 0!important;
    border-bottom-left-radius: 0!important;
    border-bottom: 0!important;
    font-weight: 500!important
  }

  .combo.combo.form-control-alt-light {
    background-color: #f7f9fc!important
  }

  .combo.combo--desc {
    border-top-right-radius: 0!important;
    border-top-left-radius: 0!important;
    border-top: 0!important;
    min-height: 0!important;
    max-height: 999999px!important;
    padding-bottom: 16px
  }

  #proposals .nav-tabs li {
    line-height: 28px;
    padding-bottom: 0!important;
    padding-top: 6px!important
  }

  .proposal-cta-button {
    min-height: 23px;
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
    padding: 1px 13px!important;
    font-size: 13px!important
  }

  .card-provider .txt-available {
    text-transform: uppercase;
    color: #6e7e94;
    line-height: 15px
  }

  .card-provider .fiat {
    width: 70px;
    height: 24px;
    border-radius: 8px;
    background: #d5ffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center
  }

  .card-provider .fiat__in {
    background: #d5ffff
  }

  .card-provider .fiat__out {
    background: #e9f2fd
  }

  .card-provider .check-header {
    text-transform: uppercase;
    line-height: 15px;
    color: #6e7e94
  }

  .card-provider .check-list__item {
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 3px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px
  }

  @media (min-width:992px) {
    .card-provider .container-border {
      border-left: 1px solid #e6ebf2
    }
  }

  #vote-stake {
    min-width: 115px
  }

  #vote-stake .open-icon {
    position: absolute;
    right: 30px
  }

  #vote-stake .block-content {
    padding-bottom: 0!important
  }

  .slide-fade-enter-active {
    -webkit-transition: all .3s ease;
    transition: all .3s ease
  }

  .slide-fade-leave-active {
    -webkit-transition: all .3s cubic-bezier(1, .5, .8, 1);
    transition: all .3s cubic-bezier(1, .5, .8, 1)
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    opacity: 0
  }

  .side-bar {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 100%;
    z-index: 10
  }

  .side-bar .app-icon-wrapper {
    position: absolute;
    width: 230px;
    height: 50px;
    background-color: #f8f9fd
  }

  .side-bar .side-bar-wrapper {
    overflow: auto;
    display: grid;
    grid-template-rows: auto 1fr 30px;
    width: 230px;
    height: 100%;
    background-color: #e6ebf2;
    margin-top: 60px;
    -webkit-transition: width .25s;
    -webkit-transition: width .25s ease-in-out;
    transition: width .25s ease-in-out
  }

  .side-bar .brand-icon {
    margin-top: 10px;
    margin-left: 19px;
    width: 80.9px;
    height: 22px;
    -o-object-fit: contain;
    object-fit: contain
  }

  .side-bar .side-bar-links {
    margin-top: 20px
  }

  .side-bar .side-bar-links .icon-newtab {
    font-size: 13px;
    position: absolute;
    right: 10px;
    top: 15px
  }

  .side-bar .side-bar-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 25px;
    width: 100%;
    height: 40px;
    cursor: pointer;
    position: relative
  }

  .side-bar .side-bar-link span {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6b7c93
  }

  .side-bar .side-bar-link .side-bar-link-icon {
    -ms-flex-item-align: center;
    align-self: center;
    width: 14px;
    margin-right: 12px
  }

  .side-bar .side-bar-link .fade-enter-active {
    -webkit-transition: opacity .35s;
    transition: opacity .35s
  }

  .side-bar .side-bar-link .fade-enter,
  .side-bar .side-bar-link .fade-leave-to {
    opacity: 0
  }

  .side-bar .clicked-link {
    background-color: #f8f9fd;
    border-left: 2px solid #0f59d1
  }

  .side-bar .clicked-link span {
    color: #0f59d1
  }

  .side-bar .clicked-link img {
    -webkit-filter: invert(.6) sepia(1) saturate(5) hue-rotate(195deg) brightness(.7);
    filter: invert(.6) sepia(1) saturate(5) hue-rotate(195deg) brightness(.7);
    color: #0f59d1
  }

  .side-bar .clicked-link:before {
    top: -26px;
    border-bottom-right-radius: 14px;
    -webkit-box-shadow: 0 11px 0 0 #f8f9fd;
    box-shadow: 0 11px 0 0 #f8f9fd
  }

  .side-bar .clicked-link:after,
  .side-bar .clicked-link:before {
    content: "";
    position: absolute;
    left: 202px;
    width: 26px;
    height: 26px;
    background-color: transparent;
    -webkit-transition: left .25s;
    -webkit-transition: left .25s ease-in-out;
    transition: left .25s ease-in-out
  }

  .side-bar .clicked-link:after {
    top: 40px;
    border-top-right-radius: 14px;
    -webkit-box-shadow: 0 -11px 0 0 #f8f9fd;
    box-shadow: 0 -11px 0 0 #f8f9fd
  }

  .side-bar .btn-toggle {
    cursor: pointer
  }

  .side-bar-dark {
    background-color: #{$dark-theme}!important
  }

  .side-bar-dark .app-icon-wrapper {
    background-color: #{$dark-theme}
  }

  .side-bar-dark .side-bar-wrapper {
    background-color: #{$dark-theme}
  }

  .side-bar-light {
    background-color: #e6ebf2
  }

  .side-bar-light .app-icon-wrapper {
    background-color: #e6ebf2
  }

  .clicked-link-dark span {
    color: #fff!important
  }

  .clicked-link-dark img {
    -webkit-filter: invert(.2) saturate(5) brightness(1);
    filter: invert(.2) saturate(5) brightness(1);
    color: #0f59d1
  }

  @media (min-width:450px) {
    .clicked-link-dark {
      background-color: #{$dark-l-theme};
      border-left: 2px solid #0f59d1
    }
    .clicked-link-dark:before {
      top: -26px;
      border-bottom-right-radius: 14px;
      -webkit-box-shadow: 0 11px 0 0 #{$dark-l-theme};
      box-shadow: 0 11px 0 0 #{$dark-l-theme}
    }
    .clicked-link-dark:after,
    .clicked-link-dark:before {
      content: "";
      position: absolute;
      left: 202px;
      width: 26px;
      height: 26px;
      background-color: transparent;
      -webkit-transition: left .25s;
      -webkit-transition: left .25s ease-in-out;
      transition: left .25s ease-in-out
    }
    .clicked-link-dark:after {
      top: 40px;
      border-top-right-radius: 14px;
      -webkit-box-shadow: 0 -11px 0 0 #{$dark-l-theme};
      box-shadow: 0 -11px 0 0 #{$dark-l-theme}
    }
  }

  .bottom-bar {
    position: fixed;
    overflow: hidden;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    background-color: #fff;
    border-top: 1px solid #1f3a55
  }

  .bottom-bar .side-bar-links {
    width: 100%;
    height: 56px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap
  }

  .bottom-bar .side-bar-link {
    padding-left: 0;
    display: inline-block;
    width: 21.2766%;
    height: 100%;
    text-align: center;
    cursor: pointer
  }

  .bottom-bar .side-bar-link div {
    height: 40px;
    font-family: Nunito Sans;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6b7c93;
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 10px
  }

  .bottom-bar .side-bar-link .side-bar-link-icon {
    -ms-flex-item-align: center;
    align-self: center;
    width: 24px;
    height: 24px;
    margin-top: 10px;
    margin-bottom: 2px
  }

  .bottom-bar .clicked-link-dark span {
    color: #fff!important
  }

  .bottom-bar .clicked-link-dark img {
    -webkit-filter: invert(.2) saturate(5) brightness(1);
    filter: invert(.2) saturate(5) brightness(1);
    color: #0f59d1
  }

  .bottom-bar .clicked-link-dark:after,
  .bottom-bar .clicked-link-dark:before {
    content: none
  }

  .bottom-bar .clicked-link span {
    color: #0f59d1!important
  }

  .bottom-bar .clicked-link img {
    -webkit-filter: invert(.6) sepia(1) saturate(5) hue-rotate(195deg) brightness(.7);
    filter: invert(.6) sepia(1) saturate(5) hue-rotate(195deg) brightness(.7);
    color: #0f59d1
  }

  .bottom-bar .btn-more {
    cursor: pointer
  }

  .bottom-bar .btn-more span {
    font-size: 14px
  }

  .bottom-bar .blur-backdoor-left {
    left: 0;
    background: -webkit-gradient(linear, left top, right top, from(#fff), to(hsla(0, 0%, 100%, .4)));
    background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, .4))
  }

  .bottom-bar .blur-backdoor-left,
  .bottom-bar .blur-backdoor-right {
    position: absolute;
    bottom: 0;
    width: 20px;
    height: 100%;
    line-height: 60px;
    -webkit-filter: blur(2px);
    filter: blur(2px)
  }

  .bottom-bar .blur-backdoor-right {
    right: 0;
    background: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, .4)), to(#fff));
    background: linear-gradient(90deg, hsla(0, 0%, 100%, .4), #fff)
  }

  .side-bar-dark .blur-backdoor-left {
    background: -webkit-gradient(linear, left top, right top, from(#{$dark-l-theme}), to(rgba(28, 52, 78, .7)));
    background: linear-gradient(90deg, #{$dark-l-theme}, rgba(28, 52, 78, .7))
  }

  .side-bar-dark .blur-backdoor-right {
    background: -webkit-gradient(linear, left top, right top, from(rgba(28, 52, 78, .7)), to(#{$dark-l-theme}));
    background: linear-gradient(90deg, rgba(28, 52, 78, .7), #{$dark-l-theme})
  }

  .side-bar-link-dark span {
    color: #aaa!important
  }

  .btn-toggle {
    position: absolute;
    top: 60px;
    left: 15px;
    z-index: 999
  }

  h2 {
    padding: 25px
  }

  @media screen and (max-width:768px) {
    .hide-on-mobile {
      display: none!important
    }
  }

  #page-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%
  }

  .loading {
    height: 100%
  }

  .fade-enter-active,
  .fade-leave-active {
    -webkit-transition: opacity .5s;
    transition: opacity .5s
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0
  }

  .main-layout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow: hidden
  }

  .main-container {
    overflow-y: auto;
    overflow-x: hidden!important;
    padding: 12px
  }

  @media screen and (max-width:768px) {
    .main-container {
      margin-bottom: 56px
    }
  }

  *,
  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block
  }

  body {
    margin: 0;
    font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: #575757;
    text-align: left;
    background-color: #f5f5f5
  }

  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0!important
  }

  hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 1.375rem
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem
  }

  abbr[data-original-title],
  abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
  }

  address {
    font-style: normal;
    line-height: inherit
  }

  address,
  dl,
  ol,
  ul {
    margin-bottom: 1rem
  }

  dl,
  ol,
  ul {
    margin-top: 0
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0
  }

  dt {
    font-weight: 600
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 0
  }

  blockquote {
    margin: 0 0 1rem
  }

  b,
  strong {
    font-weight: bolder
  }

  small {
    font-size: 80%
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
  }

  sub {
    bottom: -.25em
  }

  sup {
    top: -.5em
  }

  a {
    color: #0f59d1;
    background-color: transparent
  }

  a,
  a:hover {
    text-decoration: none
  }

  a:hover {
    color: #083072
  }

  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
  }

  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
  }

  figure {
    margin: 0 0 1rem
  }

  img {
    border-style: none
  }

  img,
  svg {
    vertical-align: middle
  }

  svg {
    overflow: hidden
  }

  table {
    border-collapse: collapse
  }

  caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
  }

  th {
    text-align: inherit;
    text-align: -webkit-match-parent
  }

  label {
    display: inline-block;
    margin-bottom: .375rem
  }

  button {
    border-radius: 0
  }

  button:focus:not(:focus-visible) {
    outline: 0
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
  }

  button,
  input {
    overflow: visible
  }

  button,
  select {
    text-transform: none
  }

  [role=button] {
    cursor: pointer
  }

  select {
    word-wrap: normal
  }

  [type=button],
  [type=reset],
  [type=submit],
  button {
    -webkit-appearance: button
  }

  [type=button]:not(:disabled),
  [type=reset]:not(:disabled),
  [type=submit]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer
  }

  [type=button]::-moz-focus-inner,
  [type=reset]::-moz-focus-inner,
  [type=submit]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border-style: none
  }

  input[type=checkbox],
  input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
  }

  textarea {
    overflow: auto;
    resize: vertical
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
  }

  progress {
    vertical-align: baseline
  }

  [type=number]::-webkit-inner-spin-button,
  [type=number]::-webkit-outer-spin-button {
    height: auto
  }

  [type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
  }

  [type=search]::-webkit-search-decoration {
    -webkit-appearance: none
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
  }

  output {
    display: inline-block
  }

  summary {
    display: list-item;
    cursor: pointer
  }

  template {
    display: none
  }

  [hidden] {
    display: none!important
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.375rem;
    font-family: Nunito Sans, Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: 800;
    line-height: 1.25;
    color: #373737
  }

  .h1,
  h1 {
    font-size: 2.25rem
  }

  .h2,
  h2 {
    font-size: 1.875rem
  }

  .h3,
  h3 {
    font-size: 1.5rem
  }

  .h4,
  h4 {
    font-size: 1.25rem
  }

  .h5,
  h5 {
    font-size: 1.125rem
  }

  .h6,
  h6 {
    font-size: 1rem
  }

  .lead {
    font-size: 1.5rem;
    font-weight: 300
  }

  .display-1 {
    font-size: 6rem
  }

  .display-1,
  .display-2 {
    font-weight: 300;
    line-height: 1.25
  }

  .display-2 {
    font-size: 5.5rem
  }

  .display-3 {
    font-size: 4.5rem
  }

  .display-3,
  .display-4 {
    font-weight: 300;
    line-height: 1.25
  }

  .display-4 {
    font-size: 3.5rem
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #ebebeb
  }

  .small,
  small {
    font-size: 80%;
    font-weight: 400
  }

  .mark,
  mark {
    padding: .2em;
    background-color: #fcf8e3
  }

  .list-inline,
  .list-unstyled {
    padding-left: 0;
    list-style: none
  }

  .list-inline-item {
    display: inline-block
  }

  .list-inline-item:not(:last-child) {
    margin-right: .5rem
  }

  .initialism {
    font-size: 90%;
    text-transform: uppercase
  }

  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
  }

  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d
  }

  .blockquote-footer:before {
    content: "\2014\00A0"
  }

  .img-fluid,
  .img-thumbnail {
    max-width: 100%;
    height: auto
  }

  .img-thumbnail {
    padding: .25rem;
    background-color: #f5f5f5;
    border: 1px solid #dee2e6;
    border-radius: 8px
  }

  .figure {
    display: inline-block
  }

  .figure-img {
    margin-bottom: .5rem;
    line-height: 1
  }

  .figure-caption {
    font-size: 90%;
    color: #6c757d
  }

  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word
  }

  a>code {
    color: inherit
  }

  kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
  }

  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600
  }

  pre {
    display: block;
    font-size: 87.5%;
    color: #212529
  }

  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
  }

  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
    padding-right: 14px;
    padding-left: 14px;
    margin-right: auto;
    margin-left: auto
  }

  @media (min-width:576px) {
    .container,
    .container-sm {
      max-width: 540px
    }
  }

  @media (min-width:768px) {
    .container,
    .container-md,
    .container-sm {
      max-width: 720px
    }
  }

  @media (min-width:992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px
    }
  }

  @media (min-width:1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1140px
    }
  }

  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -14px;
    margin-left: -14px
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0
  }

  .no-gutters>.col,
  .no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
  }

  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 14px;
    padding-left: 14px
  }

  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
  }

  .row-cols-1>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-2>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-3>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .row-cols-4>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-5>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-6>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-1,
  .col-auto {
    -webkit-box-flex: 0
  }

  .col-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-2,
  .col-3 {
    -webkit-box-flex: 0
  }

  .col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-4,
  .col-5 {
    -webkit-box-flex: 0
  }

  .col-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-6,
  .col-7 {
    -webkit-box-flex: 0
  }

  .col-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-8,
  .col-9 {
    -webkit-box-flex: 0
  }

  .col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-10,
  .col-11 {
    -webkit-box-flex: 0
  }

  .col-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
  }

  .order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13
  }

  .order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0
  }

  .order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
  }

  .order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
  }

  .order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
  }

  .order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4
  }

  .order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5
  }

  .order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6
  }

  .order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7
  }

  .order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8
  }

  .order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9
  }

  .order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10
  }

  .order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11
  }

  .order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12
  }

  .offset-1 {
    margin-left: 8.33333%
  }

  .offset-2 {
    margin-left: 16.66667%
  }

  .offset-3 {
    margin-left: 25%
  }

  .offset-4 {
    margin-left: 33.33333%
  }

  .offset-5 {
    margin-left: 41.66667%
  }

  .offset-6 {
    margin-left: 50%
  }

  .offset-7 {
    margin-left: 58.33333%
  }

  .offset-8 {
    margin-left: 66.66667%
  }

  .offset-9 {
    margin-left: 75%
  }

  .offset-10 {
    margin-left: 83.33333%
  }

  .offset-11 {
    margin-left: 91.66667%
  }

  @media (min-width:576px) {
    .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
    }
    .row-cols-sm-1>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
    .row-cols-sm-2>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
    .row-cols-sm-3>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%
    }
    .row-cols-sm-4>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
    .row-cols-sm-5>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%
    }
    .row-cols-sm-6>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%
    }
    .col-sm-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%
    }
    .col-sm-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%
    }
    .col-sm-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%
    }
    .col-sm-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
    .col-sm-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%
    }
    .col-sm-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%
    }
    .col-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
    .col-sm-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%
    }
    .col-sm-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%
    }
    .col-sm-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }
    .col-sm-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%
    }
    .col-sm-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%
    }
    .col-sm-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
    .order-sm-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1
    }
    .order-sm-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13
    }
    .order-sm-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0
    }
    .order-sm-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1
    }
    .order-sm-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2
    }
    .order-sm-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3
    }
    .order-sm-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4
    }
    .order-sm-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5
    }
    .order-sm-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6
    }
    .order-sm-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7
    }
    .order-sm-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8
    }
    .order-sm-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9
    }
    .order-sm-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10
    }
    .order-sm-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11
    }
    .order-sm-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12
    }
    .offset-sm-0 {
      margin-left: 0
    }
    .offset-sm-1 {
      margin-left: 8.33333%
    }
    .offset-sm-2 {
      margin-left: 16.66667%
    }
    .offset-sm-3 {
      margin-left: 25%
    }
    .offset-sm-4 {
      margin-left: 33.33333%
    }
    .offset-sm-5 {
      margin-left: 41.66667%
    }
    .offset-sm-6 {
      margin-left: 50%
    }
    .offset-sm-7 {
      margin-left: 58.33333%
    }
    .offset-sm-8 {
      margin-left: 66.66667%
    }
    .offset-sm-9 {
      margin-left: 75%
    }
    .offset-sm-10 {
      margin-left: 83.33333%
    }
    .offset-sm-11 {
      margin-left: 91.66667%
    }
  }

  @media (min-width:768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
    }
    .row-cols-md-1>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
    .row-cols-md-2>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
    .row-cols-md-3>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%
    }
    .row-cols-md-4>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
    .row-cols-md-5>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%
    }
    .row-cols-md-6>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%
    }
    .col-md-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%
    }
    .col-md-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%
    }
    .col-md-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%
    }
    .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%
    }
    .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%
    }
    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
    .col-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%
    }
    .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%
    }
    .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }
    .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%
    }
    .col-md-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%
    }
    .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
    .order-md-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1
    }
    .order-md-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13
    }
    .order-md-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0
    }
    .order-md-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1
    }
    .order-md-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2
    }
    .order-md-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3
    }
    .order-md-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4
    }
    .order-md-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5
    }
    .order-md-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6
    }
    .order-md-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7
    }
    .order-md-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8
    }
    .order-md-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9
    }
    .order-md-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10
    }
    .order-md-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11
    }
    .order-md-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12
    }
    .offset-md-0 {
      margin-left: 0
    }
    .offset-md-1 {
      margin-left: 8.33333%
    }
    .offset-md-2 {
      margin-left: 16.66667%
    }
    .offset-md-3 {
      margin-left: 25%
    }
    .offset-md-4 {
      margin-left: 33.33333%
    }
    .offset-md-5 {
      margin-left: 41.66667%
    }
    .offset-md-6 {
      margin-left: 50%
    }
    .offset-md-7 {
      margin-left: 58.33333%
    }
    .offset-md-8 {
      margin-left: 66.66667%
    }
    .offset-md-9 {
      margin-left: 75%
    }
    .offset-md-10 {
      margin-left: 83.33333%
    }
    .offset-md-11 {
      margin-left: 91.66667%
    }
  }

  @media (min-width:992px) {
    .col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
    }
    .row-cols-lg-1>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
    .row-cols-lg-2>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
    .row-cols-lg-3>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%
    }
    .row-cols-lg-4>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
    .row-cols-lg-5>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%
    }
    .row-cols-lg-6>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%
    }
    .col-lg-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%
    }
    .col-lg-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%
    }
    .col-lg-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%
    }
    .col-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
    .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%
    }
    .col-lg-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%
    }
    .col-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
    .col-lg-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%
    }
    .col-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%
    }
    .col-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }
    .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%
    }
    .col-lg-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%
    }
    .col-lg-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
    .order-lg-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1
    }
    .order-lg-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13
    }
    .order-lg-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0
    }
    .order-lg-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1
    }
    .order-lg-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2
    }
    .order-lg-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3
    }
    .order-lg-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4
    }
    .order-lg-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5
    }
    .order-lg-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6
    }
    .order-lg-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7
    }
    .order-lg-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8
    }
    .order-lg-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9
    }
    .order-lg-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10
    }
    .order-lg-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11
    }
    .order-lg-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12
    }
    .offset-lg-0 {
      margin-left: 0
    }
    .offset-lg-1 {
      margin-left: 8.33333%
    }
    .offset-lg-2 {
      margin-left: 16.66667%
    }
    .offset-lg-3 {
      margin-left: 25%
    }
    .offset-lg-4 {
      margin-left: 33.33333%
    }
    .offset-lg-5 {
      margin-left: 41.66667%
    }
    .offset-lg-6 {
      margin-left: 50%
    }
    .offset-lg-7 {
      margin-left: 58.33333%
    }
    .offset-lg-8 {
      margin-left: 66.66667%
    }
    .offset-lg-9 {
      margin-left: 75%
    }
    .offset-lg-10 {
      margin-left: 83.33333%
    }
    .offset-lg-11 {
      margin-left: 91.66667%
    }
  }

  @media (min-width:1200px) {
    .col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
    }
    .row-cols-xl-1>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
    .row-cols-xl-2>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
    .row-cols-xl-3>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%
    }
    .row-cols-xl-4>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
    .row-cols-xl-5>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%
    }
    .row-cols-xl-6>* {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%
    }
    .col-xl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%
    }
    .col-xl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%
    }
    .col-xl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%
    }
    .col-xl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }
    .col-xl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%
    }
    .col-xl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%
    }
    .col-xl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }
    .col-xl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%
    }
    .col-xl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%
    }
    .col-xl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }
    .col-xl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%
    }
    .col-xl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%
    }
    .col-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }
    .order-xl-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1
    }
    .order-xl-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13
    }
    .order-xl-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0
    }
    .order-xl-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1
    }
    .order-xl-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2
    }
    .order-xl-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3
    }
    .order-xl-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4
    }
    .order-xl-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5
    }
    .order-xl-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6
    }
    .order-xl-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7
    }
    .order-xl-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8
    }
    .order-xl-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9
    }
    .order-xl-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10
    }
    .order-xl-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11
    }
    .order-xl-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12
    }
    .offset-xl-0 {
      margin-left: 0
    }
    .offset-xl-1 {
      margin-left: 8.33333%
    }
    .offset-xl-2 {
      margin-left: 16.66667%
    }
    .offset-xl-3 {
      margin-left: 25%
    }
    .offset-xl-4 {
      margin-left: 33.33333%
    }
    .offset-xl-5 {
      margin-left: 41.66667%
    }
    .offset-xl-6 {
      margin-left: 50%
    }
    .offset-xl-7 {
      margin-left: 58.33333%
    }
    .offset-xl-8 {
      margin-left: 66.66667%
    }
    .offset-xl-9 {
      margin-left: 75%
    }
    .offset-xl-10 {
      margin-left: 83.33333%
    }
    .offset-xl-11 {
      margin-left: 91.66667%
    }
  }

  .dark-table,
  .table,
  .table-dark {
    width: 100%;
    margin-bottom: 1rem;
    color: #575757;
    background-color: transparent
  }

  .dark-table td,
  .dark-table th,
  .table-dark td,
  .table-dark th,
  .table td,
  .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #ebebeb
  }

  .dark-table thead th,
  .table-dark thead th,
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ebebeb
  }

  .dark-table tbody+tbody,
  .table-dark tbody+tbody,
  .table tbody+tbody {
    border-top: 2px solid #ebebeb
  }

  .table-sm td,
  .table-sm th {
    padding: .25rem
  }

  .table-bordered,
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ebebeb
  }

  .table-bordered thead td,
  .table-bordered thead th {
    border-bottom-width: 2px
  }

  .table-borderless tbody+tbody,
  .table-borderless td,
  .table-borderless th,
  .table-borderless thead th {
    border: 0
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9
  }

  .table-hover tbody tr:hover {
    color: #575757;
    background-color: #f1f1f1
  }

  .table-primary,
  .table-primary>td,
  .table-primary>th {
    background-color: #bcd1f2
  }

  .table-primary tbody+tbody,
  .table-primary td,
  .table-primary th,
  .table-primary thead th {
    border-color: #82a9e7
  }

  .table-hover .table-primary:hover,
  .table-hover .table-primary:hover>td,
  .table-hover .table-primary:hover>th {
    background-color: #a7c2ee
  }

  .table-secondary,
  .table-secondary>td,
  .table-secondary>th {
    background-color: #d6dae1
  }

  .table-secondary tbody+tbody,
  .table-secondary td,
  .table-secondary th,
  .table-secondary thead th {
    border-color: #b2bbc7
  }

  .table-hover .table-secondary:hover,
  .table-hover .table-secondary:hover>td,
  .table-hover .table-secondary:hover>th {
    background-color: #c7cdd6
  }

  .table-success,
  .table-success>td,
  .table-success>th {
    background-color: #cbeeda
  }

  .table-success tbody+tbody,
  .table-success td,
  .table-success th,
  .table-success thead th {
    border-color: #9fe0ba
  }

  .table-hover .table-success:hover,
  .table-hover .table-success:hover>td,
  .table-hover .table-success:hover>th {
    background-color: #b8e8cc
  }

  .table-info,
  .table-info>td,
  .table-info>th {
    background-color: #d7ebf9
  }

  .table-info tbody+tbody,
  .table-info td,
  .table-info th,
  .table-info thead th {
    border-color: #b5dbf5
  }

  .table-hover .table-info:hover,
  .table-hover .table-info:hover>td,
  .table-hover .table-info:hover>th {
    background-color: #c1e0f6
  }

  .table-warning,
  .table-warning>td,
  .table-warning>th {
    background-color: #fcebd2
  }

  .table-warning tbody+tbody,
  .table-warning td,
  .table-warning th,
  .table-warning thead th {
    border-color: #f9daac
  }

  .table-hover .table-warning:hover,
  .table-hover .table-warning:hover>td,
  .table-hover .table-warning:hover>th {
    background-color: #fae0ba
  }

  .table-danger,
  .table-danger>td,
  .table-danger>th {
    background-color: #f2d5d1
  }

  .table-danger tbody+tbody,
  .table-danger td,
  .table-danger th,
  .table-danger thead th {
    border-color: #e8b2aa
  }

  .table-hover .table-danger:hover,
  .table-hover .table-danger:hover>td,
  .table-hover .table-danger:hover>th {
    background-color: #ecc3bd
  }

  .table-light,
  .table-light>td,
  .table-light>th {
    background-color: #fafafa
  }

  .table-light tbody+tbody,
  .table-light td,
  .table-light th,
  .table-light thead th {
    border-color: #f6f6f6
  }

  .table-hover .table-light:hover,
  .table-hover .table-light:hover>td,
  .table-hover .table-light:hover>th {
    background-color: #ededed
  }

  .table-dark,
  .table-dark>td,
  .table-dark>th {
    background-color: #bfc6cd
  }

  .table-dark tbody+tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #8995a3
  }

  .table-hover .table-dark:hover,
  .table-hover .table-dark:hover>td,
  .table-hover .table-dark:hover>th {
    background-color: #b1b9c2
  }

  .table-white,
  .table-white>td,
  .table-white>th {
    background-color: #fff
  }

  .table-white tbody+tbody,
  .table-white td,
  .table-white th,
  .table-white thead th {
    border-color: #fff
  }

  .table-hover .table-white:hover,
  .table-hover .table-white:hover>td,
  .table-hover .table-white:hover>th {
    background-color: #f2f2f2
  }

  .table-gray,
  .table-gray>td,
  .table-gray>th {
    background-color: #f2f4f6
  }

  .table-gray tbody+tbody,
  .table-gray td,
  .table-gray th,
  .table-gray thead th {
    border-color: #e7eaee
  }

  .table-hover .table-gray:hover,
  .table-hover .table-gray:hover>td,
  .table-hover .table-gray:hover>th {
    background-color: #e3e7ec
  }

  .table-gray-dark,
  .table-gray-dark>td,
  .table-gray-dark>th {
    background-color: #f2f4f6
  }

  .table-gray-dark tbody+tbody,
  .table-gray-dark td,
  .table-gray-dark th,
  .table-gray-dark thead th {
    border-color: #e7eaee
  }

  .table-hover .table-gray-dark:hover,
  .table-hover .table-gray-dark:hover>td,
  .table-hover .table-gray-dark:hover>th {
    background-color: #e3e7ec
  }

  .table-active,
  .table-active>td,
  .table-active>th {
    background-color: #f1f1f1
  }

  .table-hover .table-active:hover,
  .table-hover .table-active:hover>td,
  .table-hover .table-active:hover>th {
    background-color: #e4e4e4
  }

  .dark-table .thead-dark th,
  .table-dark .thead-dark th,
  .table .thead-dark th {
    color: #fff;
    background-color: #{$dark-l-theme};
    border-color: #{$dark-l-theme}
  }

  .dark-table .thead-light th,
  .table-dark .thead-light th,
  .table .thead-light th {
    color: #575757;
    background-color: #ebebeb;
    border-color: #ebebeb
  }

  .table-dark {
    color: #fff;
    background-color: #{$dark-l-theme}
  }

  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #{$dark-l-theme}
  }

  .table-dark.table-bordered {
    border: 0
  }

  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: hsla(0, 0%, 100%, .05)
  }

  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: hsla(0, 0%, 100%, .075)
  }

  @media (max-width:575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch
    }
    .table-responsive-sm>.table-bordered {
      border: 0
    }
  }

  @media (max-width:767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch
    }
    .table-responsive-md>.table-bordered {
      border: 0
    }
  }

  @media (max-width:991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch
    }
    .table-responsive-lg>.table-bordered {
      border: 0
    }
  }

  @media (max-width:1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch
    }
    .table-responsive-xl>.table-bordered {
      border: 0
    }
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive>.table-bordered {
    border: 0
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #575757;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion:reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none
    }
  }

  .form-control::-ms-expand {
    background-color: transparent;
    border: 0
  }

  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #575757
  }

  .form-control:focus {
    color: #575757;
    background-color: #fff;
    border-color: #6b9ff4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
  }

  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
  }

  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
  }

  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
  }

  .form-control::placeholder {
    color: #6c757d;
    opacity: 1
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
  }

  input[type=date].form-control,
  input[type=datetime-local].form-control,
  input[type=month].form-control,
  input[type=time].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
  }

  select.form-control:focus::-ms-value {
    color: #575757;
    background-color: #fff
  }

  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%
  }

  .col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
  }

  .col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1rem;
    line-height: 1.5
  }

  .col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem;
    line-height: 1.5
  }

  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #575757;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
  }

  .form-control-plaintext.form-control-lg,
  .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
  }

  .form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
  }

  .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .3rem
  }

  select.form-control[multiple],
  select.form-control[size],
  textarea.form-control {
    height: auto
  }

  .form-group {
    margin-bottom: 1rem
  }

  .form-text {
    display: block;
    margin-top: .5rem
  }

  .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
  }

  .form-row>.col,
  .form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
  }

  .form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
  }

  .form-check-input:disabled~.form-check-label,
  .form-check-input[disabled]~.form-check-label {
    color: #6c757d
  }

  .form-check-label {
    margin-bottom: 0
  }

  .form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
  }

  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
  }

  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .5rem;
    font-size: .875rem;
    color: #46c37b
  }

  .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .75rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(70, 195, 123, .9);
    border-radius: 0
  }

  .form-row>.col>.valid-tooltip,
  .form-row>[class*=col-]>.valid-tooltip {
    left: 5px
  }

  .is-valid~.valid-feedback,
  .is-valid~.valid-tooltip,
  .was-validated :valid~.valid-feedback,
  .was-validated :valid~.valid-tooltip {
    display: block
  }

  .form-control.is-valid,
  .was-validated .form-control:valid {
    border-color: #46c37b
  }

  .form-control.is-valid:focus,
  .was-validated .form-control:valid:focus {
    border-color: #46c37b;
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25)
  }

  .custom-select.is-valid,
  .was-validated .custom-select:valid {
    border-color: #46c37b
  }

  .custom-select.is-valid:focus,
  .was-validated .custom-select:valid:focus {
    border-color: #46c37b;
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25)
  }

  .form-check-input.is-valid~.form-check-label,
  .was-validated .form-check-input:valid~.form-check-label {
    color: #46c37b
  }

  .form-check-input.is-valid~.valid-feedback,
  .form-check-input.is-valid~.valid-tooltip,
  .was-validated .form-check-input:valid~.valid-feedback,
  .was-validated .form-check-input:valid~.valid-tooltip {
    display: block
  }

  .custom-control-input.is-valid~.custom-control-label,
  .was-validated .custom-control-input:valid~.custom-control-label {
    color: #46c37b
  }

  .custom-control-input.is-valid~.custom-control-label:before,
  .was-validated .custom-control-input:valid~.custom-control-label:before {
    border-color: #46c37b
  }

  .custom-control-input.is-valid:checked~.custom-control-label:before,
  .was-validated .custom-control-input:valid:checked~.custom-control-label:before {
    border-color: #6dcf96;
    background-color: #6dcf96
  }

  .custom-control-input.is-valid:focus~.custom-control-label:before,
  .was-validated .custom-control-input:valid:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25)
  }

  .custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
  .custom-file-input.is-valid~.custom-file-label,
  .was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before,
  .was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #46c37b
  }

  .custom-file-input.is-valid:focus~.custom-file-label,
  .was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #46c37b;
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25)
  }

  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .5rem;
    font-size: .875rem;
    color: #d26a5c
  }

  .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .75rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(210, 106, 92, .9);
    border-radius: 0
  }

  .form-row>.col>.invalid-tooltip,
  .form-row>[class*=col-]>.invalid-tooltip {
    left: 5px
  }

  .is-invalid~.invalid-feedback,
  .is-invalid~.invalid-tooltip,
  .was-validated :invalid~.invalid-feedback,
  .was-validated :invalid~.invalid-tooltip {
    display: block
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #d26a5c
  }

  .form-control.is-invalid:focus,
  .was-validated .form-control:invalid:focus {
    border-color: #d26a5c;
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25)
  }

  .custom-select.is-invalid,
  .was-validated .custom-select:invalid {
    border-color: #d26a5c
  }

  .custom-select.is-invalid:focus,
  .was-validated .custom-select:invalid:focus {
    border-color: #d26a5c;
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25)
  }

  .form-check-input.is-invalid~.form-check-label,
  .was-validated .form-check-input:invalid~.form-check-label {
    color: #d26a5c
  }

  .form-check-input.is-invalid~.invalid-feedback,
  .form-check-input.is-invalid~.invalid-tooltip,
  .was-validated .form-check-input:invalid~.invalid-feedback,
  .was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
  }

  .custom-control-input.is-invalid~.custom-control-label,
  .was-validated .custom-control-input:invalid~.custom-control-label {
    color: #d26a5c
  }

  .custom-control-input.is-invalid~.custom-control-label:before,
  .was-validated .custom-control-input:invalid~.custom-control-label:before {
    border-color: #d26a5c
  }

  .custom-control-input.is-invalid:checked~.custom-control-label:before,
  .was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
    border-color: #dd8f84;
    background-color: #dd8f84
  }

  .custom-control-input.is-invalid:focus~.custom-control-label:before,
  .was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25)
  }

  .custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
  .custom-file-input.is-invalid~.custom-file-label,
  .was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before,
  .was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #d26a5c
  }

  .custom-file-input.is-invalid:focus~.custom-file-label,
  .was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #d26a5c;
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25)
  }

  .form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .form-inline .form-check {
    width: 100%
  }

  @media (min-width:576px) {
    .form-inline label {
      -ms-flex-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
    }
    .form-inline .form-group,
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin-bottom: 0
    }
    .form-inline .form-group {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-align: center
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle
    }
    .form-inline .form-control-plaintext {
      display: inline-block
    }
    .form-inline .custom-select,
    .form-inline .input-group {
      width: auto
    }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0
    }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: .25rem;
      margin-left: 0
    }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
    }
    .form-inline .custom-control-label {
      margin-bottom: 0
    }
  }

  .btn {
    display: inline-block;
    font-weight: 600;
    color: #575757;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 8px;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion:reduce) {
    .btn {
      -webkit-transition: none;
      transition: none
    }
  }

  .btn:hover {
    color: #575757;
    text-decoration: none
  }

  .btn.focus,
  .btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .btn.disabled,
  .btn:disabled {
    opacity: .65
  }

  .btn:not(:disabled):not(.disabled) {
    cursor: pointer
  }

  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none
  }

  .btn-primary {
    color: #fff;
    background-color: #0f59d1;
    border-color: #0f59d1
  }

  .btn-primary.focus,
  .btn-primary:focus,
  .btn-primary:hover {
    color: #fff;
    background-color: #0c4aad;
    border-color: #0c45a1
  }

  .btn-primary.focus,
  .btn-primary:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(51, 114, 216, .5);
    box-shadow: 0 0 0 .2rem rgba(51, 114, 216, .5)
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #0f59d1;
    border-color: #0f59d1
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0c45a1;
    border-color: #0b4096
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show>.btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(51, 114, 216, .5);
    box-shadow: 0 0 0 .2rem rgba(51, 114, 216, .5)
  }

  .btn-secondary {
    color: #fff;
    background-color: #6b7c93;
    border-color: #6b7c93
  }

  .btn-secondary.focus,
  .btn-secondary:focus,
  .btn-secondary:hover {
    color: #fff;
    background-color: #5b697d;
    border-color: #566375
  }

  .btn-secondary.focus,
  .btn-secondary:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(129, 144, 163, .5);
    box-shadow: 0 0 0 .2rem rgba(129, 144, 163, .5)
  }

  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: #fff;
    background-color: #6b7c93;
    border-color: #6b7c93
  }

  .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #566375;
    border-color: #505d6e
  }

  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .show>.btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(129, 144, 163, .5);
    box-shadow: 0 0 0 .2rem rgba(129, 144, 163, .5)
  }

  .btn-success {
    color: #fff;
    background-color: #46c37b;
    border-color: #46c37b
  }

  .btn-success.focus,
  .btn-success:focus,
  .btn-success:hover {
    color: #fff;
    background-color: #38ab69;
    border-color: #34a263
  }

  .btn-success.focus,
  .btn-success:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(98, 204, 143, .5);
    box-shadow: 0 0 0 .2rem rgba(98, 204, 143, .5)
  }

  .btn-success.disabled,
  .btn-success:disabled {
    color: #fff;
    background-color: #46c37b;
    border-color: #46c37b
  }

  .btn-success:not(:disabled):not(.disabled).active,
  .btn-success:not(:disabled):not(.disabled):active,
  .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #34a263;
    border-color: #31985d
  }

  .btn-success:not(:disabled):not(.disabled).active:focus,
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .show>.btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(98, 204, 143, .5);
    box-shadow: 0 0 0 .2rem rgba(98, 204, 143, .5)
  }

  .btn-info {
    color: #fff;
    background-color: #70b9eb;
    border-color: #70b9eb
  }

  .btn-info.focus,
  .btn-info:focus,
  .btn-info:hover {
    color: #fff;
    background-color: #4ea9e6;
    border-color: #43a3e5
  }

  .btn-info.focus,
  .btn-info:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(133, 196, 238, .5);
    box-shadow: 0 0 0 .2rem rgba(133, 196, 238, .5)
  }

  .btn-info.disabled,
  .btn-info:disabled {
    color: #fff;
    background-color: #70b9eb;
    border-color: #70b9eb
  }

  .btn-info:not(:disabled):not(.disabled).active,
  .btn-info:not(:disabled):not(.disabled):active,
  .show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #43a3e5;
    border-color: #389ee3
  }

  .btn-info:not(:disabled):not(.disabled).active:focus,
  .btn-info:not(:disabled):not(.disabled):active:focus,
  .show>.btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(133, 196, 238, .5);
    box-shadow: 0 0 0 .2rem rgba(133, 196, 238, .5)
  }

  .btn-warning {
    color: #fff;
    background-color: #f3b760;
    border-color: #f3b760
  }

  .btn-warning.focus,
  .btn-warning:focus,
  .btn-warning:hover {
    color: #fff;
    background-color: #f0a73c;
    border-color: #efa231
  }

  .btn-warning.focus,
  .btn-warning:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(245, 194, 120, .5);
    box-shadow: 0 0 0 .2rem rgba(245, 194, 120, .5)
  }

  .btn-warning.disabled,
  .btn-warning:disabled {
    color: #fff;
    background-color: #f3b760;
    border-color: #f3b760
  }

  .btn-warning:not(:disabled):not(.disabled).active,
  .btn-warning:not(:disabled):not(.disabled):active,
  .show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #efa231;
    border-color: #ef9c25
  }

  .btn-warning:not(:disabled):not(.disabled).active:focus,
  .btn-warning:not(:disabled):not(.disabled):active:focus,
  .show>.btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(245, 194, 120, .5);
    box-shadow: 0 0 0 .2rem rgba(245, 194, 120, .5)
  }

  .btn-danger {
    color: #fff;
    background-color: #d26a5c;
    border-color: #d26a5c
  }

  .btn-danger.focus,
  .btn-danger:focus,
  .btn-danger:hover {
    color: #fff;
    background-color: #ca4f3e;
    border-color: #c54736
  }

  .btn-danger.focus,
  .btn-danger:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(217, 128, 116, .5);
    box-shadow: 0 0 0 .2rem rgba(217, 128, 116, .5)
  }

  .btn-danger.disabled,
  .btn-danger:disabled {
    color: #fff;
    background-color: #d26a5c;
    border-color: #d26a5c
  }

  .btn-danger:not(:disabled):not(.disabled).active,
  .btn-danger:not(:disabled):not(.disabled):active,
  .show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c54736;
    border-color: #bb4434
  }

  .btn-danger:not(:disabled):not(.disabled).active:focus,
  .btn-danger:not(:disabled):not(.disabled):active:focus,
  .show>.btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(217, 128, 116, .5);
    box-shadow: 0 0 0 .2rem rgba(217, 128, 116, .5)
  }

  .btn-light {
    color: #212529;
    background-color: #eee;
    border-color: #eee
  }

  .btn-light.focus,
  .btn-light:focus,
  .btn-light:hover {
    color: #212529;
    background-color: #dbdbdb;
    border-color: #d5d5d5
  }

  .btn-light.focus,
  .btn-light:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(207, 208, 208, .5);
    box-shadow: 0 0 0 .2rem rgba(207, 208, 208, .5)
  }

  .btn-light.disabled,
  .btn-light:disabled {
    color: #212529;
    background-color: #eee;
    border-color: #eee
  }

  .btn-light:not(:disabled):not(.disabled).active,
  .btn-light:not(:disabled):not(.disabled):active,
  .show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d5d5d5;
    border-color: #cecece
  }

  .btn-light:not(:disabled):not(.disabled).active:focus,
  .btn-light:not(:disabled):not(.disabled):active:focus,
  .show>.btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(207, 208, 208, .5);
    box-shadow: 0 0 0 .2rem rgba(207, 208, 208, .5)
  }

  .btn-dark {
    color: #fff;
    background-color: #{$dark-l-theme};
    border-color: #{$dark-l-theme}
  }

  .btn-dark.focus,
  .btn-dark:focus,
  .btn-dark:hover {
    color: #fff;
    background-color: #122132;
    border-color: #0f1b28
  }

  .btn-dark.focus,
  .btn-dark:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(62, 82, 105, .5);
    box-shadow: 0 0 0 .2rem rgba(62, 82, 105, .5)
  }

  .btn-dark.disabled,
  .btn-dark:disabled {
    color: #fff;
    background-color: #{$dark-l-theme};
    border-color: #{$dark-l-theme}
  }

  .btn-dark:not(:disabled):not(.disabled).active,
  .btn-dark:not(:disabled):not(.disabled):active,
  .show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0f1b28;
    border-color: #0b151f
  }

  .btn-dark:not(:disabled):not(.disabled).active:focus,
  .btn-dark:not(:disabled):not(.disabled):active:focus,
  .show>.btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(62, 82, 105, .5);
    box-shadow: 0 0 0 .2rem rgba(62, 82, 105, .5)
  }

  .btn-white {
    color: #212529;
    background-color: #fff;
    border-color: #fff
  }

  .btn-white.focus,
  .btn-white:focus,
  .btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6
  }

  .btn-white.focus,
  .btn-white:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5)
  }

  .btn-white.disabled,
  .btn-white:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff
  }

  .btn-white:not(:disabled):not(.disabled).active,
  .btn-white:not(:disabled):not(.disabled):active,
  .show>.btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf
  }

  .btn-white:not(:disabled):not(.disabled).active:focus,
  .btn-white:not(:disabled):not(.disabled):active:focus,
  .show>.btn-white.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5)
  }

  .btn-gray {
    color: #212529;
    background-color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-gray.focus,
  .btn-gray:focus,
  .btn-gray:hover {
    color: #fff;
    background-color: #b9c4d0;
    border-color: #b2bdca
  }

  .btn-gray.focus,
  .btn-gray:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(182, 188, 196, .5);
    box-shadow: 0 0 0 .2rem rgba(182, 188, 196, .5)
  }

  .btn-gray.disabled,
  .btn-gray:disabled {
    color: #212529;
    background-color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-gray:not(:disabled):not(.disabled).active,
  .btn-gray:not(:disabled):not(.disabled):active,
  .show>.btn-gray.dropdown-toggle {
    color: #fff;
    background-color: #b2bdca;
    border-color: #aab7c5
  }

  .btn-gray:not(:disabled):not(.disabled).active:focus,
  .btn-gray:not(:disabled):not(.disabled):active:focus,
  .show>.btn-gray.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(182, 188, 196, .5);
    box-shadow: 0 0 0 .2rem rgba(182, 188, 196, .5)
  }

  .btn-gray-dark {
    color: #212529;
    background-color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-gray-dark.focus,
  .btn-gray-dark:focus,
  .btn-gray-dark:hover {
    color: #fff;
    background-color: #b9c4d0;
    border-color: #b2bdca
  }

  .btn-gray-dark.focus,
  .btn-gray-dark:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(182, 188, 196, .5);
    box-shadow: 0 0 0 .2rem rgba(182, 188, 196, .5)
  }

  .btn-gray-dark.disabled,
  .btn-gray-dark:disabled {
    color: #212529;
    background-color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-gray-dark:not(:disabled):not(.disabled).active,
  .btn-gray-dark:not(:disabled):not(.disabled):active,
  .show>.btn-gray-dark.dropdown-toggle {
    color: #fff;
    background-color: #b2bdca;
    border-color: #aab7c5
  }

  .btn-gray-dark:not(:disabled):not(.disabled).active:focus,
  .btn-gray-dark:not(:disabled):not(.disabled):active:focus,
  .show>.btn-gray-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(182, 188, 196, .5);
    box-shadow: 0 0 0 .2rem rgba(182, 188, 196, .5)
  }

  .btn-outline-primary {
    color: #0f59d1;
    border-color: #0f59d1
  }

  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0f59d1;
    border-color: #0f59d1
  }

  .btn-outline-primary.focus,
  .btn-outline-primary:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .5);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .5)
  }

  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: #0f59d1;
    background-color: transparent
  }

  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0f59d1;
    border-color: #0f59d1
  }

  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .5);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .5)
  }

  .btn-outline-secondary {
    color: #6b7c93;
    border-color: #6b7c93
  }

  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6b7c93;
    border-color: #6b7c93
  }

  .btn-outline-secondary.focus,
  .btn-outline-secondary:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(107, 124, 147, .5);
    box-shadow: 0 0 0 .2rem rgba(107, 124, 147, .5)
  }

  .btn-outline-secondary.disabled,
  .btn-outline-secondary:disabled {
    color: #6b7c93;
    background-color: transparent
  }

  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6b7c93;
    border-color: #6b7c93
  }

  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(107, 124, 147, .5);
    box-shadow: 0 0 0 .2rem rgba(107, 124, 147, .5)
  }

  .btn-outline-success {
    color: #46c37b;
    border-color: #46c37b
  }

  .btn-outline-success:hover {
    color: #fff;
    background-color: #46c37b;
    border-color: #46c37b
  }

  .btn-outline-success.focus,
  .btn-outline-success:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .5);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .5)
  }

  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #46c37b;
    background-color: transparent
  }

  .btn-outline-success:not(:disabled):not(.disabled).active,
  .btn-outline-success:not(:disabled):not(.disabled):active,
  .show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #46c37b;
    border-color: #46c37b
  }

  .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .btn-outline-success:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .5);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .5)
  }

  .btn-outline-info {
    color: #70b9eb;
    border-color: #70b9eb
  }

  .btn-outline-info:hover {
    color: #fff;
    background-color: #70b9eb;
    border-color: #70b9eb
  }

  .btn-outline-info.focus,
  .btn-outline-info:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(112, 185, 235, .5);
    box-shadow: 0 0 0 .2rem rgba(112, 185, 235, .5)
  }

  .btn-outline-info.disabled,
  .btn-outline-info:disabled {
    color: #70b9eb;
    background-color: transparent
  }

  .btn-outline-info:not(:disabled):not(.disabled).active,
  .btn-outline-info:not(:disabled):not(.disabled):active,
  .show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #70b9eb;
    border-color: #70b9eb
  }

  .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .btn-outline-info:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(112, 185, 235, .5);
    box-shadow: 0 0 0 .2rem rgba(112, 185, 235, .5)
  }

  .btn-outline-warning {
    color: #f3b760;
    border-color: #f3b760
  }

  .btn-outline-warning:hover {
    color: #fff;
    background-color: #f3b760;
    border-color: #f3b760
  }

  .btn-outline-warning.focus,
  .btn-outline-warning:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(243, 183, 96, .5);
    box-shadow: 0 0 0 .2rem rgba(243, 183, 96, .5)
  }

  .btn-outline-warning.disabled,
  .btn-outline-warning:disabled {
    color: #f3b760;
    background-color: transparent
  }

  .btn-outline-warning:not(:disabled):not(.disabled).active,
  .btn-outline-warning:not(:disabled):not(.disabled):active,
  .show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f3b760;
    border-color: #f3b760
  }

  .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(243, 183, 96, .5);
    box-shadow: 0 0 0 .2rem rgba(243, 183, 96, .5)
  }

  .btn-outline-danger {
    color: #d26a5c;
    border-color: #d26a5c
  }

  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d26a5c;
    border-color: #d26a5c
  }

  .btn-outline-danger.focus,
  .btn-outline-danger:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .5);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .5)
  }

  .btn-outline-danger.disabled,
  .btn-outline-danger:disabled {
    color: #d26a5c;
    background-color: transparent
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d26a5c;
    border-color: #d26a5c
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .5);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .5)
  }

  .btn-outline-light {
    color: #eee;
    border-color: #eee
  }

  .btn-outline-light:hover {
    color: #212529;
    background-color: #eee;
    border-color: #eee
  }

  .btn-outline-light.focus,
  .btn-outline-light:focus {
    -webkit-box-shadow: 0 0 0 .2rem hsla(0, 0%, 93.3%, .5);
    box-shadow: 0 0 0 .2rem hsla(0, 0%, 93.3%, .5)
  }

  .btn-outline-light.disabled,
  .btn-outline-light:disabled {
    color: #eee;
    background-color: transparent
  }

  .btn-outline-light:not(:disabled):not(.disabled).active,
  .btn-outline-light:not(:disabled):not(.disabled):active,
  .show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #eee;
    border-color: #eee
  }

  .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .btn-outline-light:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem hsla(0, 0%, 93.3%, .5);
    box-shadow: 0 0 0 .2rem hsla(0, 0%, 93.3%, .5)
  }

  .btn-outline-dark {
    color: #{$dark-l-theme};
    border-color: #{$dark-l-theme}
  }

  .btn-outline-dark:hover {
    color: #fff;
    background-color: #{$dark-l-theme};
    border-color: #{$dark-l-theme}
  }

  .btn-outline-dark.focus,
  .btn-outline-dark:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(28, 52, 78, .5);
    box-shadow: 0 0 0 .2rem rgba(28, 52, 78, .5)
  }

  .btn-outline-dark.disabled,
  .btn-outline-dark:disabled {
    color: #{$dark-l-theme};
    background-color: transparent
  }

  .btn-outline-dark:not(:disabled):not(.disabled).active,
  .btn-outline-dark:not(:disabled):not(.disabled):active,
  .show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #{$dark-l-theme};
    border-color: #{$dark-l-theme}
  }

  .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(28, 52, 78, .5);
    box-shadow: 0 0 0 .2rem rgba(28, 52, 78, .5)
  }

  .btn-outline-white {
    color: #fff;
    border-color: #fff
  }

  .btn-outline-white:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff
  }

  .btn-outline-white.focus,
  .btn-outline-white:focus {
    -webkit-box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5);
    box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5)
  }

  .btn-outline-white.disabled,
  .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent
  }

  .btn-outline-white:not(:disabled):not(.disabled).active,
  .btn-outline-white:not(:disabled):not(.disabled):active,
  .show>.btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff
  }

  .btn-outline-white:not(:disabled):not(.disabled).active:focus,
  .btn-outline-white:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-white.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5);
    box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5)
  }

  .btn-outline-gray {
    color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-outline-gray:hover {
    color: #212529;
    background-color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-outline-gray.focus,
  .btn-outline-gray:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5)
  }

  .btn-outline-gray.disabled,
  .btn-outline-gray:disabled {
    color: #d0d7df;
    background-color: transparent
  }

  .btn-outline-gray:not(:disabled):not(.disabled).active,
  .btn-outline-gray:not(:disabled):not(.disabled):active,
  .show>.btn-outline-gray.dropdown-toggle {
    color: #212529;
    background-color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
  .btn-outline-gray:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-gray.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5)
  }

  .btn-outline-gray-dark {
    color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-outline-gray-dark:hover {
    color: #212529;
    background-color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-outline-gray-dark.focus,
  .btn-outline-gray-dark:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5)
  }

  .btn-outline-gray-dark.disabled,
  .btn-outline-gray-dark:disabled {
    color: #d0d7df;
    background-color: transparent
  }

  .btn-outline-gray-dark:not(:disabled):not(.disabled).active,
  .btn-outline-gray-dark:not(:disabled):not(.disabled):active,
  .show>.btn-outline-gray-dark.dropdown-toggle {
    color: #212529;
    background-color: #d0d7df;
    border-color: #d0d7df
  }

  .btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
  .btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-gray-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5)
  }

  .btn-link {
    font-weight: 400;
    color: #0f59d1;
    text-decoration: none
  }

  .btn-link:hover {
    color: #083072
  }

  .btn-link.focus,
  .btn-link:focus,
  .btn-link:hover {
    text-decoration: none
  }

  .btn-link.disabled,
  .btn-link:disabled {
    color: #6c757d;
    pointer-events: none
  }

  .btn-group-lg>.btn,
  .btn-lg {
    padding: .5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .3rem
  }

  .btn-group-sm>.btn,
  .btn-sm {
    padding: .5rem 0.75rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
  }

  .btn-block {
    display: block;
    width: 100%
  }

  .btn-block+.btn-block {
    margin-top: .5rem
  }

  input[type=button].btn-block,
  input[type=reset].btn-block,
  input[type=submit].btn-block {
    width: 100%
  }

  .fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear
  }

  @media (prefers-reduced-motion:reduce) {
    .fade {
      -webkit-transition: none;
      transition: none
    }
  }

  .fade:not(.show) {
    opacity: 0
  }

  .collapse:not(.show) {
    display: none
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    transition: height .35s ease
  }

  @media (prefers-reduced-motion:reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none
    }
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    position: relative
  }

  .dropdown-toggle {
    white-space: nowrap
  }

  .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
  }

  .dropdown-toggle:empty:after {
    margin-left: 0
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 11.25rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #575757;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e1e1e1;
    border-radius: 0
  }

  .dropdown-menu-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-right {
    right: 0;
    left: auto
  }

  @media (min-width:576px) {
    .dropdown-menu-sm-left {
      right: auto;
      left: 0
    }
    .dropdown-menu-sm-right {
      right: 0;
      left: auto
    }
  }

  @media (min-width:768px) {
    .dropdown-menu-md-left {
      right: auto;
      left: 0
    }
    .dropdown-menu-md-right {
      right: 0;
      left: auto
    }
  }

  @media (min-width:992px) {
    .dropdown-menu-lg-left {
      right: auto;
      left: 0
    }
    .dropdown-menu-lg-right {
      right: 0;
      left: auto
    }
  }

  @media (min-width:1200px) {
    .dropdown-menu-xl-left {
      right: auto;
      left: 0
    }
    .dropdown-menu-xl-right {
      right: 0;
      left: auto
    }
  }

  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
  }

  .dropup .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
  }

  .dropup .dropdown-toggle:empty:after {
    margin-left: 0
  }

  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
  }

  .dropright .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
  }

  .dropright .dropdown-toggle:empty:after {
    margin-left: 0
  }

  .dropright .dropdown-toggle:after {
    vertical-align: 0
  }

  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
  }

  .dropleft .dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    display: none
  }

  .dropleft .dropdown-toggle:before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
  }

  .dropleft .dropdown-toggle:empty:after {
    margin-left: 0
  }

  .dropleft .dropdown-toggle:before {
    vertical-align: 0
  }

  .dropdown-menu[x-placement^=bottom],
  .dropdown-menu[x-placement^=left],
  .dropdown-menu[x-placement^=right],
  .dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto
  }

  .dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #ebebeb
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    clear: both;
    font-weight: 400;
    color: #575757;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #575757;
    text-decoration: none;
    background-color: #f5f5f5
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0f59d1
  }

  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent
  }

  .dropdown-menu.show {
    display: block
  }

  .dropdown-header {
    display: block;
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #6c757d;
    white-space: nowrap
  }

  .dropdown-item-text {
    display: block;
    padding: .5rem .75rem;
    color: #575757
  }

  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
  }

  .btn-group-vertical>.btn,
  .btn-group>.btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
  }

  .btn-group-vertical>.btn.active,
  .btn-group-vertical>.btn:active,
  .btn-group-vertical>.btn:focus,
  .btn-group-vertical>.btn:hover,
  .btn-group>.btn.active,
  .btn-group>.btn:active,
  .btn-group>.btn:focus,
  .btn-group>.btn:hover {
    z-index: 1
  }

  .btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .btn-toolbar .input-group {
    width: auto
  }

  .btn-group>.btn-group:not(:first-child),
  .btn-group>.btn:not(:first-child) {
    margin-left: -1px
  }

  .btn-group>.btn-group:not(:last-child)>.btn,
  .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .btn-group>.btn-group:not(:first-child)>.btn,
  .btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
  }

  .dropdown-toggle-split:after,
  .dropright .dropdown-toggle-split:after,
  .dropup .dropdown-toggle-split:after {
    margin-left: 0
  }

  .dropleft .dropdown-toggle-split:before {
    margin-right: 0
  }

  .btn-group-sm>.btn+.dropdown-toggle-split,
  .btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
  }

  .btn-group-lg>.btn+.dropdown-toggle-split,
  .btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
  }

  .btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .btn-group-vertical>.btn,
  .btn-group-vertical>.btn-group {
    width: 100%
  }

  .btn-group-vertical>.btn-group:not(:first-child),
  .btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
  }

  .btn-group-vertical>.btn-group:not(:last-child)>.btn,
  .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .btn-group-vertical>.btn-group:not(:first-child)>.btn,
  .btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .btn-group-toggle>.btn,
  .btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
  }

  .btn-group-toggle>.btn-group>.btn input[type=checkbox],
  .btn-group-toggle>.btn-group>.btn input[type=radio],
  .btn-group-toggle>.btn input[type=checkbox],
  .btn-group-toggle>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
  }

  .input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
  }

  .input-group>.custom-file,
  .input-group>.custom-select,
  .input-group>.form-control,
  .input-group>.form-control-plaintext {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0
  }

  .input-group>.custom-file+.custom-file,
  .input-group>.custom-file+.custom-select,
  .input-group>.custom-file+.form-control,
  .input-group>.custom-select+.custom-file,
  .input-group>.custom-select+.custom-select,
  .input-group>.custom-select+.form-control,
  .input-group>.form-control+.custom-file,
  .input-group>.form-control+.custom-select,
  .input-group>.form-control+.form-control,
  .input-group>.form-control-plaintext+.custom-file,
  .input-group>.form-control-plaintext+.custom-select,
  .input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
  }

  .input-group>.custom-file .custom-file-input:focus~.custom-file-label,
  .input-group>.custom-select:focus,
  .input-group>.form-control:focus {
    z-index: 3
  }

  .input-group>.custom-file .custom-file-input:focus {
    z-index: 4
  }

  .input-group>.custom-select:not(:first-child),
  .input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .input-group>.custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .input-group>.custom-file:not(:first-child) .custom-file-label,
  .input-group>.custom-file:not(:last-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label:after,
  .input-group.has-validation>.custom-select:nth-last-child(n+3),
  .input-group.has-validation>.form-control:nth-last-child(n+3),
  .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label:after,
  .input-group:not(.has-validation)>.custom-select:not(:last-child),
  .input-group:not(.has-validation)>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group-append,
  .input-group-prepend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  .input-group-append .btn,
  .input-group-prepend .btn {
    position: relative;
    z-index: 2
  }

  .input-group-append .btn:focus,
  .input-group-prepend .btn:focus {
    z-index: 3
  }

  .input-group-append .btn+.btn,
  .input-group-append .btn+.input-group-text,
  .input-group-append .input-group-text+.btn,
  .input-group-append .input-group-text+.input-group-text,
  .input-group-prepend .btn+.btn,
  .input-group-prepend .btn+.input-group-text,
  .input-group-prepend .input-group-text+.btn,
  .input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
  }

  .input-group-prepend {
    margin-right: -1px
  }

  .input-group-append {
    margin-left: -1px
  }

  .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #575757;
    text-align: center;
    white-space: nowrap;
    background-color: #f5f5f5;
    border: 1px solid #e1e1e1;
    border-radius: 8px
  }

  .input-group-text input[type=checkbox],
  .input-group-text input[type=radio] {
    margin-top: 0
  }

  .input-group-lg>.custom-select,
  .input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em + 1rem + 2px)
  }

  .input-group-lg>.custom-select,
  .input-group-lg>.form-control,
  .input-group-lg>.input-group-append>.btn,
  .input-group-lg>.input-group-append>.input-group-text,
  .input-group-lg>.input-group-prepend>.btn,
  .input-group-lg>.input-group-prepend>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .3rem
  }

  .input-group-sm>.custom-select,
  .input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em + .5rem + 2px)
  }

  .input-group-sm>.custom-select,
  .input-group-sm>.form-control,
  .input-group-sm>.input-group-append>.btn,
  .input-group-sm>.input-group-append>.input-group-text,
  .input-group-sm>.input-group-prepend>.btn,
  .input-group-sm>.input-group-prepend>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
  }

  .input-group-lg>.custom-select,
  .input-group-sm>.custom-select {
    padding-right: 1.75rem
  }

  .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,
  .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,
  .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,
  .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,
  .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
  .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
  .input-group>.input-group-prepend>.btn,
  .input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group>.input-group-append>.btn,
  .input-group>.input-group-append>.input-group-text,
  .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
  .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
  .input-group>.input-group-prepend:not(:first-child)>.btn,
  .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact
  }

  .custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem
  }

  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0
  }

  .custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: #0f59d1;
    background-color: #0f59d1
  }

  .custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #6b9ff4
  }

  .custom-control-input:not(:disabled):active~.custom-control-label:before {
    color: #fff;
    background-color: #9bbef8;
    border-color: #9bbef8
  }

  .custom-control-input:disabled~.custom-control-label,
  .custom-control-input[disabled]~.custom-control-label {
    color: #6c757d
  }

  .custom-control-input:disabled~.custom-control-label:before,
  .custom-control-input[disabled]~.custom-control-label:before {
    background-color: #e9ecef
  }

  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top
  }

  .custom-control-label:before {
    pointer-events: none;
    background-color: #e1e1e1;
    border: 1px solid #adb5bd
  }

  .custom-control-label:after,
  .custom-control-label:before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: ""
  }

  .custom-control-label:after {
    background: 50%/50% 50% no-repeat
  }

  .custom-checkbox .custom-control-label:before {
    border-radius: 8px
  }

  .custom-checkbox .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E")
  }

  .custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    border-color: #0f59d1;
    background-color: #0f59d1
  }

  .custom-checkbox .custom-control-input:indeterminate~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
  }

  .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(15, 89, 209, .5)
  }

  .custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label:before {
    background-color: rgba(15, 89, 209, .5)
  }

  .custom-radio .custom-control-label:before {
    border-radius: 50%
  }

  .custom-radio .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
  }

  .custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(15, 89, 209, .5)
  }

  .custom-switch {
    padding-left: 2.5rem
  }

  .custom-switch .custom-control-label:before {
    left: -2.5rem;
    pointer-events: all;
    border-radius: .5rem
  }

  .custom-switch .custom-control-label:after {
    top: calc(.25rem + 2px);
    left: calc(-2.5rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    border-radius: .5rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion:reduce) {
    .custom-switch .custom-control-label:after {
      -webkit-transition: none;
      transition: none
    }
  }

  .custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #e1e1e1;
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem)
  }

  .custom-switch .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(15, 89, 209, .5)
  }

  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #575757;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center/8px 10px no-repeat;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
  }

  .custom-select:focus {
    border-color: #6b9ff4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .custom-select:focus::-ms-value {
    color: #575757;
    background-color: #fff
  }

  .custom-select[multiple],
  .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: .75rem;
    background-image: none
  }

  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef
  }

  .custom-select::-ms-expand {
    display: none
  }

  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #575757
  }

  .custom-select-sm {
    height: calc(1.5em + .5rem + 2px);
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .875rem
  }

  .custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1rem
  }

  .custom-file {
    display: inline-block;
    margin-bottom: 0
  }

  .custom-file,
  .custom-file-input {
    position: relative;
    width: 100%;
    height: calc(1.5em + .75rem + 2px)
  }

  .custom-file-input {
    z-index: 2;
    margin: 0;
    overflow: hidden;
    opacity: 0
  }

  .custom-file-input:focus~.custom-file-label {
    border-color: #6b9ff4;
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .custom-file-input:disabled~.custom-file-label,
  .custom-file-input[disabled]~.custom-file-label {
    background-color: #e9ecef
  }

  .custom-file-input:lang(en)~.custom-file-label:after {
    content: "Browse"
  }

  .custom-file-input~.custom-file-label[data-browse]:after {
    content: attr(data-browse)
  }

  .custom-file-label {
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    overflow: hidden;
    font-weight: 400;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 8px
  }

  .custom-file-label,
  .custom-file-label:after {
    position: absolute;
    top: 0;
    right: 0;
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #575757
  }

  .custom-file-label:after {
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    content: "Browse";
    background-color: #f5f5f5;
    border-left: inherit;
    border-radius: 0 8px 8px 0
  }

  .custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
  }

  .custom-range:focus {
    outline: 0
  }

  .custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .custom-range::-moz-focus-outer {
    border: 0
  }

  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #0f59d1;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
  }

  @media (prefers-reduced-motion:reduce) {
    .custom-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none
    }
  }

  .custom-range::-webkit-slider-thumb:active {
    background-color: #9bbef8
  }

  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
  }

  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0f59d1;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
  }

  @media (prefers-reduced-motion:reduce) {
    .custom-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none
    }
  }

  .custom-range::-moz-range-thumb:active {
    background-color: #9bbef8
  }

  .custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
  }

  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;
    background-color: #0f59d1;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
  }

  @media (prefers-reduced-motion:reduce) {
    .custom-range::-ms-thumb {
      -ms-transition: none;
      transition: none
    }
  }

  .custom-range::-ms-thumb:active {
    background-color: #9bbef8
  }

  .custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem
  }

  .custom-range::-ms-fill-lower,
  .custom-range::-ms-fill-upper {
    background-color: #dee2e6;
    border-radius: 1rem
  }

  .custom-range::-ms-fill-upper {
    margin-right: 15px
  }

  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
  }

  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
  }

  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
  }

  .custom-range:disabled::-moz-range-track {
    cursor: default
  }

  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd
  }

  .custom-control-label:before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion:reduce) {
    .custom-control-label:before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none
    }
  }

  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
  }

  .nav-link {
    display: block;
    padding: .5rem 1rem
  }

  .nav-link:focus,
  .nav-link:hover {
    text-decoration: none
  }

  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
  }

  .nav-tabs {
    border-bottom: 1px solid #ebebeb
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: #ebebeb #ebebeb #ebebeb
  }

  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #575757;
    background-color: #fff;
    border-color: #ebebeb #ebebeb #ebebeb
  }

  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .nav-pills .nav-link {
    border-radius: 8px
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0f59d1
  }

  .nav-fill .nav-item,
  .nav-fill>.nav-link {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
  }

  .nav-justified .nav-item,
  .nav-justified>.nav-link {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
  }

  .tab-content>.tab-pane {
    display: none
  }

  .tab-content>.active {
    display: block
  }

  .breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 8px
  }

  .breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
  }

  .breadcrumb-item+.breadcrumb-item:before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: "/"
  }

  .breadcrumb-item+.breadcrumb-item:hover:before {
    text-decoration: underline;
    text-decoration: none
  }

  .breadcrumb-item.active {
    color: #6c757d
  }

  .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 8px
  }

  .page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -2px;
    line-height: 1.25;
    color: #575757;
    border: 2px solid transparent
  }

  .page-link,
  .page-link:hover {
    background-color: transparent
  }

  .page-link:hover {
    z-index: 2;
    color: #0f59d1;
    text-decoration: none;
    border-color: #0f59d1
  }

  .page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
  }

  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #0f59d1;
    background-color: #f9f9f9;
    border-color: #0f59d1
  }

  .page-item.disabled .page-link {
    color: #adb5bd;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #fff
  }

  .pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5
  }

  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
  }

  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
  }

  .pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5
  }

  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
  }

  .badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 8px;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion:reduce) {
    .badge {
      -webkit-transition: none;
      transition: none
    }
  }

  a.badge:focus,
  a.badge:hover {
    text-decoration: none
  }

  .badge:empty {
    display: none
  }

  .btn .badge {
    position: relative;
    top: -1px
  }

  .badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
  }

  .badge-primary {
    color: #fff;
    background-color: #0f59d1
  }

  a.badge-primary:focus,
  a.badge-primary:hover {
    color: #fff;
    background-color: #0c45a1
  }

  a.badge-primary.focus,
  a.badge-primary:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .5);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .5)
  }

  .badge-secondary {
    color: #fff;
    background-color: #6b7c93
  }

  a.badge-secondary:focus,
  a.badge-secondary:hover {
    color: #fff;
    background-color: #566375
  }

  a.badge-secondary.focus,
  a.badge-secondary:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(107, 124, 147, .5);
    box-shadow: 0 0 0 .2rem rgba(107, 124, 147, .5)
  }

  .badge-success {
    color: #fff;
    background-color: #46c37b
  }

  a.badge-success:focus,
  a.badge-success:hover {
    color: #fff;
    background-color: #34a263
  }

  a.badge-success.focus,
  a.badge-success:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .5);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .5)
  }

  .badge-info {
    color: #fff;
    background-color: #70b9eb
  }

  a.badge-info:focus,
  a.badge-info:hover {
    color: #fff;
    background-color: #43a3e5
  }

  a.badge-info.focus,
  a.badge-info:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(112, 185, 235, .5);
    box-shadow: 0 0 0 .2rem rgba(112, 185, 235, .5)
  }

  .badge-warning {
    color: #fff;
    background-color: #f3b760
  }

  a.badge-warning:focus,
  a.badge-warning:hover {
    color: #fff;
    background-color: #efa231
  }

  a.badge-warning.focus,
  a.badge-warning:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(243, 183, 96, .5);
    box-shadow: 0 0 0 .2rem rgba(243, 183, 96, .5)
  }

  .badge-danger {
    color: #fff;
    background-color: #d26a5c
  }

  a.badge-danger:focus,
  a.badge-danger:hover {
    color: #fff;
    background-color: #c54736
  }

  a.badge-danger.focus,
  a.badge-danger:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .5);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .5)
  }

  .badge-light {
    color: #212529;
    background-color: #eee
  }

  a.badge-light:focus,
  a.badge-light:hover {
    color: #212529;
    background-color: #d5d5d5
  }

  a.badge-light.focus,
  a.badge-light:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem hsla(0, 0%, 93.3%, .5);
    box-shadow: 0 0 0 .2rem hsla(0, 0%, 93.3%, .5)
  }

  .badge-dark {
    color: #fff;
    background-color: #{$dark-l-theme}
  }

  a.badge-dark:focus,
  a.badge-dark:hover {
    color: #fff;
    background-color: #0f1b28
  }

  a.badge-dark.focus,
  a.badge-dark:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(28, 52, 78, .5);
    box-shadow: 0 0 0 .2rem rgba(28, 52, 78, .5)
  }

  .badge-white {
    color: #212529;
    background-color: #fff
  }

  a.badge-white:focus,
  a.badge-white:hover {
    color: #212529;
    background-color: #e6e6e6
  }

  a.badge-white.focus,
  a.badge-white:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5);
    box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5)
  }

  .badge-gray {
    color: #212529;
    background-color: #d0d7df
  }

  a.badge-gray:focus,
  a.badge-gray:hover {
    color: #212529;
    background-color: #b2bdca
  }

  a.badge-gray.focus,
  a.badge-gray:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5)
  }

  .badge-gray-dark {
    color: #212529;
    background-color: #d0d7df
  }

  a.badge-gray-dark:focus,
  a.badge-gray-dark:hover {
    color: #212529;
    background-color: #b2bdca
  }

  a.badge-gray-dark.focus,
  a.badge-gray-dark:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5);
    box-shadow: 0 0 0 .2rem rgba(208, 215, 223, .5)
  }

  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem
  }

  @media (min-width:576px) {
    .jumbotron {
      padding: 4rem 2rem
    }
  }

  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
  }

  .alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 0 solid transparent;
    border-radius: 0
  }

  .alert-heading {
    color: inherit
  }

  .alert-link {
    font-weight: 600
  }

  .alert-dismissible {
    padding-right: 4rem
  }

  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: .75rem 1.25rem;
    color: inherit
  }

  .alert-primary {
    color: #082e6d;
    background-color: #cfdef6;
    border-color: #bcd1f2;
    -webkit-box-shadow: 0 .125rem #bacff2;
    box-shadow: 0 .125rem #bacff2
  }

  .alert-primary hr {
    border-top-color: #a7c2ee
  }

  .alert-primary .alert-link {
    color: #051a3d
  }

  .alert-secondary {
    color: #38404c;
    background-color: #e1e5e9;
    border-color: #d6dae1;
    -webkit-box-shadow: 0 .125rem #d2d8de;
    box-shadow: 0 .125rem #d2d8de
  }

  .alert-secondary hr {
    border-top-color: #c7cdd6
  }

  .alert-secondary .alert-link {
    color: #22272f
  }

  .alert-success {
    color: #246540;
    background-color: #daf3e5;
    border-color: #cbeeda;
    -webkit-box-shadow: 0 .125rem #c7edd7;
    box-shadow: 0 .125rem #c7edd7
  }

  .alert-success hr {
    border-top-color: #b8e8cc
  }

  .alert-success .alert-link {
    color: #173f28
  }

  .alert-info {
    color: #3a607a;
    background-color: #e2f1fb;
    border-color: #d7ebf9;
    -webkit-box-shadow: 0 .125rem #cce6f8;
    box-shadow: 0 .125rem #cce6f8
  }

  .alert-info hr {
    border-top-color: #c1e0f6
  }

  .alert-info .alert-link {
    color: #2a4557
  }

  .alert-warning {
    color: #7e5f32;
    background-color: #fdf1df;
    border-color: #fcebd2;
    -webkit-box-shadow: 0 .125rem #fce7c7;
    box-shadow: 0 .125rem #fce7c7
  }

  .alert-warning hr {
    border-top-color: #fae0ba
  }

  .alert-warning .alert-link {
    color: #594324
  }

  .alert-danger {
    color: #6d3730;
    background-color: #f6e1de;
    border-color: #f2d5d1;
    -webkit-box-shadow: 0 .125rem #f1cfca;
    box-shadow: 0 .125rem #f1cfca
  }

  .alert-danger hr {
    border-top-color: #ecc3bd
  }

  .alert-danger .alert-link {
    color: #4a2520
  }

  .alert-light {
    color: #7c7c7c;
    background-color: #fcfcfc;
    border-color: #fafafa;
    -webkit-box-shadow: 0 .125rem #efefef;
    box-shadow: 0 .125rem #efefef
  }

  .alert-light hr {
    border-top-color: #ededed
  }

  .alert-light .alert-link {
    color: #636363
  }

  .alert-dark {
    color: #0f1b29;
    background-color: #d2d6dc;
    border-color: #bfc6cd;
    -webkit-box-shadow: 0 .125rem #c4c9d1;
    box-shadow: 0 .125rem #c4c9d1
  }

  .alert-dark hr {
    border-top-color: #b1b9c2
  }

  .alert-dark .alert-link {
    color: #010204
  }

  .alert-white {
    color: #858585;
    background-color: #fff;
    border-color: #fff;
    -webkit-box-shadow: 0 .125rem #f2f2f2;
    box-shadow: 0 .125rem #f2f2f2
  }

  .alert-white hr {
    border-top-color: #f2f2f2
  }

  .alert-white .alert-link {
    color: #6c6c6c
  }

  .alert-gray {
    color: #6c7074;
    background-color: #f6f7f9;
    border-color: #f2f4f6;
    -webkit-box-shadow: 0 .125rem #e7e9ef;
    box-shadow: 0 .125rem #e7e9ef
  }

  .alert-gray hr {
    border-top-color: #e3e7ec
  }

  .alert-gray .alert-link {
    color: #53575a
  }

  .alert-gray-dark {
    color: #6c7074;
    background-color: #f6f7f9;
    border-color: #f2f4f6;
    -webkit-box-shadow: 0 .125rem #e7e9ef;
    box-shadow: 0 .125rem #e7e9ef
  }

  .alert-gray-dark hr {
    border-top-color: #e3e7ec
  }

  .alert-gray-dark .alert-link {
    color: #53575a
  }

  @-webkit-keyframes progress-bar-stripes {
    0% {
      background-position: 1.5rem 0
    }
    to {
      background-position: 0 0
    }
  }

  @keyframes progress-bar-stripes {
    0% {
      background-position: 1.5rem 0
    }
    to {
      background-position: 0 0
    }
  }

  .progress {
    height: 1.5rem;
    line-height: 0;
    font-size: 1rem;
    background-color: #e9ecef;
    border-radius: .25
  }

  .progress,
  .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden
  }

  .progress-bar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0f59d1;
    -webkit-transition: width .6s ease;
    transition: width .6s ease
  }

  @media (prefers-reduced-motion:reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none
    }
  }

  .progress-bar-striped {
    background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
    background-size: 1.5rem 1.5rem
  }

  .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
  }

  @media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none
    }
  }

  .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
  }

  .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
  }

  .list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 8px
  }

  .list-group-item-action {
    width: 100%;
    color: #575757;
    text-align: inherit
  }

  .list-group-item-action:focus,
  .list-group-item-action:hover {
    z-index: 1;
    color: #575757;
    text-decoration: none;
    background-color: #f9f9f9
  }

  .list-group-item-action:active {
    color: #575757;
    background-color: #ebebeb
  }

  .list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    background-color: #fff;
    border: 1px solid #ebebeb
  }

  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
  }

  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
  }

  .list-group-item.disabled,
  .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff
  }

  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0f59d1;
    border-color: #0f59d1
  }

  .list-group-item+.list-group-item {
    border-top-width: 0
  }

  .list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
  }

  .list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0
  }

  .list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }

  @media (min-width:576px) {
    .list-group-horizontal-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }
    .list-group-horizontal-sm>.list-group-item:first-child {
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0
    }
    .list-group-horizontal-sm>.list-group-item:last-child {
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0
    }
    .list-group-horizontal-sm>.list-group-item.active {
      margin-top: 0
    }
    .list-group-horizontal-sm>.list-group-item+.list-group-item {
      border-top-width: 1px;
      border-left-width: 0
    }
    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px
    }
  }

  @media (min-width:768px) {
    .list-group-horizontal-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }
    .list-group-horizontal-md>.list-group-item:first-child {
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0
    }
    .list-group-horizontal-md>.list-group-item:last-child {
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0
    }
    .list-group-horizontal-md>.list-group-item.active {
      margin-top: 0
    }
    .list-group-horizontal-md>.list-group-item+.list-group-item {
      border-top-width: 1px;
      border-left-width: 0
    }
    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px
    }
  }

  @media (min-width:992px) {
    .list-group-horizontal-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }
    .list-group-horizontal-lg>.list-group-item:first-child {
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0
    }
    .list-group-horizontal-lg>.list-group-item:last-child {
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0
    }
    .list-group-horizontal-lg>.list-group-item.active {
      margin-top: 0
    }
    .list-group-horizontal-lg>.list-group-item+.list-group-item {
      border-top-width: 1px;
      border-left-width: 0
    }
    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px
    }
  }

  @media (min-width:1200px) {
    .list-group-horizontal-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }
    .list-group-horizontal-xl>.list-group-item:first-child {
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0
    }
    .list-group-horizontal-xl>.list-group-item:last-child {
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0
    }
    .list-group-horizontal-xl>.list-group-item.active {
      margin-top: 0
    }
    .list-group-horizontal-xl>.list-group-item+.list-group-item {
      border-top-width: 1px;
      border-left-width: 0
    }
    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px
    }
  }

  .list-group-flush {
    border-radius: 0
  }

  .list-group-flush>.list-group-item {
    border-width: 0 0 1px
  }

  .list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
  }

  .list-group-item-primary {
    color: #082e6d;
    background-color: #bcd1f2
  }

  .list-group-item-primary.list-group-item-action:focus,
  .list-group-item-primary.list-group-item-action:hover {
    color: #082e6d;
    background-color: #a7c2ee
  }

  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #082e6d;
    border-color: #082e6d
  }

  .list-group-item-secondary {
    color: #38404c;
    background-color: #d6dae1
  }

  .list-group-item-secondary.list-group-item-action:focus,
  .list-group-item-secondary.list-group-item-action:hover {
    color: #38404c;
    background-color: #c7cdd6
  }

  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #38404c;
    border-color: #38404c
  }

  .list-group-item-success {
    color: #246540;
    background-color: #cbeeda
  }

  .list-group-item-success.list-group-item-action:focus,
  .list-group-item-success.list-group-item-action:hover {
    color: #246540;
    background-color: #b8e8cc
  }

  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #246540;
    border-color: #246540
  }

  .list-group-item-info {
    color: #3a607a;
    background-color: #d7ebf9
  }

  .list-group-item-info.list-group-item-action:focus,
  .list-group-item-info.list-group-item-action:hover {
    color: #3a607a;
    background-color: #c1e0f6
  }

  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #3a607a;
    border-color: #3a607a
  }

  .list-group-item-warning {
    color: #7e5f32;
    background-color: #fcebd2
  }

  .list-group-item-warning.list-group-item-action:focus,
  .list-group-item-warning.list-group-item-action:hover {
    color: #7e5f32;
    background-color: #fae0ba
  }

  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7e5f32;
    border-color: #7e5f32
  }

  .list-group-item-danger {
    color: #6d3730;
    background-color: #f2d5d1
  }

  .list-group-item-danger.list-group-item-action:focus,
  .list-group-item-danger.list-group-item-action:hover {
    color: #6d3730;
    background-color: #ecc3bd
  }

  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6d3730;
    border-color: #6d3730
  }

  .list-group-item-light {
    color: #7c7c7c;
    background-color: #fafafa
  }

  .list-group-item-light.list-group-item-action:focus,
  .list-group-item-light.list-group-item-action:hover {
    color: #7c7c7c;
    background-color: #ededed
  }

  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7c7c7c;
    border-color: #7c7c7c
  }

  .list-group-item-dark {
    color: #0f1b29;
    background-color: #bfc6cd
  }

  .list-group-item-dark.list-group-item-action:focus,
  .list-group-item-dark.list-group-item-action:hover {
    color: #0f1b29;
    background-color: #b1b9c2
  }

  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0f1b29;
    border-color: #0f1b29
  }

  .list-group-item-white {
    color: #858585;
    background-color: #fff
  }

  .list-group-item-white.list-group-item-action:focus,
  .list-group-item-white.list-group-item-action:hover {
    color: #858585;
    background-color: #f2f2f2
  }

  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585
  }

  .list-group-item-gray {
    color: #6c7074;
    background-color: #f2f4f6
  }

  .list-group-item-gray.list-group-item-action:focus,
  .list-group-item-gray.list-group-item-action:hover {
    color: #6c7074;
    background-color: #e3e7ec
  }

  .list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: #6c7074;
    border-color: #6c7074
  }

  .list-group-item-gray-dark {
    color: #6c7074;
    background-color: #f2f4f6
  }

  .list-group-item-gray-dark.list-group-item-action:focus,
  .list-group-item-gray-dark.list-group-item-action:hover {
    color: #6c7074;
    background-color: #e3e7ec
  }

  .list-group-item-gray-dark.list-group-item-action.active {
    color: #fff;
    background-color: #6c7074;
    border-color: #6c7074
  }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
  }

  .close:hover {
    color: #000;
    text-decoration: none
  }

  .close:not(:disabled):not(.disabled):focus,
  .close:not(:disabled):not(.disabled):hover {
    opacity: .75
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0
  }

  a.close.disabled {
    pointer-events: none
  }

  .toast {
    -ms-flex-preferred-size: 350px;
    flex-basis: 350px;
    max-width: 350px;
    font-size: .875rem;
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
    opacity: 0;
    border-radius: .25rem
  }

  .toast:not(:last-child) {
    margin-bottom: .75rem
  }

  .toast.showing {
    opacity: 1
  }

  .toast.show {
    display: block;
    opacity: 1
  }

  .toast.hide {
    display: none
  }

  .toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .25rem .75rem;
    color: #6c757d;
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
  }

  .toast-body {
    padding: .75rem
  }

  .modal-open {
    overflow: hidden
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
  }

  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px)
  }

  @media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
      -webkit-transition: none;
      transition: none
    }
  }

  .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
  }

  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
  }

  .modal-dialog-scrollable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem)
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
  }

  .modal-dialog-scrollable .modal-footer,
  .modal-dialog-scrollable .modal-header {
    -ms-flex-negative: 0;
    flex-shrink: 0
  }

  .modal-dialog-scrollable .modal-body {
    overflow-y: auto
  }

  .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem)
  }

  .modal-dialog-centered:before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""
  }

  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%
  }

  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
  }

  .modal-dialog-centered.modal-dialog-scrollable:before {
    content: none
  }

  .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, .2);
    border-radius: 0;
    outline: 0
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #1d3144
  }

  .modal-backdrop.fade {
    opacity: 0
  }

  .modal-backdrop.show {
    opacity: .7
  }

  .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0 solid #ebebeb;
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto
  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5
  }

  .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem
  }

  .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 0 solid #ebebeb;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
  }

  .modal-footer>* {
    margin: .25rem
  }

  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
  }

  @media (min-width:576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto
    }
    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem)
    }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem)
    }
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem)
    }
    .modal-dialog-centered:before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content
    }
    .modal-sm {
      max-width: 379px
    }
  }

  @media (min-width:992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px
    }
  }

  @media (min-width:1200px) {
    .modal-xl {
      max-width: 1140px
    }
  }

  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
  }

  .tooltip.show {
    opacity: .9
  }

  .tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
  }

  .tooltip .arrow:before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
  }

  .bs-tooltip-auto[x-placement^=top],
  .bs-tooltip-top,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top],
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=top] {
    padding: .4rem 0
  }

  .bs-tooltip-auto[x-placement^=top] .arrow,
  .bs-tooltip-top .arrow,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow,
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=top] .arrow {
    bottom: 0
  }

  .bs-tooltip-auto[x-placement^=top] .arrow:before,
  .bs-tooltip-top .arrow:before,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=top] .arrow:before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: rgba(0, 0, 0, .9)
  }

  .bs-tooltip-auto[x-placement^=right],
  .bs-tooltip-right,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right],
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=right] {
    padding: 0 .4rem
  }

  .bs-tooltip-auto[x-placement^=right] .arrow,
  .bs-tooltip-right .arrow,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=right] .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
  }

  .bs-tooltip-auto[x-placement^=right] .arrow:before,
  .bs-tooltip-right .arrow:before,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=right] .arrow:before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: rgba(0, 0, 0, .9)
  }

  .bs-tooltip-auto[x-placement^=bottom],
  .bs-tooltip-bottom,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom],
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=bottom] {
    padding: .4rem 0
  }

  .bs-tooltip-auto[x-placement^=bottom] .arrow,
  .bs-tooltip-bottom .arrow,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow,
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=bottom] .arrow {
    top: 0
  }

  .bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .bs-tooltip-bottom .arrow:before,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=bottom] .arrow:before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: rgba(0, 0, 0, .9)
  }

  .bs-tooltip-auto[x-placement^=left],
  .bs-tooltip-left,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left],
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=left] {
    padding: 0 .4rem
  }

  .bs-tooltip-auto[x-placement^=left] .arrow,
  .bs-tooltip-left .arrow,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=left] .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
  }

  .bs-tooltip-auto[x-placement^=left] .arrow:before,
  .bs-tooltip-left .arrow:before,
  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=left] .arrow:before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: rgba(0, 0, 0, .9)
  }

  .tooltip-inner {
    max-width: 200px;
    padding: .25rem .75rem;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, .9);
    border-radius: 0
  }

  .popover {
    top: 0;
    left: 0;
    z-index: 1060;
    max-width: 276px;
    font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebebeb;
    border-radius: .3rem
  }

  .popover,
  .popover .arrow {
    position: absolute;
    display: block
  }

  .popover .arrow {
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem
  }

  .popover .arrow:after,
  .popover .arrow:before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=top],
  .b-popover-danger.bs-popover-auto[x-placement^=top],
  .b-popover-dark.bs-popover-auto[x-placement^=top],
  .b-popover-gray-dark.bs-popover-auto[x-placement^=top],
  .b-popover-gray.bs-popover-auto[x-placement^=top],
  .b-popover-info-light.bs-popover-auto[x-placement^=top],
  .b-popover-info.bs-popover-auto[x-placement^=top],
  .b-popover-light.bs-popover-auto[x-placement^=top],
  .b-popover-primary-dark.bs-popover-auto[x-placement^=top],
  .b-popover-primary-darker.bs-popover-auto[x-placement^=top],
  .b-popover-primary-light.bs-popover-auto[x-placement^=top],
  .b-popover-primary-lighter.bs-popover-auto[x-placement^=top],
  .b-popover-primary.bs-popover-auto[x-placement^=top],
  .b-popover-secondary.bs-popover-auto[x-placement^=top],
  .b-popover-success-light.bs-popover-auto[x-placement^=top],
  .b-popover-success.bs-popover-auto[x-placement^=top],
  .b-popover-warning-light.bs-popover-auto[x-placement^=top],
  .b-popover-warning.bs-popover-auto[x-placement^=top],
  .b-popover-white.bs-popover-auto[x-placement^=top],
  .bs-popover-auto[x-placement^=top],
  .bs-popover-top {
    margin-bottom: .5rem
  }

  .bs-popover-auto[x-placement^=top]>.arrow,
  .bs-popover-top>.arrow {
    bottom: calc(-.5rem - 1px)
  }

  .bs-popover-auto[x-placement^=top]>.arrow:before,
  .bs-popover-top>.arrow:before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: #ebebeb
  }

  .bs-popover-auto[x-placement^=top]>.arrow:after,
  .bs-popover-top>.arrow:after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=right],
  .b-popover-danger.bs-popover-auto[x-placement^=right],
  .b-popover-dark.bs-popover-auto[x-placement^=right],
  .b-popover-gray-dark.bs-popover-auto[x-placement^=right],
  .b-popover-gray.bs-popover-auto[x-placement^=right],
  .b-popover-info-light.bs-popover-auto[x-placement^=right],
  .b-popover-info.bs-popover-auto[x-placement^=right],
  .b-popover-light.bs-popover-auto[x-placement^=right],
  .b-popover-primary-dark.bs-popover-auto[x-placement^=right],
  .b-popover-primary-darker.bs-popover-auto[x-placement^=right],
  .b-popover-primary-light.bs-popover-auto[x-placement^=right],
  .b-popover-primary-lighter.bs-popover-auto[x-placement^=right],
  .b-popover-primary.bs-popover-auto[x-placement^=right],
  .b-popover-secondary.bs-popover-auto[x-placement^=right],
  .b-popover-success-light.bs-popover-auto[x-placement^=right],
  .b-popover-success.bs-popover-auto[x-placement^=right],
  .b-popover-warning-light.bs-popover-auto[x-placement^=right],
  .b-popover-warning.bs-popover-auto[x-placement^=right],
  .b-popover-white.bs-popover-auto[x-placement^=right],
  .bs-popover-auto[x-placement^=right],
  .bs-popover-right {
    margin-left: .5rem
  }

  .bs-popover-auto[x-placement^=right]>.arrow,
  .bs-popover-right>.arrow {
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
  }

  .bs-popover-auto[x-placement^=right]>.arrow:before,
  .bs-popover-right>.arrow:before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #ebebeb
  }

  .bs-popover-auto[x-placement^=right]>.arrow:after,
  .bs-popover-right>.arrow:after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=bottom],
  .b-popover-danger.bs-popover-auto[x-placement^=bottom],
  .b-popover-dark.bs-popover-auto[x-placement^=bottom],
  .b-popover-gray-dark.bs-popover-auto[x-placement^=bottom],
  .b-popover-gray.bs-popover-auto[x-placement^=bottom],
  .b-popover-info-light.bs-popover-auto[x-placement^=bottom],
  .b-popover-info.bs-popover-auto[x-placement^=bottom],
  .b-popover-light.bs-popover-auto[x-placement^=bottom],
  .b-popover-primary-dark.bs-popover-auto[x-placement^=bottom],
  .b-popover-primary-darker.bs-popover-auto[x-placement^=bottom],
  .b-popover-primary-light.bs-popover-auto[x-placement^=bottom],
  .b-popover-primary-lighter.bs-popover-auto[x-placement^=bottom],
  .b-popover-primary.bs-popover-auto[x-placement^=bottom],
  .b-popover-secondary.bs-popover-auto[x-placement^=bottom],
  .b-popover-success-light.bs-popover-auto[x-placement^=bottom],
  .b-popover-success.bs-popover-auto[x-placement^=bottom],
  .b-popover-warning-light.bs-popover-auto[x-placement^=bottom],
  .b-popover-warning.bs-popover-auto[x-placement^=bottom],
  .b-popover-white.bs-popover-auto[x-placement^=bottom],
  .bs-popover-auto[x-placement^=bottom],
  .bs-popover-bottom {
    margin-top: .5rem
  }

  .bs-popover-auto[x-placement^=bottom]>.arrow,
  .bs-popover-bottom>.arrow {
    top: calc(-.5rem - 1px)
  }

  .bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .bs-popover-bottom>.arrow:before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #ebebeb
  }

  .bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .bs-popover-bottom>.arrow:after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
  }

  .bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #fff
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=left],
  .b-popover-danger.bs-popover-auto[x-placement^=left],
  .b-popover-dark.bs-popover-auto[x-placement^=left],
  .b-popover-gray-dark.bs-popover-auto[x-placement^=left],
  .b-popover-gray.bs-popover-auto[x-placement^=left],
  .b-popover-info-light.bs-popover-auto[x-placement^=left],
  .b-popover-info.bs-popover-auto[x-placement^=left],
  .b-popover-light.bs-popover-auto[x-placement^=left],
  .b-popover-primary-dark.bs-popover-auto[x-placement^=left],
  .b-popover-primary-darker.bs-popover-auto[x-placement^=left],
  .b-popover-primary-light.bs-popover-auto[x-placement^=left],
  .b-popover-primary-lighter.bs-popover-auto[x-placement^=left],
  .b-popover-primary.bs-popover-auto[x-placement^=left],
  .b-popover-secondary.bs-popover-auto[x-placement^=left],
  .b-popover-success-light.bs-popover-auto[x-placement^=left],
  .b-popover-success.bs-popover-auto[x-placement^=left],
  .b-popover-warning-light.bs-popover-auto[x-placement^=left],
  .b-popover-warning.bs-popover-auto[x-placement^=left],
  .b-popover-white.bs-popover-auto[x-placement^=left],
  .bs-popover-auto[x-placement^=left],
  .bs-popover-left {
    margin-right: .5rem
  }

  .bs-popover-auto[x-placement^=left]>.arrow,
  .bs-popover-left>.arrow {
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
  }

  .bs-popover-auto[x-placement^=left]>.arrow:before,
  .bs-popover-left>.arrow:before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #ebebeb
  }

  .bs-popover-auto[x-placement^=left]>.arrow:after,
  .bs-popover-left>.arrow:after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
  }

  .popover-header {
    padding: .75rem .625rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #373737;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
  }

  .popover-header:empty {
    display: none
  }

  .popover-body {
    padding: .625rem .625rem;
    color: #575757
  }

  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
    }
  }

  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
    }
  }

  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite
  }

  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
  }

  @-webkit-keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0)
    }
    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none
    }
  }

  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0)
    }
    50% {
      opacity: 1;
      -webkit-transform: none;
      transform: none
    }
  }

  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite
  }

  .spinner-grow-sm {
    width: 1rem;
    height: 1rem
  }

  @media (prefers-reduced-motion:reduce) {
    .spinner-border,
    .spinner-grow {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s
    }
  }

  .align-baseline {
    vertical-align: baseline!important
  }

  .align-top {
    vertical-align: top!important
  }

  .align-middle,
  .dark-table td,
  .table-dark td,
  .table td {
    vertical-align: middle!important
  }

  .align-bottom {
    vertical-align: bottom!important
  }

  .align-text-bottom {
    vertical-align: text-bottom!important
  }

  .align-text-top {
    vertical-align: text-top!important
  }

  .bg-primary {
    background-color: #0f59d1!important
  }

  a.bg-primary:focus,
  a.bg-primary:hover,
  button.bg-primary:focus,
  button.bg-primary:hover {
    background-color: #0c45a1!important
  }

  .bg-secondary {
    background-color: #6b7c93!important
  }

  a.bg-secondary:focus,
  a.bg-secondary:hover,
  button.bg-secondary:focus,
  button.bg-secondary:hover {
    background-color: #566375!important
  }

  .bg-success {
    background-color: #46c37b!important
  }

  a.bg-success:focus,
  a.bg-success:hover,
  button.bg-success:focus,
  button.bg-success:hover {
    background-color: #34a263!important
  }

  .bg-info {
    background-color: #70b9eb!important
  }

  a.bg-info:focus,
  a.bg-info:hover,
  button.bg-info:focus,
  button.bg-info:hover {
    background-color: #43a3e5!important
  }

  .bg-warning {
    background-color: #f3b760!important
  }

  a.bg-warning:focus,
  a.bg-warning:hover,
  button.bg-warning:focus,
  button.bg-warning:hover {
    background-color: #efa231!important
  }

  .bg-danger {
    background-color: #d26a5c!important
  }

  a.bg-danger:focus,
  a.bg-danger:hover,
  button.bg-danger:focus,
  button.bg-danger:hover {
    background-color: #c54736!important
  }

  .bg-light {
    background-color: #eee!important
  }

  a.bg-light:focus,
  a.bg-light:hover,
  button.bg-light:focus,
  button.bg-light:hover {
    background-color: #d5d5d5!important
  }

  .bg-dark {
    background-color: #{$dark-l-theme}!important
  }

  a.bg-dark:focus,
  a.bg-dark:hover,
  button.bg-dark:focus,
  button.bg-dark:hover {
    background-color: #0f1b28!important
  }

  .bg-gray {
    background-color: #d0d7df!important
  }

  a.bg-gray:focus,
  a.bg-gray:hover,
  button.bg-gray:focus,
  button.bg-gray:hover {
    background-color: #b2bdca!important
  }

  .bg-gray-dark {
    background-color: #d0d7df!important
  }

  a.bg-gray-dark:focus,
  a.bg-gray-dark:hover,
  button.bg-gray-dark:focus,
  button.bg-gray-dark:hover {
    background-color: #b2bdca!important
  }

  .bg-transparent {
    background-color: transparent!important
  }

  .border {
    border: 1px solid #ebebeb!important
  }

  .border-top {
    border-top: 1px solid #ebebeb!important
  }

  .border-right {
    border-right: 1px solid #ebebeb!important
  }

  .border-bottom {
    border-bottom: 1px solid #ebebeb!important
  }

  .border-left {
    border-left: 1px solid #ebebeb!important
  }

  .border-0 {
    border: 0!important
  }

  .border-top-0 {
    border-top: 0!important
  }

  .border-right-0 {
    border-right: 0!important
  }

  .border-bottom-0 {
    border-bottom: 0!important
  }

  .border-left-0 {
    border-left: 0!important
  }

  .border-primary {
    border-color: #0f59d1!important
  }

  .border-secondary {
    border-color: #6b7c93!important
  }

  .border-success {
    border-color: #46c37b!important
  }

  .border-info {
    border-color: #70b9eb!important
  }

  .border-warning {
    border-color: #f3b760!important
  }

  .border-danger {
    border-color: #d26a5c!important
  }

  .border-light {
    border-color: #eee!important
  }

  .border-dark {
    border-color: #{$dark-l-theme}!important
  }

  .border-gray,
  .border-gray-dark {
    border-color: #d0d7df!important
  }

  .border-white {
    border-color: #fff!important
  }

  .rounded-sm {
    border-radius: .2rem!important
  }

  .rounded {
    border-radius: 8px!important
  }

  .rounded-top {
    border-top-left-radius: 8px!important
  }

  .rounded-right,
  .rounded-top {
    border-top-right-radius: 8px!important
  }

  .rounded-bottom,
  .rounded-right {
    border-bottom-right-radius: 8px!important
  }

  .rounded-bottom,
  .rounded-left {
    border-bottom-left-radius: 8px!important
  }

  .rounded-left {
    border-top-left-radius: 8px!important
  }

  .rounded-lg {
    border-radius: .3rem!important
  }

  .rounded-circle {
    border-radius: 50%!important
  }

  .rounded-pill {
    border-radius: 50rem!important
  }

  .rounded-0 {
    border-radius: 0!important
  }

  .clearfix:after {
    display: block;
    clear: both;
    content: ""
  }

  .d-none {
    display: none!important
  }

  .d-inline {
    display: inline!important
  }

  .d-inline-block {
    display: inline-block!important
  }

  .d-block {
    display: block!important
  }

  .d-table {
    display: table!important
  }

  .d-table-row {
    display: table-row!important
  }

  .d-table-cell {
    display: table-cell!important
  }

  .d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important
  }

  .d-inline-flex {
    display: -webkit-inline-box!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
  }

  @media (min-width:576px) {
    .d-sm-none {
      display: none!important
    }
    .d-sm-inline {
      display: inline!important
    }
    .d-sm-inline-block {
      display: inline-block!important
    }
    .d-sm-block {
      display: block!important
    }
    .d-sm-table {
      display: table!important
    }
    .d-sm-table-row {
      display: table-row!important
    }
    .d-sm-table-cell {
      display: table-cell!important
    }
    .d-sm-flex {
      display: -webkit-box!important;
      display: -ms-flexbox!important;
      display: flex!important
    }
    .d-sm-inline-flex {
      display: -webkit-inline-box!important;
      display: -ms-inline-flexbox!important;
      display: inline-flex!important
    }
  }

  @media (min-width:768px) {
    .d-md-none {
      display: none!important
    }
    .d-md-inline {
      display: inline!important
    }
    .d-md-inline-block {
      display: inline-block!important
    }
    .d-md-block {
      display: block!important
    }
    .d-md-table {
      display: table!important
    }
    .d-md-table-row {
      display: table-row!important
    }
    .d-md-table-cell {
      display: table-cell!important
    }
    .d-md-flex {
      display: -webkit-box!important;
      display: -ms-flexbox!important;
      display: flex!important
    }
    .d-md-inline-flex {
      display: -webkit-inline-box!important;
      display: -ms-inline-flexbox!important;
      display: inline-flex!important
    }
  }

  @media (min-width:992px) {
    .d-lg-none {
      display: none!important
    }
    .d-lg-inline {
      display: inline!important
    }
    .d-lg-inline-block {
      display: inline-block!important
    }
    .d-lg-block {
      display: block!important
    }
    .d-lg-table {
      display: table!important
    }
    .d-lg-table-row {
      display: table-row!important
    }
    .d-lg-table-cell {
      display: table-cell!important
    }
    .d-lg-flex {
      display: -webkit-box!important;
      display: -ms-flexbox!important;
      display: flex!important
    }
    .d-lg-inline-flex {
      display: -webkit-inline-box!important;
      display: -ms-inline-flexbox!important;
      display: inline-flex!important
    }
  }

  @media (min-width:1200px) {
    .d-xl-none {
      display: none!important
    }
    .d-xl-inline {
      display: inline!important
    }
    .d-xl-inline-block {
      display: inline-block!important
    }
    .d-xl-block {
      display: block!important
    }
    .d-xl-table {
      display: table!important
    }
    .d-xl-table-row {
      display: table-row!important
    }
    .d-xl-table-cell {
      display: table-cell!important
    }
    .d-xl-flex {
      display: -webkit-box!important;
      display: -ms-flexbox!important;
      display: flex!important
    }
    .d-xl-inline-flex {
      display: -webkit-inline-box!important;
      display: -ms-inline-flexbox!important;
      display: inline-flex!important
    }
  }

  @media print {
    .d-print-none {
      display: none!important
    }
    .d-print-inline {
      display: inline!important
    }
    .d-print-inline-block {
      display: inline-block!important
    }
    .d-print-block {
      display: block!important
    }
    .d-print-table {
      display: table!important
    }
    .d-print-table-row {
      display: table-row!important
    }
    .d-print-table-cell {
      display: table-cell!important
    }
    .d-print-flex {
      display: -webkit-box!important;
      display: -ms-flexbox!important;
      display: flex!important
    }
    .d-print-inline-flex {
      display: -webkit-inline-box!important;
      display: -ms-inline-flexbox!important;
      display: inline-flex!important
    }
  }

  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
  }

  .embed-responsive:before {
    display: block;
    content: ""
  }

  .embed-responsive .embed-responsive-item,
  .embed-responsive embed,
  .embed-responsive iframe,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
  }

  .embed-responsive-21by9:before {
    padding-top: 42.85714%
  }

  .embed-responsive-16by9:before {
    padding-top: 56.25%
  }

  .embed-responsive-4by3:before {
    padding-top: 75%
  }

  .embed-responsive-1by1:before {
    padding-top: 100%
  }

  .flex-row {
    -webkit-box-orient: horizontal!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important
  }

  .flex-column,
  .flex-row {
    -webkit-box-direction: normal!important
  }

  .flex-column {
    -webkit-box-orient: vertical!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important
  }

  .flex-row-reverse {
    -webkit-box-orient: horizontal!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
  }

  .flex-column-reverse,
  .flex-row-reverse {
    -webkit-box-direction: reverse!important
  }

  .flex-column-reverse {
    -webkit-box-orient: vertical!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
  }

  .flex-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
  }

  .flex-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
  }

  .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
  }

  .flex-fill {
    -webkit-box-flex: 1!important;
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important
  }

  .flex-grow-0 {
    -webkit-box-flex: 0!important;
    -ms-flex-positive: 0!important;
    flex-grow: 0!important
  }

  .flex-grow-1 {
    -webkit-box-flex: 1!important;
    -ms-flex-positive: 1!important;
    flex-grow: 1!important
  }

  .flex-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0!important
  }

  .flex-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1!important
  }

  .justify-content-start {
    -webkit-box-pack: start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
  }

  .justify-content-end {
    -webkit-box-pack: end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
  }

  .justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important
  }

  .justify-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
  }

  .justify-content-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
  }

  .align-items-start {
    -webkit-box-align: start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important
  }

  .align-items-end {
    -webkit-box-align: end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important
  }

  .align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
  }

  .align-items-baseline {
    -webkit-box-align: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important
  }

  .align-items-stretch {
    -webkit-box-align: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important
  }

  .align-content-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
  }

  .align-content-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
  }

  .align-content-center {
    -ms-flex-line-pack: center!important;
    align-content: center!important
  }

  .align-content-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
  }

  .align-content-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
  }

  .align-content-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
  }

  .align-self-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto!important
  }

  .align-self-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
  }

  .align-self-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
  }

  .align-self-center {
    -ms-flex-item-align: center!important;
    align-self: center!important
  }

  .align-self-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
  }

  .align-self-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important
  }

  @media (min-width:576px) {
    .flex-sm-row {
      -webkit-box-orient: horizontal!important;
      -ms-flex-direction: row!important;
      flex-direction: row!important
    }
    .flex-sm-column,
    .flex-sm-row {
      -webkit-box-direction: normal!important
    }
    .flex-sm-column {
      -webkit-box-orient: vertical!important;
      -ms-flex-direction: column!important;
      flex-direction: column!important
    }
    .flex-sm-row-reverse {
      -webkit-box-orient: horizontal!important;
      -webkit-box-direction: reverse!important;
      -ms-flex-direction: row-reverse!important;
      flex-direction: row-reverse!important
    }
    .flex-sm-column-reverse {
      -webkit-box-orient: vertical!important;
      -webkit-box-direction: reverse!important;
      -ms-flex-direction: column-reverse!important;
      flex-direction: column-reverse!important
    }
    .flex-sm-wrap {
      -ms-flex-wrap: wrap!important;
      flex-wrap: wrap!important
    }
    .flex-sm-nowrap {
      -ms-flex-wrap: nowrap!important;
      flex-wrap: nowrap!important
    }
    .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse!important;
      flex-wrap: wrap-reverse!important
    }
    .flex-sm-fill {
      -webkit-box-flex: 1!important;
      -ms-flex: 1 1 auto!important;
      flex: 1 1 auto!important
    }
    .flex-sm-grow-0 {
      -webkit-box-flex: 0!important;
      -ms-flex-positive: 0!important;
      flex-grow: 0!important
    }
    .flex-sm-grow-1 {
      -webkit-box-flex: 1!important;
      -ms-flex-positive: 1!important;
      flex-grow: 1!important
    }
    .flex-sm-shrink-0 {
      -ms-flex-negative: 0!important;
      flex-shrink: 0!important
    }
    .flex-sm-shrink-1 {
      -ms-flex-negative: 1!important;
      flex-shrink: 1!important
    }
    .justify-content-sm-start {
      -webkit-box-pack: start!important;
      -ms-flex-pack: start!important;
      justify-content: flex-start!important
    }
    .justify-content-sm-end {
      -webkit-box-pack: end!important;
      -ms-flex-pack: end!important;
      justify-content: flex-end!important
    }
    .justify-content-sm-center {
      -webkit-box-pack: center!important;
      -ms-flex-pack: center!important;
      justify-content: center!important
    }
    .justify-content-sm-between {
      -webkit-box-pack: justify!important;
      -ms-flex-pack: justify!important;
      justify-content: space-between!important
    }
    .justify-content-sm-around {
      -ms-flex-pack: distribute!important;
      justify-content: space-around!important
    }
    .align-items-sm-start {
      -webkit-box-align: start!important;
      -ms-flex-align: start!important;
      align-items: flex-start!important
    }
    .align-items-sm-end {
      -webkit-box-align: end!important;
      -ms-flex-align: end!important;
      align-items: flex-end!important
    }
    .align-items-sm-center {
      -webkit-box-align: center!important;
      -ms-flex-align: center!important;
      align-items: center!important
    }
    .align-items-sm-baseline {
      -webkit-box-align: baseline!important;
      -ms-flex-align: baseline!important;
      align-items: baseline!important
    }
    .align-items-sm-stretch {
      -webkit-box-align: stretch!important;
      -ms-flex-align: stretch!important;
      align-items: stretch!important
    }
    .align-content-sm-start {
      -ms-flex-line-pack: start!important;
      align-content: flex-start!important
    }
    .align-content-sm-end {
      -ms-flex-line-pack: end!important;
      align-content: flex-end!important
    }
    .align-content-sm-center {
      -ms-flex-line-pack: center!important;
      align-content: center!important
    }
    .align-content-sm-between {
      -ms-flex-line-pack: justify!important;
      align-content: space-between!important
    }
    .align-content-sm-around {
      -ms-flex-line-pack: distribute!important;
      align-content: space-around!important
    }
    .align-content-sm-stretch {
      -ms-flex-line-pack: stretch!important;
      align-content: stretch!important
    }
    .align-self-sm-auto {
      -ms-flex-item-align: auto!important;
      align-self: auto!important
    }
    .align-self-sm-start {
      -ms-flex-item-align: start!important;
      align-self: flex-start!important
    }
    .align-self-sm-end {
      -ms-flex-item-align: end!important;
      align-self: flex-end!important
    }
    .align-self-sm-center {
      -ms-flex-item-align: center!important;
      align-self: center!important
    }
    .align-self-sm-baseline {
      -ms-flex-item-align: baseline!important;
      align-self: baseline!important
    }
    .align-self-sm-stretch {
      -ms-flex-item-align: stretch!important;
      align-self: stretch!important
    }
  }

  @media (min-width:768px) {
    .flex-md-row {
      -webkit-box-orient: horizontal!important;
      -ms-flex-direction: row!important;
      flex-direction: row!important
    }
    .flex-md-column,
    .flex-md-row {
      -webkit-box-direction: normal!important
    }
    .flex-md-column {
      -webkit-box-orient: vertical!important;
      -ms-flex-direction: column!important;
      flex-direction: column!important
    }
    .flex-md-row-reverse {
      -webkit-box-orient: horizontal!important;
      -webkit-box-direction: reverse!important;
      -ms-flex-direction: row-reverse!important;
      flex-direction: row-reverse!important
    }
    .flex-md-column-reverse {
      -webkit-box-orient: vertical!important;
      -webkit-box-direction: reverse!important;
      -ms-flex-direction: column-reverse!important;
      flex-direction: column-reverse!important
    }
    .flex-md-wrap {
      -ms-flex-wrap: wrap!important;
      flex-wrap: wrap!important
    }
    .flex-md-nowrap {
      -ms-flex-wrap: nowrap!important;
      flex-wrap: nowrap!important
    }
    .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse!important;
      flex-wrap: wrap-reverse!important
    }
    .flex-md-fill {
      -webkit-box-flex: 1!important;
      -ms-flex: 1 1 auto!important;
      flex: 1 1 auto!important
    }
    .flex-md-grow-0 {
      -webkit-box-flex: 0!important;
      -ms-flex-positive: 0!important;
      flex-grow: 0!important
    }
    .flex-md-grow-1 {
      -webkit-box-flex: 1!important;
      -ms-flex-positive: 1!important;
      flex-grow: 1!important
    }
    .flex-md-shrink-0 {
      -ms-flex-negative: 0!important;
      flex-shrink: 0!important
    }
    .flex-md-shrink-1 {
      -ms-flex-negative: 1!important;
      flex-shrink: 1!important
    }
    .justify-content-md-start {
      -webkit-box-pack: start!important;
      -ms-flex-pack: start!important;
      justify-content: flex-start!important
    }
    .justify-content-md-end {
      -webkit-box-pack: end!important;
      -ms-flex-pack: end!important;
      justify-content: flex-end!important
    }
    .justify-content-md-center {
      -webkit-box-pack: center!important;
      -ms-flex-pack: center!important;
      justify-content: center!important
    }
    .justify-content-md-between {
      -webkit-box-pack: justify!important;
      -ms-flex-pack: justify!important;
      justify-content: space-between!important
    }
    .justify-content-md-around {
      -ms-flex-pack: distribute!important;
      justify-content: space-around!important
    }
    .align-items-md-start {
      -webkit-box-align: start!important;
      -ms-flex-align: start!important;
      align-items: flex-start!important
    }
    .align-items-md-end {
      -webkit-box-align: end!important;
      -ms-flex-align: end!important;
      align-items: flex-end!important
    }
    .align-items-md-center {
      -webkit-box-align: center!important;
      -ms-flex-align: center!important;
      align-items: center!important
    }
    .align-items-md-baseline {
      -webkit-box-align: baseline!important;
      -ms-flex-align: baseline!important;
      align-items: baseline!important
    }
    .align-items-md-stretch {
      -webkit-box-align: stretch!important;
      -ms-flex-align: stretch!important;
      align-items: stretch!important
    }
    .align-content-md-start {
      -ms-flex-line-pack: start!important;
      align-content: flex-start!important
    }
    .align-content-md-end {
      -ms-flex-line-pack: end!important;
      align-content: flex-end!important
    }
    .align-content-md-center {
      -ms-flex-line-pack: center!important;
      align-content: center!important
    }
    .align-content-md-between {
      -ms-flex-line-pack: justify!important;
      align-content: space-between!important
    }
    .align-content-md-around {
      -ms-flex-line-pack: distribute!important;
      align-content: space-around!important
    }
    .align-content-md-stretch {
      -ms-flex-line-pack: stretch!important;
      align-content: stretch!important
    }
    .align-self-md-auto {
      -ms-flex-item-align: auto!important;
      align-self: auto!important
    }
    .align-self-md-start {
      -ms-flex-item-align: start!important;
      align-self: flex-start!important
    }
    .align-self-md-end {
      -ms-flex-item-align: end!important;
      align-self: flex-end!important
    }
    .align-self-md-center {
      -ms-flex-item-align: center!important;
      align-self: center!important
    }
    .align-self-md-baseline {
      -ms-flex-item-align: baseline!important;
      align-self: baseline!important
    }
    .align-self-md-stretch {
      -ms-flex-item-align: stretch!important;
      align-self: stretch!important
    }
  }

  @media (min-width:992px) {
    .flex-lg-row {
      -webkit-box-orient: horizontal!important;
      -ms-flex-direction: row!important;
      flex-direction: row!important
    }
    .flex-lg-column,
    .flex-lg-row {
      -webkit-box-direction: normal!important
    }
    .flex-lg-column {
      -webkit-box-orient: vertical!important;
      -ms-flex-direction: column!important;
      flex-direction: column!important
    }
    .flex-lg-row-reverse {
      -webkit-box-orient: horizontal!important;
      -webkit-box-direction: reverse!important;
      -ms-flex-direction: row-reverse!important;
      flex-direction: row-reverse!important
    }
    .flex-lg-column-reverse {
      -webkit-box-orient: vertical!important;
      -webkit-box-direction: reverse!important;
      -ms-flex-direction: column-reverse!important;
      flex-direction: column-reverse!important
    }
    .flex-lg-wrap {
      -ms-flex-wrap: wrap!important;
      flex-wrap: wrap!important
    }
    .flex-lg-nowrap {
      -ms-flex-wrap: nowrap!important;
      flex-wrap: nowrap!important
    }
    .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse!important;
      flex-wrap: wrap-reverse!important
    }
    .flex-lg-fill {
      -webkit-box-flex: 1!important;
      -ms-flex: 1 1 auto!important;
      flex: 1 1 auto!important
    }
    .flex-lg-grow-0 {
      -webkit-box-flex: 0!important;
      -ms-flex-positive: 0!important;
      flex-grow: 0!important
    }
    .flex-lg-grow-1 {
      -webkit-box-flex: 1!important;
      -ms-flex-positive: 1!important;
      flex-grow: 1!important
    }
    .flex-lg-shrink-0 {
      -ms-flex-negative: 0!important;
      flex-shrink: 0!important
    }
    .flex-lg-shrink-1 {
      -ms-flex-negative: 1!important;
      flex-shrink: 1!important
    }
    .justify-content-lg-start {
      -webkit-box-pack: start!important;
      -ms-flex-pack: start!important;
      justify-content: flex-start!important
    }
    .justify-content-lg-end {
      -webkit-box-pack: end!important;
      -ms-flex-pack: end!important;
      justify-content: flex-end!important
    }
    .justify-content-lg-center {
      -webkit-box-pack: center!important;
      -ms-flex-pack: center!important;
      justify-content: center!important
    }
    .justify-content-lg-between {
      -webkit-box-pack: justify!important;
      -ms-flex-pack: justify!important;
      justify-content: space-between!important
    }
    .justify-content-lg-around {
      -ms-flex-pack: distribute!important;
      justify-content: space-around!important
    }
    .align-items-lg-start {
      -webkit-box-align: start!important;
      -ms-flex-align: start!important;
      align-items: flex-start!important
    }
    .align-items-lg-end {
      -webkit-box-align: end!important;
      -ms-flex-align: end!important;
      align-items: flex-end!important
    }
    .align-items-lg-center {
      -webkit-box-align: center!important;
      -ms-flex-align: center!important;
      align-items: center!important
    }
    .align-items-lg-baseline {
      -webkit-box-align: baseline!important;
      -ms-flex-align: baseline!important;
      align-items: baseline!important
    }
    .align-items-lg-stretch {
      -webkit-box-align: stretch!important;
      -ms-flex-align: stretch!important;
      align-items: stretch!important
    }
    .align-content-lg-start {
      -ms-flex-line-pack: start!important;
      align-content: flex-start!important
    }
    .align-content-lg-end {
      -ms-flex-line-pack: end!important;
      align-content: flex-end!important
    }
    .align-content-lg-center {
      -ms-flex-line-pack: center!important;
      align-content: center!important
    }
    .align-content-lg-between {
      -ms-flex-line-pack: justify!important;
      align-content: space-between!important
    }
    .align-content-lg-around {
      -ms-flex-line-pack: distribute!important;
      align-content: space-around!important
    }
    .align-content-lg-stretch {
      -ms-flex-line-pack: stretch!important;
      align-content: stretch!important
    }
    .align-self-lg-auto {
      -ms-flex-item-align: auto!important;
      align-self: auto!important
    }
    .align-self-lg-start {
      -ms-flex-item-align: start!important;
      align-self: flex-start!important
    }
    .align-self-lg-end {
      -ms-flex-item-align: end!important;
      align-self: flex-end!important
    }
    .align-self-lg-center {
      -ms-flex-item-align: center!important;
      align-self: center!important
    }
    .align-self-lg-baseline {
      -ms-flex-item-align: baseline!important;
      align-self: baseline!important
    }
    .align-self-lg-stretch {
      -ms-flex-item-align: stretch!important;
      align-self: stretch!important
    }
  }

  @media (min-width:1200px) {
    .flex-xl-row {
      -webkit-box-orient: horizontal!important;
      -ms-flex-direction: row!important;
      flex-direction: row!important
    }
    .flex-xl-column,
    .flex-xl-row {
      -webkit-box-direction: normal!important
    }
    .flex-xl-column {
      -webkit-box-orient: vertical!important;
      -ms-flex-direction: column!important;
      flex-direction: column!important
    }
    .flex-xl-row-reverse {
      -webkit-box-orient: horizontal!important;
      -webkit-box-direction: reverse!important;
      -ms-flex-direction: row-reverse!important;
      flex-direction: row-reverse!important
    }
    .flex-xl-column-reverse {
      -webkit-box-orient: vertical!important;
      -webkit-box-direction: reverse!important;
      -ms-flex-direction: column-reverse!important;
      flex-direction: column-reverse!important
    }
    .flex-xl-wrap {
      -ms-flex-wrap: wrap!important;
      flex-wrap: wrap!important
    }
    .flex-xl-nowrap {
      -ms-flex-wrap: nowrap!important;
      flex-wrap: nowrap!important
    }
    .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse!important;
      flex-wrap: wrap-reverse!important
    }
    .flex-xl-fill {
      -webkit-box-flex: 1!important;
      -ms-flex: 1 1 auto!important;
      flex: 1 1 auto!important
    }
    .flex-xl-grow-0 {
      -webkit-box-flex: 0!important;
      -ms-flex-positive: 0!important;
      flex-grow: 0!important
    }
    .flex-xl-grow-1 {
      -webkit-box-flex: 1!important;
      -ms-flex-positive: 1!important;
      flex-grow: 1!important
    }
    .flex-xl-shrink-0 {
      -ms-flex-negative: 0!important;
      flex-shrink: 0!important
    }
    .flex-xl-shrink-1 {
      -ms-flex-negative: 1!important;
      flex-shrink: 1!important
    }
    .justify-content-xl-start {
      -webkit-box-pack: start!important;
      -ms-flex-pack: start!important;
      justify-content: flex-start!important
    }
    .justify-content-xl-end {
      -webkit-box-pack: end!important;
      -ms-flex-pack: end!important;
      justify-content: flex-end!important
    }
    .justify-content-xl-center {
      -webkit-box-pack: center!important;
      -ms-flex-pack: center!important;
      justify-content: center!important
    }
    .justify-content-xl-between {
      -webkit-box-pack: justify!important;
      -ms-flex-pack: justify!important;
      justify-content: space-between!important
    }
    .justify-content-xl-around {
      -ms-flex-pack: distribute!important;
      justify-content: space-around!important
    }
    .align-items-xl-start {
      -webkit-box-align: start!important;
      -ms-flex-align: start!important;
      align-items: flex-start!important
    }
    .align-items-xl-end {
      -webkit-box-align: end!important;
      -ms-flex-align: end!important;
      align-items: flex-end!important
    }
    .align-items-xl-center {
      -webkit-box-align: center!important;
      -ms-flex-align: center!important;
      align-items: center!important
    }
    .align-items-xl-baseline {
      -webkit-box-align: baseline!important;
      -ms-flex-align: baseline!important;
      align-items: baseline!important
    }
    .align-items-xl-stretch {
      -webkit-box-align: stretch!important;
      -ms-flex-align: stretch!important;
      align-items: stretch!important
    }
    .align-content-xl-start {
      -ms-flex-line-pack: start!important;
      align-content: flex-start!important
    }
    .align-content-xl-end {
      -ms-flex-line-pack: end!important;
      align-content: flex-end!important
    }
    .align-content-xl-center {
      -ms-flex-line-pack: center!important;
      align-content: center!important
    }
    .align-content-xl-between {
      -ms-flex-line-pack: justify!important;
      align-content: space-between!important
    }
    .align-content-xl-around {
      -ms-flex-line-pack: distribute!important;
      align-content: space-around!important
    }
    .align-content-xl-stretch {
      -ms-flex-line-pack: stretch!important;
      align-content: stretch!important
    }
    .align-self-xl-auto {
      -ms-flex-item-align: auto!important;
      align-self: auto!important
    }
    .align-self-xl-start {
      -ms-flex-item-align: start!important;
      align-self: flex-start!important
    }
    .align-self-xl-end {
      -ms-flex-item-align: end!important;
      align-self: flex-end!important
    }
    .align-self-xl-center {
      -ms-flex-item-align: center!important;
      align-self: center!important
    }
    .align-self-xl-baseline {
      -ms-flex-item-align: baseline!important;
      align-self: baseline!important
    }
    .align-self-xl-stretch {
      -ms-flex-item-align: stretch!important;
      align-self: stretch!important
    }
  }

  .float-left {
    float: left!important
  }

  .float-right {
    float: right!important
  }

  .float-none {
    float: none!important
  }

  @media (min-width:576px) {
    .float-sm-left {
      float: left!important
    }
    .float-sm-right {
      float: right!important
    }
    .float-sm-none {
      float: none!important
    }
  }

  @media (min-width:768px) {
    .float-md-left {
      float: left!important
    }
    .float-md-right {
      float: right!important
    }
    .float-md-none {
      float: none!important
    }
  }

  @media (min-width:992px) {
    .float-lg-left {
      float: left!important
    }
    .float-lg-right {
      float: right!important
    }
    .float-lg-none {
      float: none!important
    }
  }

  @media (min-width:1200px) {
    .float-xl-left {
      float: left!important
    }
    .float-xl-right {
      float: right!important
    }
    .float-xl-none {
      float: none!important
    }
  }

  .user-select-all {
    -webkit-user-select: all!important;
    -moz-user-select: all!important;
    -ms-user-select: all!important;
    user-select: all!important
  }

  .user-select-auto {
    -webkit-user-select: auto!important;
    -moz-user-select: auto!important;
    -ms-user-select: auto!important;
    user-select: auto!important
  }

  .user-select-none {
    -webkit-user-select: none!important;
    -moz-user-select: none!important;
    -ms-user-select: none!important;
    user-select: none!important
  }

  .overflow-auto {
    overflow: auto!important
  }

  .overflow-hidden {
    overflow: hidden!important
  }

  .position-static {
    position: static!important
  }

  .position-relative {
    position: relative!important
  }

  .position-absolute {
    position: absolute!important
  }

  .position-fixed {
    position: fixed!important
  }

  .position-sticky {
    position: sticky!important
  }

  .fixed-top {
    top: 0
  }

  .fixed-bottom,
  .fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
  }

  .fixed-bottom {
    bottom: 0
  }

  @supports (position:sticky) {
    .sticky-top {
      position: sticky;
      top: 0;
      z-index: 1020
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
  }

  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
  }

  .shadow-sm {
    -webkit-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important
  }

  .shadow {
    -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important
  }

  .shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175)!important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175)!important
  }

  .shadow-none {
    -webkit-box-shadow: none!important;
    box-shadow: none!important
  }

  .w-25 {
    width: 25%!important
  }

  .w-50 {
    width: 50%!important
  }

  .w-75 {
    width: 75%!important
  }

  .w-100 {
    width: 100%!important
  }

  .w-auto {
    width: auto!important
  }

  .h-25 {
    height: 25%!important
  }

  .h-50 {
    height: 50%!important
  }

  .h-75 {
    height: 75%!important
  }

  .h-100 {
    height: 100%!important
  }

  .h-auto {
    height: auto!important
  }

  .mw-100 {
    max-width: 100%!important
  }

  .mh-100 {
    max-height: 100%!important
  }

  .min-vw-100 {
    min-width: 100vw!important
  }

  .min-vh-100 {
    min-height: 100vh!important
  }

  .vw-100 {
    width: 100vw!important
  }

  .vh-100 {
    height: 100vh!important
  }

  .m-0 {
    margin: 0!important
  }

  .mt-0,
  .my-0 {
    margin-top: 0!important
  }

  .mr-0,
  .mx-0 {
    margin-right: 0!important
  }

  .mb-0,
  .my-0 {
    margin-bottom: 0!important
  }

  .ml-0,
  .mx-0 {
    margin-left: 0!important
  }

  .m-1 {
    margin: .25rem!important
  }

  .mt-1,
  .my-1 {
    margin-top: .25rem!important
  }

  .mr-1,
  .mx-1 {
    margin-right: .25rem!important
  }

  .mb-1,
  .my-1 {
    margin-bottom: .25rem!important
  }

  .ml-1,
  .mx-1 {
    margin-left: .25rem!important
  }

  .m-2 {
    margin: .5rem!important
  }

  .mt-2,
  .my-2 {
    margin-top: .5rem!important
  }

  .mr-2,
  .mx-2 {
    margin-right: .5rem!important
  }

  .mb-2,
  .my-2 {
    margin-bottom: .5rem!important
  }

  .ml-2,
  .mx-2 {
    margin-left: .5rem!important
  }

  .m-3 {
    margin: 1rem!important
  }

  .mt-3,
  .my-3 {
    margin-top: 1rem!important
  }

  .mr-3,
  .mx-3 {
    margin-right: 1rem!important
  }

  .mb-3,
  .my-3 {
    margin-bottom: 1rem!important
  }

  .ml-3,
  .mx-3 {
    margin-left: 1rem!important
  }

  .m-4 {
    margin: 1.5rem!important
  }

  .mt-4,
  .my-4 {
    margin-top: 1.5rem!important
  }

  .mr-4,
  .mx-4 {
    margin-right: 1.5rem!important
  }

  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem!important
  }

  .ml-4,
  .mx-4 {
    margin-left: 1.5rem!important
  }

  .m-5 {
    margin: 3rem!important
  }

  .mt-5,
  .my-5 {
    margin-top: 3rem!important
  }

  .mr-5,
  .mx-5 {
    margin-right: 3rem!important
  }

  .mb-5,
  .my-5 {
    margin-bottom: 3rem!important
  }

  .ml-5,
  .mx-5 {
    margin-left: 3rem!important
  }

  .m-6 {
    margin: 4.5rem!important
  }

  .mt-6,
  .my-6 {
    margin-top: 4.5rem!important
  }

  .mr-6,
  .mx-6 {
    margin-right: 4.5rem!important
  }

  .mb-6,
  .my-6 {
    margin-bottom: 4.5rem!important
  }

  .ml-6,
  .mx-6 {
    margin-left: 4.5rem!important
  }

  .m-7 {
    margin: 6rem!important
  }

  .mt-7,
  .my-7 {
    margin-top: 6rem!important
  }

  .mr-7,
  .mx-7 {
    margin-right: 6rem!important
  }

  .mb-7,
  .my-7 {
    margin-bottom: 6rem!important
  }

  .ml-7,
  .mx-7 {
    margin-left: 6rem!important
  }

  .m-8 {
    margin: 9rem!important
  }

  .mt-8,
  .my-8 {
    margin-top: 9rem!important
  }

  .mr-8,
  .mx-8 {
    margin-right: 9rem!important
  }

  .mb-8,
  .my-8 {
    margin-bottom: 9rem!important
  }

  .ml-8,
  .mx-8 {
    margin-left: 9rem!important
  }

  .m-9 {
    margin: 12rem!important
  }

  .mt-9,
  .my-9 {
    margin-top: 12rem!important
  }

  .mr-9,
  .mx-9 {
    margin-right: 12rem!important
  }

  .mb-9,
  .my-9 {
    margin-bottom: 12rem!important
  }

  .ml-9,
  .mx-9 {
    margin-left: 12rem!important
  }

  .m-10 {
    margin: 18rem!important
  }

  .mt-10,
  .my-10 {
    margin-top: 18rem!important
  }

  .mr-10,
  .mx-10 {
    margin-right: 18rem!important
  }

  .mb-10,
  .my-10 {
    margin-bottom: 18rem!important
  }

  .ml-10,
  .mx-10 {
    margin-left: 18rem!important
  }

  .p-0 {
    padding: 0!important
  }

  .pt-0,
  .py-0 {
    padding-top: 0!important
  }

  .pr-0,
  .px-0 {
    padding-right: 0!important
  }

  .pb-0,
  .py-0 {
    padding-bottom: 0!important
  }

  .pl-0,
  .px-0 {
    padding-left: 0!important
  }

  .p-1 {
    padding: .25rem!important
  }

  .pt-1,
  .py-1 {
    padding-top: .25rem!important
  }

  .pr-1,
  .px-1 {
    padding-right: .25rem!important
  }

  .pb-1,
  .py-1 {
    padding-bottom: .25rem!important
  }

  .pl-1,
  .px-1 {
    padding-left: .25rem!important
  }

  .p-2 {
    padding: .5rem!important
  }

  .pt-2,
  .py-2 {
    padding-top: .5rem!important
  }

  .pr-2,
  .px-2 {
    padding-right: .5rem!important
  }

  .pb-2,
  .py-2 {
    padding-bottom: .5rem!important
  }

  .pl-2,
  .px-2 {
    padding-left: .5rem!important
  }

  .p-3 {
    padding: 1rem!important
  }

  .pt-3,
  .py-3 {
    padding-top: 1rem!important
  }

  .pr-3,
  .px-3 {
    padding-right: 1rem!important
  }

  .pb-3,
  .py-3 {
    padding-bottom: 1rem!important
  }

  .pl-3,
  .px-3 {
    padding-left: 1rem!important
  }

  .p-4 {
    padding: 1.5rem!important
  }

  .pt-4,
  .py-4 {
    padding-top: 1.5rem!important
  }

  .pr-4,
  .px-4 {
    padding-right: 1.5rem!important
  }

  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem!important
  }

  .pl-4,
  .px-4 {
    padding-left: 1.5rem!important
  }

  .p-5 {
    padding: 3rem!important
  }

  .pt-5,
  .py-5 {
    padding-top: 3rem!important
  }

  .pr-5,
  .px-5 {
    padding-right: 3rem!important
  }

  .pb-5,
  .py-5 {
    padding-bottom: 3rem!important
  }

  .pl-5,
  .px-5 {
    padding-left: 3rem!important
  }

  .p-6 {
    padding: 4.5rem!important
  }

  .pt-6,
  .py-6 {
    padding-top: 4.5rem!important
  }

  .pr-6,
  .px-6 {
    padding-right: 4.5rem!important
  }

  .pb-6,
  .py-6 {
    padding-bottom: 4.5rem!important
  }

  .pl-6,
  .px-6 {
    padding-left: 4.5rem!important
  }

  .p-7 {
    padding: 6rem!important
  }

  .pt-7,
  .py-7 {
    padding-top: 6rem!important
  }

  .pr-7,
  .px-7 {
    padding-right: 6rem!important
  }

  .pb-7,
  .py-7 {
    padding-bottom: 6rem!important
  }

  .pl-7,
  .px-7 {
    padding-left: 6rem!important
  }

  .p-8 {
    padding: 9rem!important
  }

  .pt-8,
  .py-8 {
    padding-top: 9rem!important
  }

  .pr-8,
  .px-8 {
    padding-right: 9rem!important
  }

  .pb-8,
  .py-8 {
    padding-bottom: 9rem!important
  }

  .pl-8,
  .px-8 {
    padding-left: 9rem!important
  }

  .p-9 {
    padding: 12rem!important
  }

  .pt-9,
  .py-9 {
    padding-top: 12rem!important
  }

  .pr-9,
  .px-9 {
    padding-right: 12rem!important
  }

  .pb-9,
  .py-9 {
    padding-bottom: 12rem!important
  }

  .pl-9,
  .px-9 {
    padding-left: 12rem!important
  }

  .p-10 {
    padding: 18rem!important
  }

  .pt-10,
  .py-10 {
    padding-top: 18rem!important
  }

  .pr-10,
  .px-10 {
    padding-right: 18rem!important
  }

  .pb-10,
  .py-10 {
    padding-bottom: 18rem!important
  }

  .pl-10,
  .px-10 {
    padding-left: 18rem!important
  }

  .m-n1 {
    margin: -.25rem!important
  }

  .mt-n1,
  .my-n1 {
    margin-top: -.25rem!important
  }

  .mr-n1,
  .mx-n1 {
    margin-right: -.25rem!important
  }

  .mb-n1,
  .my-n1 {
    margin-bottom: -.25rem!important
  }

  .ml-n1,
  .mx-n1 {
    margin-left: -.25rem!important
  }

  .m-n2 {
    margin: -.5rem!important
  }

  .mt-n2,
  .my-n2 {
    margin-top: -.5rem!important
  }

  .mr-n2,
  .mx-n2 {
    margin-right: -.5rem!important
  }

  .mb-n2,
  .my-n2 {
    margin-bottom: -.5rem!important
  }

  .ml-n2,
  .mx-n2 {
    margin-left: -.5rem!important
  }

  .m-n3 {
    margin: -1rem!important
  }

  .mt-n3,
  .my-n3 {
    margin-top: -1rem!important
  }

  .mr-n3,
  .mx-n3 {
    margin-right: -1rem!important
  }

  .mb-n3,
  .my-n3 {
    margin-bottom: -1rem!important
  }

  .ml-n3,
  .mx-n3 {
    margin-left: -1rem!important
  }

  .m-n4 {
    margin: -1.5rem!important
  }

  .mt-n4,
  .my-n4 {
    margin-top: -1.5rem!important
  }

  .mr-n4,
  .mx-n4 {
    margin-right: -1.5rem!important
  }

  .mb-n4,
  .my-n4 {
    margin-bottom: -1.5rem!important
  }

  .ml-n4,
  .mx-n4 {
    margin-left: -1.5rem!important
  }

  .m-n5 {
    margin: -3rem!important
  }

  .mt-n5,
  .my-n5 {
    margin-top: -3rem!important
  }

  .mr-n5,
  .mx-n5 {
    margin-right: -3rem!important
  }

  .mb-n5,
  .my-n5 {
    margin-bottom: -3rem!important
  }

  .ml-n5,
  .mx-n5 {
    margin-left: -3rem!important
  }

  .m-n6 {
    margin: -4.5rem!important
  }

  .mt-n6,
  .my-n6 {
    margin-top: -4.5rem!important
  }

  .mr-n6,
  .mx-n6 {
    margin-right: -4.5rem!important
  }

  .mb-n6,
  .my-n6 {
    margin-bottom: -4.5rem!important
  }

  .ml-n6,
  .mx-n6 {
    margin-left: -4.5rem!important
  }

  .m-n7 {
    margin: -6rem!important
  }

  .mt-n7,
  .my-n7 {
    margin-top: -6rem!important
  }

  .mr-n7,
  .mx-n7 {
    margin-right: -6rem!important
  }

  .mb-n7,
  .my-n7 {
    margin-bottom: -6rem!important
  }

  .ml-n7,
  .mx-n7 {
    margin-left: -6rem!important
  }

  .m-n8 {
    margin: -9rem!important
  }

  .mt-n8,
  .my-n8 {
    margin-top: -9rem!important
  }

  .mr-n8,
  .mx-n8 {
    margin-right: -9rem!important
  }

  .mb-n8,
  .my-n8 {
    margin-bottom: -9rem!important
  }

  .ml-n8,
  .mx-n8 {
    margin-left: -9rem!important
  }

  .m-n9 {
    margin: -12rem!important
  }

  .mt-n9,
  .my-n9 {
    margin-top: -12rem!important
  }

  .mr-n9,
  .mx-n9 {
    margin-right: -12rem!important
  }

  .mb-n9,
  .my-n9 {
    margin-bottom: -12rem!important
  }

  .ml-n9,
  .mx-n9 {
    margin-left: -12rem!important
  }

  .m-n10 {
    margin: -18rem!important
  }

  .mt-n10,
  .my-n10 {
    margin-top: -18rem!important
  }

  .mr-n10,
  .mx-n10 {
    margin-right: -18rem!important
  }

  .mb-n10,
  .my-n10 {
    margin-bottom: -18rem!important
  }

  .ml-n10,
  .mx-n10 {
    margin-left: -18rem!important
  }

  .m-auto {
    margin: auto!important
  }

  .mt-auto,
  .my-auto {
    margin-top: auto!important
  }

  .mr-auto,
  .mx-auto {
    margin-right: auto!important
  }

  .mb-auto,
  .my-auto {
    margin-bottom: auto!important
  }

  .ml-auto,
  .mx-auto {
    margin-left: auto!important
  }

  @media (min-width:576px) {
    .m-sm-0 {
      margin: 0!important
    }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0!important
    }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0!important
    }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0!important
    }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0!important
    }
    .m-sm-1 {
      margin: .25rem!important
    }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: .25rem!important
    }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: .25rem!important
    }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: .25rem!important
    }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: .25rem!important
    }
    .m-sm-2 {
      margin: .5rem!important
    }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: .5rem!important
    }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: .5rem!important
    }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: .5rem!important
    }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: .5rem!important
    }
    .m-sm-3 {
      margin: 1rem!important
    }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 1rem!important
    }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 1rem!important
    }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 1rem!important
    }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 1rem!important
    }
    .m-sm-4 {
      margin: 1.5rem!important
    }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 1.5rem!important
    }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 1.5rem!important
    }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 1.5rem!important
    }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 1.5rem!important
    }
    .m-sm-5 {
      margin: 3rem!important
    }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 3rem!important
    }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 3rem!important
    }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 3rem!important
    }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 3rem!important
    }
    .m-sm-6 {
      margin: 4.5rem!important
    }
    .mt-sm-6,
    .my-sm-6 {
      margin-top: 4.5rem!important
    }
    .mr-sm-6,
    .mx-sm-6 {
      margin-right: 4.5rem!important
    }
    .mb-sm-6,
    .my-sm-6 {
      margin-bottom: 4.5rem!important
    }
    .ml-sm-6,
    .mx-sm-6 {
      margin-left: 4.5rem!important
    }
    .m-sm-7 {
      margin: 6rem!important
    }
    .mt-sm-7,
    .my-sm-7 {
      margin-top: 6rem!important
    }
    .mr-sm-7,
    .mx-sm-7 {
      margin-right: 6rem!important
    }
    .mb-sm-7,
    .my-sm-7 {
      margin-bottom: 6rem!important
    }
    .ml-sm-7,
    .mx-sm-7 {
      margin-left: 6rem!important
    }
    .m-sm-8 {
      margin: 9rem!important
    }
    .mt-sm-8,
    .my-sm-8 {
      margin-top: 9rem!important
    }
    .mr-sm-8,
    .mx-sm-8 {
      margin-right: 9rem!important
    }
    .mb-sm-8,
    .my-sm-8 {
      margin-bottom: 9rem!important
    }
    .ml-sm-8,
    .mx-sm-8 {
      margin-left: 9rem!important
    }
    .m-sm-9 {
      margin: 12rem!important
    }
    .mt-sm-9,
    .my-sm-9 {
      margin-top: 12rem!important
    }
    .mr-sm-9,
    .mx-sm-9 {
      margin-right: 12rem!important
    }
    .mb-sm-9,
    .my-sm-9 {
      margin-bottom: 12rem!important
    }
    .ml-sm-9,
    .mx-sm-9 {
      margin-left: 12rem!important
    }
    .m-sm-10 {
      margin: 18rem!important
    }
    .mt-sm-10,
    .my-sm-10 {
      margin-top: 18rem!important
    }
    .mr-sm-10,
    .mx-sm-10 {
      margin-right: 18rem!important
    }
    .mb-sm-10,
    .my-sm-10 {
      margin-bottom: 18rem!important
    }
    .ml-sm-10,
    .mx-sm-10 {
      margin-left: 18rem!important
    }
    .p-sm-0 {
      padding: 0!important
    }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0!important
    }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0!important
    }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0!important
    }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0!important
    }
    .p-sm-1 {
      padding: .25rem!important
    }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: .25rem!important
    }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: .25rem!important
    }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: .25rem!important
    }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: .25rem!important
    }
    .p-sm-2 {
      padding: .5rem!important
    }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: .5rem!important
    }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: .5rem!important
    }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: .5rem!important
    }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: .5rem!important
    }
    .p-sm-3 {
      padding: 1rem!important
    }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1rem!important
    }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1rem!important
    }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1rem!important
    }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1rem!important
    }
    .p-sm-4 {
      padding: 1.5rem!important
    }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 1.5rem!important
    }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 1.5rem!important
    }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 1.5rem!important
    }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 1.5rem!important
    }
    .p-sm-5 {
      padding: 3rem!important
    }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 3rem!important
    }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 3rem!important
    }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 3rem!important
    }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 3rem!important
    }
    .p-sm-6 {
      padding: 4.5rem!important
    }
    .pt-sm-6,
    .py-sm-6 {
      padding-top: 4.5rem!important
    }
    .pr-sm-6,
    .px-sm-6 {
      padding-right: 4.5rem!important
    }
    .pb-sm-6,
    .py-sm-6 {
      padding-bottom: 4.5rem!important
    }
    .pl-sm-6,
    .px-sm-6 {
      padding-left: 4.5rem!important
    }
    .p-sm-7 {
      padding: 6rem!important
    }
    .pt-sm-7,
    .py-sm-7 {
      padding-top: 6rem!important
    }
    .pr-sm-7,
    .px-sm-7 {
      padding-right: 6rem!important
    }
    .pb-sm-7,
    .py-sm-7 {
      padding-bottom: 6rem!important
    }
    .pl-sm-7,
    .px-sm-7 {
      padding-left: 6rem!important
    }
    .p-sm-8 {
      padding: 9rem!important
    }
    .pt-sm-8,
    .py-sm-8 {
      padding-top: 9rem!important
    }
    .pr-sm-8,
    .px-sm-8 {
      padding-right: 9rem!important
    }
    .pb-sm-8,
    .py-sm-8 {
      padding-bottom: 9rem!important
    }
    .pl-sm-8,
    .px-sm-8 {
      padding-left: 9rem!important
    }
    .p-sm-9 {
      padding: 12rem!important
    }
    .pt-sm-9,
    .py-sm-9 {
      padding-top: 12rem!important
    }
    .pr-sm-9,
    .px-sm-9 {
      padding-right: 12rem!important
    }
    .pb-sm-9,
    .py-sm-9 {
      padding-bottom: 12rem!important
    }
    .pl-sm-9,
    .px-sm-9 {
      padding-left: 12rem!important
    }
    .p-sm-10 {
      padding: 18rem!important
    }
    .pt-sm-10,
    .py-sm-10 {
      padding-top: 18rem!important
    }
    .pr-sm-10,
    .px-sm-10 {
      padding-right: 18rem!important
    }
    .pb-sm-10,
    .py-sm-10 {
      padding-bottom: 18rem!important
    }
    .pl-sm-10,
    .px-sm-10 {
      padding-left: 18rem!important
    }
    .m-sm-n1 {
      margin: -.25rem!important
    }
    .mt-sm-n1,
    .my-sm-n1 {
      margin-top: -.25rem!important
    }
    .mr-sm-n1,
    .mx-sm-n1 {
      margin-right: -.25rem!important
    }
    .mb-sm-n1,
    .my-sm-n1 {
      margin-bottom: -.25rem!important
    }
    .ml-sm-n1,
    .mx-sm-n1 {
      margin-left: -.25rem!important
    }
    .m-sm-n2 {
      margin: -.5rem!important
    }
    .mt-sm-n2,
    .my-sm-n2 {
      margin-top: -.5rem!important
    }
    .mr-sm-n2,
    .mx-sm-n2 {
      margin-right: -.5rem!important
    }
    .mb-sm-n2,
    .my-sm-n2 {
      margin-bottom: -.5rem!important
    }
    .ml-sm-n2,
    .mx-sm-n2 {
      margin-left: -.5rem!important
    }
    .m-sm-n3 {
      margin: -1rem!important
    }
    .mt-sm-n3,
    .my-sm-n3 {
      margin-top: -1rem!important
    }
    .mr-sm-n3,
    .mx-sm-n3 {
      margin-right: -1rem!important
    }
    .mb-sm-n3,
    .my-sm-n3 {
      margin-bottom: -1rem!important
    }
    .ml-sm-n3,
    .mx-sm-n3 {
      margin-left: -1rem!important
    }
    .m-sm-n4 {
      margin: -1.5rem!important
    }
    .mt-sm-n4,
    .my-sm-n4 {
      margin-top: -1.5rem!important
    }
    .mr-sm-n4,
    .mx-sm-n4 {
      margin-right: -1.5rem!important
    }
    .mb-sm-n4,
    .my-sm-n4 {
      margin-bottom: -1.5rem!important
    }
    .ml-sm-n4,
    .mx-sm-n4 {
      margin-left: -1.5rem!important
    }
    .m-sm-n5 {
      margin: -3rem!important
    }
    .mt-sm-n5,
    .my-sm-n5 {
      margin-top: -3rem!important
    }
    .mr-sm-n5,
    .mx-sm-n5 {
      margin-right: -3rem!important
    }
    .mb-sm-n5,
    .my-sm-n5 {
      margin-bottom: -3rem!important
    }
    .ml-sm-n5,
    .mx-sm-n5 {
      margin-left: -3rem!important
    }
    .m-sm-n6 {
      margin: -4.5rem!important
    }
    .mt-sm-n6,
    .my-sm-n6 {
      margin-top: -4.5rem!important
    }
    .mr-sm-n6,
    .mx-sm-n6 {
      margin-right: -4.5rem!important
    }
    .mb-sm-n6,
    .my-sm-n6 {
      margin-bottom: -4.5rem!important
    }
    .ml-sm-n6,
    .mx-sm-n6 {
      margin-left: -4.5rem!important
    }
    .m-sm-n7 {
      margin: -6rem!important
    }
    .mt-sm-n7,
    .my-sm-n7 {
      margin-top: -6rem!important
    }
    .mr-sm-n7,
    .mx-sm-n7 {
      margin-right: -6rem!important
    }
    .mb-sm-n7,
    .my-sm-n7 {
      margin-bottom: -6rem!important
    }
    .ml-sm-n7,
    .mx-sm-n7 {
      margin-left: -6rem!important
    }
    .m-sm-n8 {
      margin: -9rem!important
    }
    .mt-sm-n8,
    .my-sm-n8 {
      margin-top: -9rem!important
    }
    .mr-sm-n8,
    .mx-sm-n8 {
      margin-right: -9rem!important
    }
    .mb-sm-n8,
    .my-sm-n8 {
      margin-bottom: -9rem!important
    }
    .ml-sm-n8,
    .mx-sm-n8 {
      margin-left: -9rem!important
    }
    .m-sm-n9 {
      margin: -12rem!important
    }
    .mt-sm-n9,
    .my-sm-n9 {
      margin-top: -12rem!important
    }
    .mr-sm-n9,
    .mx-sm-n9 {
      margin-right: -12rem!important
    }
    .mb-sm-n9,
    .my-sm-n9 {
      margin-bottom: -12rem!important
    }
    .ml-sm-n9,
    .mx-sm-n9 {
      margin-left: -12rem!important
    }
    .m-sm-n10 {
      margin: -18rem!important
    }
    .mt-sm-n10,
    .my-sm-n10 {
      margin-top: -18rem!important
    }
    .mr-sm-n10,
    .mx-sm-n10 {
      margin-right: -18rem!important
    }
    .mb-sm-n10,
    .my-sm-n10 {
      margin-bottom: -18rem!important
    }
    .ml-sm-n10,
    .mx-sm-n10 {
      margin-left: -18rem!important
    }
    .m-sm-auto {
      margin: auto!important
    }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto!important
    }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto!important
    }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto!important
    }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto!important
    }
  }

  @media (min-width:768px) {
    .m-md-0 {
      margin: 0!important
    }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0!important
    }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0!important
    }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0!important
    }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0!important
    }
    .m-md-1 {
      margin: .25rem!important
    }
    .mt-md-1,
    .my-md-1 {
      margin-top: .25rem!important
    }
    .mr-md-1,
    .mx-md-1 {
      margin-right: .25rem!important
    }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: .25rem!important
    }
    .ml-md-1,
    .mx-md-1 {
      margin-left: .25rem!important
    }
    .m-md-2 {
      margin: .5rem!important
    }
    .mt-md-2,
    .my-md-2 {
      margin-top: .5rem!important
    }
    .mr-md-2,
    .mx-md-2 {
      margin-right: .5rem!important
    }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: .5rem!important
    }
    .ml-md-2,
    .mx-md-2 {
      margin-left: .5rem!important
    }
    .m-md-3 {
      margin: 1rem!important
    }
    .mt-md-3,
    .my-md-3 {
      margin-top: 1rem!important
    }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 1rem!important
    }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 1rem!important
    }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 1rem!important
    }
    .m-md-4 {
      margin: 1.5rem!important
    }
    .mt-md-4,
    .my-md-4 {
      margin-top: 1.5rem!important
    }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 1.5rem!important
    }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 1.5rem!important
    }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 1.5rem!important
    }
    .m-md-5 {
      margin: 3rem!important
    }
    .mt-md-5,
    .my-md-5 {
      margin-top: 3rem!important
    }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 3rem!important
    }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 3rem!important
    }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 3rem!important
    }
    .m-md-6 {
      margin: 4.5rem!important
    }
    .mt-md-6,
    .my-md-6 {
      margin-top: 4.5rem!important
    }
    .mr-md-6,
    .mx-md-6 {
      margin-right: 4.5rem!important
    }
    .mb-md-6,
    .my-md-6 {
      margin-bottom: 4.5rem!important
    }
    .ml-md-6,
    .mx-md-6 {
      margin-left: 4.5rem!important
    }
    .m-md-7 {
      margin: 6rem!important
    }
    .mt-md-7,
    .my-md-7 {
      margin-top: 6rem!important
    }
    .mr-md-7,
    .mx-md-7 {
      margin-right: 6rem!important
    }
    .mb-md-7,
    .my-md-7 {
      margin-bottom: 6rem!important
    }
    .ml-md-7,
    .mx-md-7 {
      margin-left: 6rem!important
    }
    .m-md-8 {
      margin: 9rem!important
    }
    .mt-md-8,
    .my-md-8 {
      margin-top: 9rem!important
    }
    .mr-md-8,
    .mx-md-8 {
      margin-right: 9rem!important
    }
    .mb-md-8,
    .my-md-8 {
      margin-bottom: 9rem!important
    }
    .ml-md-8,
    .mx-md-8 {
      margin-left: 9rem!important
    }
    .m-md-9 {
      margin: 12rem!important
    }
    .mt-md-9,
    .my-md-9 {
      margin-top: 12rem!important
    }
    .mr-md-9,
    .mx-md-9 {
      margin-right: 12rem!important
    }
    .mb-md-9,
    .my-md-9 {
      margin-bottom: 12rem!important
    }
    .ml-md-9,
    .mx-md-9 {
      margin-left: 12rem!important
    }
    .m-md-10 {
      margin: 18rem!important
    }
    .mt-md-10,
    .my-md-10 {
      margin-top: 18rem!important
    }
    .mr-md-10,
    .mx-md-10 {
      margin-right: 18rem!important
    }
    .mb-md-10,
    .my-md-10 {
      margin-bottom: 18rem!important
    }
    .ml-md-10,
    .mx-md-10 {
      margin-left: 18rem!important
    }
    .p-md-0 {
      padding: 0!important
    }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0!important
    }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0!important
    }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0!important
    }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0!important
    }
    .p-md-1 {
      padding: .25rem!important
    }
    .pt-md-1,
    .py-md-1 {
      padding-top: .25rem!important
    }
    .pr-md-1,
    .px-md-1 {
      padding-right: .25rem!important
    }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: .25rem!important
    }
    .pl-md-1,
    .px-md-1 {
      padding-left: .25rem!important
    }
    .p-md-2 {
      padding: .5rem!important
    }
    .pt-md-2,
    .py-md-2 {
      padding-top: .5rem!important
    }
    .pr-md-2,
    .px-md-2 {
      padding-right: .5rem!important
    }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: .5rem!important
    }
    .pl-md-2,
    .px-md-2 {
      padding-left: .5rem!important
    }
    .p-md-3 {
      padding: 1rem!important
    }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1rem!important
    }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1rem!important
    }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1rem!important
    }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1rem!important
    }
    .p-md-4 {
      padding: 1.5rem!important
    }
    .pt-md-4,
    .py-md-4 {
      padding-top: 1.5rem!important
    }
    .pr-md-4,
    .px-md-4 {
      padding-right: 1.5rem!important
    }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 1.5rem!important
    }
    .pl-md-4,
    .px-md-4 {
      padding-left: 1.5rem!important
    }
    .p-md-5 {
      padding: 3rem!important
    }
    .pt-md-5,
    .py-md-5 {
      padding-top: 3rem!important
    }
    .pr-md-5,
    .px-md-5 {
      padding-right: 3rem!important
    }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 3rem!important
    }
    .pl-md-5,
    .px-md-5 {
      padding-left: 3rem!important
    }
    .p-md-6 {
      padding: 4.5rem!important
    }
    .pt-md-6,
    .py-md-6 {
      padding-top: 4.5rem!important
    }
    .pr-md-6,
    .px-md-6 {
      padding-right: 4.5rem!important
    }
    .pb-md-6,
    .py-md-6 {
      padding-bottom: 4.5rem!important
    }
    .pl-md-6,
    .px-md-6 {
      padding-left: 4.5rem!important
    }
    .p-md-7 {
      padding: 6rem!important
    }
    .pt-md-7,
    .py-md-7 {
      padding-top: 6rem!important
    }
    .pr-md-7,
    .px-md-7 {
      padding-right: 6rem!important
    }
    .pb-md-7,
    .py-md-7 {
      padding-bottom: 6rem!important
    }
    .pl-md-7,
    .px-md-7 {
      padding-left: 6rem!important
    }
    .p-md-8 {
      padding: 9rem!important
    }
    .pt-md-8,
    .py-md-8 {
      padding-top: 9rem!important
    }
    .pr-md-8,
    .px-md-8 {
      padding-right: 9rem!important
    }
    .pb-md-8,
    .py-md-8 {
      padding-bottom: 9rem!important
    }
    .pl-md-8,
    .px-md-8 {
      padding-left: 9rem!important
    }
    .p-md-9 {
      padding: 12rem!important
    }
    .pt-md-9,
    .py-md-9 {
      padding-top: 12rem!important
    }
    .pr-md-9,
    .px-md-9 {
      padding-right: 12rem!important
    }
    .pb-md-9,
    .py-md-9 {
      padding-bottom: 12rem!important
    }
    .pl-md-9,
    .px-md-9 {
      padding-left: 12rem!important
    }
    .p-md-10 {
      padding: 18rem!important
    }
    .pt-md-10,
    .py-md-10 {
      padding-top: 18rem!important
    }
    .pr-md-10,
    .px-md-10 {
      padding-right: 18rem!important
    }
    .pb-md-10,
    .py-md-10 {
      padding-bottom: 18rem!important
    }
    .pl-md-10,
    .px-md-10 {
      padding-left: 18rem!important
    }
    .m-md-n1 {
      margin: -.25rem!important
    }
    .mt-md-n1,
    .my-md-n1 {
      margin-top: -.25rem!important
    }
    .mr-md-n1,
    .mx-md-n1 {
      margin-right: -.25rem!important
    }
    .mb-md-n1,
    .my-md-n1 {
      margin-bottom: -.25rem!important
    }
    .ml-md-n1,
    .mx-md-n1 {
      margin-left: -.25rem!important
    }
    .m-md-n2 {
      margin: -.5rem!important
    }
    .mt-md-n2,
    .my-md-n2 {
      margin-top: -.5rem!important
    }
    .mr-md-n2,
    .mx-md-n2 {
      margin-right: -.5rem!important
    }
    .mb-md-n2,
    .my-md-n2 {
      margin-bottom: -.5rem!important
    }
    .ml-md-n2,
    .mx-md-n2 {
      margin-left: -.5rem!important
    }
    .m-md-n3 {
      margin: -1rem!important
    }
    .mt-md-n3,
    .my-md-n3 {
      margin-top: -1rem!important
    }
    .mr-md-n3,
    .mx-md-n3 {
      margin-right: -1rem!important
    }
    .mb-md-n3,
    .my-md-n3 {
      margin-bottom: -1rem!important
    }
    .ml-md-n3,
    .mx-md-n3 {
      margin-left: -1rem!important
    }
    .m-md-n4 {
      margin: -1.5rem!important
    }
    .mt-md-n4,
    .my-md-n4 {
      margin-top: -1.5rem!important
    }
    .mr-md-n4,
    .mx-md-n4 {
      margin-right: -1.5rem!important
    }
    .mb-md-n4,
    .my-md-n4 {
      margin-bottom: -1.5rem!important
    }
    .ml-md-n4,
    .mx-md-n4 {
      margin-left: -1.5rem!important
    }
    .m-md-n5 {
      margin: -3rem!important
    }
    .mt-md-n5,
    .my-md-n5 {
      margin-top: -3rem!important
    }
    .mr-md-n5,
    .mx-md-n5 {
      margin-right: -3rem!important
    }
    .mb-md-n5,
    .my-md-n5 {
      margin-bottom: -3rem!important
    }
    .ml-md-n5,
    .mx-md-n5 {
      margin-left: -3rem!important
    }
    .m-md-n6 {
      margin: -4.5rem!important
    }
    .mt-md-n6,
    .my-md-n6 {
      margin-top: -4.5rem!important
    }
    .mr-md-n6,
    .mx-md-n6 {
      margin-right: -4.5rem!important
    }
    .mb-md-n6,
    .my-md-n6 {
      margin-bottom: -4.5rem!important
    }
    .ml-md-n6,
    .mx-md-n6 {
      margin-left: -4.5rem!important
    }
    .m-md-n7 {
      margin: -6rem!important
    }
    .mt-md-n7,
    .my-md-n7 {
      margin-top: -6rem!important
    }
    .mr-md-n7,
    .mx-md-n7 {
      margin-right: -6rem!important
    }
    .mb-md-n7,
    .my-md-n7 {
      margin-bottom: -6rem!important
    }
    .ml-md-n7,
    .mx-md-n7 {
      margin-left: -6rem!important
    }
    .m-md-n8 {
      margin: -9rem!important
    }
    .mt-md-n8,
    .my-md-n8 {
      margin-top: -9rem!important
    }
    .mr-md-n8,
    .mx-md-n8 {
      margin-right: -9rem!important
    }
    .mb-md-n8,
    .my-md-n8 {
      margin-bottom: -9rem!important
    }
    .ml-md-n8,
    .mx-md-n8 {
      margin-left: -9rem!important
    }
    .m-md-n9 {
      margin: -12rem!important
    }
    .mt-md-n9,
    .my-md-n9 {
      margin-top: -12rem!important
    }
    .mr-md-n9,
    .mx-md-n9 {
      margin-right: -12rem!important
    }
    .mb-md-n9,
    .my-md-n9 {
      margin-bottom: -12rem!important
    }
    .ml-md-n9,
    .mx-md-n9 {
      margin-left: -12rem!important
    }
    .m-md-n10 {
      margin: -18rem!important
    }
    .mt-md-n10,
    .my-md-n10 {
      margin-top: -18rem!important
    }
    .mr-md-n10,
    .mx-md-n10 {
      margin-right: -18rem!important
    }
    .mb-md-n10,
    .my-md-n10 {
      margin-bottom: -18rem!important
    }
    .ml-md-n10,
    .mx-md-n10 {
      margin-left: -18rem!important
    }
    .m-md-auto {
      margin: auto!important
    }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto!important
    }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto!important
    }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto!important
    }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto!important
    }
  }

  @media (min-width:992px) {
    .m-lg-0 {
      margin: 0!important
    }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0!important
    }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0!important
    }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0!important
    }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0!important
    }
    .m-lg-1 {
      margin: .25rem!important
    }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: .25rem!important
    }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: .25rem!important
    }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: .25rem!important
    }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: .25rem!important
    }
    .m-lg-2 {
      margin: .5rem!important
    }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: .5rem!important
    }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: .5rem!important
    }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: .5rem!important
    }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: .5rem!important
    }
    .m-lg-3 {
      margin: 1rem!important
    }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 1rem!important
    }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1rem!important
    }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 1rem!important
    }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 1rem!important
    }
    .m-lg-4 {
      margin: 1.5rem!important
    }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 1.5rem!important
    }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 1.5rem!important
    }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 1.5rem!important
    }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 1.5rem!important
    }
    .m-lg-5 {
      margin: 3rem!important
    }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 3rem!important
    }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 3rem!important
    }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 3rem!important
    }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 3rem!important
    }
    .m-lg-6 {
      margin: 4.5rem!important
    }
    .mt-lg-6,
    .my-lg-6 {
      margin-top: 4.5rem!important
    }
    .mr-lg-6,
    .mx-lg-6 {
      margin-right: 4.5rem!important
    }
    .mb-lg-6,
    .my-lg-6 {
      margin-bottom: 4.5rem!important
    }
    .ml-lg-6,
    .mx-lg-6 {
      margin-left: 4.5rem!important
    }
    .m-lg-7 {
      margin: 6rem!important
    }
    .mt-lg-7,
    .my-lg-7 {
      margin-top: 6rem!important
    }
    .mr-lg-7,
    .mx-lg-7 {
      margin-right: 6rem!important
    }
    .mb-lg-7,
    .my-lg-7 {
      margin-bottom: 6rem!important
    }
    .ml-lg-7,
    .mx-lg-7 {
      margin-left: 6rem!important
    }
    .m-lg-8 {
      margin: 9rem!important
    }
    .mt-lg-8,
    .my-lg-8 {
      margin-top: 9rem!important
    }
    .mr-lg-8,
    .mx-lg-8 {
      margin-right: 9rem!important
    }
    .mb-lg-8,
    .my-lg-8 {
      margin-bottom: 9rem!important
    }
    .ml-lg-8,
    .mx-lg-8 {
      margin-left: 9rem!important
    }
    .m-lg-9 {
      margin: 12rem!important
    }
    .mt-lg-9,
    .my-lg-9 {
      margin-top: 12rem!important
    }
    .mr-lg-9,
    .mx-lg-9 {
      margin-right: 12rem!important
    }
    .mb-lg-9,
    .my-lg-9 {
      margin-bottom: 12rem!important
    }
    .ml-lg-9,
    .mx-lg-9 {
      margin-left: 12rem!important
    }
    .m-lg-10 {
      margin: 18rem!important
    }
    .mt-lg-10,
    .my-lg-10 {
      margin-top: 18rem!important
    }
    .mr-lg-10,
    .mx-lg-10 {
      margin-right: 18rem!important
    }
    .mb-lg-10,
    .my-lg-10 {
      margin-bottom: 18rem!important
    }
    .ml-lg-10,
    .mx-lg-10 {
      margin-left: 18rem!important
    }
    .p-lg-0 {
      padding: 0!important
    }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0!important
    }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0!important
    }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0!important
    }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0!important
    }
    .p-lg-1 {
      padding: .25rem!important
    }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: .25rem!important
    }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: .25rem!important
    }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: .25rem!important
    }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: .25rem!important
    }
    .p-lg-2 {
      padding: .5rem!important
    }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: .5rem!important
    }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: .5rem!important
    }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: .5rem!important
    }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: .5rem!important
    }
    .p-lg-3 {
      padding: 1rem!important
    }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1rem!important
    }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1rem!important
    }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1rem!important
    }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1rem!important
    }
    .p-lg-4 {
      padding: 1.5rem!important
    }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 1.5rem!important
    }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 1.5rem!important
    }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 1.5rem!important
    }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 1.5rem!important
    }
    .p-lg-5 {
      padding: 3rem!important
    }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 3rem!important
    }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 3rem!important
    }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 3rem!important
    }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 3rem!important
    }
    .p-lg-6 {
      padding: 4.5rem!important
    }
    .pt-lg-6,
    .py-lg-6 {
      padding-top: 4.5rem!important
    }
    .pr-lg-6,
    .px-lg-6 {
      padding-right: 4.5rem!important
    }
    .pb-lg-6,
    .py-lg-6 {
      padding-bottom: 4.5rem!important
    }
    .pl-lg-6,
    .px-lg-6 {
      padding-left: 4.5rem!important
    }
    .p-lg-7 {
      padding: 6rem!important
    }
    .pt-lg-7,
    .py-lg-7 {
      padding-top: 6rem!important
    }
    .pr-lg-7,
    .px-lg-7 {
      padding-right: 6rem!important
    }
    .pb-lg-7,
    .py-lg-7 {
      padding-bottom: 6rem!important
    }
    .pl-lg-7,
    .px-lg-7 {
      padding-left: 6rem!important
    }
    .p-lg-8 {
      padding: 9rem!important
    }
    .pt-lg-8,
    .py-lg-8 {
      padding-top: 9rem!important
    }
    .pr-lg-8,
    .px-lg-8 {
      padding-right: 9rem!important
    }
    .pb-lg-8,
    .py-lg-8 {
      padding-bottom: 9rem!important
    }
    .pl-lg-8,
    .px-lg-8 {
      padding-left: 9rem!important
    }
    .p-lg-9 {
      padding: 12rem!important
    }
    .pt-lg-9,
    .py-lg-9 {
      padding-top: 12rem!important
    }
    .pr-lg-9,
    .px-lg-9 {
      padding-right: 12rem!important
    }
    .pb-lg-9,
    .py-lg-9 {
      padding-bottom: 12rem!important
    }
    .pl-lg-9,
    .px-lg-9 {
      padding-left: 12rem!important
    }
    .p-lg-10 {
      padding: 18rem!important
    }
    .pt-lg-10,
    .py-lg-10 {
      padding-top: 18rem!important
    }
    .pr-lg-10,
    .px-lg-10 {
      padding-right: 18rem!important
    }
    .pb-lg-10,
    .py-lg-10 {
      padding-bottom: 18rem!important
    }
    .pl-lg-10,
    .px-lg-10 {
      padding-left: 18rem!important
    }
    .m-lg-n1 {
      margin: -.25rem!important
    }
    .mt-lg-n1,
    .my-lg-n1 {
      margin-top: -.25rem!important
    }
    .mr-lg-n1,
    .mx-lg-n1 {
      margin-right: -.25rem!important
    }
    .mb-lg-n1,
    .my-lg-n1 {
      margin-bottom: -.25rem!important
    }
    .ml-lg-n1,
    .mx-lg-n1 {
      margin-left: -.25rem!important
    }
    .m-lg-n2 {
      margin: -.5rem!important
    }
    .mt-lg-n2,
    .my-lg-n2 {
      margin-top: -.5rem!important
    }
    .mr-lg-n2,
    .mx-lg-n2 {
      margin-right: -.5rem!important
    }
    .mb-lg-n2,
    .my-lg-n2 {
      margin-bottom: -.5rem!important
    }
    .ml-lg-n2,
    .mx-lg-n2 {
      margin-left: -.5rem!important
    }
    .m-lg-n3 {
      margin: -1rem!important
    }
    .mt-lg-n3,
    .my-lg-n3 {
      margin-top: -1rem!important
    }
    .mr-lg-n3,
    .mx-lg-n3 {
      margin-right: -1rem!important
    }
    .mb-lg-n3,
    .my-lg-n3 {
      margin-bottom: -1rem!important
    }
    .ml-lg-n3,
    .mx-lg-n3 {
      margin-left: -1rem!important
    }
    .m-lg-n4 {
      margin: -1.5rem!important
    }
    .mt-lg-n4,
    .my-lg-n4 {
      margin-top: -1.5rem!important
    }
    .mr-lg-n4,
    .mx-lg-n4 {
      margin-right: -1.5rem!important
    }
    .mb-lg-n4,
    .my-lg-n4 {
      margin-bottom: -1.5rem!important
    }
    .ml-lg-n4,
    .mx-lg-n4 {
      margin-left: -1.5rem!important
    }
    .m-lg-n5 {
      margin: -3rem!important
    }
    .mt-lg-n5,
    .my-lg-n5 {
      margin-top: -3rem!important
    }
    .mr-lg-n5,
    .mx-lg-n5 {
      margin-right: -3rem!important
    }
    .mb-lg-n5,
    .my-lg-n5 {
      margin-bottom: -3rem!important
    }
    .ml-lg-n5,
    .mx-lg-n5 {
      margin-left: -3rem!important
    }
    .m-lg-n6 {
      margin: -4.5rem!important
    }
    .mt-lg-n6,
    .my-lg-n6 {
      margin-top: -4.5rem!important
    }
    .mr-lg-n6,
    .mx-lg-n6 {
      margin-right: -4.5rem!important
    }
    .mb-lg-n6,
    .my-lg-n6 {
      margin-bottom: -4.5rem!important
    }
    .ml-lg-n6,
    .mx-lg-n6 {
      margin-left: -4.5rem!important
    }
    .m-lg-n7 {
      margin: -6rem!important
    }
    .mt-lg-n7,
    .my-lg-n7 {
      margin-top: -6rem!important
    }
    .mr-lg-n7,
    .mx-lg-n7 {
      margin-right: -6rem!important
    }
    .mb-lg-n7,
    .my-lg-n7 {
      margin-bottom: -6rem!important
    }
    .ml-lg-n7,
    .mx-lg-n7 {
      margin-left: -6rem!important
    }
    .m-lg-n8 {
      margin: -9rem!important
    }
    .mt-lg-n8,
    .my-lg-n8 {
      margin-top: -9rem!important
    }
    .mr-lg-n8,
    .mx-lg-n8 {
      margin-right: -9rem!important
    }
    .mb-lg-n8,
    .my-lg-n8 {
      margin-bottom: -9rem!important
    }
    .ml-lg-n8,
    .mx-lg-n8 {
      margin-left: -9rem!important
    }
    .m-lg-n9 {
      margin: -12rem!important
    }
    .mt-lg-n9,
    .my-lg-n9 {
      margin-top: -12rem!important
    }
    .mr-lg-n9,
    .mx-lg-n9 {
      margin-right: -12rem!important
    }
    .mb-lg-n9,
    .my-lg-n9 {
      margin-bottom: -12rem!important
    }
    .ml-lg-n9,
    .mx-lg-n9 {
      margin-left: -12rem!important
    }
    .m-lg-n10 {
      margin: -18rem!important
    }
    .mt-lg-n10,
    .my-lg-n10 {
      margin-top: -18rem!important
    }
    .mr-lg-n10,
    .mx-lg-n10 {
      margin-right: -18rem!important
    }
    .mb-lg-n10,
    .my-lg-n10 {
      margin-bottom: -18rem!important
    }
    .ml-lg-n10,
    .mx-lg-n10 {
      margin-left: -18rem!important
    }
    .m-lg-auto {
      margin: auto!important
    }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto!important
    }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto!important
    }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto!important
    }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto!important
    }
  }

  @media (min-width:1200px) {
    .m-xl-0 {
      margin: 0!important
    }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0!important
    }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0!important
    }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0!important
    }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0!important
    }
    .m-xl-1 {
      margin: .25rem!important
    }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: .25rem!important
    }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: .25rem!important
    }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: .25rem!important
    }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: .25rem!important
    }
    .m-xl-2 {
      margin: .5rem!important
    }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: .5rem!important
    }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: .5rem!important
    }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: .5rem!important
    }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: .5rem!important
    }
    .m-xl-3 {
      margin: 1rem!important
    }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 1rem!important
    }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 1rem!important
    }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 1rem!important
    }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 1rem!important
    }
    .m-xl-4 {
      margin: 1.5rem!important
    }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 1.5rem!important
    }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 1.5rem!important
    }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 1.5rem!important
    }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 1.5rem!important
    }
    .m-xl-5 {
      margin: 3rem!important
    }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 3rem!important
    }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 3rem!important
    }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 3rem!important
    }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 3rem!important
    }
    .m-xl-6 {
      margin: 4.5rem!important
    }
    .mt-xl-6,
    .my-xl-6 {
      margin-top: 4.5rem!important
    }
    .mr-xl-6,
    .mx-xl-6 {
      margin-right: 4.5rem!important
    }
    .mb-xl-6,
    .my-xl-6 {
      margin-bottom: 4.5rem!important
    }
    .ml-xl-6,
    .mx-xl-6 {
      margin-left: 4.5rem!important
    }
    .m-xl-7 {
      margin: 6rem!important
    }
    .mt-xl-7,
    .my-xl-7 {
      margin-top: 6rem!important
    }
    .mr-xl-7,
    .mx-xl-7 {
      margin-right: 6rem!important
    }
    .mb-xl-7,
    .my-xl-7 {
      margin-bottom: 6rem!important
    }
    .ml-xl-7,
    .mx-xl-7 {
      margin-left: 6rem!important
    }
    .m-xl-8 {
      margin: 9rem!important
    }
    .mt-xl-8,
    .my-xl-8 {
      margin-top: 9rem!important
    }
    .mr-xl-8,
    .mx-xl-8 {
      margin-right: 9rem!important
    }
    .mb-xl-8,
    .my-xl-8 {
      margin-bottom: 9rem!important
    }
    .ml-xl-8,
    .mx-xl-8 {
      margin-left: 9rem!important
    }
    .m-xl-9 {
      margin: 12rem!important
    }
    .mt-xl-9,
    .my-xl-9 {
      margin-top: 12rem!important
    }
    .mr-xl-9,
    .mx-xl-9 {
      margin-right: 12rem!important
    }
    .mb-xl-9,
    .my-xl-9 {
      margin-bottom: 12rem!important
    }
    .ml-xl-9,
    .mx-xl-9 {
      margin-left: 12rem!important
    }
    .m-xl-10 {
      margin: 18rem!important
    }
    .mt-xl-10,
    .my-xl-10 {
      margin-top: 18rem!important
    }
    .mr-xl-10,
    .mx-xl-10 {
      margin-right: 18rem!important
    }
    .mb-xl-10,
    .my-xl-10 {
      margin-bottom: 18rem!important
    }
    .ml-xl-10,
    .mx-xl-10 {
      margin-left: 18rem!important
    }
    .p-xl-0 {
      padding: 0!important
    }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0!important
    }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0!important
    }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0!important
    }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0!important
    }
    .p-xl-1 {
      padding: .25rem!important
    }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: .25rem!important
    }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: .25rem!important
    }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: .25rem!important
    }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: .25rem!important
    }
    .p-xl-2 {
      padding: .5rem!important
    }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: .5rem!important
    }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: .5rem!important
    }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: .5rem!important
    }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: .5rem!important
    }
    .p-xl-3 {
      padding: 1rem!important
    }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1rem!important
    }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1rem!important
    }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1rem!important
    }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1rem!important
    }
    .p-xl-4 {
      padding: 1.5rem!important
    }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 1.5rem!important
    }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 1.5rem!important
    }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 1.5rem!important
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 1.5rem!important
    }
    .p-xl-5 {
      padding: 3rem!important
    }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 3rem!important
    }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 3rem!important
    }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 3rem!important
    }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3rem!important
    }
    .p-xl-6 {
      padding: 4.5rem!important
    }
    .pt-xl-6,
    .py-xl-6 {
      padding-top: 4.5rem!important
    }
    .pr-xl-6,
    .px-xl-6 {
      padding-right: 4.5rem!important
    }
    .pb-xl-6,
    .py-xl-6 {
      padding-bottom: 4.5rem!important
    }
    .pl-xl-6,
    .px-xl-6 {
      padding-left: 4.5rem!important
    }
    .p-xl-7 {
      padding: 6rem!important
    }
    .pt-xl-7,
    .py-xl-7 {
      padding-top: 6rem!important
    }
    .pr-xl-7,
    .px-xl-7 {
      padding-right: 6rem!important
    }
    .pb-xl-7,
    .py-xl-7 {
      padding-bottom: 6rem!important
    }
    .pl-xl-7,
    .px-xl-7 {
      padding-left: 6rem!important
    }
    .p-xl-8 {
      padding: 9rem!important
    }
    .pt-xl-8,
    .py-xl-8 {
      padding-top: 9rem!important
    }
    .pr-xl-8,
    .px-xl-8 {
      padding-right: 9rem!important
    }
    .pb-xl-8,
    .py-xl-8 {
      padding-bottom: 9rem!important
    }
    .pl-xl-8,
    .px-xl-8 {
      padding-left: 9rem!important
    }
    .p-xl-9 {
      padding: 12rem!important
    }
    .pt-xl-9,
    .py-xl-9 {
      padding-top: 12rem!important
    }
    .pr-xl-9,
    .px-xl-9 {
      padding-right: 12rem!important
    }
    .pb-xl-9,
    .py-xl-9 {
      padding-bottom: 12rem!important
    }
    .pl-xl-9,
    .px-xl-9 {
      padding-left: 12rem!important
    }
    .p-xl-10 {
      padding: 18rem!important
    }
    .pt-xl-10,
    .py-xl-10 {
      padding-top: 18rem!important
    }
    .pr-xl-10,
    .px-xl-10 {
      padding-right: 18rem!important
    }
    .pb-xl-10,
    .py-xl-10 {
      padding-bottom: 18rem!important
    }
    .pl-xl-10,
    .px-xl-10 {
      padding-left: 18rem!important
    }
    .m-xl-n1 {
      margin: -.25rem!important
    }
    .mt-xl-n1,
    .my-xl-n1 {
      margin-top: -.25rem!important
    }
    .mr-xl-n1,
    .mx-xl-n1 {
      margin-right: -.25rem!important
    }
    .mb-xl-n1,
    .my-xl-n1 {
      margin-bottom: -.25rem!important
    }
    .ml-xl-n1,
    .mx-xl-n1 {
      margin-left: -.25rem!important
    }
    .m-xl-n2 {
      margin: -.5rem!important
    }
    .mt-xl-n2,
    .my-xl-n2 {
      margin-top: -.5rem!important
    }
    .mr-xl-n2,
    .mx-xl-n2 {
      margin-right: -.5rem!important
    }
    .mb-xl-n2,
    .my-xl-n2 {
      margin-bottom: -.5rem!important
    }
    .ml-xl-n2,
    .mx-xl-n2 {
      margin-left: -.5rem!important
    }
    .m-xl-n3 {
      margin: -1rem!important
    }
    .mt-xl-n3,
    .my-xl-n3 {
      margin-top: -1rem!important
    }
    .mr-xl-n3,
    .mx-xl-n3 {
      margin-right: -1rem!important
    }
    .mb-xl-n3,
    .my-xl-n3 {
      margin-bottom: -1rem!important
    }
    .ml-xl-n3,
    .mx-xl-n3 {
      margin-left: -1rem!important
    }
    .m-xl-n4 {
      margin: -1.5rem!important
    }
    .mt-xl-n4,
    .my-xl-n4 {
      margin-top: -1.5rem!important
    }
    .mr-xl-n4,
    .mx-xl-n4 {
      margin-right: -1.5rem!important
    }
    .mb-xl-n4,
    .my-xl-n4 {
      margin-bottom: -1.5rem!important
    }
    .ml-xl-n4,
    .mx-xl-n4 {
      margin-left: -1.5rem!important
    }
    .m-xl-n5 {
      margin: -3rem!important
    }
    .mt-xl-n5,
    .my-xl-n5 {
      margin-top: -3rem!important
    }
    .mr-xl-n5,
    .mx-xl-n5 {
      margin-right: -3rem!important
    }
    .mb-xl-n5,
    .my-xl-n5 {
      margin-bottom: -3rem!important
    }
    .ml-xl-n5,
    .mx-xl-n5 {
      margin-left: -3rem!important
    }
    .m-xl-n6 {
      margin: -4.5rem!important
    }
    .mt-xl-n6,
    .my-xl-n6 {
      margin-top: -4.5rem!important
    }
    .mr-xl-n6,
    .mx-xl-n6 {
      margin-right: -4.5rem!important
    }
    .mb-xl-n6,
    .my-xl-n6 {
      margin-bottom: -4.5rem!important
    }
    .ml-xl-n6,
    .mx-xl-n6 {
      margin-left: -4.5rem!important
    }
    .m-xl-n7 {
      margin: -6rem!important
    }
    .mt-xl-n7,
    .my-xl-n7 {
      margin-top: -6rem!important
    }
    .mr-xl-n7,
    .mx-xl-n7 {
      margin-right: -6rem!important
    }
    .mb-xl-n7,
    .my-xl-n7 {
      margin-bottom: -6rem!important
    }
    .ml-xl-n7,
    .mx-xl-n7 {
      margin-left: -6rem!important
    }
    .m-xl-n8 {
      margin: -9rem!important
    }
    .mt-xl-n8,
    .my-xl-n8 {
      margin-top: -9rem!important
    }
    .mr-xl-n8,
    .mx-xl-n8 {
      margin-right: -9rem!important
    }
    .mb-xl-n8,
    .my-xl-n8 {
      margin-bottom: -9rem!important
    }
    .ml-xl-n8,
    .mx-xl-n8 {
      margin-left: -9rem!important
    }
    .m-xl-n9 {
      margin: -12rem!important
    }
    .mt-xl-n9,
    .my-xl-n9 {
      margin-top: -12rem!important
    }
    .mr-xl-n9,
    .mx-xl-n9 {
      margin-right: -12rem!important
    }
    .mb-xl-n9,
    .my-xl-n9 {
      margin-bottom: -12rem!important
    }
    .ml-xl-n9,
    .mx-xl-n9 {
      margin-left: -12rem!important
    }
    .m-xl-n10 {
      margin: -18rem!important
    }
    .mt-xl-n10,
    .my-xl-n10 {
      margin-top: -18rem!important
    }
    .mr-xl-n10,
    .mx-xl-n10 {
      margin-right: -18rem!important
    }
    .mb-xl-n10,
    .my-xl-n10 {
      margin-bottom: -18rem!important
    }
    .ml-xl-n10,
    .mx-xl-n10 {
      margin-left: -18rem!important
    }
    .m-xl-auto {
      margin: auto!important
    }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto!important
    }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto!important
    }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto!important
    }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto!important
    }
  }

  .stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent
  }

  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace!important
  }

  .text-justify {
    text-align: justify!important
  }

  .text-wrap {
    white-space: normal!important
  }

  .text-nowrap {
    white-space: nowrap!important
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .text-left {
    text-align: left!important
  }

  .text-right {
    text-align: right!important
  }

  .text-center {
    text-align: center!important
  }

  @media (min-width:576px) {
    .text-sm-left {
      text-align: left!important
    }
    .text-sm-right {
      text-align: right!important
    }
    .text-sm-center {
      text-align: center!important
    }
  }

  @media (min-width:768px) {
    .text-md-left {
      text-align: left!important
    }
    .text-md-right {
      text-align: right!important
    }
    .text-md-center {
      text-align: center!important
    }
  }

  @media (min-width:992px) {
    .text-lg-left {
      text-align: left!important
    }
    .text-lg-right {
      text-align: right!important
    }
    .text-lg-center {
      text-align: center!important
    }
  }

  @media (min-width:1200px) {
    .text-xl-left {
      text-align: left!important
    }
    .text-xl-right {
      text-align: right!important
    }
    .text-xl-center {
      text-align: center!important
    }
  }

  .text-lowercase {
    text-transform: lowercase!important
  }

  .text-uppercase {
    text-transform: uppercase!important
  }

  .text-capitalize {
    text-transform: capitalize!important
  }

  .font-weight-light {
    font-weight: 300!important
  }

  .font-weight-lighter {
    font-weight: lighter!important
  }

  .font-weight-normal {
    font-weight: 400!important
  }

  .font-weight-bold {
    font-weight: 600!important
  }

  .font-weight-bolder {
    font-weight: bolder!important
  }

  .font-italic {
    font-style: italic!important
  }

  .text-primary {
    color: #0f59d1!important
  }

  a.text-primary.link-fx:before {
    background-color: #0f59d1
  }

  .text-secondary {
    color: #6b7c93!important
  }

  a.text-secondary.link-fx:before {
    background-color: #6b7c93
  }

  .text-success {
    color: #46c37b!important
  }

  a.text-success.link-fx:before {
    background-color: #46c37b
  }

  .text-info {
    color: #70b9eb!important
  }

  a.text-info.link-fx:before {
    background-color: #70b9eb
  }

  .text-warning {
    color: #f3b760!important
  }

  a.text-warning.link-fx:before {
    background-color: #f3b760
  }

  .text-danger {
    color: #d26a5c!important
  }

  a.text-danger.link-fx:before {
    background-color: #d26a5c
  }

  .text-light {
    color: #eee!important
  }

  a.text-light.link-fx:before {
    background-color: #eee
  }

  .text-dark {
    color: #{$dark-l-theme}!important
  }

  a.text-dark.link-fx:before {
    background-color: #{$dark-l-theme}
  }

  .text-gray {
    color: #d0d7df!important
  }

  a.text-gray.link-fx:before {
    background-color: #d0d7df
  }

  .text-gray-dark {
    color: #d0d7df!important
  }

  a.text-gray-dark.link-fx:before {
    background-color: #d0d7df
  }

  .text-body {
    color: #575757!important
  }

  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
  }

  .text-decoration-none {
    text-decoration: none!important
  }

  .text-break {
    word-break: break-word!important;
    word-wrap: break-word!important
  }

  .text-reset {
    color: inherit!important
  }

  .visible {
    visibility: visible!important
  }

  .invisible {
    visibility: hidden!important
  }

  @media print {
    *,
    :after,
    :before {
      text-shadow: none!important;
      -webkit-box-shadow: none!important;
      box-shadow: none!important
    }
    a:not(.btn) {
      text-decoration: underline
    }
    abbr[title]:after {
      content: " (" attr(title) ")"
    }
    pre {
      white-space: pre-wrap!important
    }
    blockquote,
    pre {
      border: 1px solid #adb5bd;
      page-break-inside: avoid
    }
    thead {
      display: table-header-group
    }
    img,
    tr {
      page-break-inside: avoid
    }
    h2,
    h3,
    p {
      orphans: 3;
      widows: 3
    }
    h2,
    h3 {
      page-break-after: avoid
    }
    @page {
      size: a3
    }
    .container,
    body {
      min-width: 992px!important
    }
    .navbar {
      display: none
    }
    .badge {
      border: 1px solid #000
    }
    .dark-table,
    .table,
    .table-dark {
      border-collapse: collapse!important
    }
    .dark-table td,
    .dark-table th,
    .table-dark td,
    .table-dark th,
    .table td,
    .table th {
      background-color: #fff!important
    }
    .table-bordered td,
    .table-bordered th {
      border: 1px solid #dee2e6!important
    }
    .table-dark {
      color: inherit
    }
    .table-dark tbody+tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
      border-color: #ebebeb
    }
    .dark-table .thead-dark th,
    .table-dark .thead-dark th,
    .table .thead-dark th {
      color: inherit;
      border-color: #ebebeb
    }
  }

  html {
    font-size: 16px
  }

  body,
  html {
    height: 100%
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }

  b,
  strong {
    font-weight: 600
  }

  a {
    -webkit-transition: color .12s ease-out;
    transition: color .12s ease-out
  }

  @media (min-width:576px) {
    a.link-fx {
      position: relative
    }
    a.link-fx:before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      content: "";
      background-color: #0f59d1;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: -webkit-transform .12s ease-out;
      transition: -webkit-transform .12s ease-out;
      transition: transform .12s ease-out;
      transition: transform .12s ease-out, -webkit-transform .12s ease-out
    }
  }

  a:hover.link-fx:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  a:focus {
    outline-style: none!important
  }

  p {
    line-height: 1.6;
    margin-bottom: 1.875rem
  }

  .story p,
  p.story {
    line-height: 1.8;
    font-size: 1.125rem;
    color: #495057
  }

  .story h2,
  .story h3,
  .story h4 {
    margin-top: 3rem
  }

  label {
    font-weight: 600
  }

  .dark-table thead th,
  .table-dark thead th,
  .table thead th {
    font-weight: 600;
    font-size: .875rem
  }

  .table-vcenter td,
  .table-vcenter th {
    vertical-align: middle
  }

  .form-control {
    font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol
  }

  .form-control.form-control-alt-light {
    border-color: #eee;
    background-color: #eee;
    -webkit-transition: none;
    transition: none
  }

  .form-control.form-control-alt-light:focus {
    border-color: #e2e2e2;
    background-color: #e2e2e2;
    -webkit-box-shadow: none;
    box-shadow: none
  }

  .form-control.form-control-alt-light.is-valid {
    border-color: #e0f5e9;
    background-color: #e0f5e9
  }

  .form-control.form-control-alt-light.is-valid:focus {
    border-color: #e8f7ee;
    background-color: #e8f7ee
  }

  .form-control.form-control-alt-light.is-invalid {
    border-color: #f9eae8;
    background-color: #f9eae8
  }

  .form-control.form-control-alt-light.is-invalid:focus {
    border-color: #fbf1f0;
    background-color: #fbf1f0
  }

  .form-control.form-control-alt-dark {
    color: #fff;
    font-weight: 400;
    border-color: #{$dark-l-theme};
    background-color: #{$dark-l-theme};
    -webkit-transition: none;
    transition: none
  }

  .form-control.form-control-alt-dark:focus {
    color: #fff;
    border-color: rgba(28, 52, 78, .6);
    background-color: rgba(28, 52, 78, .6);
    -webkit-box-shadow: none;
    box-shadow: none
  }

  .form-control.form-control-alt-dark.is-valid {
    border-color: #e0f5e9;
    background-color: #e0f5e9
  }

  .form-control.form-control-alt-dark.is-valid:focus {
    border-color: #e8f7ee;
    background-color: #e8f7ee
  }

  .form-control.form-control-alt-dark.is-invalid {
    border-color: #f9eae8;
    background-color: #f9eae8
  }

  .form-control.form-control-alt-dark.is-invalid:focus {
    border-color: #fbf1f0;
    background-color: #fbf1f0
  }

  .btn {
    font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol
  }

  .btn.btn-square {
    border-radius: 0
  }

  .btn.btn-rounded {
    border-radius: 2rem
  }

  .btn .fa,
  .btn .si {
    position: relative;
    top: 1px
  }

  .btn-group-sm>.btn .fa,
  .btn.btn-sm .fa {
    top: 0
  }

  .btn-dual {
    color: #fff;
    background-color: #3a5363;
    border-color: #3a5363
  }

  .btn-dual.focus,
  .btn-dual:focus,
  .btn-dual:hover {
    color: #fff;
    background-color: #2b3d49;
    border-color: #23333c;
    -webkit-box-shadow: none;
    box-shadow: none
  }

  .btn-dual.disabled,
  .btn-dual:disabled {
    background-color: transparent;
    border-color: transparent
  }

  .btn-dual.active,
  .btn-dual:active {
    color: #fff;
    background-color: #182229;
    border-color: #11181c
  }

  .show>.btn-dual.dropdown-toggle {
    color: #fff;
    background-color: #3a5363;
    border-color: #3a5363
  }

  .page-header-dark #page-header .btn-dual,
  .sidebar-dark #sidebar .btn-dual {
    color: #fff;
    background-color: #1061e4;
    border-color: #1061e4
  }

  .page-header-dark #page-header .btn-dual.focus,
  .page-header-dark #page-header .btn-dual:focus,
  .page-header-dark #page-header .btn-dual:hover,
  .sidebar-dark #sidebar .btn-dual.focus,
  .sidebar-dark #sidebar .btn-dual:focus,
  .sidebar-dark #sidebar .btn-dual:hover {
    color: #fff;
    background-color: #0e51be;
    border-color: #0c49ab;
    -webkit-box-shadow: none;
    box-shadow: none
  }

  .page-header-dark #page-header .btn-dual.disabled,
  .page-header-dark #page-header .btn-dual:disabled,
  .sidebar-dark #sidebar .btn-dual.disabled,
  .sidebar-dark #sidebar .btn-dual:disabled {
    background-color: transparent;
    border-color: transparent
  }

  .page-header-dark #page-header .btn-dual.active,
  .page-header-dark #page-header .btn-dual:active,
  .sidebar-dark #sidebar .btn-dual.active,
  .sidebar-dark #sidebar .btn-dual:active {
    color: #fff;
    background-color: #0a3d8e;
    border-color: #09357b
  }

  .show>.page-header-dark #page-header .btn-dual.dropdown-toggle,
  .show>.sidebar-dark #sidebar .btn-dual.dropdown-toggle {
    color: #fff;
    background-color: #1061e4;
    border-color: #1061e4
  }

  .fade {
    -webkit-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out
  }

  .fade.fade-left,
  .fade.fade-right,
  .fade.fade-up {
    -webkit-transition: opacity .25s ease-out, -webkit-transform .25s ease-out;
    transition: opacity .25s ease-out, -webkit-transform .25s ease-out;
    transition: opacity .25s ease-out, transform .25s ease-out;
    transition: opacity .25s ease-out, transform .25s ease-out, -webkit-transform .25s ease-out
  }

  .fade.fade-up {
    -webkit-transform: translateY(50px);
    transform: translateY(50px)
  }

  .fade.fade-up.show {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  .fade.fade-right {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px)
  }

  .fade.fade-right.show {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  .fade.fade-left {
    -webkit-transform: translateX(50px);
    transform: translateX(50px)
  }

  .fade.fade-left.show {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  .dropdown-menu {
    -webkit-box-shadow: 0 .25rem 2rem rgba(0, 0, 0, .08);
    box-shadow: 0 .25rem 2rem rgba(0, 0, 0, .08);
    border-radius: 8px;
    border: none
  }

  .dropdown-menu.dropdown-menu-lg {
    min-width: 300px
  }

  .input-group-text.input-group-text-alt {
    background-color: #ebebeb;
    border-color: #ebebeb
  }

  .custom-switch {
    padding-left: 0
  }

  .custom-switch .custom-control-label {
    left: 0;
    padding-left: 2.5rem
  }

  .custom-switch .custom-control-label:before {
    top: .125rem;
    left: 0;
    width: 2rem;
    height: 1.25rem;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-out;
    transition: background-color .15s ease-out;
    background-color: #fff
  }

  .custom-switch .custom-control-label:after {
    top: .3125rem;
    left: .25rem;
    width: .875rem;
    height: .875rem;
    -webkit-transform: none;
    transform: none;
    background-color: #fff;
    border-radius: 1rem;
    background-color: #adb5bd
  }

  .custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #fff;
    -webkit-transform: translateX(.625rem);
    transform: translateX(.625rem);
    -webkit-box-shadow: -.125rem 0 .125rem rgba(0, 0, 0, .2);
    box-shadow: -.125rem 0 .125rem rgba(0, 0, 0, .2)
  }

  .custom-control-label {
    font-weight: 400
  }

  .custom-control-label:before {
    background-color: #ebebeb
  }

  .custom-control-label:after {
    -webkit-transition: -webkit-transform .12s ease-out;
    transition: -webkit-transform .12s ease-out;
    transition: transform .12s ease-out;
    transition: transform .12s ease-out, -webkit-transform .12s ease-out
  }

  .custom-checkbox.custom-checkbox-square .custom-control-label:before {
    border-radius: 0
  }

  .custom-checkbox.custom-checkbox-rounded-circle .custom-control-label:before {
    border-radius: 1rem
  }

  .custom-control-primary .custom-control-input:checked~.custom-control-label:before {
    background-color: #0f59d1;
    border-color: #0f59d1
  }

  .custom-control-primary .custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .custom-control-primary .custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #0f59d1
  }

  .custom-control-primary .custom-control-input:not(:disabled):active~.custom-control-label:before {
    background-color: #5390f3;
    border-color: #5390f3
  }

  .custom-control-primary .custom-control-label:before {
    background-color: #79a9f5;
    border-color: #105fdf
  }

  .custom-control-primary.custom-checkbox .custom-control-input:checked~.custom-control-label:before {
    background-color: #0f59d1
  }

  .custom-control-primary.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    background-color: #0f59d1
  }

  .custom-control-primary.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: #0f59d1
  }

  .custom-control-primary.custom-switch .custom-control-label:after {
    background-color: rgba(15, 89, 209, .6)
  }

  .custom-control-success .custom-control-input:checked~.custom-control-label:before {
    background-color: #46c37b;
    border-color: #46c37b
  }

  .custom-control-success .custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(70, 195, 123, .25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(70, 195, 123, .25)
  }

  .custom-control-success .custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #46c37b
  }

  .custom-control-success .custom-control-input:not(:disabled):active~.custom-control-label:before {
    background-color: #93dcb2;
    border-color: #93dcb2
  }

  .custom-control-success .custom-control-label:before {
    background-color: #e0f5e9;
    border-color: #80d6a5
  }

  .custom-control-success.custom-checkbox .custom-control-input:checked~.custom-control-label:before {
    background-color: #46c37b
  }

  .custom-control-success.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    background-color: #46c37b
  }

  .custom-control-success.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: #46c37b
  }

  .custom-control-success.custom-switch .custom-control-label:after {
    background-color: rgba(70, 195, 123, .6)
  }

  .custom-control-info .custom-control-input:checked~.custom-control-label:before {
    background-color: #70b9eb;
    border-color: #70b9eb
  }

  .custom-control-info .custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(112, 185, 235, .25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(112, 185, 235, .25)
  }

  .custom-control-info .custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #70b9eb
  }

  .custom-control-info .custom-control-input:not(:disabled):active~.custom-control-label:before {
    background-color: #c9e5f8;
    border-color: #c9e5f8
  }

  .custom-control-info .custom-control-label:before {
    background-color: #edf6fd;
    border-color: #7abdf0
  }

  .custom-control-info.custom-checkbox .custom-control-input:checked~.custom-control-label:before {
    background-color: #70b9eb
  }

  .custom-control-info.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    background-color: #70b9eb
  }

  .custom-control-info.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: #70b9eb
  }

  .custom-control-info.custom-switch .custom-control-label:after {
    background-color: rgba(112, 185, 235, .6)
  }

  .custom-control-warning .custom-control-input:checked~.custom-control-label:before {
    background-color: #f3b760;
    border-color: #f3b760
  }

  .custom-control-warning .custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(243, 183, 96, .25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(243, 183, 96, .25)
  }

  .custom-control-warning .custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #f3b760
  }

  .custom-control-warning .custom-control-input:not(:disabled):active~.custom-control-label:before {
    background-color: #fae2bf;
    border-color: #fae2bf
  }

  .custom-control-warning .custom-control-label:before {
    background-color: #fdf3e5;
    border-color: #f4bc6f
  }

  .custom-control-warning.custom-checkbox .custom-control-input:checked~.custom-control-label:before {
    background-color: #f3b760
  }

  .custom-control-warning.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    background-color: #f3b760
  }

  .custom-control-warning.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: #f3b760
  }

  .custom-control-warning.custom-switch .custom-control-label:after {
    background-color: rgba(243, 183, 96, .6)
  }

  .custom-control-danger .custom-control-input:checked~.custom-control-label:before {
    background-color: #d26a5c;
    border-color: #d26a5c
  }

  .custom-control-danger .custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(210, 106, 92, .25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(210, 106, 92, .25)
  }

  .custom-control-danger .custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #d26a5c
  }

  .custom-control-danger .custom-control-input:not(:disabled):active~.custom-control-label:before {
    background-color: #e8b3ac;
    border-color: #e8b3ac
  }

  .custom-control-danger .custom-control-label:before {
    background-color: #f9eae8;
    border-color: #df8e83
  }

  .custom-control-danger.custom-checkbox .custom-control-input:checked~.custom-control-label:before {
    background-color: #d26a5c
  }

  .custom-control-danger.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    background-color: #d26a5c
  }

  .custom-control-danger.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: #d26a5c
  }

  .custom-control-danger.custom-switch .custom-control-label:after {
    background-color: rgba(210, 106, 92, .6)
  }

  .custom-control-light .custom-control-input:checked~.custom-control-label:before {
    background-color: #adb5bd;
    border-color: #adb5bd
  }

  .custom-control-light .custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(173, 181, 189, .25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(173, 181, 189, .25)
  }

  .custom-control-light .custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #adb5bd
  }

  .custom-control-light .custom-control-input:not(:disabled):active~.custom-control-label:before {
    background-color: #e6e8ea;
    border-color: #e6e8ea
  }

  .custom-control-light .custom-control-label:before {
    background-color: #dee2e6;
    border-color: #95a2af
  }

  .custom-control-light.custom-checkbox .custom-control-input:checked~.custom-control-label:before {
    background-color: #adb5bd
  }

  .custom-control-light.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    background-color: #adb5bd
  }

  .custom-control-light.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: #adb5bd
  }

  .custom-control-light.custom-switch .custom-control-label:after {
    background-color: rgba(173, 181, 189, .6)
  }

  .custom-control-dark .custom-control-input:checked~.custom-control-label:before {
    background-color: #{$dark-l-theme};
    border-color: #{$dark-l-theme}
  }

  .custom-control-dark .custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(28, 52, 78, .25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(28, 52, 78, .25)
  }

  .custom-control-dark .custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #{$dark-l-theme}
  }

  .custom-control-dark .custom-control-input:not(:disabled):active~.custom-control-label:before {
    background-color: #376699;
    border-color: #376699
  }

  .custom-control-dark .custom-control-label:before {
    background-color: #dee2e6;
    border-color: #95a2af
  }

  .custom-control-dark.custom-checkbox .custom-control-input:checked~.custom-control-label:before {
    background-color: #{$dark-l-theme}
  }

  .custom-control-dark.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    background-color: #{$dark-l-theme}
  }

  .custom-control-dark.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: #{$dark-l-theme}
  }

  .custom-control-dark.custom-switch .custom-control-label:after {
    background-color: rgba(28, 52, 78, .6)
  }

  .custom-control-lg {
    padding-left: 1.75rem
  }

  .custom-control-lg .custom-control-label:after,
  .custom-control-lg .custom-control-label:before {
    top: .125rem;
    left: -1.75rem;
    width: 1.25rem;
    height: 1.25rem
  }

  .custom-control-lg.custom-switch {
    padding-left: 0
  }

  .custom-control-lg.custom-switch .custom-control-label {
    padding-left: 3.25rem
  }

  .custom-control-lg.custom-switch .custom-control-label:before {
    top: -.0625rem;
    left: 0;
    width: 2.75rem;
    height: 1.625rem
  }

  .custom-control-lg.custom-switch .custom-control-label:after {
    top: .125rem;
    left: .25rem;
    width: 1.25rem;
    height: 1.25rem
  }

  .custom-control-lg.custom-switch .custom-control-input:checked~.custom-control-label:after {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem)
  }

  .breadcrumb.breadcrumb-alt .breadcrumb-item {
    font-size: .875rem;
    font-weight: 600
  }

  .breadcrumb.breadcrumb-alt .breadcrumb-item+.breadcrumb-item:before {
    position: relative;
    top: 1px;
    width: 1.25rem;
    font-family: simple-line-icons;
    font-size: .875rem;
    color: #ced4da;
    content: "\e606"
  }

  .badge-secondary {
    border-radius: 8px
  }

  .block {
    margin-bottom: 1.875rem;
    background-color: #fff;
    -webkit-box-shadow: 0 .125rem rgba(0, 0, 0, .01);
    box-shadow: 0 .125rem rgba(0, 0, 0, .01)
  }

  .block .block,
  .content-side .block {
    -webkit-box-shadow: none;
    box-shadow: none
  }

  .block-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .625rem 1.25rem;
    -webkit-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out
  }

  .block-header.block-header-rtl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
  }

  .block-header.block-header-rtl .block-title {
    text-align: right
  }

  .block-header.block-header-rtl .block-options {
    padding-right: 1.25rem;
    padding-left: 0
  }

  .block-header-default {
    background-color: #f9f9f9
  }

  .block-title {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1.75rem;
    margin: 0;
    font-size: .9375rem;
    font-weight: 600;
    line-height: 1.75
  }

  .block-title small {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    color: #6c757d;
    text-transform: none;
    letter-spacing: normal
  }

  .block-content {
    -webkit-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out;
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem 1.25rem 1px;
    overflow-x: visible
  }

  .block-content>.pull-t,
  .block-content>.pull-y {
    margin-top: -1.25rem
  }

  .block-content>.pull-b,
  .block-content>.pull-y {
    margin-bottom: -1px
  }

  .block-content>.pull-r,
  .block-content>.pull-x {
    margin-right: -1.25rem;
    margin-left: -1.25rem
  }

  .block-content>.pull {
    margin: -1.25rem -1.25rem -1px
  }

  .block-content.block-content-full {
    padding-bottom: 1.25rem
  }

  .block-content.block-content-full>.pull,
  .block-content.block-content-full>.pull-b,
  .block-content.block-content-full>.pull-y {
    margin-bottom: -1.25rem
  }

  .block-content .block,
  .block-content .items-push>div,
  .block-content .push,
  .block-content p {
    margin-bottom: 1.25rem
  }

  .block-content .items-push-2x>div {
    margin-bottom: 2.5rem
  }

  .block-content .items-push-3x>div {
    margin-bottom: 3.75rem
  }

  .block-content.block-content-sm {
    padding-top: .625rem
  }

  .block-content.block-content-sm>.pull,
  .block-content.block-content-sm>.pull-t,
  .block-content.block-content-sm>.pull-y {
    margin-top: -.625rem
  }

  .block-content.block-content-sm.block-content-full {
    padding-bottom: .625rem
  }

  .block-content.block-content-sm.block-content-full>.pull,
  .block-content.block-content-sm.block-content-full>.pull-b,
  .block-content.block-content-sm.block-content-full>.pull-y {
    margin-bottom: -.625rem
  }

  .block.block-bordered {
    border: 1px solid #ebebeb;
    -webkit-box-shadow: none;
    box-shadow: none
  }

  .block.block-rounded-tabs {
    border-radius: 0 8px 8px 8px!important
  }

  .block.block-rounded-tabs>.block-content:first-child {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .block.block-rounded-tabs>.block-content:last-child {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .block.block-rounded {
    border-radius: 8px
  }

  .block.block-rounded>.block-header,
  .block.block-rounded>.nav-tabs {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .block.block-rounded.block-mode-hidden>.block-header.block-header-default,
  .block.block-rounded>.block-header:last-child,
  .block.block-rounded>.nav-tabs:last-child {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .block.block-rounded>.block-content:first-child {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem
  }

  .block.block-rounded>.block-content:last-child {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem
  }

  .block.block-rounded>.nav-tabs>.nav-item:first-child>.nav-link {
    border-top-left-radius: .2rem
  }

  .block.block-rounded>.nav-tabs>.nav-item:last-child>.nav-link {
    border-top-right-radius: .2rem
  }

  .block.block-themed>.block-header {
    border-bottom: none;
    color: #fff;
    background-color: #0f59d1
  }

  .block.block-themed>.block-header>.block-title {
    color: hsla(0, 0%, 100%, .9)
  }

  .block.block-themed>.block-header>.block-title small {
    color: hsla(0, 0%, 100%, .7)
  }

  .block.block-transparent {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
  }

  .block.block-mode-hidden.block-bordered>.block-header {
    border-bottom: none
  }

  .block.block-mode-hidden>.block-content {
    display: none
  }

  .block.block-mode-loading {
    position: relative;
    overflow: hidden
  }

  .block.block-mode-loading>.block-content,
  .block.block-mode-loading>.block-header,
  .block.block-mode-loading>.nav-tabs {
    opacity: .05
  }

  .block.block-mode-loading:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 1;
    content: " "
  }

  .block.block-mode-loading:after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    color: #0e53c3;
    font-family: Simple-Line-Icons;
    font-size: 1.125rem;
    text-align: center;
    z-index: 2;
    content: "\e09a";
    -webkit-animation: fa-spin 1.75s linear infinite;
    animation: fa-spin 1.75s linear infinite;
    border-radius: 1.5rem
  }

  .block.block-mode-loading.block-mode-hidden:after {
    margin: -1rem 0 0 -1rem;
    width: 2rem;
    height: 2rem;
    line-height: 2rem
  }

  .block.block-mode-loading.block-mode-loading-dark:after {
    background-color: #0e53c3;
    color: #fff
  }

  .block.block-mode-loading.block-mode-loading-location:after {
    content: "\e06e"
  }

  .block.block-mode-loading.block-mode-loading-energy:after {
    content: "\e020"
  }

  .block.block-mode-loading.block-mode-loading-refresh:after {
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro;
    font-weight: 900;
    content: "\f021"
  }

  .block.block-mode-loading.block-mode-loading-sun:after {
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro;
    font-weight: 900;
    content: "\f185"
  }

  .block.block-mode-loading.block-mode-loading-repeat:after {
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro;
    font-weight: 900;
    content: "\f01e"
  }

  .block.block-mode-loading.block-mode-loading-hourglass:after {
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro;
    font-weight: 400;
    content: "\f254"
  }

  .block.block-mode-loading.block-mode-loading-oneui:after {
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro;
    font-weight: 900;
    content: "\f1ce"
  }

  .block.block-mode-fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1035;
    margin-bottom: 0;
    overflow-y: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch
  }

  .block.block-mode-fullscreen.block-bordered {
    border: none
  }

  .block.block-mode-fullscreen.block-rounded {
    border-radius: 0
  }

  .block.block-mode-fullscreen.block-transparent {
    background-color: #fff
  }

  .block.block-mode-pinned {
    position: fixed;
    right: .75rem;
    bottom: 0;
    margin-bottom: 0!important;
    width: 100%;
    max-width: 300px;
    z-index: 1031;
    -webkit-box-shadow: 0 0 2rem rgba(0, 0, 0, .1);
    box-shadow: 0 0 2rem rgba(0, 0, 0, .1)
  }

  .block.block-mode-pinned>.block-content {
    max-height: 250px;
    overflow-y: auto
  }

  a.block {
    display: block;
    color: #575757;
    font-weight: 400;
    -webkit-transition: opacity .15s ease-out, -webkit-transform .15s ease-out, -webkit-box-shadow .15s ease-out;
    transition: opacity .15s ease-out, -webkit-transform .15s ease-out, -webkit-box-shadow .15s ease-out;
    transition: transform .15s ease-out, opacity .15s ease-out, box-shadow .15s ease-out;
    transition: transform .15s ease-out, opacity .15s ease-out, box-shadow .15s ease-out, -webkit-transform .15s ease-out, -webkit-box-shadow .15s ease-out
  }

  a.block:hover {
    color: #575757;
    opacity: .75
  }

  a.block:active {
    opacity: 1
  }

  a.block.block-link-rotate:hover {
    -webkit-transform: rotate(1.5deg);
    transform: rotate(1.5deg);
    opacity: 1
  }

  a.block.block-link-rotate:active {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  a.block.block-link-pop:hover {
    -webkit-box-shadow: 0 .5rem 2.5rem #e6e6e6;
    box-shadow: 0 .5rem 2.5rem #e6e6e6;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    opacity: 1
  }

  a.block.block-link-pop:active {
    -webkit-box-shadow: 0 .375rem .55rem #f2f2f2;
    box-shadow: 0 .375rem .55rem #f2f2f2;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  a.block.block-link-shadow:hover {
    -webkit-box-shadow: 0 0 1.5rem #e6e6e6;
    box-shadow: 0 0 1.5rem #e6e6e6;
    opacity: 1
  }

  a.block.block-link-shadow:active {
    -webkit-box-shadow: 0 0 .75rem #ebebeb;
    box-shadow: 0 0 .75rem #ebebeb
  }

  .block.block-fx-shadow {
    -webkit-box-shadow: 0 0 1.5rem #e6e6e6;
    box-shadow: 0 0 1.5rem #e6e6e6;
    opacity: 1
  }

  .block.block-fx-pop {
    -webkit-box-shadow: 0 .5rem 2.5rem #e6e6e6;
    box-shadow: 0 .5rem 2.5rem #e6e6e6;
    opacity: 1
  }

  .block.block-fx-rotate-right {
    -webkit-transform: rotate(1.5deg);
    transform: rotate(1.5deg);
    opacity: 1
  }

  .block.block-fx-rotate-left {
    -webkit-transform: rotate(-1.5deg);
    transform: rotate(-1.5deg);
    opacity: 1
  }

  .block-options {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-left: 1.25rem
  }

  .block-options .block-options-item {
    padding: 0 .25rem;
    line-height: 1.2
  }

  .block.block-themed .block-options .block-options-item {
    color: #fff
  }

  .block-options .block-options-item,
  .block-options .dropdown {
    display: inline-block
  }

  .block-sticky-options {
    position: relative
  }

  .block-sticky-options .block-options {
    position: absolute;
    top: 14px;
    right: 1.25rem
  }

  .block-sticky-options .block-options.block-options-left {
    right: auto;
    left: 1.25rem;
    padding-right: 10px;
    padding-left: 0
  }

  .btn-block-option {
    display: inline-block;
    padding: 6px 5px;
    line-height: 1;
    color: #adb5bd;
    background: none;
    border: none;
    cursor: pointer
  }

  .btn-block-option.dropdown-toggle:after {
    position: relative;
    top: 2px
  }

  .btn-block-option .si {
    position: relative;
    top: 1px
  }

  .active>a.btn-block-option,
  .btn-block-option:hover,
  .show>button.btn-block-option,
  a.btn-block-option:focus {
    text-decoration: none;
    color: #495057
  }

  .btn-block-option:focus {
    outline: none!important
  }

  .btn-block-option:active {
    color: #adb5bd
  }

  .block.block-themed .btn-block-option {
    color: #fff;
    opacity: .7
  }

  .active>a.block.block-themed .btn-block-option,
  .block.block-themed .btn-block-option:hover,
  .show>button.block.block-themed .btn-block-option,
  a.block.block-themed .btn-block-option:focus {
    color: #fff;
    opacity: 1
  }

  .block.block-themed .btn-block-option:active {
    color: #fff;
    opacity: .6
  }

  .img-avatar {
    display: inline-block!important;
    width: 64px;
    height: 64px;
    border-radius: 50%
  }

  .img-avatar.img-avatar16 {
    width: 16px;
    height: 16px
  }

  .img-avatar.img-avatar20 {
    width: 20px;
    height: 20px
  }

  .img-avatar.img-avatar32 {
    width: 32px;
    height: 32px
  }

  .img-avatar.img-avatar48 {
    width: 48px;
    height: 48px
  }

  .img-avatar.img-avatar96 {
    width: 96px;
    height: 96px
  }

  .img-avatar.img-avatar128 {
    width: 128px;
    height: 128px
  }

  .img-avatar-thumb {
    margin: .25rem;
    -webkit-box-shadow: 0 0 0 .25rem hsla(0, 0%, 100%, .3);
    box-shadow: 0 0 0 .25rem hsla(0, 0%, 100%, .3)
  }

  .img-avatar.img-avatar-rounded,
  .img-thumb {
    border-radius: .25rem
  }

  .img-thumb {
    padding: .375rem;
    background-color: #fff
  }

  .img-link {
    display: inline-block;
    -webkit-transition: opacity .25s ease-out, -webkit-transform .25s ease-out;
    transition: opacity .25s ease-out, -webkit-transform .25s ease-out;
    transition: transform .25s ease-out, opacity .25s ease-out;
    transition: transform .25s ease-out, opacity .25s ease-out, -webkit-transform .25s ease-out
  }

  .img-link:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    opacity: .75
  }

  .img-link:active {
    opacity: 1
  }

  .img-link.img-link-simple:hover,
  .img-link:active {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  .img-link-zoom-in {
    cursor: -webkit-zoom-in;
    cursor: zoom-in
  }

  .img-fluid-100 .img-fluid,
  .img-fluid.img-fluid-100 {
    width: 100%
  }

  .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    -webkit-transition: opacity .25s ease-out, -webkit-transform .25s ease-out;
    transition: opacity .25s ease-out, -webkit-transform .25s ease-out;
    transition: opacity .25s ease-out, transform .25s ease-out;
    transition: opacity .25s ease-out, transform .25s ease-out, -webkit-transform .25s ease-out
  }

  a.item {
    will-change: opacity
  }

  a.item:focus,
  a.item:hover {
    opacity: .6
  }

  a.item:active {
    opacity: 1
  }

  a.item.item-link-pop {
    will-change: transform
  }

  a.item.item-link-pop:focus,
  a.item.item-link-pop:hover {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }

  a.item.item-link-pop:active {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  .item.item-tiny {
    width: 1rem;
    height: 1rem
  }

  .item.item-2x {
    width: 6rem;
    height: 6rem
  }

  .item.item-3x {
    width: 8rem;
    height: 8rem
  }

  .item.item-circle {
    border-radius: 50%
  }

  .item.item-rounded {
    border-radius: 8px
  }

  .item.item-rounded-big {
    border-radius: 1.75rem
  }

  .item.item-rounded-big.item-2x {
    border-radius: 2.25rem
  }

  .item.item-rounded-big.item-3x {
    border-radius: 3rem
  }

  .bg-black-5 {
    background-color: rgba(0, 0, 0, .05)!important
  }

  .bg-black-10 {
    background-color: rgba(0, 0, 0, .1)!important
  }

  .bg-black-25 {
    background-color: rgba(0, 0, 0, .25)!important
  }

  .bg-black-50 {
    background-color: rgba(0, 0, 0, .5)!important
  }

  .bg-black-75 {
    background-color: rgba(0, 0, 0, .75)!important
  }

  .bg-black-90 {
    background-color: rgba(0, 0, 0, .9)!important
  }

  .bg-black-95 {
    background-color: rgba(0, 0, 0, .95)!important
  }

  .bg-white-5 {
    background-color: hsla(0, 0%, 100%, .05)!important
  }

  .bg-white-10 {
    background-color: hsla(0, 0%, 100%, .1)!important
  }

  .bg-white-25 {
    background-color: hsla(0, 0%, 100%, .25)!important
  }

  .bg-white-50 {
    background-color: hsla(0, 0%, 100%, .5)!important
  }

  .bg-white-75 {
    background-color: hsla(0, 0%, 100%, .75)!important
  }

  .bg-white-90 {
    background-color: hsla(0, 0%, 100%, .9)!important
  }

  .bg-white-95 {
    background-color: hsla(0, 0%, 100%, .95)!important
  }

  .bg-image {
    background-position: 0 50%;
    background-size: cover
  }

  .bg-image-top {
    background-position-y: 0
  }

  .bg-image-bottom {
    background-position-y: 100%
  }

  @media (min-width:1200px) {
    .bg-image-fixed {
      background-attachment: fixed
    }
  }

  .bg-pattern {
    background-repeat: repeat
  }

  .bg-video {
    width: 100%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  .bg-primary-op {
    background-color: rgba(15, 89, 209, .75)!important
  }

  a.bg-primary-op:focus,
  a.bg-primary-op:hover,
  button.bg-primary-op:focus,
  button.bg-primary-op:hover {
    background-color: rgba(12, 69, 161, .75)!important
  }

  .bg-primary-dark {
    background-color: #0e53c3!important
  }

  a.bg-primary-dark:focus,
  a.bg-primary-dark:hover,
  button.bg-primary-dark:focus,
  button.bg-primary-dark:hover {
    background-color: #0b3f93!important
  }

  .bg-primary-dark-op {
    background-color: rgba(14, 83, 195, .8)!important
  }

  a.bg-primary-dark-op:focus,
  a.bg-primary-dark-op:hover,
  button.bg-primary-dark-op:focus,
  button.bg-primary-dark-op:hover {
    background-color: rgba(11, 63, 147, .8)!important
  }

  .bg-primary-darker {
    background-color: #0d4fb9!important
  }

  a.bg-primary-darker:focus,
  a.bg-primary-darker:hover,
  button.bg-primary-darker:focus,
  button.bg-primary-darker:hover {
    background-color: #0a3b8a!important
  }

  .bg-primary-light {
    background-color: #3c81f1!important
  }

  a.bg-primary-light:focus,
  a.bg-primary-light:hover,
  button.bg-primary-light:focus,
  button.bg-primary-light:hover {
    background-color: #1163e9!important
  }

  .bg-primary-lighter {
    background-color: #79a9f5!important
  }

  a.bg-primary-lighter:focus,
  a.bg-primary-lighter:hover,
  button.bg-primary-lighter:focus,
  button.bg-primary-lighter:hover {
    background-color: #4a8af2!important
  }

  .bg-success-light {
    background-color: #e0f5e9!important
  }

  a.bg-success-light:focus,
  a.bg-success-light:hover,
  button.bg-success-light:focus,
  button.bg-success-light:hover {
    background-color: #b9e9ce!important
  }

  .bg-warning-light {
    background-color: #fdf3e5!important
  }

  a.bg-warning-light:focus,
  a.bg-warning-light:hover,
  button.bg-warning-light:focus,
  button.bg-warning-light:hover {
    background-color: #f9ddb6!important
  }

  .bg-info-light {
    background-color: #edf6fd!important
  }

  a.bg-info-light:focus,
  a.bg-info-light:hover,
  button.bg-info-light:focus,
  button.bg-info-light:hover {
    background-color: #bfdff8!important
  }

  .bg-danger-light {
    background-color: #f9eae8!important
  }

  a.bg-danger-light:focus,
  a.bg-danger-light:hover,
  button.bg-danger-light:focus,
  button.bg-danger-light:hover {
    background-color: #eec5c0!important
  }

  .bg-success-op {
    background-color: rgba(224, 245, 233, .95)!important
  }

  a.bg-success-op:focus,
  a.bg-success-op:hover,
  button.bg-success-op:focus,
  button.bg-success-op:hover {
    background-color: rgba(185, 233, 206, .95)!important
  }

  .bg-warning-op {
    background-color: rgba(253, 243, 229, .95)!important
  }

  a.bg-warning-op:focus,
  a.bg-warning-op:hover,
  button.bg-warning-op:focus,
  button.bg-warning-op:hover {
    background-color: rgba(249, 221, 182, .95)!important
  }

  .bg-info-op {
    background-color: rgba(237, 246, 253, .95)!important
  }

  a.bg-info-op:focus,
  a.bg-info-op:hover,
  button.bg-info-op:focus,
  button.bg-info-op:hover {
    background-color: rgba(191, 223, 248, .95)!important
  }

  .bg-danger-op {
    background-color: rgba(249, 234, 232, .95)!important
  }

  a.bg-danger-op:focus,
  a.bg-danger-op:hover,
  button.bg-danger-op:focus,
  button.bg-danger-op:hover {
    background-color: rgba(238, 197, 192, .95)!important
  }

  .bg-body {
    background-color: #f5f5f5!important
  }

  a.bg-body:focus,
  a.bg-body:hover,
  button.bg-body:focus,
  button.bg-body:hover {
    background-color: #dcdcdc!important
  }

  .bg-body-light {
    background-color: #f9f9f9!important
  }

  a.bg-body-light:focus,
  a.bg-body-light:hover,
  button.bg-body-light:focus,
  button.bg-body-light:hover {
    background-color: #dfdfdf!important
  }

  .bg-body-dark {
    background-color: #ebebeb!important
  }

  a.bg-body-dark:focus,
  a.bg-body-dark:hover,
  button.bg-body-dark:focus,
  button.bg-body-dark:hover {
    background-color: #d1d1d1!important
  }

  .bg-muted {
    background-color: #6c757d!important
  }

  a.bg-muted:focus,
  a.bg-muted:hover,
  button.bg-muted:focus,
  button.bg-muted:hover {
    background-color: #545b62!important
  }

  .bg-white {
    background-color: #fff!important
  }

  a.bg-white:focus,
  a.bg-white:hover,
  button.bg-white:focus,
  button.bg-white:hover {
    background-color: #e6e6e6!important
  }

  .bg-black,
  a.bg-black:focus,
  a.bg-black:hover,
  button.bg-black:focus,
  button.bg-black:hover {
    background-color: #000!important
  }

  .bg-gray {
    background-color: #ced4da!important
  }

  a.bg-gray:focus,
  a.bg-gray:hover,
  button.bg-gray:focus,
  button.bg-gray:hover {
    background-color: #b1bbc4!important
  }

  .bg-gray-dark {
    background-color: #6c757d!important
  }

  a.bg-gray-dark:focus,
  a.bg-gray-dark:hover,
  button.bg-gray-dark:focus,
  button.bg-gray-dark:hover {
    background-color: #545b62!important
  }

  .bg-gray-darker {
    background-color: #343a40!important
  }

  a.bg-gray-darker:focus,
  a.bg-gray-darker:hover,
  button.bg-gray-darker:focus,
  button.bg-gray-darker:hover {
    background-color: #1d2124!important
  }

  .bg-gray-light {
    background-color: #e9ecef!important
  }

  a.bg-gray-light:focus,
  a.bg-gray-light:hover,
  button.bg-gray-light:focus,
  button.bg-gray-light:hover {
    background-color: #cbd3da!important
  }

  .bg-gray-lighter {
    background-color: #f8f9fa!important
  }

  a.bg-gray-lighter:focus,
  a.bg-gray-lighter:hover,
  button.bg-gray-lighter:focus,
  button.bg-gray-lighter:hover {
    background-color: #dae0e5!important
  }

  .bg-header-light {
    background-color: #fff!important
  }

  a.bg-header-light:focus,
  a.bg-header-light:hover,
  button.bg-header-light:focus,
  button.bg-header-light:hover {
    background-color: #e6e6e6!important
  }

  .bg-header-dark {
    background-color: #0f59d1!important
  }

  a.bg-header-dark:focus,
  a.bg-header-dark:hover,
  button.bg-header-dark:focus,
  button.bg-header-dark:hover {
    background-color: #0c45a1!important
  }

  .bg-sidebar-light {
    background-color: #fff!important
  }

  a.bg-sidebar-light:focus,
  a.bg-sidebar-light:hover,
  button.bg-sidebar-light:focus,
  button.bg-sidebar-light:hover {
    background-color: #e6e6e6!important
  }

  .bg-sidebar-dark {
    background-color: #0d4fb9!important
  }

  a.bg-sidebar-dark:focus,
  a.bg-sidebar-dark:hover,
  button.bg-sidebar-dark:focus,
  button.bg-sidebar-dark:hover {
    background-color: #0a3b8a!important
  }

  .bg-default {
    background-color: #0f59d1!important
  }

  a.bg-default:focus,
  a.bg-default:hover,
  button.bg-default:focus,
  button.bg-default:hover {
    background-color: #0c45a1!important
  }

  .bg-default-op {
    background-color: rgba(15, 89, 209, .75)!important
  }

  a.bg-default-op:focus,
  a.bg-default-op:hover,
  button.bg-default-op:focus,
  button.bg-default-op:hover {
    background-color: rgba(12, 69, 161, .75)!important
  }

  .bg-default-dark {
    background-color: #0e53c3!important
  }

  a.bg-default-dark:focus,
  a.bg-default-dark:hover,
  button.bg-default-dark:focus,
  button.bg-default-dark:hover {
    background-color: #0b3f93!important
  }

  .bg-default-dark-op {
    background-color: rgba(14, 83, 195, .8)!important
  }

  a.bg-default-dark-op:focus,
  a.bg-default-dark-op:hover,
  button.bg-default-dark-op:focus,
  button.bg-default-dark-op:hover {
    background-color: rgba(11, 63, 147, .8)!important
  }

  .bg-default-darker {
    background-color: #0d4fb9!important
  }

  a.bg-default-darker:focus,
  a.bg-default-darker:hover,
  button.bg-default-darker:focus,
  button.bg-default-darker:hover {
    background-color: #0a3b8a!important
  }

  .bg-default-light {
    background-color: #3c81f1!important
  }

  a.bg-default-light:focus,
  a.bg-default-light:hover,
  button.bg-default-light:focus,
  button.bg-default-light:hover {
    background-color: #1163e9!important
  }

  .bg-default-lighter {
    background-color: #79a9f5!important
  }

  a.bg-default-lighter:focus,
  a.bg-default-lighter:hover,
  button.bg-default-lighter:focus,
  button.bg-default-lighter:hover {
    background-color: #4a8af2!important
  }

  .bg-amethyst {
    background-color: #a68ad4!important
  }

  a.bg-amethyst:focus,
  a.bg-amethyst:hover,
  button.bg-amethyst:focus,
  button.bg-amethyst:hover {
    background-color: #8a65c6!important
  }

  .bg-amethyst-op {
    background-color: rgba(166, 138, 212, .75)!important
  }

  a.bg-amethyst-op:focus,
  a.bg-amethyst-op:hover,
  button.bg-amethyst-op:focus,
  button.bg-amethyst-op:hover {
    background-color: rgba(138, 101, 198, .75)!important
  }

  .bg-amethyst-dark {
    background-color: #4f546b!important
  }

  a.bg-amethyst-dark:focus,
  a.bg-amethyst-dark:hover,
  button.bg-amethyst-dark:focus,
  button.bg-amethyst-dark:hover {
    background-color: #393d4e!important
  }

  .bg-amethyst-dark-op {
    background-color: rgba(79, 84, 107, .8)!important
  }

  a.bg-amethyst-dark-op:focus,
  a.bg-amethyst-dark-op:hover,
  button.bg-amethyst-dark-op:focus,
  button.bg-amethyst-dark-op:hover {
    background-color: rgba(57, 61, 78, .8)!important
  }

  .bg-amethyst-darker {
    background-color: #353847!important
  }

  a.bg-amethyst-darker:focus,
  a.bg-amethyst-darker:hover,
  button.bg-amethyst-darker:focus,
  button.bg-amethyst-darker:hover {
    background-color: #1f212a!important
  }

  .bg-amethyst-light {
    background-color: #c8b7e4!important
  }

  a.bg-amethyst-light:focus,
  a.bg-amethyst-light:hover,
  button.bg-amethyst-light:focus,
  button.bg-amethyst-light:hover {
    background-color: #ac91d7!important
  }

  .bg-amethyst-lighter {
    background-color: #e4dcf2!important
  }

  a.bg-amethyst-lighter:focus,
  a.bg-amethyst-lighter:hover,
  button.bg-amethyst-lighter:focus,
  button.bg-amethyst-lighter:hover {
    background-color: #c8b7e4!important
  }

  .bg-city {
    background-color: #0f59d1!important
  }

  a.bg-city:focus,
  a.bg-city:hover,
  button.bg-city:focus,
  button.bg-city:hover {
    background-color: #0c45a1!important
  }

  .bg-city-op {
    background-color: rgba(15, 89, 209, .75)!important
  }

  a.bg-city-op:focus,
  a.bg-city-op:hover,
  button.bg-city-op:focus,
  button.bg-city-op:hover {
    background-color: rgba(12, 69, 161, .75)!important
  }

  .bg-city-dark {
    background-color: #{$dark-l-theme}!important
  }

  a.bg-city-dark:focus,
  a.bg-city-dark:hover,
  button.bg-city-dark:focus,
  button.bg-city-dark:hover {
    background-color: #0f1b28!important
  }

  .bg-city-dark-op {
    background-color: rgba(28, 52, 78, .8)!important
  }

  a.bg-city-dark-op:focus,
  a.bg-city-dark-op:hover,
  button.bg-city-dark-op:focus,
  button.bg-city-dark-op:hover {
    background-color: rgba(15, 27, 40, .8)!important
  }

  .bg-city-darker {
    background-color: #333!important
  }

  a.bg-city-darker:focus,
  a.bg-city-darker:hover,
  button.bg-city-darker:focus,
  button.bg-city-darker:hover {
    background-color: #1a1a1a!important
  }

  .bg-city-light {
    background-color: #1568ee!important
  }

  a.bg-city-light:focus,
  a.bg-city-light:hover,
  button.bg-city-light:focus,
  button.bg-city-light:hover {
    background-color: #0e53c3!important
  }

  .bg-city-lighter {
    background-color: #3c81f1!important
  }

  a.bg-city-lighter:focus,
  a.bg-city-lighter:hover,
  button.bg-city-lighter:focus,
  button.bg-city-lighter:hover {
    background-color: #1163e9!important
  }

  .bg-flat {
    background-color: #45b5ae!important
  }

  a.bg-flat:focus,
  a.bg-flat:hover,
  button.bg-flat:focus,
  button.bg-flat:hover {
    background-color: #37908b!important
  }

  .bg-flat-op {
    background-color: rgba(69, 181, 174, .75)!important
  }

  a.bg-flat-op:focus,
  a.bg-flat-op:hover,
  button.bg-flat-op:focus,
  button.bg-flat-op:hover {
    background-color: rgba(55, 144, 139, .75)!important
  }

  .bg-flat-dark {
    background-color: #3f5259!important
  }

  a.bg-flat-dark:focus,
  a.bg-flat-dark:hover,
  button.bg-flat-dark:focus,
  button.bg-flat-dark:hover {
    background-color: #2a363b!important
  }

  .bg-flat-dark-op {
    background-color: rgba(63, 82, 89, .8)!important
  }

  a.bg-flat-dark-op:focus,
  a.bg-flat-dark-op:hover,
  button.bg-flat-dark-op:focus,
  button.bg-flat-dark-op:hover {
    background-color: rgba(42, 54, 59, .8)!important
  }

  .bg-flat-darker {
    background-color: #242f33!important
  }

  a.bg-flat-darker:focus,
  a.bg-flat-darker:hover,
  button.bg-flat-darker:focus,
  button.bg-flat-darker:hover {
    background-color: #0f1315!important
  }

  .bg-flat-light {
    background-color: #85d1cc!important
  }

  a.bg-flat-light:focus,
  a.bg-flat-light:hover,
  button.bg-flat-light:focus,
  button.bg-flat-light:hover {
    background-color: #60c3bc!important
  }

  .bg-flat-lighter {
    background-color: #aadfdb!important
  }

  a.bg-flat-lighter:focus,
  a.bg-flat-lighter:hover,
  button.bg-flat-lighter:focus,
  button.bg-flat-lighter:hover {
    background-color: #85d1cc!important
  }

  .bg-modern {
    background-color: #14adc4!important
  }

  a.bg-modern:focus,
  a.bg-modern:hover,
  button.bg-modern:focus,
  button.bg-modern:hover {
    background-color: #0f8496!important
  }

  .bg-modern-op {
    background-color: rgba(20, 173, 196, .75)!important
  }

  a.bg-modern-op:focus,
  a.bg-modern-op:hover,
  button.bg-modern-op:focus,
  button.bg-modern-op:hover {
    background-color: rgba(15, 132, 150, .75)!important
  }

  .bg-modern-dark {
    background-color: #3e4d52!important
  }

  a.bg-modern-dark:focus,
  a.bg-modern-dark:hover,
  button.bg-modern-dark:focus,
  button.bg-modern-dark:hover {
    background-color: #283235!important
  }

  .bg-modern-dark-op {
    background-color: rgba(62, 77, 82, .8)!important
  }

  a.bg-modern-dark-op:focus,
  a.bg-modern-dark-op:hover,
  button.bg-modern-dark-op:focus,
  button.bg-modern-dark-op:hover {
    background-color: rgba(40, 50, 53, .8)!important
  }

  .bg-modern-darker {
    background-color: #323e42!important
  }

  a.bg-modern-darker:focus,
  a.bg-modern-darker:hover,
  button.bg-modern-darker:focus,
  button.bg-modern-darker:hover {
    background-color: #1c2325!important
  }

  .bg-modern-light {
    background-color: #7fe3f2!important
  }

  a.bg-modern-light:focus,
  a.bg-modern-light:hover,
  button.bg-modern-light:focus,
  button.bg-modern-light:hover {
    background-color: #51d9ed!important
  }

  .bg-modern-lighter {
    background-color: #c4f2f9!important
  }

  a.bg-modern-lighter:focus,
  a.bg-modern-lighter:hover,
  button.bg-modern-lighter:focus,
  button.bg-modern-lighter:hover {
    background-color: #96e8f4!important
  }

  .bg-smooth {
    background-color: #ff6c9d!important
  }

  a.bg-smooth:focus,
  a.bg-smooth:hover,
  button.bg-smooth:focus,
  button.bg-smooth:hover {
    background-color: #ff397b!important
  }

  .bg-smooth-op {
    background-color: rgba(255, 108, 157, .75)!important
  }

  a.bg-smooth-op:focus,
  a.bg-smooth-op:hover,
  button.bg-smooth-op:focus,
  button.bg-smooth-op:hover {
    background-color: rgba(255, 57, 123, .75)!important
  }

  .bg-smooth-dark {
    background-color: #4a5568!important
  }

  a.bg-smooth-dark:focus,
  a.bg-smooth-dark:hover,
  button.bg-smooth-dark:focus,
  button.bg-smooth-dark:hover {
    background-color: #353d4a!important
  }

  .bg-smooth-dark-op {
    background-color: rgba(74, 85, 104, .8)!important
  }

  a.bg-smooth-dark-op:focus,
  a.bg-smooth-dark-op:hover,
  button.bg-smooth-dark-op:focus,
  button.bg-smooth-dark-op:hover {
    background-color: rgba(53, 61, 74, .8)!important
  }

  .bg-smooth-darker {
    background-color: #333a47!important
  }

  a.bg-smooth-darker:focus,
  a.bg-smooth-darker:hover,
  button.bg-smooth-darker:focus,
  button.bg-smooth-darker:hover {
    background-color: #1e2229!important
  }

  .bg-smooth-light {
    background-color: #ff90b5!important
  }

  a.bg-smooth-light:focus,
  a.bg-smooth-light:hover,
  button.bg-smooth-light:focus,
  button.bg-smooth-light:hover {
    background-color: #ff5d93!important
  }

  .bg-smooth-lighter {
    background-color: #ffb9d0!important
  }

  a.bg-smooth-lighter:focus,
  a.bg-smooth-lighter:hover,
  button.bg-smooth-lighter:focus,
  button.bg-smooth-lighter:hover {
    background-color: #ff86ae!important
  }

  .border-white-op {
    border-color: hsla(0, 0%, 100%, .1)!important
  }

  .border-black-op {
    border-color: rgba(0, 0, 0, .1)!important
  }

  .border-2x {
    border-width: 2px!important
  }

  .border-3x {
    border-width: 3px!important
  }

  .border-4x {
    border-width: 4px!important
  }

  .border-5x {
    border-width: 5px!important
  }

  .border-10x {
    border-width: 10px!important
  }

  .overflow-y-auto {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
  }

  .opacity-0 {
    opacity: 0
  }

  .opacity-25 {
    opacity: .25
  }

  .opacity-50 {
    opacity: .5
  }

  .opacity-75 {
    opacity: .75
  }

  .opacity-1 {
    opacity: 1
  }

  .no-transition {
    -webkit-transition: none!important;
    transition: none!important
  }

  .click-ripple {
    display: block;
    position: absolute;
    background: hsla(0, 0%, 100%, .6);
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  .click-ripple.animate {
    -webkit-animation: click-ripple .6s ease-out;
    animation: click-ripple .6s ease-out
  }

  @-webkit-keyframes click-ripple {
    50% {
      opacity: .6;
      -webkit-transform: scale(2);
      transform: scale(2)
    }
    to {
      opacity: 0;
      -webkit-transform: scale(3);
      transform: scale(3)
    }
  }

  @keyframes click-ripple {
    50% {
      opacity: .6;
      -webkit-transform: scale(2);
      transform: scale(2)
    }
    to {
      opacity: 0;
      -webkit-transform: scale(3);
      transform: scale(3)
    }
  }

  .flex-00-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
  }

  .flex-10-auto {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto
  }

  @media (min-width:576px) {
    .flex-sm-00-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto
    }
    .flex-sm-10-auto {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto
    }
  }

  @media (min-width:768px) {
    .flex-md-00-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto
    }
    .flex-md-10-auto {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto
    }
  }

  @media (min-width:992px) {
    .flex-lg-00-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto
    }
    .flex-lg-10-auto {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto
    }
  }

  @media (min-width:1200px) {
    .flex-xl-00-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto
    }
    .flex-xl-10-auto {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto
    }
  }

  .font-w100 {
    font-weight: 100!important
  }

  .font-w200 {
    font-weight: 200!important
  }

  .font-w300 {
    font-weight: 300!important
  }

  .font-w400 {
    font-weight: 400!important
  }

  .font-w500 {
    font-weight: 500!important
  }

  .font-w600 {
    font-weight: 600!important
  }

  .font-w700 {
    font-weight: 700!important
  }

  .font-w800 {
    font-weight: 800!important
  }

  .font-w900 {
    font-weight: 900!important
  }

  .font-size-base {
    font-size: 1rem!important
  }

  .font-size-lg {
    font-size: 1.25rem!important
  }

  .font-size-sm {
    font-size: .875rem!important
  }

  .font-size-10 {
    font-size: .625rem!important
  }

  .font-size-12 {
    font-size: .75rem!important
  }

  .font-size-14 {
    font-size: .875rem!important
  }

  .font-size-16 {
    font-size: 1rem!important
  }

  .font-size-18 {
    font-size: 1.125rem!important
  }

  .font-size-20 {
    font-size: 1.25rem!important
  }

  .font-size-24 {
    font-size: 1.5rem!important
  }

  .text-wrap-break-word {
    word-wrap: break-word
  }

  .text-primary-dark {
    color: #0e53c3!important
  }

  a.text-primary-dark.link-fx:before {
    background-color: #0e53c3
  }

  .text-primary-darker {
    color: #0d4fb9!important
  }

  a.text-primary-darker.link-fx:before {
    background-color: #0d4fb9
  }

  .text-primary-light {
    color: #3c81f1!important
  }

  a.text-primary-light.link-fx:before {
    background-color: #3c81f1
  }

  .text-primary-lighter {
    color: #79a9f5!important
  }

  a.text-primary-lighter.link-fx:before {
    background-color: #79a9f5
  }

  .text-success-light {
    color: #e0f5e9!important
  }

  a.text-success-light.link-fx:before {
    background-color: #e0f5e9
  }

  .text-warning-light {
    color: #fdf3e5!important
  }

  a.text-warning-light.link-fx:before {
    background-color: #fdf3e5
  }

  .text-info-light {
    color: #edf6fd!important
  }

  a.text-info-light.link-fx:before {
    background-color: #edf6fd
  }

  .text-danger-light {
    color: #f9eae8!important
  }

  a.text-danger-light.link-fx:before {
    background-color: #f9eae8
  }

  .text-body-bg {
    color: #f5f5f5!important
  }

  a.text-body-bg.link-fx:before {
    background-color: #f5f5f5
  }

  .text-body-bg-dark {
    color: #ebebeb!important
  }

  a.text-body-bg-dark.link-fx:before {
    background-color: #ebebeb
  }

  .text-body-bg-light {
    color: #f9f9f9!important
  }

  a.text-body-bg-light.link-fx:before {
    background-color: #f9f9f9
  }

  .text-body-color {
    color: #575757!important
  }

  a.text-body-color.link-fx:before {
    background-color: #575757
  }

  .text-body-color-dark {
    color: #373737!important
  }

  a.text-body-color-dark.link-fx:before {
    background-color: #373737
  }

  .text-body-color-light {
    color: #ebebeb!important
  }

  a.text-body-color-light.link-fx:before {
    background-color: #ebebeb
  }

  .text-muted {
    color: #6c757d!important
  }

  a.text-muted.link-fx:before {
    background-color: #6c757d
  }

  .text-white {
    color: #fff!important
  }

  a.text-white.link-fx:before {
    background-color: #fff
  }

  .text-white-50 {
    color: hsla(0, 0%, 100%, .5)!important
  }

  a.text-white-50.link-fx:before {
    background-color: hsla(0, 0%, 100%, .5)
  }

  .text-white-75 {
    color: hsla(0, 0%, 100%, .75)!important
  }

  a.text-white-75.link-fx:before {
    background-color: hsla(0, 0%, 100%, .75)
  }

  .text-black {
    color: #000!important
  }

  a.text-black.link-fx:before {
    background-color: #000
  }

  .text-black-50 {
    color: rgba(0, 0, 0, .5)!important
  }

  a.text-black-50.link-fx:before {
    background-color: rgba(0, 0, 0, .5)
  }

  .text-black-75 {
    color: rgba(0, 0, 0, .75)!important
  }

  a.text-black-75.link-fx:before {
    background-color: rgba(0, 0, 0, .75)
  }

  .text-gray {
    color: #ced4da!important
  }

  a.text-gray.link-fx:before {
    background-color: #ced4da
  }

  .text-gray-dark {
    color: #6c757d!important
  }

  a.text-gray-dark.link-fx:before {
    background-color: #6c757d
  }

  .text-gray-darker {
    color: #343a40!important
  }

  a.text-gray-darker.link-fx:before {
    background-color: #343a40
  }

  .text-gray-light {
    color: #e9ecef!important
  }

  a.text-gray-light.link-fx:before {
    background-color: #e9ecef
  }

  .text-gray-lighter {
    color: #f8f9fa!important
  }

  a.text-gray-lighter.link-fx:before {
    background-color: #f8f9fa
  }

  .text-dual {
    color: #0e53c3!important
  }

  a.text-dual.link-fx:before {
    background-color: #0e53c3
  }

  .page-header-dark #page-header .text-dual,
  .sidebar-dark #sidebar .text-dual {
    color: #ebebeb!important
  }

  .page-header-dark #page-header a.text-dual.link-fx:before,
  .sidebar-dark #sidebar a.text-dual.link-fx:before {
    background-color: #ebebeb
  }

  .text-default {
    color: #0f59d1!important
  }

  a.text-default.link-fx:before {
    background-color: #0f59d1
  }

  .text-default-dark {
    color: #0e53c3!important
  }

  a.text-default-dark.link-fx:before {
    background-color: #0e53c3
  }

  .text-default-darker {
    color: #0d4fb9!important
  }

  a.text-default-darker.link-fx:before {
    background-color: #0d4fb9
  }

  .text-default-light {
    color: #3c81f1!important
  }

  a.text-default-light.link-fx:before {
    background-color: #3c81f1
  }

  .text-default-lighter {
    color: #79a9f5!important
  }

  a.text-default-lighter.link-fx:before {
    background-color: #79a9f5
  }

  .text-amethyst {
    color: #a68ad4!important
  }

  a.text-amethyst.link-fx:before {
    background-color: #a68ad4
  }

  .text-amethyst-dark {
    color: #4f546b!important
  }

  a.text-amethyst-dark.link-fx:before {
    background-color: #4f546b
  }

  .text-amethyst-darker {
    color: #353847!important
  }

  a.text-amethyst-darker.link-fx:before {
    background-color: #353847
  }

  .text-amethyst-light {
    color: #c8b7e4!important
  }

  a.text-amethyst-light.link-fx:before {
    background-color: #c8b7e4
  }

  .text-amethyst-lighter {
    color: #e4dcf2!important
  }

  a.text-amethyst-lighter.link-fx:before {
    background-color: #e4dcf2
  }

  .text-city {
    color: #0f59d1!important
  }

  a.text-city.link-fx:before {
    background-color: #0f59d1
  }

  .text-city-dark {
    color: #{$dark-l-theme}!important
  }

  a.text-city-dark.link-fx:before {
    background-color: #{$dark-l-theme}
  }

  .text-city-darker {
    color: #333!important
  }

  a.text-city-darker.link-fx:before {
    background-color: #333
  }

  .text-city-light {
    color: #1568ee!important
  }

  a.text-city-light.link-fx:before {
    background-color: #1568ee
  }

  .text-city-lighter {
    color: #3c81f1!important
  }

  a.text-city-lighter.link-fx:before {
    background-color: #3c81f1
  }

  .text-flat {
    color: #45b5ae!important
  }

  a.text-flat.link-fx:before {
    background-color: #45b5ae
  }

  .text-flat-dark {
    color: #3f5259!important
  }

  a.text-flat-dark.link-fx:before {
    background-color: #3f5259
  }

  .text-flat-darker {
    color: #242f33!important
  }

  a.text-flat-darker.link-fx:before {
    background-color: #242f33
  }

  .text-flat-light {
    color: #85d1cc!important
  }

  a.text-flat-light.link-fx:before {
    background-color: #85d1cc
  }

  .text-flat-lighter {
    color: #aadfdb!important
  }

  a.text-flat-lighter.link-fx:before {
    background-color: #aadfdb
  }

  .text-modern {
    color: #14adc4!important
  }

  a.text-modern.link-fx:before {
    background-color: #14adc4
  }

  .text-modern-dark {
    color: #3e4d52!important
  }

  a.text-modern-dark.link-fx:before {
    background-color: #3e4d52
  }

  .text-modern-darker {
    color: #323e42!important
  }

  a.text-modern-darker.link-fx:before {
    background-color: #323e42
  }

  .text-modern-light {
    color: #7fe3f2!important
  }

  a.text-modern-light.link-fx:before {
    background-color: #7fe3f2
  }

  .text-modern-lighter {
    color: #c4f2f9!important
  }

  a.text-modern-lighter.link-fx:before {
    background-color: #c4f2f9
  }

  .text-smooth {
    color: #ff6c9d!important
  }

  a.text-smooth.link-fx:before {
    background-color: #ff6c9d
  }

  .text-smooth-dark {
    color: #4a5568!important
  }

  a.text-smooth-dark.link-fx:before {
    background-color: #4a5568
  }

  .text-smooth-darker {
    color: #333a47!important
  }

  a.text-smooth-darker.link-fx:before {
    background-color: #333a47
  }

  .text-smooth-light {
    color: #ff90b5!important
  }

  a.text-smooth-light.link-fx:before {
    background-color: #ff90b5
  }

  .text-smooth-lighter {
    color: #ffb9d0!important
  }

  a.text-smooth-lighter.link-fx:before {
    background-color: #ffb9d0
  }

  .bv-no-focus-ring:focus {
    outline: none
  }

  @media (max-width:575.98px) {
    .bv-d-xs-down-none {
      display: none!important
    }
  }

  @media (max-width:767.98px) {
    .bv-d-sm-down-none {
      display: none!important
    }
  }

  @media (max-width:991.98px) {
    .bv-d-md-down-none {
      display: none!important
    }
  }

  @media (max-width:1199.98px) {
    .bv-d-lg-down-none {
      display: none!important
    }
  }

  .bv-d-xl-down-none {
    display: none!important
  }

  .form-control.focus {
    color: #575757;
    background-color: #fff;
    border-color: #6b9ff4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .form-control.focus.is-valid {
    border-color: #46c37b;
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25)
  }

  .form-control.focus.is-invalid {
    border-color: #d26a5c;
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25)
  }

  .b-avatar {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
    font-size: inherit;
    font-weight: 400;
    line-height: 1;
    max-width: 100%;
    max-height: auto;
    text-align: center;
    overflow: visible;
    position: relative;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
  }

  .b-avatar:focus {
    outline: 0
  }

  .b-avatar.btn,
  .b-avatar[href] {
    padding: 0;
    border: 0
  }

  .b-avatar.btn .b-avatar-img img,
  .b-avatar[href] .b-avatar-img img {
    -webkit-transition: -webkit-transform .15s ease-in-out;
    transition: -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out
  }

  .b-avatar.btn:not(:disabled):not(.disabled),
  .b-avatar[href]:not(:disabled):not(.disabled) {
    cursor: pointer
  }

  .b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img,
  .b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
  }

  .b-avatar.disabled,
  .b-avatar:disabled,
  .b-avatar[disabled] {
    opacity: .65;
    pointer-events: none
  }

  .b-avatar .b-avatar-custom,
  .b-avatar .b-avatar-img,
  .b-avatar .b-avatar-text {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-mask-image: radial-gradient(#fff, #000);
    mask-image: radial-gradient(#fff, #000)
  }

  .b-avatar .b-avatar-text {
    text-transform: uppercase;
    white-space: nowrap
  }

  .b-avatar[href] {
    text-decoration: none
  }

  .b-avatar>.b-icon {
    width: 60%;
    height: auto;
    max-width: 100%
  }

  .b-avatar .b-avatar-img img {
    width: 100%;
    height: 100%;
    max-height: auto;
    border-radius: inherit;
    -o-object-fit: cover;
    object-fit: cover
  }

  .b-avatar .b-avatar-badge {
    position: absolute;
    min-height: 1.5em;
    min-width: 1.5em;
    padding: .25em;
    line-height: 1;
    border-radius: 10em;
    font-size: 70%;
    font-weight: 700;
    z-index: 1
  }

  .b-avatar-sm {
    width: 1.5rem;
    height: 1.5rem
  }

  .b-avatar-sm .b-avatar-text {
    font-size: .6rem
  }

  .b-avatar-sm .b-avatar-badge {
    font-size: .42rem
  }

  .b-avatar-lg {
    width: 3.5rem;
    height: 3.5rem
  }

  .b-avatar-lg .b-avatar-text {
    font-size: 1.4rem
  }

  .b-avatar-lg .b-avatar-badge {
    font-size: .98rem
  }

  .b-avatar-group .b-avatar-group-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .b-avatar-group .b-avatar {
    border: 1px solid #ebebeb
  }

  .b-avatar-group .btn.b-avatar:hover:not(.disabled):not(disabled),
  .b-avatar-group a.b-avatar:hover:not(.disabled):not(disabled) {
    z-index: 1
  }

  .b-calendar {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
  }

  .b-calendar .b-calendar-inner {
    min-width: 250px
  }

  .b-calendar .b-calendar-header,
  .b-calendar .b-calendar-nav {
    margin-bottom: .25rem
  }

  .b-calendar .b-calendar-nav .btn {
    padding: .25rem
  }

  .b-calendar output {
    padding: .25rem;
    font-size: 80%
  }

  .b-calendar output.readonly {
    background-color: #e9ecef;
    opacity: 1
  }

  .b-calendar .b-calendar-footer {
    margin-top: .5rem
  }

  .b-calendar .b-calendar-grid {
    padding: 0;
    margin: 0;
    overflow: hidden
  }

  .b-calendar .b-calendar-grid .row {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .b-calendar .b-calendar-grid-caption {
    padding: .25rem
  }

  .b-calendar .b-calendar-grid-body .col[data-date] .btn {
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 1;
    margin: 3px auto;
    padding: 9px 0
  }

  .b-calendar .btn.disabled,
  .b-calendar .btn:disabled,
  .b-calendar .btn[aria-disabled=true] {
    cursor: default;
    pointer-events: none
  }

  .card-img-left {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px
  }

  .card-img-right {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px
  }

  .dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret:before,
  .dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret:after {
    display: none!important
  }

  .dropdown .dropdown-menu:focus {
    outline: none
  }

  .b-dropdown-form {
    display: inline-block;
    padding: .5rem .75rem;
    width: 100%;
    clear: both;
    font-weight: 400
  }

  .b-dropdown-form:focus {
    outline: 1px dotted!important;
    outline: 5px auto -webkit-focus-ring-color!important
  }

  .b-dropdown-form.disabled,
  .b-dropdown-form:disabled {
    outline: 0!important;
    color: #adb5bd;
    pointer-events: none
  }

  .b-dropdown-text {
    display: inline-block;
    padding: .5rem .75rem;
    margin-bottom: 0;
    width: 100%;
    clear: both;
    font-weight: lighter
  }

  .custom-checkbox.b-custom-control-lg,
  .input-group-lg .custom-checkbox {
    font-size: 1.25rem;
    line-height: 1.5;
    padding-left: 1.875rem
  }

  .custom-checkbox.b-custom-control-lg .custom-control-label:before,
  .input-group-lg .custom-checkbox .custom-control-label:before {
    top: .3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: .3rem
  }

  .custom-checkbox.b-custom-control-lg .custom-control-label:after,
  .input-group-lg .custom-checkbox .custom-control-label:after {
    top: .3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    background-size: 50% 50%
  }

  .custom-checkbox.b-custom-control-sm,
  .input-group-sm .custom-checkbox {
    font-size: .875rem;
    line-height: 1.5;
    padding-left: 1.3125rem
  }

  .custom-checkbox.b-custom-control-sm .custom-control-label:before,
  .input-group-sm .custom-checkbox .custom-control-label:before {
    top: .21875rem;
    left: -1.3125rem;
    width: .875rem;
    height: .875rem;
    border-radius: .2rem
  }

  .custom-checkbox.b-custom-control-sm .custom-control-label:after,
  .input-group-sm .custom-checkbox .custom-control-label:after {
    top: .21875rem;
    left: -1.3125rem;
    width: .875rem;
    height: .875rem;
    background-size: 50% 50%
  }

  .custom-switch.b-custom-control-lg,
  .input-group-lg .custom-switch {
    padding-left: 2.8125rem
  }

  .custom-switch.b-custom-control-lg .custom-control-label,
  .input-group-lg .custom-switch .custom-control-label {
    font-size: 1.25rem;
    line-height: 1.5
  }

  .custom-switch.b-custom-control-lg .custom-control-label:before,
  .input-group-lg .custom-switch .custom-control-label:before {
    top: .3125rem;
    height: 1.25rem;
    left: -2.8125rem;
    width: 2.1875rem;
    border-radius: .625rem
  }

  .custom-switch.b-custom-control-lg .custom-control-label:after,
  .input-group-lg .custom-switch .custom-control-label:after {
    top: calc(.3125rem + 2px);
    left: calc(-2.8125rem + 2px);
    width: calc(1.25rem - 4px);
    height: calc(1.25rem - 4px);
    border-radius: .625rem;
    background-size: 50% 50%
  }

  .custom-switch.b-custom-control-lg .custom-control-input:checked~.custom-control-label:after,
  .input-group-lg .custom-switch .custom-control-input:checked~.custom-control-label:after {
    -webkit-transform: translateX(.9375rem);
    transform: translateX(.9375rem)
  }

  .custom-switch.b-custom-control-sm,
  .input-group-sm .custom-switch {
    padding-left: 1.96875rem
  }

  .custom-switch.b-custom-control-sm .custom-control-label,
  .input-group-sm .custom-switch .custom-control-label {
    font-size: .875rem;
    line-height: 1.5
  }

  .custom-switch.b-custom-control-sm .custom-control-label:before,
  .input-group-sm .custom-switch .custom-control-label:before {
    top: .21875rem;
    left: -1.96875rem;
    width: 1.53125rem;
    height: .875rem;
    border-radius: .4375rem
  }

  .custom-switch.b-custom-control-sm .custom-control-label:after,
  .input-group-sm .custom-switch .custom-control-label:after {
    top: calc(.21875rem + 2px);
    left: calc(-1.96875rem + 2px);
    width: calc(.875rem - 4px);
    height: calc(.875rem - 4px);
    border-radius: .4375rem;
    background-size: 50% 50%
  }

  .custom-switch.b-custom-control-sm .custom-control-input:checked~.custom-control-label:after,
  .input-group-sm .custom-switch .custom-control-input:checked~.custom-control-label:after {
    -webkit-transform: translateX(.65625rem);
    transform: translateX(.65625rem)
  }

  .input-group>.input-group-append:last-child>.btn-group:not(:last-child):not(.dropdown-toggle)>.btn,
  .input-group>.input-group-append:not(:last-child)>.btn-group>.btn,
  .input-group>.input-group-prepend>.btn-group>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group>.input-group-append>.btn-group>.btn,
  .input-group>.input-group-prepend:first-child>.btn-group:not(:first-child)>.btn,
  .input-group>.input-group-prepend:not(:first-child)>.btn-group>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .b-form-btn-label-control.form-control {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: auto;
    padding: 0;
    background-image: none
  }

  .input-group .b-form-btn-label-control.form-control {
    padding: 0
  }

  .b-form-btn-label-control.form-control[dir=rtl],
  [dir=rtl] .b-form-btn-label-control.form-control {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
  }

  .b-form-btn-label-control.form-control[dir=rtl]>label,
  [dir=rtl] .b-form-btn-label-control.form-control>label {
    text-align: right
  }

  .b-form-btn-label-control.form-control>.btn {
    line-height: 1;
    font-size: inherit;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    border: 0
  }

  .b-form-btn-label-control.form-control>.btn:disabled {
    pointer-events: none
  }

  .b-form-btn-label-control.form-control.is-valid>.btn {
    color: #46c37b
  }

  .b-form-btn-label-control.form-control.is-invalid>.btn {
    color: #d26a5c
  }

  .b-form-btn-label-control.form-control>.dropdown-menu {
    padding: .5rem
  }

  .b-form-btn-label-control.form-control>.form-control {
    height: auto;
    min-height: calc(1.5em + .75rem);
    padding-left: .25rem;
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    word-break: break-word;
    font-size: inherit;
    white-space: normal;
    cursor: pointer
  }

  .b-form-btn-label-control.form-control>.form-control.form-control-sm {
    min-height: calc(1.5em + .5rem)
  }

  .b-form-btn-label-control.form-control>.form-control.form-control-lg {
    min-height: calc(1.5em + 1rem)
  }

  .input-group.input-group-sm .b-form-btn-label-control.form-control>.form-control {
    min-height: calc(1.5em + .5rem);
    padding-top: .25rem;
    padding-bottom: .25rem
  }

  .input-group.input-group-lg .b-form-btn-label-control.form-control>.form-control {
    min-height: calc(1.5em + 1rem);
    padding-top: .5rem;
    padding-bottom: .5rem
  }

  .b-form-btn-label-control.form-control[aria-disabled=true],
  .b-form-btn-label-control.form-control[aria-readonly=true] {
    background-color: #e9ecef;
    opacity: 1
  }

  .b-form-btn-label-control.form-control[aria-disabled=true] {
    pointer-events: none
  }

  .b-form-btn-label-control.form-control[aria-disabled=true]>label {
    cursor: default
  }

  .b-form-btn-label-control.btn-group>.dropdown-menu {
    padding: .5rem
  }

  .custom-file-label {
    white-space: nowrap;
    overflow-x: hidden
  }

  .b-custom-control-lg.custom-file,
  .b-custom-control-lg .custom-file-input,
  .b-custom-control-lg .custom-file-label,
  .input-group-lg.custom-file,
  .input-group-lg .custom-file-input,
  .input-group-lg .custom-file-label {
    font-size: 1rem;
    height: calc(1.5em + 1rem + 2px)
  }

  .b-custom-control-lg .custom-file-label,
  .b-custom-control-lg .custom-file-label:after,
  .input-group-lg .custom-file-label,
  .input-group-lg .custom-file-label:after {
    padding: .5rem 1rem;
    line-height: 1.5
  }

  .b-custom-control-lg .custom-file-label,
  .input-group-lg .custom-file-label {
    border-radius: .3rem
  }

  .b-custom-control-lg .custom-file-label:after,
  .input-group-lg .custom-file-label:after {
    font-size: inherit;
    height: calc(1.5em + 1rem);
    border-radius: 0 .3rem .3rem 0
  }

  .b-custom-control-sm.custom-file,
  .b-custom-control-sm .custom-file-input,
  .b-custom-control-sm .custom-file-label,
  .input-group-sm.custom-file,
  .input-group-sm .custom-file-input,
  .input-group-sm .custom-file-label {
    font-size: .875rem;
    height: calc(1.5em + .5rem + 2px)
  }

  .b-custom-control-sm .custom-file-label,
  .b-custom-control-sm .custom-file-label:after,
  .input-group-sm .custom-file-label,
  .input-group-sm .custom-file-label:after {
    padding: .25rem .5rem;
    line-height: 1.5
  }

  .b-custom-control-sm .custom-file-label,
  .input-group-sm .custom-file-label {
    border-radius: .2rem
  }

  .b-custom-control-sm .custom-file-label:after,
  .input-group-sm .custom-file-label:after {
    font-size: inherit;
    height: calc(1.5em + .5rem);
    border-radius: 0 .2rem .2rem 0
  }

  input[type=color].form-control {
    height: calc(1.5em + .75rem + 2px);
    padding: .125rem .25rem
  }

  .input-group-sm input[type=color].form-control,
  input[type=color].form-control.form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .125rem .25rem
  }

  .input-group-lg input[type=color].form-control,
  input[type=color].form-control.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: .125rem .25rem
  }

  input[type=color].form-control:disabled {
    background-color: #adb5bd;
    opacity: .65
  }

  .input-group>.custom-range {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
  }

  .input-group>.custom-file+.custom-range,
  .input-group>.custom-range+.custom-file,
  .input-group>.custom-range+.custom-range,
  .input-group>.custom-range+.custom-select,
  .input-group>.custom-range+.form-control,
  .input-group>.custom-range+.form-control-plaintext,
  .input-group>.custom-select+.custom-range,
  .input-group>.form-control+.custom-range,
  .input-group>.form-control-plaintext+.custom-range {
    margin-left: -1px
  }

  .input-group>.custom-range:focus {
    z-index: 3
  }

  .input-group>.custom-range:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group>.custom-range:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .input-group>.custom-range {
    padding: 0 .75rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e1e1e1;
    height: calc(1.5em + .75rem + 2px);
    border-radius: 8px;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
  }

  @media (prefers-reduced-motion:reduce) {
    .input-group>.custom-range {
      -webkit-transition: none;
      transition: none
    }
  }

  .input-group>.custom-range:focus {
    color: #575757;
    background-color: #fff;
    border-color: #6b9ff4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .input-group>.custom-range:disabled,
  .input-group>.custom-range[readonly] {
    background-color: #e9ecef
  }

  .input-group-lg>.custom-range {
    height: calc(1.5em + 1rem + 2px);
    padding: 0 1rem;
    border-radius: .3rem
  }

  .input-group-sm>.custom-range {
    height: calc(1.5em + .5rem + 2px);
    padding: 0 .5rem;
    border-radius: .2rem
  }

  .input-group .custom-range.is-valid,
  .was-validated .input-group .custom-range:valid {
    border-color: #46c37b
  }

  .input-group .custom-range.is-valid:focus,
  .was-validated .input-group .custom-range:valid:focus {
    border-color: #46c37b;
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25)
  }

  .custom-range.is-valid:focus::-webkit-slider-thumb,
  .was-validated .custom-range:valid:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem #cdefdb;
    box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem #cdefdb
  }

  .custom-range.is-valid:focus::-moz-range-thumb,
  .was-validated .custom-range:valid:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem #cdefdb
  }

  .custom-range.is-valid:focus::-ms-thumb,
  .was-validated .custom-range:valid:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem #cdefdb
  }

  .custom-range.is-valid::-webkit-slider-thumb,
  .was-validated .custom-range:valid::-webkit-slider-thumb {
    background-color: #46c37b;
    background-image: none
  }

  .custom-range.is-valid::-webkit-slider-thumb:active,
  .was-validated .custom-range:valid::-webkit-slider-thumb:active {
    background-color: #cdefdb;
    background-image: none
  }

  .custom-range.is-valid::-webkit-slider-runnable-track,
  .was-validated .custom-range:valid::-webkit-slider-runnable-track {
    background-color: rgba(70, 195, 123, .35)
  }

  .custom-range.is-valid::-moz-range-thumb,
  .was-validated .custom-range:valid::-moz-range-thumb {
    background-color: #46c37b;
    background-image: none
  }

  .custom-range.is-valid::-moz-range-thumb:active,
  .was-validated .custom-range:valid::-moz-range-thumb:active {
    background-color: #cdefdb;
    background-image: none
  }

  .custom-range.is-valid::-moz-range-track,
  .was-validated .custom-range:valid::-moz-range-track {
    background: rgba(70, 195, 123, .35)
  }

  .custom-range.is-valid~.valid-feedback,
  .custom-range.is-valid~.valid-tooltip,
  .was-validated .custom-range:valid~.valid-feedback,
  .was-validated .custom-range:valid~.valid-tooltip {
    display: block
  }

  .custom-range.is-valid::-ms-thumb,
  .was-validated .custom-range:valid::-ms-thumb {
    background-color: #46c37b;
    background-image: none
  }

  .custom-range.is-valid::-ms-thumb:active,
  .was-validated .custom-range:valid::-ms-thumb:active {
    background-color: #cdefdb;
    background-image: none
  }

  .custom-range.is-valid::-ms-track-lower,
  .custom-range.is-valid::-ms-track-upper,
  .was-validated .custom-range:valid::-ms-track-lower,
  .was-validated .custom-range:valid::-ms-track-upper {
    background: rgba(70, 195, 123, .35)
  }

  .input-group .custom-range.is-invalid,
  .was-validated .input-group .custom-range:invalid {
    border-color: #d26a5c
  }

  .input-group .custom-range.is-invalid:focus,
  .was-validated .input-group .custom-range:invalid:focus {
    border-color: #d26a5c;
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25)
  }

  .custom-range.is-invalid:focus::-webkit-slider-thumb,
  .was-validated .custom-range:invalid:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem #f9eae8;
    box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem #f9eae8
  }

  .custom-range.is-invalid:focus::-moz-range-thumb,
  .was-validated .custom-range:invalid:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem #f9eae8
  }

  .custom-range.is-invalid:focus::-ms-thumb,
  .was-validated .custom-range:invalid:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 .2rem #f9eae8
  }

  .custom-range.is-invalid::-webkit-slider-thumb,
  .was-validated .custom-range:invalid::-webkit-slider-thumb {
    background-color: #d26a5c;
    background-image: none
  }

  .custom-range.is-invalid::-webkit-slider-thumb:active,
  .was-validated .custom-range:invalid::-webkit-slider-thumb:active {
    background-color: #f9eae8;
    background-image: none
  }

  .custom-range.is-invalid::-webkit-slider-runnable-track,
  .was-validated .custom-range:invalid::-webkit-slider-runnable-track {
    background-color: rgba(210, 106, 92, .35)
  }

  .custom-range.is-invalid::-moz-range-thumb,
  .was-validated .custom-range:invalid::-moz-range-thumb {
    background-color: #d26a5c;
    background-image: none
  }

  .custom-range.is-invalid::-moz-range-thumb:active,
  .was-validated .custom-range:invalid::-moz-range-thumb:active {
    background-color: #f9eae8;
    background-image: none
  }

  .custom-range.is-invalid::-moz-range-track,
  .was-validated .custom-range:invalid::-moz-range-track {
    background: rgba(210, 106, 92, .35)
  }

  .custom-range.is-invalid~.invalid-feedback,
  .custom-range.is-invalid~.invalid-tooltip,
  .was-validated .custom-range:invalid~.invalid-feedback,
  .was-validated .custom-range:invalid~.invalid-tooltip {
    display: block
  }

  .custom-range.is-invalid::-ms-thumb,
  .was-validated .custom-range:invalid::-ms-thumb {
    background-color: #d26a5c;
    background-image: none
  }

  .custom-range.is-invalid::-ms-thumb:active,
  .was-validated .custom-range:invalid::-ms-thumb:active {
    background-color: #f9eae8;
    background-image: none
  }

  .custom-range.is-invalid::-ms-track-lower,
  .custom-range.is-invalid::-ms-track-upper,
  .was-validated .custom-range:invalid::-ms-track-lower,
  .was-validated .custom-range:invalid::-ms-track-upper {
    background: rgba(210, 106, 92, .35)
  }

  .custom-radio.b-custom-control-lg,
  .input-group-lg .custom-radio {
    font-size: 1.25rem;
    line-height: 1.5;
    padding-left: 1.875rem
  }

  .custom-radio.b-custom-control-lg .custom-control-label:before,
  .input-group-lg .custom-radio .custom-control-label:before {
    top: .3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%
  }

  .custom-radio.b-custom-control-lg .custom-control-label:after,
  .input-group-lg .custom-radio .custom-control-label:after {
    top: .3125rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    background: no-repeat 50%/50% 50%
  }

  .custom-radio.b-custom-control-sm,
  .input-group-sm .custom-radio {
    font-size: .875rem;
    line-height: 1.5;
    padding-left: 1.3125rem
  }

  .custom-radio.b-custom-control-sm .custom-control-label:before,
  .input-group-sm .custom-radio .custom-control-label:before {
    top: .21875rem;
    left: -1.3125rem;
    width: .875rem;
    height: .875rem;
    border-radius: 50%
  }

  .custom-radio.b-custom-control-sm .custom-control-label:after,
  .input-group-sm .custom-radio .custom-control-label:after {
    top: .21875rem;
    left: -1.3125rem;
    width: .875rem;
    height: .875rem;
    background: no-repeat 50%/50% 50%
  }

  .b-rating {
    text-align: center
  }

  .b-rating.d-inline-flex {
    width: auto
  }

  .b-rating .b-rating-star,
  .b-rating .b-rating-value {
    padding: 0 .25em
  }

  .b-rating .b-rating-value {
    min-width: 2.5em
  }

  .b-rating .b-rating-star {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    outline: 0
  }

  .b-rating .b-rating-star,
  .b-rating .b-rating-star .b-rating-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
  }

  .b-rating .b-rating-star .b-rating-icon {
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
  }

  .b-rating.disabled,
  .b-rating:disabled {
    background-color: #e9ecef;
    color: #6c757d
  }

  .b-rating:not(.disabled):not(.readonly) .b-rating-star {
    cursor: pointer
  }

  .b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon,
  .b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon {
    -webkit-transform: scale(1.5);
    transform: scale(1.5)
  }

  .b-rating[dir=rtl] .b-rating-star-half {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
  }

  .b-form-spinbutton {
    text-align: center;
    overflow: hidden;
    background-image: none;
    padding: 0
  }

  .b-form-spinbutton[dir=rtl]:not(.flex-column),
  [dir=rtl] .b-form-spinbutton:not(.flex-column) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
  }

  .b-form-spinbutton output {
    font-size: inherit;
    outline: 0;
    border: 0;
    background-color: transparent;
    width: auto;
    margin: 0;
    padding: 0 .25rem
  }

  .b-form-spinbutton output>bdi,
  .b-form-spinbutton output>div {
    display: block;
    min-width: 2.25em;
    height: 1.5em
  }

  .b-form-spinbutton.flex-column {
    height: auto;
    width: auto
  }

  .b-form-spinbutton.flex-column output {
    margin: 0 .25rem;
    padding: .25rem 0
  }

  .b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
    output-width: 100%
  }

  .b-form-spinbutton.d-inline-flex:not(.flex-column) {
    width: auto
  }

  .b-form-spinbutton .btn {
    line-height: 1;
    -webkit-box-shadow: none!important;
    box-shadow: none!important
  }

  .b-form-spinbutton .btn:disabled {
    pointer-events: none
  }

  .b-form-spinbutton .btn:hover:not(:disabled)>div>.b-icon {
    -webkit-transform: scale(1.25);
    transform: scale(1.25)
  }

  .b-form-spinbutton.disabled,
  .b-form-spinbutton.readonly {
    background-color: #e9ecef
  }

  .b-form-spinbutton.disabled {
    pointer-events: none
  }

  .b-form-tags .b-form-tags-list {
    margin-top: -.25rem
  }

  .b-form-tags .b-form-tags-list .b-form-tag,
  .b-form-tags .b-form-tags-list .b-from-tags-field {
    margin-top: .25rem
  }

  .b-form-tags.focus {
    color: #575757;
    background-color: #fff;
    border-color: #6b9ff4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25);
    box-shadow: 0 0 0 .2rem rgba(15, 89, 209, .25)
  }

  .b-form-tags.focus.is-valid {
    border-color: #46c37b;
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25);
    box-shadow: 0 0 0 .2rem rgba(70, 195, 123, .25)
  }

  .b-form-tags.focus.is-invalid {
    border-color: #d26a5c;
    -webkit-box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25);
    box-shadow: 0 0 0 .2rem rgba(210, 106, 92, .25)
  }

  .b-form-tags.disabled {
    background-color: #e9ecef
  }

  .b-form-tag {
    font-size: 75%;
    font-weight: 400;
    line-height: 1.5;
    margin-right: .25rem
  }

  .b-form-tag.disabled {
    opacity: .75
  }

  .b-form-tag>button.b-form-tag-remove {
    color: inherit;
    font-size: 125%;
    line-height: 1;
    float: none;
    margin-left: .25rem
  }

  .form-control-lg .b-form-tag,
  .form-control-sm .b-form-tag {
    line-height: 1.5
  }

  .media-aside {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 1rem
  }

  .media-aside-right {
    margin-right: 0;
    margin-left: 1rem
  }

  .modal-backdrop {
    opacity: .7
  }

  .b-pagination-pills .page-item .page-link {
    border-radius: 50rem!important;
    margin-left: .25rem;
    line-height: 1
  }

  .b-pagination-pills .page-item:first-child .page-link {
    margin-left: 0
  }

  .popover.b-popover {
    display: block;
    opacity: 1;
    outline: 0
  }

  .popover.b-popover.fade:not(.show) {
    opacity: 0
  }

  .popover.b-popover.show {
    opacity: 1
  }

  .b-popover-primary-light.popover {
    background-color: #d8e6fc;
    border-color: #c8dcfb
  }

  .b-popover-primary-light.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-primary-light.bs-popover-top>.arrow:before {
    border-top-color: #c8dcfb
  }

  .b-popover-primary-light.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-primary-light.bs-popover-top>.arrow:after {
    border-top-color: #d8e6fc
  }

  .b-popover-primary-light.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-primary-light.bs-popover-right>.arrow:before {
    border-right-color: #c8dcfb
  }

  .b-popover-primary-light.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-primary-light.bs-popover-right>.arrow:after {
    border-right-color: #d8e6fc
  }

  .b-popover-primary-light.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-primary-light.bs-popover-bottom>.arrow:before {
    border-bottom-color: #c8dcfb
  }

  .b-popover-primary-light.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-primary-light.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-primary-light.bs-popover-bottom .popover-header:before,
  .b-popover-primary-light.bs-popover-bottom>.arrow:after {
    border-bottom-color: #caddfb
  }

  .b-popover-primary-light.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-primary-light.bs-popover-left>.arrow:before {
    border-left-color: #c8dcfb
  }

  .b-popover-primary-light.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-primary-light.bs-popover-left>.arrow:after {
    border-left-color: #d8e6fc
  }

  .b-popover-primary-light .popover-header {
    color: #212529;
    background-color: #caddfb;
    border-bottom-color: #b2cef9
  }

  .b-popover-primary-light .popover-body {
    color: #1f437d
  }

  .b-popover-primary-lighter.popover {
    background-color: #e4eefd;
    border-color: #dae7fc
  }

  .b-popover-primary-lighter.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-primary-lighter.bs-popover-top>.arrow:before {
    border-top-color: #dae7fc
  }

  .b-popover-primary-lighter.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-primary-lighter.bs-popover-top>.arrow:after {
    border-top-color: #e4eefd
  }

  .b-popover-primary-lighter.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-primary-lighter.bs-popover-right>.arrow:before {
    border-right-color: #dae7fc
  }

  .b-popover-primary-lighter.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-primary-lighter.bs-popover-right>.arrow:after {
    border-right-color: #e4eefd
  }

  .b-popover-primary-lighter.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-primary-lighter.bs-popover-bottom>.arrow:before {
    border-bottom-color: #dae7fc
  }

  .b-popover-primary-lighter.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-primary-lighter.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-primary-lighter.bs-popover-bottom .popover-header:before,
  .b-popover-primary-lighter.bs-popover-bottom>.arrow:after {
    border-bottom-color: #d6e5fc
  }

  .b-popover-primary-lighter.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-primary-lighter.bs-popover-left>.arrow:before {
    border-left-color: #dae7fc
  }

  .b-popover-primary-lighter.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-primary-lighter.bs-popover-left>.arrow:after {
    border-left-color: #e4eefd
  }

  .b-popover-primary-lighter .popover-header {
    color: #212529;
    background-color: #d6e5fc;
    border-bottom-color: #bed6fa
  }

  .b-popover-primary-lighter .popover-body {
    color: #3f5880
  }

  .b-popover-primary-dark.popover {
    background-color: #cfddf3;
    border-color: #bccfee
  }

  .b-popover-primary-dark.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-primary-dark.bs-popover-top>.arrow:before {
    border-top-color: #bccfee
  }

  .b-popover-primary-dark.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-primary-dark.bs-popover-top>.arrow:after {
    border-top-color: #cfddf3
  }

  .b-popover-primary-dark.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-primary-dark.bs-popover-right>.arrow:before {
    border-right-color: #bccfee
  }

  .b-popover-primary-dark.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-primary-dark.bs-popover-right>.arrow:after {
    border-right-color: #cfddf3
  }

  .b-popover-primary-dark.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-primary-dark.bs-popover-bottom>.arrow:before {
    border-bottom-color: #bccfee
  }

  .b-popover-primary-dark.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-primary-dark.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-primary-dark.bs-popover-bottom .popover-header:before,
  .b-popover-primary-dark.bs-popover-bottom>.arrow:after {
    border-bottom-color: #c3d4f0
  }

  .b-popover-primary-dark.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-primary-dark.bs-popover-left>.arrow:before {
    border-left-color: #bccfee
  }

  .b-popover-primary-dark.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-primary-dark.bs-popover-left>.arrow:after {
    border-left-color: #cfddf3
  }

  .b-popover-primary-dark .popover-header {
    color: #212529;
    background-color: #c3d4f0;
    border-bottom-color: #aec6eb
  }

  .b-popover-primary-dark .popover-body {
    color: #072b65
  }

  .b-popover-primary-darker.popover {
    background-color: #cfdcf1;
    border-color: #bbceeb
  }

  .b-popover-primary-darker.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-primary-darker.bs-popover-top>.arrow:before {
    border-top-color: #bbceeb
  }

  .b-popover-primary-darker.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-primary-darker.bs-popover-top>.arrow:after {
    border-top-color: #cfdcf1
  }

  .b-popover-primary-darker.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-primary-darker.bs-popover-right>.arrow:before {
    border-right-color: #bbceeb
  }

  .b-popover-primary-darker.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-primary-darker.bs-popover-right>.arrow:after {
    border-right-color: #cfdcf1
  }

  .b-popover-primary-darker.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-primary-darker.bs-popover-bottom>.arrow:before {
    border-bottom-color: #bbceeb
  }

  .b-popover-primary-darker.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-primary-darker.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-primary-darker.bs-popover-bottom .popover-header:before,
  .b-popover-primary-darker.bs-popover-bottom>.arrow:after {
    border-bottom-color: #c3d3ee
  }

  .b-popover-primary-darker.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-primary-darker.bs-popover-left>.arrow:before {
    border-left-color: #bbceeb
  }

  .b-popover-primary-darker.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-primary-darker.bs-popover-left>.arrow:after {
    border-left-color: #cfdcf1
  }

  .b-popover-primary-darker .popover-header {
    color: #212529;
    background-color: #c3d3ee;
    border-bottom-color: #afc5e8
  }

  .b-popover-primary-darker .popover-body {
    color: #072960
  }

  .b-popover-success-light.popover {
    background-color: #f9fdfb;
    border-color: #f6fcf9
  }

  .b-popover-success-light.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-success-light.bs-popover-top>.arrow:before {
    border-top-color: #f6fcf9
  }

  .b-popover-success-light.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-success-light.bs-popover-top>.arrow:after {
    border-top-color: #f9fdfb
  }

  .b-popover-success-light.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-success-light.bs-popover-right>.arrow:before {
    border-right-color: #f6fcf9
  }

  .b-popover-success-light.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-success-light.bs-popover-right>.arrow:after {
    border-right-color: #f9fdfb
  }

  .b-popover-success-light.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-success-light.bs-popover-bottom>.arrow:before {
    border-bottom-color: #f6fcf9
  }

  .b-popover-success-light.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-success-light.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-success-light.bs-popover-bottom .popover-header:before,
  .b-popover-success-light.bs-popover-bottom>.arrow:after {
    border-bottom-color: #eef9f3
  }

  .b-popover-success-light.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-success-light.bs-popover-left>.arrow:before {
    border-left-color: #f6fcf9
  }

  .b-popover-success-light.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-success-light.bs-popover-left>.arrow:after {
    border-left-color: #f9fdfb
  }

  .b-popover-success-light .popover-header {
    color: #212529;
    background-color: #eef9f3;
    border-bottom-color: #daf3e7
  }

  .b-popover-success-light .popover-body {
    color: #747f79
  }

  .b-popover-info-light.popover {
    background-color: #fbfdff;
    border-color: #fafcfe
  }

  .b-popover-info-light.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-info-light.bs-popover-top>.arrow:before {
    border-top-color: #fafcfe
  }

  .b-popover-info-light.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-info-light.bs-popover-top>.arrow:after {
    border-top-color: #fbfdff
  }

  .b-popover-info-light.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-info-light.bs-popover-right>.arrow:before {
    border-right-color: #fafcfe
  }

  .b-popover-info-light.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-info-light.bs-popover-right>.arrow:after {
    border-right-color: #fbfdff
  }

  .b-popover-info-light.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-info-light.bs-popover-bottom>.arrow:before {
    border-bottom-color: #fafcfe
  }

  .b-popover-info-light.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-info-light.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-info-light.bs-popover-bottom .popover-header:before,
  .b-popover-info-light.bs-popover-bottom>.arrow:after {
    border-bottom-color: #ecf5ff
  }

  .b-popover-info-light.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-info-light.bs-popover-left>.arrow:before {
    border-left-color: #fafcfe
  }

  .b-popover-info-light.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-info-light.bs-popover-left>.arrow:after {
    border-left-color: #fbfdff
  }

  .b-popover-info-light .popover-header {
    color: #212529;
    background-color: #ecf5ff;
    border-bottom-color: #d2e9ff
  }

  .b-popover-info-light .popover-body {
    color: #7b8084
  }

  .b-popover-warning-light.popover {
    background-color: #fffdfa;
    border-color: #fefcf8
  }

  .b-popover-warning-light.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-warning-light.bs-popover-top>.arrow:before {
    border-top-color: #fefcf8
  }

  .b-popover-warning-light.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-warning-light.bs-popover-top>.arrow:after {
    border-top-color: #fffdfa
  }

  .b-popover-warning-light.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-warning-light.bs-popover-right>.arrow:before {
    border-right-color: #fefcf8
  }

  .b-popover-warning-light.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-warning-light.bs-popover-right>.arrow:after {
    border-right-color: #fffdfa
  }

  .b-popover-warning-light.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-warning-light.bs-popover-bottom>.arrow:before {
    border-bottom-color: #fefcf8
  }

  .b-popover-warning-light.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-warning-light.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-warning-light.bs-popover-bottom .popover-header:before,
  .b-popover-warning-light.bs-popover-bottom>.arrow:after {
    border-bottom-color: #fff7eb
  }

  .b-popover-warning-light.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-warning-light.bs-popover-left>.arrow:before {
    border-left-color: #fefcf8
  }

  .b-popover-warning-light.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-warning-light.bs-popover-left>.arrow:after {
    border-left-color: #fffdfa
  }

  .b-popover-warning-light .popover-header {
    color: #212529;
    background-color: #fff7eb;
    border-bottom-color: #ffedd1
  }

  .b-popover-warning-light .popover-body {
    color: #847e77
  }

  .b-popover-danger-light.popover {
    background-color: #fefbfa;
    border-color: #fdf9f9
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-danger-light.bs-popover-top>.arrow:before {
    border-top-color: #fdf9f9
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-danger-light.bs-popover-top>.arrow:after {
    border-top-color: #fefbfa
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-danger-light.bs-popover-right>.arrow:before {
    border-right-color: #fdf9f9
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-danger-light.bs-popover-right>.arrow:after {
    border-right-color: #fefbfa
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-danger-light.bs-popover-bottom>.arrow:before {
    border-bottom-color: #fdf9f9
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-danger-light.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-danger-light.bs-popover-bottom .popover-header:before,
  .b-popover-danger-light.bs-popover-bottom>.arrow:after {
    border-bottom-color: #fbf1ed
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-danger-light.bs-popover-left>.arrow:before {
    border-left-color: #fdf9f9
  }

  .b-popover-danger-light.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-danger-light.bs-popover-left>.arrow:after {
    border-left-color: #fefbfa
  }

  .b-popover-danger-light .popover-header {
    color: #212529;
    background-color: #fbf1ed;
    border-bottom-color: #f7e0d8
  }

  .b-popover-danger-light .popover-body {
    color: #817a79
  }

  .b-popover-primary.popover {
    background-color: #cfdef6;
    border-color: #bcd1f2
  }

  .b-popover-primary.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-primary.bs-popover-top>.arrow:before {
    border-top-color: #bcd1f2
  }

  .b-popover-primary.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-primary.bs-popover-top>.arrow:after {
    border-top-color: #cfdef6
  }

  .b-popover-primary.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-primary.bs-popover-right>.arrow:before {
    border-right-color: #bcd1f2
  }

  .b-popover-primary.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-primary.bs-popover-right>.arrow:after {
    border-right-color: #cfdef6
  }

  .b-popover-primary.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-primary.bs-popover-bottom>.arrow:before {
    border-bottom-color: #bcd1f2
  }

  .b-popover-primary.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-primary.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-primary.bs-popover-bottom .popover-header:before,
  .b-popover-primary.bs-popover-bottom>.arrow:after {
    border-bottom-color: #c2d5f4
  }

  .b-popover-primary.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-primary.bs-popover-left>.arrow:before {
    border-left-color: #bcd1f2
  }

  .b-popover-primary.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-primary.bs-popover-left>.arrow:after {
    border-left-color: #cfdef6
  }

  .b-popover-primary .popover-header {
    color: #212529;
    background-color: #c2d5f4;
    border-bottom-color: #adc6f0
  }

  .b-popover-primary .popover-body {
    color: #082e6d
  }

  .b-popover-secondary.popover {
    background-color: #e1e5e9;
    border-color: #d6dae1
  }

  .b-popover-secondary.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-secondary.bs-popover-top>.arrow:before {
    border-top-color: #d6dae1
  }

  .b-popover-secondary.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-secondary.bs-popover-top>.arrow:after {
    border-top-color: #e1e5e9
  }

  .b-popover-secondary.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-secondary.bs-popover-right>.arrow:before {
    border-right-color: #d6dae1
  }

  .b-popover-secondary.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-secondary.bs-popover-right>.arrow:after {
    border-right-color: #e1e5e9
  }

  .b-popover-secondary.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-secondary.bs-popover-bottom>.arrow:before {
    border-bottom-color: #d6dae1
  }

  .b-popover-secondary.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-secondary.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-secondary.bs-popover-bottom .popover-header:before,
  .b-popover-secondary.bs-popover-bottom>.arrow:after {
    border-bottom-color: #d8dde3
  }

  .b-popover-secondary.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-secondary.bs-popover-left>.arrow:before {
    border-left-color: #d6dae1
  }

  .b-popover-secondary.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-secondary.bs-popover-left>.arrow:after {
    border-left-color: #e1e5e9
  }

  .b-popover-secondary .popover-header {
    color: #212529;
    background-color: #d8dde3;
    border-bottom-color: #c9d1d8
  }

  .b-popover-secondary .popover-body {
    color: #38404c
  }

  .b-popover-success.popover {
    background-color: #daf3e5;
    border-color: #cbeeda
  }

  .b-popover-success.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-success.bs-popover-top>.arrow:before {
    border-top-color: #cbeeda
  }

  .b-popover-success.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-success.bs-popover-top>.arrow:after {
    border-top-color: #daf3e5
  }

  .b-popover-success.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-success.bs-popover-right>.arrow:before {
    border-right-color: #cbeeda
  }

  .b-popover-success.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-success.bs-popover-right>.arrow:after {
    border-right-color: #daf3e5
  }

  .b-popover-success.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-success.bs-popover-bottom>.arrow:before {
    border-bottom-color: #cbeeda
  }

  .b-popover-success.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-success.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-success.bs-popover-bottom .popover-header:before,
  .b-popover-success.bs-popover-bottom>.arrow:after {
    border-bottom-color: #ceefdd
  }

  .b-popover-success.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-success.bs-popover-left>.arrow:before {
    border-left-color: #cbeeda
  }

  .b-popover-success.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-success.bs-popover-left>.arrow:after {
    border-left-color: #daf3e5
  }

  .b-popover-success .popover-header {
    color: #212529;
    background-color: #ceefdd;
    border-bottom-color: #bbe9cf
  }

  .b-popover-success .popover-body {
    color: #246540
  }

  .b-popover-info.popover {
    background-color: #e2f1fb;
    border-color: #d7ebf9
  }

  .b-popover-info.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-info.bs-popover-top>.arrow:before {
    border-top-color: #d7ebf9
  }

  .b-popover-info.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-info.bs-popover-top>.arrow:after {
    border-top-color: #e2f1fb
  }

  .b-popover-info.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-info.bs-popover-right>.arrow:before {
    border-right-color: #d7ebf9
  }

  .b-popover-info.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-info.bs-popover-right>.arrow:after {
    border-right-color: #e2f1fb
  }

  .b-popover-info.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-info.bs-popover-bottom>.arrow:before {
    border-bottom-color: #d7ebf9
  }

  .b-popover-info.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-info.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-info.bs-popover-bottom .popover-header:before,
  .b-popover-info.bs-popover-bottom>.arrow:after {
    border-bottom-color: #d5ebf9
  }

  .b-popover-info.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-info.bs-popover-left>.arrow:before {
    border-left-color: #d7ebf9
  }

  .b-popover-info.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-info.bs-popover-left>.arrow:after {
    border-left-color: #e2f1fb
  }

  .b-popover-info .popover-header {
    color: #212529;
    background-color: #d5ebf9;
    border-bottom-color: #bee0f6
  }

  .b-popover-info .popover-body {
    color: #3a607a
  }

  .b-popover-warning.popover {
    background-color: #fdf1df;
    border-color: #fcebd2
  }

  .b-popover-warning.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-warning.bs-popover-top>.arrow:before {
    border-top-color: #fcebd2
  }

  .b-popover-warning.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-warning.bs-popover-top>.arrow:after {
    border-top-color: #fdf1df
  }

  .b-popover-warning.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-warning.bs-popover-right>.arrow:before {
    border-right-color: #fcebd2
  }

  .b-popover-warning.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-warning.bs-popover-right>.arrow:after {
    border-right-color: #fdf1df
  }

  .b-popover-warning.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-warning.bs-popover-bottom>.arrow:before {
    border-bottom-color: #fcebd2
  }

  .b-popover-warning.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-warning.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-warning.bs-popover-bottom .popover-header:before,
  .b-popover-warning.bs-popover-bottom>.arrow:after {
    border-bottom-color: #fcebd1
  }

  .b-popover-warning.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-warning.bs-popover-left>.arrow:before {
    border-left-color: #fcebd2
  }

  .b-popover-warning.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-warning.bs-popover-left>.arrow:after {
    border-left-color: #fdf1df
  }

  .b-popover-warning .popover-header {
    color: #212529;
    background-color: #fcebd1;
    border-bottom-color: #fbe0b9
  }

  .b-popover-warning .popover-body {
    color: #7e5f32
  }

  .b-popover-danger.popover {
    background-color: #f6e1de;
    border-color: #f2d5d1
  }

  .b-popover-danger.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-danger.bs-popover-top>.arrow:before {
    border-top-color: #f2d5d1
  }

  .b-popover-danger.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-danger.bs-popover-top>.arrow:after {
    border-top-color: #f6e1de
  }

  .b-popover-danger.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-danger.bs-popover-right>.arrow:before {
    border-right-color: #f2d5d1
  }

  .b-popover-danger.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-danger.bs-popover-right>.arrow:after {
    border-right-color: #f6e1de
  }

  .b-popover-danger.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-danger.bs-popover-bottom>.arrow:before {
    border-bottom-color: #f2d5d1
  }

  .b-popover-danger.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-danger.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-danger.bs-popover-bottom .popover-header:before,
  .b-popover-danger.bs-popover-bottom>.arrow:after {
    border-bottom-color: #f3d6d2
  }

  .b-popover-danger.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-danger.bs-popover-left>.arrow:before {
    border-left-color: #f2d5d1
  }

  .b-popover-danger.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-danger.bs-popover-left>.arrow:after {
    border-left-color: #f6e1de
  }

  .b-popover-danger .popover-header {
    color: #212529;
    background-color: #f3d6d2;
    border-bottom-color: #edc4be
  }

  .b-popover-danger .popover-body {
    color: #6d3730
  }

  .b-popover-light.popover {
    background-color: #fcfcfc;
    border-color: #fafafa
  }

  .b-popover-light.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-light.bs-popover-top>.arrow:before {
    border-top-color: #fafafa
  }

  .b-popover-light.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-light.bs-popover-top>.arrow:after {
    border-top-color: #fcfcfc
  }

  .b-popover-light.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-light.bs-popover-right>.arrow:before {
    border-right-color: #fafafa
  }

  .b-popover-light.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-light.bs-popover-right>.arrow:after {
    border-right-color: #fcfcfc
  }

  .b-popover-light.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-light.bs-popover-bottom>.arrow:before {
    border-bottom-color: #fafafa
  }

  .b-popover-light.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-light.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-light.bs-popover-bottom .popover-header:before,
  .b-popover-light.bs-popover-bottom>.arrow:after {
    border-bottom-color: #f4f4f4
  }

  .b-popover-light.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-light.bs-popover-left>.arrow:before {
    border-left-color: #fafafa
  }

  .b-popover-light.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-light.bs-popover-left>.arrow:after {
    border-left-color: #fcfcfc
  }

  .b-popover-light .popover-header {
    color: #212529;
    background-color: #f4f4f4;
    border-bottom-color: #e8e8e8
  }

  .b-popover-light .popover-body {
    color: #7c7c7c
  }

  .b-popover-dark.popover {
    background-color: #d2d6dc;
    border-color: #bfc6cd
  }

  .b-popover-dark.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-dark.bs-popover-top>.arrow:before {
    border-top-color: #bfc6cd
  }

  .b-popover-dark.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-dark.bs-popover-top>.arrow:after {
    border-top-color: #d2d6dc
  }

  .b-popover-dark.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-dark.bs-popover-right>.arrow:before {
    border-right-color: #bfc6cd
  }

  .b-popover-dark.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-dark.bs-popover-right>.arrow:after {
    border-right-color: #d2d6dc
  }

  .b-popover-dark.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-dark.bs-popover-bottom>.arrow:before {
    border-bottom-color: #bfc6cd
  }

  .b-popover-dark.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-dark.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-dark.bs-popover-bottom .popover-header:before,
  .b-popover-dark.bs-popover-bottom>.arrow:after {
    border-bottom-color: #c9ced5
  }

  .b-popover-dark.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-dark.bs-popover-left>.arrow:before {
    border-left-color: #bfc6cd
  }

  .b-popover-dark.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-dark.bs-popover-left>.arrow:after {
    border-left-color: #d2d6dc
  }

  .b-popover-dark .popover-header {
    color: #212529;
    background-color: #c9ced5;
    border-bottom-color: #bbc1ca
  }

  .b-popover-dark .popover-body {
    color: #0f1b29
  }

  .b-popover-white.popover {
    background-color: #fff;
    border-color: #fff
  }

  .b-popover-white.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-white.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-white.bs-popover-top>.arrow:after,
  .b-popover-white.bs-popover-top>.arrow:before {
    border-top-color: #fff
  }

  .b-popover-white.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-white.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-white.bs-popover-right>.arrow:after,
  .b-popover-white.bs-popover-right>.arrow:before {
    border-right-color: #fff
  }

  .b-popover-white.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-white.bs-popover-bottom>.arrow:before {
    border-bottom-color: #fff
  }

  .b-popover-white.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-white.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-white.bs-popover-bottom .popover-header:before,
  .b-popover-white.bs-popover-bottom>.arrow:after {
    border-bottom-color: #f7f7f7
  }

  .b-popover-white.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-white.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-white.bs-popover-left>.arrow:after,
  .b-popover-white.bs-popover-left>.arrow:before {
    border-left-color: #fff
  }

  .b-popover-white .popover-header {
    color: #212529;
    background-color: #f7f7f7;
    border-bottom-color: #ebebeb
  }

  .b-popover-white .popover-body {
    color: #858585
  }

  .b-popover-gray.popover {
    background-color: #f6f7f9;
    border-color: #f2f4f6
  }

  .b-popover-gray.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-gray.bs-popover-top>.arrow:before {
    border-top-color: #f2f4f6
  }

  .b-popover-gray.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-gray.bs-popover-top>.arrow:after {
    border-top-color: #f6f7f9
  }

  .b-popover-gray.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-gray.bs-popover-right>.arrow:before {
    border-right-color: #f2f4f6
  }

  .b-popover-gray.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-gray.bs-popover-right>.arrow:after {
    border-right-color: #f6f7f9
  }

  .b-popover-gray.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-gray.bs-popover-bottom>.arrow:before {
    border-bottom-color: #f2f4f6
  }

  .b-popover-gray.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-gray.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-gray.bs-popover-bottom .popover-header:before,
  .b-popover-gray.bs-popover-bottom>.arrow:after {
    border-bottom-color: #edeff3
  }

  .b-popover-gray.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-gray.bs-popover-left>.arrow:before {
    border-left-color: #f2f4f6
  }

  .b-popover-gray.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-gray.bs-popover-left>.arrow:after {
    border-left-color: #f6f7f9
  }

  .b-popover-gray .popover-header {
    color: #212529;
    background-color: #edeff3;
    border-bottom-color: #dee1e9
  }

  .b-popover-gray .popover-body {
    color: #6c7074
  }

  .b-popover-gray-dark.popover {
    background-color: #f6f7f9;
    border-color: #f2f4f6
  }

  .b-popover-gray-dark.bs-popover-auto[x-placement^=top]>.arrow:before,
  .b-popover-gray-dark.bs-popover-top>.arrow:before {
    border-top-color: #f2f4f6
  }

  .b-popover-gray-dark.bs-popover-auto[x-placement^=top]>.arrow:after,
  .b-popover-gray-dark.bs-popover-top>.arrow:after {
    border-top-color: #f6f7f9
  }

  .b-popover-gray-dark.bs-popover-auto[x-placement^=right]>.arrow:before,
  .b-popover-gray-dark.bs-popover-right>.arrow:before {
    border-right-color: #f2f4f6
  }

  .b-popover-gray-dark.bs-popover-auto[x-placement^=right]>.arrow:after,
  .b-popover-gray-dark.bs-popover-right>.arrow:after {
    border-right-color: #f6f7f9
  }

  .b-popover-gray-dark.bs-popover-auto[x-placement^=bottom]>.arrow:before,
  .b-popover-gray-dark.bs-popover-bottom>.arrow:before {
    border-bottom-color: #f2f4f6
  }

  .b-popover-gray-dark.bs-popover-auto[x-placement^=bottom] .popover-header:before,
  .b-popover-gray-dark.bs-popover-auto[x-placement^=bottom]>.arrow:after,
  .b-popover-gray-dark.bs-popover-bottom .popover-header:before,
  .b-popover-gray-dark.bs-popover-bottom>.arrow:after {
    border-bottom-color: #edeff3
  }

  .b-popover-gray-dark.bs-popover-auto[x-placement^=left]>.arrow:before,
  .b-popover-gray-dark.bs-popover-left>.arrow:before {
    border-left-color: #f2f4f6
  }

  .b-popover-gray-dark.bs-popover-auto[x-placement^=left]>.arrow:after,
  .b-popover-gray-dark.bs-popover-left>.arrow:after {
    border-left-color: #f6f7f9
  }

  .b-popover-gray-dark .popover-header {
    color: #212529;
    background-color: #edeff3;
    border-bottom-color: #dee1e9
  }

  .b-popover-gray-dark .popover-body {
    color: #6c7074
  }

  .b-sidebar-outer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    overflow: visible;
    z-index: 1035
  }

  .b-sidebar-backdrop {
    left: 0;
    z-index: -1;
    width: 100vw;
    opacity: .6
  }

  .b-sidebar,
  .b-sidebar-backdrop {
    position: fixed;
    top: 0;
    height: 100vh
  }

  .b-sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 320px;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    outline: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  .b-sidebar.slide {
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
  }

  @media (prefers-reduced-motion:reduce) {
    .b-sidebar.slide {
      -webkit-transition: none;
      transition: none
    }
  }

  .b-sidebar:not(.b-sidebar-right) {
    left: 0;
    right: auto
  }

  .b-sidebar:not(.b-sidebar-right).slide:not(.show) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }

  .b-sidebar:not(.b-sidebar-right)>.b-sidebar-header .close {
    margin-left: auto
  }

  .b-sidebar.b-sidebar-right {
    left: auto;
    right: 0
  }

  .b-sidebar.b-sidebar-right.slide:not(.show) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
  }

  .b-sidebar.b-sidebar-right>.b-sidebar-header .close {
    margin-right: auto
  }

  .b-sidebar>.b-sidebar-header {
    font-size: 1.5rem;
    padding: .5rem 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  [dir=rtl] .b-sidebar>.b-sidebar-header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
  }

  .b-sidebar>.b-sidebar-header .close {
    float: none;
    font-size: 1.5rem
  }

  .b-sidebar>.b-sidebar-body {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto
  }

  .b-sidebar>.b-sidebar-footer {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0
  }

  .b-skeleton-wrapper {
    cursor: wait
  }

  .b-skeleton {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, .12);
    cursor: wait;
    -webkit-mask-image: radial-gradient(#fff, #000);
    mask-image: radial-gradient(#fff, #000)
  }

  .b-skeleton:before {
    content: "\00a0"
  }

  .b-skeleton-text {
    height: 1rem;
    margin-bottom: .25rem;
    border-radius: .25rem
  }

  .b-skeleton-button {
    width: 75px;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 8px
  }

  .b-skeleton-avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%
  }

  .b-skeleton-input {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    line-height: 1.5;
    border: 1px solid #e1e1e1;
    border-radius: 8px
  }

  .b-skeleton-icon-wrapper svg {
    color: rgba(0, 0, 0, .12)
  }

  .b-skeleton-img {
    height: 100%;
    width: 100%
  }

  .b-skeleton-animate-wave:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(hsla(0, 0%, 100%, .4)), to(transparent));
    background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .4), transparent);
    -webkit-animation: b-skeleton-animate-wave 1.75s linear infinite;
    animation: b-skeleton-animate-wave 1.75s linear infinite
  }

  @media (prefers-reduced-motion:reduce) {
    .b-skeleton-animate-wave:after {
      background: none;
      -webkit-animation: none;
      animation: none
    }
  }

  @-webkit-keyframes b-skeleton-animate-wave {
    0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%)
    }
    to {
      -webkit-transform: translateX(100%);
      transform: translateX(100%)
    }
  }

  @keyframes b-skeleton-animate-wave {
    0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%)
    }
    to {
      -webkit-transform: translateX(100%);
      transform: translateX(100%)
    }
  }

  .b-skeleton-animate-fade {
    -webkit-animation: b-skeleton-animate-fade .875s ease-in-out infinite alternate;
    animation: b-skeleton-animate-fade .875s ease-in-out infinite alternate
  }

  @media (prefers-reduced-motion:reduce) {
    .b-skeleton-animate-fade {
      -webkit-animation: none;
      animation: none
    }
  }

  @-webkit-keyframes b-skeleton-animate-fade {
    0% {
      opacity: 1
    }
    to {
      opacity: .4
    }
  }

  @keyframes b-skeleton-animate-fade {
    0% {
      opacity: 1
    }
    to {
      opacity: .4
    }
  }

  .b-skeleton-animate-throb {
    -webkit-animation: b-skeleton-animate-throb .875s ease-in infinite alternate;
    animation: b-skeleton-animate-throb .875s ease-in infinite alternate
  }

  @media (prefers-reduced-motion:reduce) {
    .b-skeleton-animate-throb {
      -webkit-animation: none;
      animation: none
    }
  }

  @-webkit-keyframes b-skeleton-animate-throb {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1)
    }
    to {
      -webkit-transform: scale(.975);
      transform: scale(.975)
    }
  }

  @keyframes b-skeleton-animate-throb {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1)
    }
    to {
      -webkit-transform: scale(.975);
      transform: scale(.975)
    }
  }

  .b-table.dark-table>tfoot>tr>[aria-sort=none],
  .b-table.dark-table>thead>tr>[aria-sort=none],
  .b-table.table-dark>tfoot>tr>[aria-sort=none],
  .b-table.table-dark>thead>tr>[aria-sort=none],
  .table.b-table>tfoot>tr>[aria-sort=none],
  .table.b-table>thead>tr>[aria-sort=none] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
  }

  .b-table.dark-table>.thead-dark>tr>[aria-sort=none],
  .b-table.table-dark.dark-table>tfoot>tr>[aria-sort=none],
  .b-table.table-dark.dark-table>thead>tr>[aria-sort=none],
  .b-table.table-dark>.thead-dark>tr>[aria-sort=none],
  .b-table.table-dark>tfoot>tr>[aria-sort=none],
  .b-table.table-dark>thead>tr>[aria-sort=none],
  .table.b-table.table-dark>tfoot>tr>[aria-sort=none],
  .table.b-table.table-dark>thead>tr>[aria-sort=none],
  .table.b-table>.thead-dark>tr>[aria-sort=none] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath fill='%23fff' opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
  }

  .b-time {
    min-width: 150px
  }

  .b-time[aria-disabled=true] output,
  .b-time[aria-readonly=true] output,
  .b-time output.disabled {
    background-color: #e9ecef;
    opacity: 1
  }

  .b-time[aria-disabled=true] output {
    pointer-events: none
  }

  [dir=rtl] .b-time>.d-flex:not(.flex-column) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
  }

  .b-time .b-time-header {
    margin-bottom: .5rem
  }

  .b-time .b-time-header output {
    padding: .25rem;
    font-size: 80%
  }

  .b-time .b-time-footer {
    margin-top: .5rem
  }

  .b-time .b-time-ampm {
    margin-left: .5rem
  }

  .b-toast {
    display: block;
    position: relative;
    max-width: 350px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-clip: padding-box;
    z-index: 1;
    border-radius: .25rem
  }

  .b-toast .toast {
    background-color: hsla(0, 0%, 100%, .85)
  }

  .b-toast:not(:last-child) {
    margin-bottom: .75rem
  }

  .b-toast.b-toast-solid .toast {
    background-color: #fff
  }

  .b-toast .toast {
    opacity: 1
  }

  .b-toast .toast.fade:not(.show) {
    opacity: 0
  }

  .b-toast .toast .toast-body {
    display: block
  }

  .b-toast-primary-light .toast {
    background-color: rgba(240, 245, 254, .85);
    border-color: rgba(200, 220, 251, .85);
    color: #1f437d
  }

  .b-toast-primary-light .toast .toast-header {
    color: #1f437d;
    background-color: rgba(216, 230, 252, .85);
    border-bottom-color: rgba(200, 220, 251, .85)
  }

  .b-toast-primary-light.b-toast-solid .toast {
    background-color: #f0f5fe
  }

  .b-toast-primary-lighter .toast {
    background-color: rgba(252, 253, 255, .85);
    border-color: rgba(218, 231, 252, .85);
    color: #3f5880
  }

  .b-toast-primary-lighter .toast .toast-header {
    color: #3f5880;
    background-color: rgba(228, 238, 253, .85);
    border-bottom-color: rgba(218, 231, 252, .85)
  }

  .b-toast-primary-lighter.b-toast-solid .toast {
    background-color: #fcfdff
  }

  .b-toast-primary-dark .toast {
    background-color: rgba(227, 235, 248, .85);
    border-color: rgba(188, 207, 238, .85);
    color: #072b65
  }

  .b-toast-primary-dark .toast .toast-header {
    color: #072b65;
    background-color: rgba(207, 221, 243, .85);
    border-bottom-color: rgba(188, 207, 238, .85)
  }

  .b-toast-primary-dark.b-toast-solid .toast {
    background-color: #e3ebf8
  }

  .b-toast-primary-darker .toast {
    background-color: rgba(227, 234, 247, .85);
    border-color: rgba(187, 206, 235, .85);
    color: #072960
  }

  .b-toast-primary-darker .toast .toast-header {
    color: #072960;
    background-color: rgba(207, 220, 241, .85);
    border-bottom-color: rgba(187, 206, 235, .85)
  }

  .b-toast-primary-darker.b-toast-solid .toast {
    background-color: #e3eaf7
  }

  .b-toast-success-light .toast {
    background-color: hsla(0, 0%, 100%, .85);
    border-color: rgba(246, 252, 249, .85);
    color: #747f79
  }

  .b-toast-success-light .toast .toast-header {
    color: #747f79;
    background-color: rgba(249, 253, 251, .85);
    border-bottom-color: rgba(246, 252, 249, .85)
  }

  .b-toast-success-light.b-toast-solid .toast {
    background-color: #fff
  }

  .b-toast-info-light .toast {
    background-color: hsla(0, 0%, 100%, .85);
    border-color: rgba(250, 252, 254, .85);
    color: #7b8084
  }

  .b-toast-info-light .toast .toast-header {
    color: #7b8084;
    background-color: rgba(251, 253, 255, .85);
    border-bottom-color: rgba(250, 252, 254, .85)
  }

  .b-toast-info-light.b-toast-solid .toast {
    background-color: #fff
  }

  .b-toast-warning-light .toast {
    background-color: hsla(0, 0%, 100%, .85);
    border-color: rgba(254, 252, 248, .85);
    color: #847e77
  }

  .b-toast-warning-light .toast .toast-header {
    color: #847e77;
    background-color: rgba(255, 253, 250, .85);
    border-bottom-color: rgba(254, 252, 248, .85)
  }

  .b-toast-warning-light.b-toast-solid .toast {
    background-color: #fff
  }

  .b-toast-danger-light .toast {
    background-color: hsla(0, 0%, 100%, .85);
    border-color: rgba(253, 249, 249, .85);
    color: #817a79
  }

  .b-toast-danger-light .toast .toast-header {
    color: #817a79;
    background-color: rgba(254, 251, 250, .85);
    border-bottom-color: rgba(253, 249, 249, .85)
  }

  .b-toast-danger-light.b-toast-solid .toast {
    background-color: #fff
  }

  .b-toast-primary .toast {
    background-color: rgba(228, 237, 250, .85);
    border-color: rgba(188, 209, 242, .85);
    color: #082e6d
  }

  .b-toast-primary .toast .toast-header {
    color: #082e6d;
    background-color: rgba(207, 222, 246, .85);
    border-bottom-color: rgba(188, 209, 242, .85)
  }

  .b-toast-primary.b-toast-solid .toast {
    background-color: #e4edfa
  }

  .b-toast-secondary .toast {
    background-color: rgba(240, 242, 244, .85);
    border-color: rgba(214, 218, 225, .85);
    color: #38404c
  }

  .b-toast-secondary .toast .toast-header {
    color: #38404c;
    background-color: rgba(225, 229, 233, .85);
    border-bottom-color: rgba(214, 218, 225, .85)
  }

  .b-toast-secondary.b-toast-solid .toast {
    background-color: #f0f2f4
  }

  .b-toast-success .toast {
    background-color: rgba(237, 249, 243, .85);
    border-color: rgba(203, 238, 218, .85);
    color: #246540
  }

  .b-toast-success .toast .toast-header {
    color: #246540;
    background-color: rgba(218, 243, 229, .85);
    border-bottom-color: rgba(203, 238, 218, .85)
  }

  .b-toast-success.b-toast-solid .toast {
    background-color: #edf9f3
  }

  .b-toast-info .toast {
    background-color: rgba(248, 252, 254, .85);
    border-color: rgba(215, 235, 249, .85);
    color: #3a607a
  }

  .b-toast-info .toast .toast-header {
    color: #3a607a;
    background-color: rgba(226, 241, 251, .85);
    border-bottom-color: rgba(215, 235, 249, .85)
  }

  .b-toast-info.b-toast-solid .toast {
    background-color: #f8fcfe
  }

  .b-toast-warning .toast {
    background-color: rgba(255, 252, 247, .85);
    border-color: rgba(252, 235, 210, .85);
    color: #7e5f32
  }

  .b-toast-warning .toast .toast-header {
    color: #7e5f32;
    background-color: rgba(253, 241, 223, .85);
    border-bottom-color: rgba(252, 235, 210, .85)
  }

  .b-toast-warning.b-toast-solid .toast {
    background-color: #fffcf7
  }

  .b-toast-danger .toast {
    background-color: rgba(251, 243, 242, .85);
    border-color: rgba(242, 213, 209, .85);
    color: #6d3730
  }

  .b-toast-danger .toast .toast-header {
    color: #6d3730;
    background-color: rgba(246, 225, 222, .85);
    border-bottom-color: rgba(242, 213, 209, .85)
  }

  .b-toast-danger.b-toast-solid .toast {
    background-color: #fbf3f2
  }

  .b-toast-light .toast {
    background-color: hsla(0, 0%, 100%, .85);
    border-color: hsla(0, 0%, 98%, .85);
    color: #7c7c7c
  }

  .b-toast-light .toast .toast-header {
    color: #7c7c7c;
    background-color: hsla(0, 0%, 98.8%, .85);
    border-bottom-color: hsla(0, 0%, 98%, .85)
  }

  .b-toast-light.b-toast-solid .toast {
    background-color: #fff
  }

  .b-toast-dark .toast {
    background-color: rgba(224, 227, 231, .85);
    border-color: rgba(191, 198, 205, .85);
    color: #0f1b29
  }

  .b-toast-dark .toast .toast-header {
    color: #0f1b29;
    background-color: rgba(210, 214, 220, .85);
    border-bottom-color: rgba(191, 198, 205, .85)
  }

  .b-toast-dark.b-toast-solid .toast {
    background-color: #e0e3e7
  }

  .b-toast-white .toast {
    background-color: hsla(0, 0%, 100%, .85);
    border-color: hsla(0, 0%, 100%, .85);
    color: #858585
  }

  .b-toast-white .toast .toast-header {
    color: #858585;
    background-color: hsla(0, 0%, 100%, .85);
    border-bottom-color: hsla(0, 0%, 100%, .85)
  }

  .b-toast-white.b-toast-solid .toast {
    background-color: #fff
  }

  .b-toast-gray .toast {
    background-color: hsla(0, 0%, 100%, .85);
    border-color: rgba(242, 244, 246, .85);
    color: #6c7074
  }

  .b-toast-gray .toast .toast-header {
    color: #6c7074;
    background-color: rgba(246, 247, 249, .85);
    border-bottom-color: rgba(242, 244, 246, .85)
  }

  .b-toast-gray.b-toast-solid .toast {
    background-color: #fff
  }

  .b-toast-gray-dark .toast {
    background-color: hsla(0, 0%, 100%, .85);
    border-color: rgba(242, 244, 246, .85);
    color: #6c7074
  }

  .b-toast-gray-dark .toast .toast-header {
    color: #6c7074;
    background-color: rgba(246, 247, 249, .85);
    border-bottom-color: rgba(242, 244, 246, .85)
  }

  .b-toast-gray-dark.b-toast-solid .toast {
    background-color: #fff
  }

  .b-toaster {
    z-index: 1100
  }

  .b-toaster .b-toaster-slot {
    position: relative;
    display: block
  }

  .b-toaster .b-toaster-slot:empty {
    display: none!important
  }

  .b-toaster.b-toaster-bottom-center,
  .b-toaster.b-toaster-bottom-full,
  .b-toaster.b-toaster-bottom-left,
  .b-toaster.b-toaster-bottom-right,
  .b-toaster.b-toaster-top-center,
  .b-toaster.b-toaster-top-full,
  .b-toaster.b-toaster-top-left,
  .b-toaster.b-toaster-top-right {
    position: fixed;
    left: .5rem;
    right: .5rem;
    margin: 0;
    padding: 0;
    height: 0;
    overflow: visible
  }

  .b-toaster.b-toaster-bottom-center .b-toaster-slot,
  .b-toaster.b-toaster-bottom-full .b-toaster-slot,
  .b-toaster.b-toaster-bottom-left .b-toaster-slot,
  .b-toaster.b-toaster-bottom-right .b-toaster-slot,
  .b-toaster.b-toaster-top-center .b-toaster-slot,
  .b-toaster.b-toaster-top-full .b-toaster-slot,
  .b-toaster.b-toaster-top-left .b-toaster-slot,
  .b-toaster.b-toaster-top-right .b-toaster-slot {
    position: absolute;
    max-width: 350px;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0
  }

  .b-toaster.b-toaster-bottom-full .b-toaster-slot,
  .b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast,
  .b-toaster.b-toaster-bottom-full .b-toaster-slot .toast,
  .b-toaster.b-toaster-top-full .b-toaster-slot,
  .b-toaster.b-toaster-top-full .b-toaster-slot .b-toast,
  .b-toaster.b-toaster-top-full .b-toaster-slot .toast {
    width: 100%;
    max-width: 100%
  }

  .b-toaster.b-toaster-top-center,
  .b-toaster.b-toaster-top-full,
  .b-toaster.b-toaster-top-left,
  .b-toaster.b-toaster-top-right {
    top: 0
  }

  .b-toaster.b-toaster-top-center .b-toaster-slot,
  .b-toaster.b-toaster-top-full .b-toaster-slot,
  .b-toaster.b-toaster-top-left .b-toaster-slot,
  .b-toaster.b-toaster-top-right .b-toaster-slot {
    top: .5rem
  }

  .b-toaster.b-toaster-bottom-center,
  .b-toaster.b-toaster-bottom-full,
  .b-toaster.b-toaster-bottom-left,
  .b-toaster.b-toaster-bottom-right {
    bottom: 0
  }

  .b-toaster.b-toaster-bottom-center .b-toaster-slot,
  .b-toaster.b-toaster-bottom-full .b-toaster-slot,
  .b-toaster.b-toaster-bottom-left .b-toaster-slot,
  .b-toaster.b-toaster-bottom-right .b-toaster-slot {
    bottom: .5rem
  }

  .b-toaster.b-toaster-bottom-center .b-toaster-slot,
  .b-toaster.b-toaster-bottom-right .b-toaster-slot,
  .b-toaster.b-toaster-top-center .b-toaster-slot,
  .b-toaster.b-toaster-top-right .b-toaster-slot {
    margin-left: auto
  }

  .b-toaster.b-toaster-bottom-center .b-toaster-slot,
  .b-toaster.b-toaster-bottom-left .b-toaster-slot,
  .b-toaster.b-toaster-top-center .b-toaster-slot,
  .b-toaster.b-toaster-top-left .b-toaster-slot {
    margin-right: auto
  }

  .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active,
  .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active,
  .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move,
  .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active,
  .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active,
  .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move,
  .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active,
  .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active,
  .b-toaster.b-toaster-top-left .b-toast.b-toaster-move,
  .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active,
  .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active,
  .b-toaster.b-toaster-top-right .b-toast.b-toaster-move {
    -webkit-transition: -webkit-transform .175s;
    transition: -webkit-transform .175s;
    transition: transform .175s;
    transition: transform .175s, -webkit-transform .175s
  }

  .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade,
  .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade,
  .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade,
  .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade,
  .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade,
  .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade,
  .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade,
  .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade {
    -webkit-transition-delay: .175s;
    transition-delay: .175s
  }

  .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active,
  .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active,
  .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active,
  .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active {
    position: absolute;
    -webkit-transition-delay: .175s;
    transition-delay: .175s
  }

  .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade,
  .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade,
  .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade,
  .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
  }

  .tooltip.b-tooltip {
    display: block;
    opacity: .9;
    outline: 0
  }

  .tooltip.b-tooltip.fade:not(.show) {
    opacity: 0
  }

  .tooltip.b-tooltip.show {
    opacity: .9
  }

  .tooltip.b-tooltip.noninteractive {
    pointer-events: none
  }

  .tooltip.b-tooltip .arrow,
  .tooltip.b-tooltip.bs-tooltip-auto[x-placement^=left] .arrow,
  .tooltip.b-tooltip.bs-tooltip-auto[x-placement^=right] .arrow,
  .tooltip.b-tooltip.bs-tooltip-left .arrow,
  .tooltip.b-tooltip.bs-tooltip-right .arrow {
    margin: 0 0
  }

  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary-light.bs-tooltip-top .arrow:before {
    border-top-color: #3c81f1
  }

  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary-light.bs-tooltip-right .arrow:before {
    border-right-color: #3c81f1
  }

  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary-light.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #3c81f1
  }

  .tooltip.b-tooltip-primary-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary-light.bs-tooltip-left .arrow:before {
    border-left-color: #3c81f1
  }

  .tooltip.b-tooltip-primary-light .tooltip-inner {
    color: #fff;
    background-color: #3c81f1
  }

  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-top .arrow:before {
    border-top-color: #79a9f5
  }

  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-right .arrow:before {
    border-right-color: #79a9f5
  }

  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #79a9f5
  }

  .tooltip.b-tooltip-primary-lighter.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary-lighter.bs-tooltip-left .arrow:before {
    border-left-color: #79a9f5
  }

  .tooltip.b-tooltip-primary-lighter .tooltip-inner {
    color: #fff;
    background-color: #79a9f5
  }

  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-top .arrow:before {
    border-top-color: #0e53c3
  }

  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-right .arrow:before {
    border-right-color: #0e53c3
  }

  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #0e53c3
  }

  .tooltip.b-tooltip-primary-dark.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary-dark.bs-tooltip-left .arrow:before {
    border-left-color: #0e53c3
  }

  .tooltip.b-tooltip-primary-dark .tooltip-inner {
    color: #fff;
    background-color: #0e53c3
  }

  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-top .arrow:before {
    border-top-color: #0d4fb9
  }

  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-right .arrow:before {
    border-right-color: #0d4fb9
  }

  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #0d4fb9
  }

  .tooltip.b-tooltip-primary-darker.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary-darker.bs-tooltip-left .arrow:before {
    border-left-color: #0d4fb9
  }

  .tooltip.b-tooltip-primary-darker .tooltip-inner {
    color: #fff;
    background-color: #0d4fb9
  }

  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-success-light.bs-tooltip-top .arrow:before {
    border-top-color: #e0f5e9
  }

  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-success-light.bs-tooltip-right .arrow:before {
    border-right-color: #e0f5e9
  }

  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-success-light.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #e0f5e9
  }

  .tooltip.b-tooltip-success-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-success-light.bs-tooltip-left .arrow:before {
    border-left-color: #e0f5e9
  }

  .tooltip.b-tooltip-success-light .tooltip-inner {
    color: #212529;
    background-color: #e0f5e9
  }

  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-info-light.bs-tooltip-top .arrow:before {
    border-top-color: #edf6fd
  }

  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-info-light.bs-tooltip-right .arrow:before {
    border-right-color: #edf6fd
  }

  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-info-light.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #edf6fd
  }

  .tooltip.b-tooltip-info-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-info-light.bs-tooltip-left .arrow:before {
    border-left-color: #edf6fd
  }

  .tooltip.b-tooltip-info-light .tooltip-inner {
    color: #212529;
    background-color: #edf6fd
  }

  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-warning-light.bs-tooltip-top .arrow:before {
    border-top-color: #fdf3e5
  }

  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-warning-light.bs-tooltip-right .arrow:before {
    border-right-color: #fdf3e5
  }

  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-warning-light.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #fdf3e5
  }

  .tooltip.b-tooltip-warning-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-warning-light.bs-tooltip-left .arrow:before {
    border-left-color: #fdf3e5
  }

  .tooltip.b-tooltip-warning-light .tooltip-inner {
    color: #212529;
    background-color: #fdf3e5
  }

  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-danger-light.bs-tooltip-top .arrow:before {
    border-top-color: #f9eae8
  }

  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-danger-light.bs-tooltip-right .arrow:before {
    border-right-color: #f9eae8
  }

  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-danger-light.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #f9eae8
  }

  .tooltip.b-tooltip-danger-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-danger-light.bs-tooltip-left .arrow:before {
    border-left-color: #f9eae8
  }

  .tooltip.b-tooltip-danger-light .tooltip-inner {
    color: #212529;
    background-color: #f9eae8
  }

  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-primary.bs-tooltip-top .arrow:before {
    border-top-color: #0f59d1
  }

  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-primary.bs-tooltip-right .arrow:before {
    border-right-color: #0f59d1
  }

  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #0f59d1
  }

  .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-primary.bs-tooltip-left .arrow:before {
    border-left-color: #0f59d1
  }

  .tooltip.b-tooltip-primary .tooltip-inner {
    color: #fff;
    background-color: #0f59d1
  }

  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-secondary.bs-tooltip-top .arrow:before {
    border-top-color: #6b7c93
  }

  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-secondary.bs-tooltip-right .arrow:before {
    border-right-color: #6b7c93
  }

  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #6b7c93
  }

  .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-secondary.bs-tooltip-left .arrow:before {
    border-left-color: #6b7c93
  }

  .tooltip.b-tooltip-secondary .tooltip-inner {
    color: #fff;
    background-color: #6b7c93
  }

  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-success.bs-tooltip-top .arrow:before {
    border-top-color: #46c37b
  }

  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-success.bs-tooltip-right .arrow:before {
    border-right-color: #46c37b
  }

  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-success.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #46c37b
  }

  .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-success.bs-tooltip-left .arrow:before {
    border-left-color: #46c37b
  }

  .tooltip.b-tooltip-success .tooltip-inner {
    color: #fff;
    background-color: #46c37b
  }

  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-info.bs-tooltip-top .arrow:before {
    border-top-color: #70b9eb
  }

  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-info.bs-tooltip-right .arrow:before {
    border-right-color: #70b9eb
  }

  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-info.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #70b9eb
  }

  .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-info.bs-tooltip-left .arrow:before {
    border-left-color: #70b9eb
  }

  .tooltip.b-tooltip-info .tooltip-inner {
    color: #fff;
    background-color: #70b9eb
  }

  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-warning.bs-tooltip-top .arrow:before {
    border-top-color: #f3b760
  }

  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-warning.bs-tooltip-right .arrow:before {
    border-right-color: #f3b760
  }

  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #f3b760
  }

  .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-warning.bs-tooltip-left .arrow:before {
    border-left-color: #f3b760
  }

  .tooltip.b-tooltip-warning .tooltip-inner {
    color: #fff;
    background-color: #f3b760
  }

  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-danger.bs-tooltip-top .arrow:before {
    border-top-color: #d26a5c
  }

  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-danger.bs-tooltip-right .arrow:before {
    border-right-color: #d26a5c
  }

  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #d26a5c
  }

  .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-danger.bs-tooltip-left .arrow:before {
    border-left-color: #d26a5c
  }

  .tooltip.b-tooltip-danger .tooltip-inner {
    color: #fff;
    background-color: #d26a5c
  }

  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-light.bs-tooltip-top .arrow:before {
    border-top-color: #eee
  }

  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-light.bs-tooltip-right .arrow:before {
    border-right-color: #eee
  }

  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-light.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #eee
  }

  .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-light.bs-tooltip-left .arrow:before {
    border-left-color: #eee
  }

  .tooltip.b-tooltip-light .tooltip-inner {
    color: #212529;
    background-color: #eee
  }

  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-dark.bs-tooltip-top .arrow:before {
    border-top-color: #{$dark-l-theme}
  }

  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-dark.bs-tooltip-right .arrow:before {
    border-right-color: #{$dark-l-theme}
  }

  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #{$dark-l-theme}
  }

  .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-dark.bs-tooltip-left .arrow:before {
    border-left-color: #{$dark-l-theme}
  }

  .tooltip.b-tooltip-dark .tooltip-inner {
    color: #fff;
    background-color: #{$dark-l-theme}
  }

  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-white.bs-tooltip-top .arrow:before {
    border-top-color: #fff
  }

  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-white.bs-tooltip-right .arrow:before {
    border-right-color: #fff
  }

  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-white.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #fff
  }

  .tooltip.b-tooltip-white.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-white.bs-tooltip-left .arrow:before {
    border-left-color: #fff
  }

  .tooltip.b-tooltip-white .tooltip-inner {
    color: #212529;
    background-color: #fff
  }

  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-gray.bs-tooltip-top .arrow:before {
    border-top-color: #d0d7df
  }

  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-gray.bs-tooltip-right .arrow:before {
    border-right-color: #d0d7df
  }

  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-gray.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #d0d7df
  }

  .tooltip.b-tooltip-gray.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-gray.bs-tooltip-left .arrow:before {
    border-left-color: #d0d7df
  }

  .tooltip.b-tooltip-gray .tooltip-inner {
    color: #212529;
    background-color: #d0d7df
  }

  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=top] .arrow:before,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-top .arrow:before {
    border-top-color: #d0d7df
  }

  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=right] .arrow:before,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-right .arrow:before {
    border-right-color: #d0d7df
  }

  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #d0d7df
  }

  .tooltip.b-tooltip-gray-dark.bs-tooltip-auto[x-placement^=left] .arrow:before,
  .tooltip.b-tooltip-gray-dark.bs-tooltip-left .arrow:before {
    border-left-color: #d0d7df
  }

  .tooltip.b-tooltip-gray-dark .tooltip-inner {
    color: #212529;
    background-color: #d0d7df
  }

  .b-icon.bi {
    display: inline-block;
    overflow: visible;
    vertical-align: -.15em
  }

  .b-icon.b-icon-animation-cylon,
  .b-icon.b-iconstack .b-icon-animation-cylon>g {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation: b-icon-animation-cylon .75s ease-in-out infinite alternate;
    animation: b-icon-animation-cylon .75s ease-in-out infinite alternate
  }

  @media (prefers-reduced-motion:reduce) {
    .b-icon.b-icon-animation-cylon,
    .b-icon.b-iconstack .b-icon-animation-cylon>g {
      -webkit-animation: none;
      animation: none
    }
  }

  .b-icon.b-icon-animation-cylon-vertical,
  .b-icon.b-iconstack .b-icon-animation-cylon-vertical>g {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation: b-icon-animation-cylon-vertical .75s ease-in-out infinite alternate;
    animation: b-icon-animation-cylon-vertical .75s ease-in-out infinite alternate
  }

  @media (prefers-reduced-motion:reduce) {
    .b-icon.b-icon-animation-cylon-vertical,
    .b-icon.b-iconstack .b-icon-animation-cylon-vertical>g {
      -webkit-animation: none;
      animation: none
    }
  }

  .b-icon.b-icon-animation-fade,
  .b-icon.b-iconstack .b-icon-animation-fade>g {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation: b-icon-animation-fade .75s ease-in-out infinite alternate;
    animation: b-icon-animation-fade .75s ease-in-out infinite alternate
  }

  @media (prefers-reduced-motion:reduce) {
    .b-icon.b-icon-animation-fade,
    .b-icon.b-iconstack .b-icon-animation-fade>g {
      -webkit-animation: none;
      animation: none
    }
  }

  .b-icon.b-icon-animation-spin,
  .b-icon.b-iconstack .b-icon-animation-spin>g {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation: b-icon-animation-spin 2s linear infinite normal;
    animation: b-icon-animation-spin 2s linear infinite normal
  }

  @media (prefers-reduced-motion:reduce) {
    .b-icon.b-icon-animation-spin,
    .b-icon.b-iconstack .b-icon-animation-spin>g {
      -webkit-animation: none;
      animation: none
    }
  }

  .b-icon.b-icon-animation-spin-reverse,
  .b-icon.b-iconstack .b-icon-animation-spin-reverse>g {
    -webkit-transform-origin: center;
    transform-origin: center;
    animation: b-icon-animation-spin 2s linear infinite reverse
  }

  @media (prefers-reduced-motion:reduce) {
    .b-icon.b-icon-animation-spin-reverse,
    .b-icon.b-iconstack .b-icon-animation-spin-reverse>g {
      -webkit-animation: none;
      animation: none
    }
  }

  .b-icon.b-icon-animation-spin-pulse,
  .b-icon.b-iconstack .b-icon-animation-spin-pulse>g {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation: b-icon-animation-spin 1s steps(8) infinite normal;
    animation: b-icon-animation-spin 1s steps(8) infinite normal
  }

  @media (prefers-reduced-motion:reduce) {
    .b-icon.b-icon-animation-spin-pulse,
    .b-icon.b-iconstack .b-icon-animation-spin-pulse>g {
      -webkit-animation: none;
      animation: none
    }
  }

  .b-icon.b-icon-animation-spin-reverse-pulse,
  .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse>g {
    -webkit-transform-origin: center;
    transform-origin: center;
    animation: b-icon-animation-spin 1s steps(8) infinite reverse
  }

  @media (prefers-reduced-motion:reduce) {
    .b-icon.b-icon-animation-spin-reverse-pulse,
    .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse>g {
      -webkit-animation: none;
      animation: none
    }
  }

  .b-icon.b-icon-animation-throb,
  .b-icon.b-iconstack .b-icon-animation-throb>g {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation: b-icon-animation-throb .75s ease-in-out infinite alternate;
    animation: b-icon-animation-throb .75s ease-in-out infinite alternate
  }

  @media (prefers-reduced-motion:reduce) {
    .b-icon.b-icon-animation-throb,
    .b-icon.b-iconstack .b-icon-animation-throb>g {
      -webkit-animation: none;
      animation: none
    }
  }

  @-webkit-keyframes b-icon-animation-cylon {
    0% {
      -webkit-transform: translateX(-25%);
      transform: translateX(-25%)
    }
    to {
      -webkit-transform: translateX(25%);
      transform: translateX(25%)
    }
  }

  @keyframes b-icon-animation-cylon {
    0% {
      -webkit-transform: translateX(-25%);
      transform: translateX(-25%)
    }
    to {
      -webkit-transform: translateX(25%);
      transform: translateX(25%)
    }
  }

  @-webkit-keyframes b-icon-animation-cylon-vertical {
    0% {
      -webkit-transform: translateY(25%);
      transform: translateY(25%)
    }
    to {
      -webkit-transform: translateY(-25%);
      transform: translateY(-25%)
    }
  }

  @keyframes b-icon-animation-cylon-vertical {
    0% {
      -webkit-transform: translateY(25%);
      transform: translateY(25%)
    }
    to {
      -webkit-transform: translateY(-25%);
      transform: translateY(-25%)
    }
  }

  @-webkit-keyframes b-icon-animation-fade {
    0% {
      opacity: .1
    }
    to {
      opacity: 1
    }
  }

  @keyframes b-icon-animation-fade {
    0% {
      opacity: .1
    }
    to {
      opacity: 1
    }
  }

  @-webkit-keyframes b-icon-animation-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
    }
  }

  @keyframes b-icon-animation-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
    }
  }

  @-webkit-keyframes b-icon-animation-throb {
    0% {
      opacity: .5;
      -webkit-transform: scale(.5);
      transform: scale(.5)
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
    }
  }

  @keyframes b-icon-animation-throb {
    0% {
      opacity: .5;
      -webkit-transform: scale(.5);
      transform: scale(.5)
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
    }
  }

  .btn .b-icon.bi,
  .dropdown-item .b-icon.bi,
  .dropdown-toggle .b-icon.bi,
  .input-group-text .b-icon.bi,
  .nav-link .b-icon.bi {
    font-size: 125%;
    vertical-align: text-bottom
  }

  .b-table.b-table-fixed.dark-table,
  .b-table.b-table-fixed.table-dark,
  .table.b-table.b-table-fixed {
    table-layout: fixed
  }

  .b-table.b-table-no-border-collapse.dark-table,
  .b-table.b-table-no-border-collapse.table-dark,
  .table.b-table.b-table-no-border-collapse {
    border-collapse: separate;
    border-spacing: 0
  }

  .b-table.dark-table[aria-busy=true],
  .b-table.table-dark[aria-busy=true],
  .table.b-table[aria-busy=true] {
    opacity: .55
  }

  .b-table.dark-table>tbody>tr.b-table-details>td,
  .b-table.table-dark>tbody>tr.b-table-details>td,
  .table.b-table>tbody>tr.b-table-details>td {
    border-top: none!important
  }

  .b-table.dark-table>caption,
  .b-table.table-dark>caption,
  .table.b-table>caption {
    caption-side: bottom
  }

  .b-table.b-table-caption-top.dark-table>caption,
  .b-table.b-table-caption-top.table-dark>caption,
  .table.b-table.b-table-caption-top>caption {
    caption-side: top!important
  }

  .b-table.dark-table>tbody>.table-active,
  .b-table.dark-table>tbody>.table-active>td,
  .b-table.dark-table>tbody>.table-active>th,
  .b-table.table-dark>tbody>.table-active,
  .b-table.table-dark>tbody>.table-active>td,
  .b-table.table-dark>tbody>.table-active>th,
  .table.b-table>tbody>.table-active,
  .table.b-table>tbody>.table-active>td,
  .table.b-table>tbody>.table-active>th {
    background-color: #f1f1f1
  }

  .b-table.table-hover.dark-table>tbody>tr.table-active:hover td,
  .b-table.table-hover.dark-table>tbody>tr.table-active:hover th,
  .b-table.table-hover.table-dark>tbody>tr.table-active:hover td,
  .b-table.table-hover.table-dark>tbody>tr.table-active:hover th,
  .table.b-table.table-hover>tbody>tr.table-active:hover td,
  .table.b-table.table-hover>tbody>tr.table-active:hover th {
    color: #575757;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f1f1f1), to(#f1f1f1));
    background-image: linear-gradient(#f1f1f1, #f1f1f1);
    background-repeat: no-repeat
  }

  .b-table.dark-table>tbody>.bg-active,
  .b-table.dark-table>tbody>.bg-active>td,
  .b-table.dark-table>tbody>.bg-active>th,
  .b-table.table-dark>tbody>.bg-active,
  .b-table.table-dark>tbody>.bg-active>td,
  .b-table.table-dark>tbody>.bg-active>th,
  .table.b-table>tbody>.bg-active,
  .table.b-table>tbody>.bg-active>td,
  .table.b-table>tbody>.bg-active>th {
    background-color: hsla(0, 0%, 100%, .075)!important
  }

  .b-table.table-hover.table-dark.dark-table>tbody>tr.bg-active:hover td,
  .b-table.table-hover.table-dark.dark-table>tbody>tr.bg-active:hover th,
  .b-table.table-hover.table-dark>tbody>tr.bg-active:hover td,
  .b-table.table-hover.table-dark>tbody>tr.bg-active:hover th,
  .table.b-table.table-hover.table-dark>tbody>tr.bg-active:hover td,
  .table.b-table.table-hover.table-dark>tbody>tr.bg-active:hover th {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, .075)), to(hsla(0, 0%, 100%, .075)));
    background-image: linear-gradient(hsla(0, 0%, 100%, .075), hsla(0, 0%, 100%, .075));
    background-repeat: no-repeat
  }

  .b-table-sticky-header,
  .table-responsive,
  [class*=table-responsive-] {
    margin-bottom: 1rem
  }

  .b-table-sticky-header>.dark-table,
  .b-table-sticky-header>.table,
  .b-table-sticky-header>.table-dark,
  .table-responsive>.dark-table,
  .table-responsive>.table,
  .table-responsive>.table-dark,
  [class*=table-responsive-]>.dark-table,
  [class*=table-responsive-]>.table,
  [class*=table-responsive-]>.table-dark {
    margin-bottom: 0
  }

  .b-table-sticky-header {
    overflow-y: auto;
    max-height: 300px
  }

  @media print {
    .b-table-sticky-header {
      overflow-y: visible!important;
      max-height: none!important
    }
  }

  @supports (position:sticky) {
    .b-table-sticky-header>.b-table.dark-table>thead>tr>th,
    .b-table-sticky-header>.b-table.table-dark>thead>tr>th,
    .b-table-sticky-header>.table.b-table>thead>tr>th {
      position: sticky;
      top: 0;
      z-index: 2
    }
    .b-table-sticky-header>.b-table.dark-table>tbody>tr>.b-table-sticky-column,
    .b-table-sticky-header>.b-table.dark-table>tfoot>tr>.b-table-sticky-column,
    .b-table-sticky-header>.b-table.dark-table>thead>tr>.b-table-sticky-column,
    .b-table-sticky-header>.b-table.table-dark>tbody>tr>.b-table-sticky-column,
    .b-table-sticky-header>.b-table.table-dark>tfoot>tr>.b-table-sticky-column,
    .b-table-sticky-header>.b-table.table-dark>thead>tr>.b-table-sticky-column,
    .b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,
    .b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,
    .b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,
    .table-responsive>.b-table.dark-table>tbody>tr>.b-table-sticky-column,
    .table-responsive>.b-table.dark-table>tfoot>tr>.b-table-sticky-column,
    .table-responsive>.b-table.dark-table>thead>tr>.b-table-sticky-column,
    .table-responsive>.b-table.table-dark>tbody>tr>.b-table-sticky-column,
    .table-responsive>.b-table.table-dark>tfoot>tr>.b-table-sticky-column,
    .table-responsive>.b-table.table-dark>thead>tr>.b-table-sticky-column,
    .table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,
    .table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,
    .table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.dark-table>tbody>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.dark-table>tfoot>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.dark-table>thead>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.table-dark>tbody>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.table-dark>tfoot>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.table-dark>thead>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column {
      position: sticky;
      left: 0
    }
    .b-table-sticky-header>.b-table.dark-table>thead>tr>.b-table-sticky-column,
    .b-table-sticky-header>.b-table.table-dark>thead>tr>.b-table-sticky-column,
    .b-table-sticky-header>.table.b-table>thead>tr>.b-table-sticky-column,
    .table-responsive>.b-table.dark-table>thead>tr>.b-table-sticky-column,
    .table-responsive>.b-table.table-dark>thead>tr>.b-table-sticky-column,
    .table-responsive>.table.b-table>thead>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.dark-table>thead>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.table-dark>thead>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.table.b-table>thead>tr>.b-table-sticky-column {
      z-index: 5
    }
    .b-table-sticky-header>.b-table.dark-table>tbody>tr>.b-table-sticky-column,
    .b-table-sticky-header>.b-table.dark-table>tfoot>tr>.b-table-sticky-column,
    .b-table-sticky-header>.b-table.table-dark>tbody>tr>.b-table-sticky-column,
    .b-table-sticky-header>.b-table.table-dark>tfoot>tr>.b-table-sticky-column,
    .b-table-sticky-header>.table.b-table>tbody>tr>.b-table-sticky-column,
    .b-table-sticky-header>.table.b-table>tfoot>tr>.b-table-sticky-column,
    .table-responsive>.b-table.dark-table>tbody>tr>.b-table-sticky-column,
    .table-responsive>.b-table.dark-table>tfoot>tr>.b-table-sticky-column,
    .table-responsive>.b-table.table-dark>tbody>tr>.b-table-sticky-column,
    .table-responsive>.b-table.table-dark>tfoot>tr>.b-table-sticky-column,
    .table-responsive>.table.b-table>tbody>tr>.b-table-sticky-column,
    .table-responsive>.table.b-table>tfoot>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.dark-table>tbody>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.dark-table>tfoot>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.table-dark>tbody>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.b-table.table-dark>tfoot>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.table.b-table>tbody>tr>.b-table-sticky-column,
    [class*=table-responsive-]>.table.b-table>tfoot>tr>.b-table-sticky-column {
      z-index: 2
    }
    .b-table.dark-table>tbody>tr>.table-b-table-default,
    .b-table.dark-table>tfoot>tr>.table-b-table-default,
    .b-table.dark-table>thead>tr>.table-b-table-default,
    .b-table.table-dark>tbody>tr>.table-b-table-default,
    .b-table.table-dark>tfoot>tr>.table-b-table-default,
    .b-table.table-dark>thead>tr>.table-b-table-default,
    .table.b-table>tbody>tr>.table-b-table-default,
    .table.b-table>tfoot>tr>.table-b-table-default,
    .table.b-table>thead>tr>.table-b-table-default {
      color: #575757;
      background-color: transparent
    }
    .b-table.table-dark.dark-table>tbody>tr>.bg-b-table-default,
    .b-table.table-dark.dark-table>tfoot>tr>.bg-b-table-default,
    .b-table.table-dark.dark-table>thead>tr>.bg-b-table-default,
    .b-table.table-dark>tbody>tr>.bg-b-table-default,
    .b-table.table-dark>tfoot>tr>.bg-b-table-default,
    .b-table.table-dark>thead>tr>.bg-b-table-default,
    .table.b-table.table-dark>tbody>tr>.bg-b-table-default,
    .table.b-table.table-dark>tfoot>tr>.bg-b-table-default,
    .table.b-table.table-dark>thead>tr>.bg-b-table-default {
      color: #fff;
      background-color: #{$dark-l-theme}
    }
    .b-table.table-striped.dark-table>tbody>tr:nth-of-type(odd)>.table-b-table-default,
    .b-table.table-striped.table-dark>tbody>tr:nth-of-type(odd)>.table-b-table-default,
    .table.b-table.table-striped>tbody>tr:nth-of-type(odd)>.table-b-table-default {
      background-image: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#f9f9f9));
      background-image: linear-gradient(#f9f9f9, #f9f9f9);
      background-repeat: no-repeat
    }
    .b-table.table-striped.table-dark.dark-table>tbody>tr:nth-of-type(odd)>.bg-b-table-default,
    .b-table.table-striped.table-dark>tbody>tr:nth-of-type(odd)>.bg-b-table-default,
    .table.b-table.table-striped.table-dark>tbody>tr:nth-of-type(odd)>.bg-b-table-default {
      background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, .05)), to(hsla(0, 0%, 100%, .05)));
      background-image: linear-gradient(hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, .05));
      background-repeat: no-repeat
    }
    .b-table.table-hover.dark-table>tbody>tr:hover>.table-b-table-default,
    .b-table.table-hover.table-dark>tbody>tr:hover>.table-b-table-default,
    .table.b-table.table-hover>tbody>tr:hover>.table-b-table-default {
      color: #575757;
      background-image: -webkit-gradient(linear, left top, left bottom, from(#f1f1f1), to(#f1f1f1));
      background-image: linear-gradient(#f1f1f1, #f1f1f1);
      background-repeat: no-repeat
    }
    .b-table.table-hover.table-dark.dark-table>tbody>tr:hover>.bg-b-table-default,
    .b-table.table-hover.table-dark>tbody>tr:hover>.bg-b-table-default,
    .table.b-table.table-hover.table-dark>tbody>tr:hover>.bg-b-table-default {
      color: #fff;
      background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, .075)), to(hsla(0, 0%, 100%, .075)));
      background-image: linear-gradient(hsla(0, 0%, 100%, .075), hsla(0, 0%, 100%, .075));
      background-repeat: no-repeat
    }
  }

  .b-table.dark-table>tfoot>tr>[aria-sort],
  .b-table.dark-table>thead>tr>[aria-sort],
  .b-table.table-dark>tfoot>tr>[aria-sort],
  .b-table.table-dark>thead>tr>[aria-sort],
  .table.b-table>tfoot>tr>[aria-sort],
  .table.b-table>thead>tr>[aria-sort] {
    cursor: pointer;
    background-image: none;
    background-repeat: no-repeat;
    background-size: .65em 1em
  }

  .b-table.dark-table>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .b-table.dark-table>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .b-table.table-dark>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .b-table.table-dark>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table>thead>tr>[aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right .375rem center;
    padding-right: calc(.75rem + .65em)
  }

  .b-table.dark-table>tfoot>tr>[aria-sort].b-table-sort-icon-left,
  .b-table.dark-table>thead>tr>[aria-sort].b-table-sort-icon-left,
  .b-table.table-dark>tfoot>tr>[aria-sort].b-table-sort-icon-left,
  .b-table.table-dark>thead>tr>[aria-sort].b-table-sort-icon-left,
  .table.b-table>tfoot>tr>[aria-sort].b-table-sort-icon-left,
  .table.b-table>thead>tr>[aria-sort].b-table-sort-icon-left {
    background-position: left .375rem center;
    padding-left: calc(.75rem + .65em)
  }

  .b-table.dark-table>tfoot>tr>[aria-sort=none],
  .b-table.dark-table>thead>tr>[aria-sort=none],
  .b-table.table-dark>tfoot>tr>[aria-sort=none],
  .b-table.table-dark>thead>tr>[aria-sort=none],
  .table.b-table>tfoot>tr>[aria-sort=none],
  .table.b-table>thead>tr>[aria-sort=none] {
    background-image: none
  }

  .b-table.dark-table>tfoot>tr>[aria-sort=ascending],
  .b-table.dark-table>thead>tr>[aria-sort=ascending],
  .b-table.table-dark>tfoot>tr>[aria-sort=ascending],
  .b-table.table-dark>thead>tr>[aria-sort=ascending],
  .table.b-table>tfoot>tr>[aria-sort=ascending],
  .table.b-table>thead>tr>[aria-sort=ascending] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%230F59D1' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/%3E%3C/svg%3E")
  }

  .b-table.dark-table>tfoot>tr>[aria-sort=descending],
  .b-table.dark-table>thead>tr>[aria-sort=descending],
  .b-table.table-dark>tfoot>tr>[aria-sort=descending],
  .b-table.table-dark>thead>tr>[aria-sort=descending],
  .table.b-table>tfoot>tr>[aria-sort=descending],
  .table.b-table>thead>tr>[aria-sort=descending] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%230F59D1' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E")
  }

  .b-table.dark-table>.thead-dark>tr>[aria-sort=none],
  .b-table.table-dark.dark-table>tfoot>tr>[aria-sort=none],
  .b-table.table-dark.dark-table>thead>tr>[aria-sort=none],
  .b-table.table-dark>.thead-dark>tr>[aria-sort=none],
  .b-table.table-dark>tfoot>tr>[aria-sort=none],
  .b-table.table-dark>thead>tr>[aria-sort=none],
  .table.b-table.table-dark>tfoot>tr>[aria-sort=none],
  .table.b-table.table-dark>thead>tr>[aria-sort=none],
  .table.b-table>.thead-dark>tr>[aria-sort=none] {
    background-image: none
  }

  .b-table.dark-table>.thead-dark>tr>[aria-sort=ascending],
  .b-table.table-dark.dark-table>tfoot>tr>[aria-sort=ascending],
  .b-table.table-dark.dark-table>thead>tr>[aria-sort=ascending],
  .b-table.table-dark>.thead-dark>tr>[aria-sort=ascending],
  .b-table.table-dark>tfoot>tr>[aria-sort=ascending],
  .b-table.table-dark>thead>tr>[aria-sort=ascending],
  .table.b-table.table-dark>tfoot>tr>[aria-sort=ascending],
  .table.b-table.table-dark>thead>tr>[aria-sort=ascending],
  .table.b-table>.thead-dark>tr>[aria-sort=ascending] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23FFF' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/%3E%3C/svg%3E")
  }

  .b-table.dark-table>.thead-dark>tr>[aria-sort=descending],
  .b-table.table-dark.dark-table>tfoot>tr>[aria-sort=descending],
  .b-table.table-dark.dark-table>thead>tr>[aria-sort=descending],
  .b-table.table-dark>.thead-dark>tr>[aria-sort=descending],
  .b-table.table-dark>tfoot>tr>[aria-sort=descending],
  .b-table.table-dark>thead>tr>[aria-sort=descending],
  .table.b-table.table-dark>tfoot>tr>[aria-sort=descending],
  .table.b-table.table-dark>thead>tr>[aria-sort=descending],
  .table.b-table>.thead-dark>tr>[aria-sort=descending] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23FFF' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E")
  }

  .b-table.dark-table>tfoot>tr>.table-dark[aria-sort=none],
  .b-table.dark-table>thead>tr>.table-dark[aria-sort=none],
  .b-table.table-dark>tfoot>tr>.table-dark[aria-sort=none],
  .b-table.table-dark>thead>tr>.table-dark[aria-sort=none],
  .table.b-table>tfoot>tr>.table-dark[aria-sort=none],
  .table.b-table>thead>tr>.table-dark[aria-sort=none] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' preserveAspectRatio='none'%3E%3Cpath fill='%23fff' opacity='.3' d='M51 1l25 23 24 22H1l25-22zm0 100l25-23 24-22H1l25 22z'/%3E%3C/svg%3E")
  }

  .b-table.dark-table>tfoot>tr>.table-dark[aria-sort=ascending],
  .b-table.dark-table>thead>tr>.table-dark[aria-sort=ascending],
  .b-table.table-dark>tfoot>tr>.table-dark[aria-sort=ascending],
  .b-table.table-dark>thead>tr>.table-dark[aria-sort=ascending],
  .table.b-table>tfoot>tr>.table-dark[aria-sort=ascending],
  .table.b-table>thead>tr>.table-dark[aria-sort=ascending] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23FFF' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/%3E%3C/svg%3E")
  }

  .b-table.dark-table>tfoot>tr>.table-dark[aria-sort=descending],
  .b-table.dark-table>thead>tr>.table-dark[aria-sort=descending],
  .b-table.table-dark>tfoot>tr>.table-dark[aria-sort=descending],
  .b-table.table-dark>thead>tr>.table-dark[aria-sort=descending],
  .table.b-table>tfoot>tr>.table-dark[aria-sort=descending],
  .table.b-table>thead>tr>.table-dark[aria-sort=descending] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23FFF' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E")
  }

  .b-table.table-sm.dark-table>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .b-table.table-sm.dark-table>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .b-table.table-sm.table-dark>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .b-table.table-sm.table-dark>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm>thead>tr>[aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right .125rem center;
    padding-right: calc(.25rem + .65em)
  }

  .b-table.table-sm.dark-table>tfoot>tr>[aria-sort].b-table-sort-icon-left,
  .b-table.table-sm.dark-table>thead>tr>[aria-sort].b-table-sort-icon-left,
  .b-table.table-sm.table-dark>tfoot>tr>[aria-sort].b-table-sort-icon-left,
  .b-table.table-sm.table-dark>thead>tr>[aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm>tfoot>tr>[aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm>thead>tr>[aria-sort].b-table-sort-icon-left {
    background-position: left .125rem center;
    padding-left: calc(.25rem + .65em)
  }

  .b-table.b-table-selectable.dark-table:not(.b-table-selectable-no-click)>tbody>tr,
  .b-table.b-table-selectable.table-dark:not(.b-table-selectable-no-click)>tbody>tr,
  .table.b-table.b-table-selectable:not(.b-table-selectable-no-click)>tbody>tr {
    cursor: pointer
  }

  .b-table.b-table-selectable.dark-table:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range>tbody>tr,
  .b-table.b-table-selectable.table-dark:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range>tbody>tr,
  .table.b-table.b-table-selectable:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range>tbody>tr {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }

  @media (max-width:575.98px) {
    .b-table.b-table-stacked-sm.dark-table,
    .b-table.b-table-stacked-sm.table-dark,
    .table.b-table.b-table-stacked-sm {
      display: block;
      width: 100%
    }
    .b-table.b-table-stacked-sm.dark-table>caption,
    .b-table.b-table-stacked-sm.dark-table>tbody,
    .b-table.b-table-stacked-sm.dark-table>tbody>tr,
    .b-table.b-table-stacked-sm.dark-table>tbody>tr>td,
    .b-table.b-table-stacked-sm.dark-table>tbody>tr>th,
    .b-table.b-table-stacked-sm.table-dark>caption,
    .b-table.b-table-stacked-sm.table-dark>tbody,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr>td,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr>th,
    .table.b-table.b-table-stacked-sm>caption,
    .table.b-table.b-table-stacked-sm>tbody,
    .table.b-table.b-table-stacked-sm>tbody>tr,
    .table.b-table.b-table-stacked-sm>tbody>tr>td,
    .table.b-table.b-table-stacked-sm>tbody>tr>th {
      display: block
    }
    .b-table.b-table-stacked-sm.dark-table>tfoot,
    .b-table.b-table-stacked-sm.dark-table>tfoot>tr.b-table-bottom-row,
    .b-table.b-table-stacked-sm.dark-table>tfoot>tr.b-table-top-row,
    .b-table.b-table-stacked-sm.dark-table>thead,
    .b-table.b-table-stacked-sm.dark-table>thead>tr.b-table-bottom-row,
    .b-table.b-table-stacked-sm.dark-table>thead>tr.b-table-top-row,
    .b-table.b-table-stacked-sm.table-dark>tfoot,
    .b-table.b-table-stacked-sm.table-dark>tfoot>tr.b-table-bottom-row,
    .b-table.b-table-stacked-sm.table-dark>tfoot>tr.b-table-top-row,
    .b-table.b-table-stacked-sm.table-dark>thead,
    .b-table.b-table-stacked-sm.table-dark>thead>tr.b-table-bottom-row,
    .b-table.b-table-stacked-sm.table-dark>thead>tr.b-table-top-row,
    .table.b-table.b-table-stacked-sm>tfoot,
    .table.b-table.b-table-stacked-sm>tfoot>tr.b-table-bottom-row,
    .table.b-table.b-table-stacked-sm>tfoot>tr.b-table-top-row,
    .table.b-table.b-table-stacked-sm>thead,
    .table.b-table.b-table-stacked-sm>thead>tr.b-table-bottom-row,
    .table.b-table.b-table-stacked-sm>thead>tr.b-table-top-row {
      display: none
    }
    .b-table.b-table-stacked-sm.dark-table>caption,
    .b-table.b-table-stacked-sm.table-dark>caption,
    .table.b-table.b-table-stacked-sm>caption {
      caption-side: top!important
    }
    .b-table.b-table-stacked-sm.dark-table>tbody>tr>[data-label]:before,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr>[data-label]:before,
    .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]:before {
      content: attr(data-label);
      width: 40%;
      float: left;
      text-align: right;
      overflow-wrap: break-word;
      font-weight: 700;
      font-style: normal;
      padding: 0 .5rem 0 0;
      margin: 0
    }
    .b-table.b-table-stacked-sm.dark-table>tbody>tr>[data-label]:after,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr>[data-label]:after,
    .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]:after {
      display: block;
      clear: both;
      content: ""
    }
    .b-table.b-table-stacked-sm.dark-table>tbody>tr>[data-label]>div,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr>[data-label]>div,
    .table.b-table.b-table-stacked-sm>tbody>tr>[data-label]>div {
      display: inline-block;
      width: 60%;
      padding: 0 0 0 .5rem;
      margin: 0
    }
    .b-table.b-table-stacked-sm.dark-table>tbody>tr.bottom-row,
    .b-table.b-table-stacked-sm.dark-table>tbody>tr.top-row,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr.bottom-row,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr.top-row,
    .table.b-table.b-table-stacked-sm>tbody>tr.bottom-row,
    .table.b-table.b-table-stacked-sm>tbody>tr.top-row {
      display: none
    }
    .b-table.b-table-stacked-sm.dark-table>tbody>tr>:first-child,
    .b-table.b-table-stacked-sm.dark-table>tbody>tr>[rowspan]+td,
    .b-table.b-table-stacked-sm.dark-table>tbody>tr>[rowspan]+th,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr>:first-child,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr>[rowspan]+td,
    .b-table.b-table-stacked-sm.table-dark>tbody>tr>[rowspan]+th,
    .table.b-table.b-table-stacked-sm>tbody>tr>:first-child,
    .table.b-table.b-table-stacked-sm>tbody>tr>[rowspan]+td,
    .table.b-table.b-table-stacked-sm>tbody>tr>[rowspan]+th {
      border-top-width: 3px
    }
  }

  @media (max-width:767.98px) {
    .b-table.b-table-stacked-md.dark-table,
    .b-table.b-table-stacked-md.table-dark,
    .table.b-table.b-table-stacked-md {
      display: block;
      width: 100%
    }
    .b-table.b-table-stacked-md.dark-table>caption,
    .b-table.b-table-stacked-md.dark-table>tbody,
    .b-table.b-table-stacked-md.dark-table>tbody>tr,
    .b-table.b-table-stacked-md.dark-table>tbody>tr>td,
    .b-table.b-table-stacked-md.dark-table>tbody>tr>th,
    .b-table.b-table-stacked-md.table-dark>caption,
    .b-table.b-table-stacked-md.table-dark>tbody,
    .b-table.b-table-stacked-md.table-dark>tbody>tr,
    .b-table.b-table-stacked-md.table-dark>tbody>tr>td,
    .b-table.b-table-stacked-md.table-dark>tbody>tr>th,
    .table.b-table.b-table-stacked-md>caption,
    .table.b-table.b-table-stacked-md>tbody,
    .table.b-table.b-table-stacked-md>tbody>tr,
    .table.b-table.b-table-stacked-md>tbody>tr>td,
    .table.b-table.b-table-stacked-md>tbody>tr>th {
      display: block
    }
    .b-table.b-table-stacked-md.dark-table>tfoot,
    .b-table.b-table-stacked-md.dark-table>tfoot>tr.b-table-bottom-row,
    .b-table.b-table-stacked-md.dark-table>tfoot>tr.b-table-top-row,
    .b-table.b-table-stacked-md.dark-table>thead,
    .b-table.b-table-stacked-md.dark-table>thead>tr.b-table-bottom-row,
    .b-table.b-table-stacked-md.dark-table>thead>tr.b-table-top-row,
    .b-table.b-table-stacked-md.table-dark>tfoot,
    .b-table.b-table-stacked-md.table-dark>tfoot>tr.b-table-bottom-row,
    .b-table.b-table-stacked-md.table-dark>tfoot>tr.b-table-top-row,
    .b-table.b-table-stacked-md.table-dark>thead,
    .b-table.b-table-stacked-md.table-dark>thead>tr.b-table-bottom-row,
    .b-table.b-table-stacked-md.table-dark>thead>tr.b-table-top-row,
    .table.b-table.b-table-stacked-md>tfoot,
    .table.b-table.b-table-stacked-md>tfoot>tr.b-table-bottom-row,
    .table.b-table.b-table-stacked-md>tfoot>tr.b-table-top-row,
    .table.b-table.b-table-stacked-md>thead,
    .table.b-table.b-table-stacked-md>thead>tr.b-table-bottom-row,
    .table.b-table.b-table-stacked-md>thead>tr.b-table-top-row {
      display: none
    }
    .b-table.b-table-stacked-md.dark-table>caption,
    .b-table.b-table-stacked-md.table-dark>caption,
    .table.b-table.b-table-stacked-md>caption {
      caption-side: top!important
    }
    .b-table.b-table-stacked-md.dark-table>tbody>tr>[data-label]:before,
    .b-table.b-table-stacked-md.table-dark>tbody>tr>[data-label]:before,
    .table.b-table.b-table-stacked-md>tbody>tr>[data-label]:before {
      content: attr(data-label);
      width: 40%;
      float: left;
      text-align: right;
      overflow-wrap: break-word;
      font-weight: 700;
      font-style: normal;
      padding: 0 .5rem 0 0;
      margin: 0
    }
    .b-table.b-table-stacked-md.dark-table>tbody>tr>[data-label]:after,
    .b-table.b-table-stacked-md.table-dark>tbody>tr>[data-label]:after,
    .table.b-table.b-table-stacked-md>tbody>tr>[data-label]:after {
      display: block;
      clear: both;
      content: ""
    }
    .b-table.b-table-stacked-md.dark-table>tbody>tr>[data-label]>div,
    .b-table.b-table-stacked-md.table-dark>tbody>tr>[data-label]>div,
    .table.b-table.b-table-stacked-md>tbody>tr>[data-label]>div {
      display: inline-block;
      width: 60%;
      padding: 0 0 0 .5rem;
      margin: 0
    }
    .b-table.b-table-stacked-md.dark-table>tbody>tr.bottom-row,
    .b-table.b-table-stacked-md.dark-table>tbody>tr.top-row,
    .b-table.b-table-stacked-md.table-dark>tbody>tr.bottom-row,
    .b-table.b-table-stacked-md.table-dark>tbody>tr.top-row,
    .table.b-table.b-table-stacked-md>tbody>tr.bottom-row,
    .table.b-table.b-table-stacked-md>tbody>tr.top-row {
      display: none
    }
    .b-table.b-table-stacked-md.dark-table>tbody>tr>:first-child,
    .b-table.b-table-stacked-md.dark-table>tbody>tr>[rowspan]+td,
    .b-table.b-table-stacked-md.dark-table>tbody>tr>[rowspan]+th,
    .b-table.b-table-stacked-md.table-dark>tbody>tr>:first-child,
    .b-table.b-table-stacked-md.table-dark>tbody>tr>[rowspan]+td,
    .b-table.b-table-stacked-md.table-dark>tbody>tr>[rowspan]+th,
    .table.b-table.b-table-stacked-md>tbody>tr>:first-child,
    .table.b-table.b-table-stacked-md>tbody>tr>[rowspan]+td,
    .table.b-table.b-table-stacked-md>tbody>tr>[rowspan]+th {
      border-top-width: 3px
    }
  }

  @media (max-width:991.98px) {
    .b-table.b-table-stacked-lg.dark-table,
    .b-table.b-table-stacked-lg.table-dark,
    .table.b-table.b-table-stacked-lg {
      display: block;
      width: 100%
    }
    .b-table.b-table-stacked-lg.dark-table>caption,
    .b-table.b-table-stacked-lg.dark-table>tbody,
    .b-table.b-table-stacked-lg.dark-table>tbody>tr,
    .b-table.b-table-stacked-lg.dark-table>tbody>tr>td,
    .b-table.b-table-stacked-lg.dark-table>tbody>tr>th,
    .b-table.b-table-stacked-lg.table-dark>caption,
    .b-table.b-table-stacked-lg.table-dark>tbody,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr>td,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr>th,
    .table.b-table.b-table-stacked-lg>caption,
    .table.b-table.b-table-stacked-lg>tbody,
    .table.b-table.b-table-stacked-lg>tbody>tr,
    .table.b-table.b-table-stacked-lg>tbody>tr>td,
    .table.b-table.b-table-stacked-lg>tbody>tr>th {
      display: block
    }
    .b-table.b-table-stacked-lg.dark-table>tfoot,
    .b-table.b-table-stacked-lg.dark-table>tfoot>tr.b-table-bottom-row,
    .b-table.b-table-stacked-lg.dark-table>tfoot>tr.b-table-top-row,
    .b-table.b-table-stacked-lg.dark-table>thead,
    .b-table.b-table-stacked-lg.dark-table>thead>tr.b-table-bottom-row,
    .b-table.b-table-stacked-lg.dark-table>thead>tr.b-table-top-row,
    .b-table.b-table-stacked-lg.table-dark>tfoot,
    .b-table.b-table-stacked-lg.table-dark>tfoot>tr.b-table-bottom-row,
    .b-table.b-table-stacked-lg.table-dark>tfoot>tr.b-table-top-row,
    .b-table.b-table-stacked-lg.table-dark>thead,
    .b-table.b-table-stacked-lg.table-dark>thead>tr.b-table-bottom-row,
    .b-table.b-table-stacked-lg.table-dark>thead>tr.b-table-top-row,
    .table.b-table.b-table-stacked-lg>tfoot,
    .table.b-table.b-table-stacked-lg>tfoot>tr.b-table-bottom-row,
    .table.b-table.b-table-stacked-lg>tfoot>tr.b-table-top-row,
    .table.b-table.b-table-stacked-lg>thead,
    .table.b-table.b-table-stacked-lg>thead>tr.b-table-bottom-row,
    .table.b-table.b-table-stacked-lg>thead>tr.b-table-top-row {
      display: none
    }
    .b-table.b-table-stacked-lg.dark-table>caption,
    .b-table.b-table-stacked-lg.table-dark>caption,
    .table.b-table.b-table-stacked-lg>caption {
      caption-side: top!important
    }
    .b-table.b-table-stacked-lg.dark-table>tbody>tr>[data-label]:before,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr>[data-label]:before,
    .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]:before {
      content: attr(data-label);
      width: 40%;
      float: left;
      text-align: right;
      overflow-wrap: break-word;
      font-weight: 700;
      font-style: normal;
      padding: 0 .5rem 0 0;
      margin: 0
    }
    .b-table.b-table-stacked-lg.dark-table>tbody>tr>[data-label]:after,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr>[data-label]:after,
    .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]:after {
      display: block;
      clear: both;
      content: ""
    }
    .b-table.b-table-stacked-lg.dark-table>tbody>tr>[data-label]>div,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr>[data-label]>div,
    .table.b-table.b-table-stacked-lg>tbody>tr>[data-label]>div {
      display: inline-block;
      width: 60%;
      padding: 0 0 0 .5rem;
      margin: 0
    }
    .b-table.b-table-stacked-lg.dark-table>tbody>tr.bottom-row,
    .b-table.b-table-stacked-lg.dark-table>tbody>tr.top-row,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr.bottom-row,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr.top-row,
    .table.b-table.b-table-stacked-lg>tbody>tr.bottom-row,
    .table.b-table.b-table-stacked-lg>tbody>tr.top-row {
      display: none
    }
    .b-table.b-table-stacked-lg.dark-table>tbody>tr>:first-child,
    .b-table.b-table-stacked-lg.dark-table>tbody>tr>[rowspan]+td,
    .b-table.b-table-stacked-lg.dark-table>tbody>tr>[rowspan]+th,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr>:first-child,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr>[rowspan]+td,
    .b-table.b-table-stacked-lg.table-dark>tbody>tr>[rowspan]+th,
    .table.b-table.b-table-stacked-lg>tbody>tr>:first-child,
    .table.b-table.b-table-stacked-lg>tbody>tr>[rowspan]+td,
    .table.b-table.b-table-stacked-lg>tbody>tr>[rowspan]+th {
      border-top-width: 3px
    }
  }

  @media (max-width:1199.98px) {
    .b-table.b-table-stacked-xl.dark-table,
    .b-table.b-table-stacked-xl.table-dark,
    .table.b-table.b-table-stacked-xl {
      display: block;
      width: 100%
    }
    .b-table.b-table-stacked-xl.dark-table>caption,
    .b-table.b-table-stacked-xl.dark-table>tbody,
    .b-table.b-table-stacked-xl.dark-table>tbody>tr,
    .b-table.b-table-stacked-xl.dark-table>tbody>tr>td,
    .b-table.b-table-stacked-xl.dark-table>tbody>tr>th,
    .b-table.b-table-stacked-xl.table-dark>caption,
    .b-table.b-table-stacked-xl.table-dark>tbody,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr>td,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr>th,
    .table.b-table.b-table-stacked-xl>caption,
    .table.b-table.b-table-stacked-xl>tbody,
    .table.b-table.b-table-stacked-xl>tbody>tr,
    .table.b-table.b-table-stacked-xl>tbody>tr>td,
    .table.b-table.b-table-stacked-xl>tbody>tr>th {
      display: block
    }
    .b-table.b-table-stacked-xl.dark-table>tfoot,
    .b-table.b-table-stacked-xl.dark-table>tfoot>tr.b-table-bottom-row,
    .b-table.b-table-stacked-xl.dark-table>tfoot>tr.b-table-top-row,
    .b-table.b-table-stacked-xl.dark-table>thead,
    .b-table.b-table-stacked-xl.dark-table>thead>tr.b-table-bottom-row,
    .b-table.b-table-stacked-xl.dark-table>thead>tr.b-table-top-row,
    .b-table.b-table-stacked-xl.table-dark>tfoot,
    .b-table.b-table-stacked-xl.table-dark>tfoot>tr.b-table-bottom-row,
    .b-table.b-table-stacked-xl.table-dark>tfoot>tr.b-table-top-row,
    .b-table.b-table-stacked-xl.table-dark>thead,
    .b-table.b-table-stacked-xl.table-dark>thead>tr.b-table-bottom-row,
    .b-table.b-table-stacked-xl.table-dark>thead>tr.b-table-top-row,
    .table.b-table.b-table-stacked-xl>tfoot,
    .table.b-table.b-table-stacked-xl>tfoot>tr.b-table-bottom-row,
    .table.b-table.b-table-stacked-xl>tfoot>tr.b-table-top-row,
    .table.b-table.b-table-stacked-xl>thead,
    .table.b-table.b-table-stacked-xl>thead>tr.b-table-bottom-row,
    .table.b-table.b-table-stacked-xl>thead>tr.b-table-top-row {
      display: none
    }
    .b-table.b-table-stacked-xl.dark-table>caption,
    .b-table.b-table-stacked-xl.table-dark>caption,
    .table.b-table.b-table-stacked-xl>caption {
      caption-side: top!important
    }
    .b-table.b-table-stacked-xl.dark-table>tbody>tr>[data-label]:before,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr>[data-label]:before,
    .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]:before {
      content: attr(data-label);
      width: 40%;
      float: left;
      text-align: right;
      overflow-wrap: break-word;
      font-weight: 700;
      font-style: normal;
      padding: 0 .5rem 0 0;
      margin: 0
    }
    .b-table.b-table-stacked-xl.dark-table>tbody>tr>[data-label]:after,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr>[data-label]:after,
    .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]:after {
      display: block;
      clear: both;
      content: ""
    }
    .b-table.b-table-stacked-xl.dark-table>tbody>tr>[data-label]>div,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr>[data-label]>div,
    .table.b-table.b-table-stacked-xl>tbody>tr>[data-label]>div {
      display: inline-block;
      width: 60%;
      padding: 0 0 0 .5rem;
      margin: 0
    }
    .b-table.b-table-stacked-xl.dark-table>tbody>tr.bottom-row,
    .b-table.b-table-stacked-xl.dark-table>tbody>tr.top-row,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr.bottom-row,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr.top-row,
    .table.b-table.b-table-stacked-xl>tbody>tr.bottom-row,
    .table.b-table.b-table-stacked-xl>tbody>tr.top-row {
      display: none
    }
    .b-table.b-table-stacked-xl.dark-table>tbody>tr>:first-child,
    .b-table.b-table-stacked-xl.dark-table>tbody>tr>[rowspan]+td,
    .b-table.b-table-stacked-xl.dark-table>tbody>tr>[rowspan]+th,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr>:first-child,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr>[rowspan]+td,
    .b-table.b-table-stacked-xl.table-dark>tbody>tr>[rowspan]+th,
    .table.b-table.b-table-stacked-xl>tbody>tr>:first-child,
    .table.b-table.b-table-stacked-xl>tbody>tr>[rowspan]+td,
    .table.b-table.b-table-stacked-xl>tbody>tr>[rowspan]+th {
      border-top-width: 3px
    }
  }

  .b-table.b-table-stacked.dark-table,
  .b-table.b-table-stacked.table-dark,
  .table.b-table.b-table-stacked {
    display: block;
    width: 100%
  }

  .b-table.b-table-stacked.dark-table>caption,
  .b-table.b-table-stacked.dark-table>tbody,
  .b-table.b-table-stacked.dark-table>tbody>tr,
  .b-table.b-table-stacked.dark-table>tbody>tr>td,
  .b-table.b-table-stacked.dark-table>tbody>tr>th,
  .b-table.b-table-stacked.table-dark>caption,
  .b-table.b-table-stacked.table-dark>tbody,
  .b-table.b-table-stacked.table-dark>tbody>tr,
  .b-table.b-table-stacked.table-dark>tbody>tr>td,
  .b-table.b-table-stacked.table-dark>tbody>tr>th,
  .table.b-table.b-table-stacked>caption,
  .table.b-table.b-table-stacked>tbody,
  .table.b-table.b-table-stacked>tbody>tr,
  .table.b-table.b-table-stacked>tbody>tr>td,
  .table.b-table.b-table-stacked>tbody>tr>th {
    display: block
  }

  .b-table.b-table-stacked.dark-table>tfoot,
  .b-table.b-table-stacked.dark-table>tfoot>tr.b-table-bottom-row,
  .b-table.b-table-stacked.dark-table>tfoot>tr.b-table-top-row,
  .b-table.b-table-stacked.dark-table>thead,
  .b-table.b-table-stacked.dark-table>thead>tr.b-table-bottom-row,
  .b-table.b-table-stacked.dark-table>thead>tr.b-table-top-row,
  .b-table.b-table-stacked.table-dark>tfoot,
  .b-table.b-table-stacked.table-dark>tfoot>tr.b-table-bottom-row,
  .b-table.b-table-stacked.table-dark>tfoot>tr.b-table-top-row,
  .b-table.b-table-stacked.table-dark>thead,
  .b-table.b-table-stacked.table-dark>thead>tr.b-table-bottom-row,
  .b-table.b-table-stacked.table-dark>thead>tr.b-table-top-row,
  .table.b-table.b-table-stacked>tfoot,
  .table.b-table.b-table-stacked>tfoot>tr.b-table-bottom-row,
  .table.b-table.b-table-stacked>tfoot>tr.b-table-top-row,
  .table.b-table.b-table-stacked>thead,
  .table.b-table.b-table-stacked>thead>tr.b-table-bottom-row,
  .table.b-table.b-table-stacked>thead>tr.b-table-top-row {
    display: none
  }

  .b-table.b-table-stacked.dark-table>caption,
  .b-table.b-table-stacked.table-dark>caption,
  .table.b-table.b-table-stacked>caption {
    caption-side: top!important
  }

  .b-table.b-table-stacked.dark-table>tbody>tr>[data-label]:before,
  .b-table.b-table-stacked.table-dark>tbody>tr>[data-label]:before,
  .table.b-table.b-table-stacked>tbody>tr>[data-label]:before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: 700;
    font-style: normal;
    padding: 0 .5rem 0 0;
    margin: 0
  }

  .b-table.b-table-stacked.dark-table>tbody>tr>[data-label]:after,
  .b-table.b-table-stacked.table-dark>tbody>tr>[data-label]:after,
  .table.b-table.b-table-stacked>tbody>tr>[data-label]:after {
    display: block;
    clear: both;
    content: ""
  }

  .b-table.b-table-stacked.dark-table>tbody>tr>[data-label]>div,
  .b-table.b-table-stacked.table-dark>tbody>tr>[data-label]>div,
  .table.b-table.b-table-stacked>tbody>tr>[data-label]>div {
    display: inline-block;
    width: 60%;
    padding: 0 0 0 .5rem;
    margin: 0
  }

  .b-table.b-table-stacked.dark-table>tbody>tr.bottom-row,
  .b-table.b-table-stacked.dark-table>tbody>tr.top-row,
  .b-table.b-table-stacked.table-dark>tbody>tr.bottom-row,
  .b-table.b-table-stacked.table-dark>tbody>tr.top-row,
  .table.b-table.b-table-stacked>tbody>tr.bottom-row,
  .table.b-table.b-table-stacked>tbody>tr.top-row {
    display: none
  }

  .b-table.b-table-stacked.dark-table>tbody>tr>:first-child,
  .b-table.b-table-stacked.dark-table>tbody>tr>[rowspan]+td,
  .b-table.b-table-stacked.dark-table>tbody>tr>[rowspan]+th,
  .b-table.b-table-stacked.table-dark>tbody>tr>:first-child,
  .b-table.b-table-stacked.table-dark>tbody>tr>[rowspan]+td,
  .b-table.b-table-stacked.table-dark>tbody>tr>[rowspan]+th,
  .table.b-table.b-table-stacked>tbody>tr>:first-child,
  .table.b-table.b-table-stacked>tbody>tr>[rowspan]+td,
  .table.b-table.b-table-stacked>tbody>tr>[rowspan]+th {
    border-top-width: 3px
  }

  .table-header-light {
    background-color: #f7f9fc;
    color: #6b7c93!important
  }

  .table-header-dark {
    background-color: #1f3a55;
    color: #cdd1d6!important
  }

  .dark-table,
  .table,
  .table-dark {
    background-color: transparent!important
  }

  .dark-table thead tr,
  .table-dark thead tr,
  .table thead tr {
    background-color: #f7f9fc;
    color: #6b7c93!important
  }

  .dark-table thead th,
  .table-dark thead th,
  .table thead th {
    font-size: 14px!important;
    font-weight: 500!important;
    border-top: 1px solid #e6ebf2!important;
    border-bottom: 1px solid #e6ebf2!important
  }

  .dark-table thead th:first-child,
  .table-dark thead th:first-child,
  .table thead th:first-child {
    padding-left: 1.25rem!important
  }

  .dark-table td,
  .table-dark td,
  .table td {
    font-size: 14px;
    font-weight: 500;
    color: #{$dark-theme};
    border-top: none!important;
    border-bottom: 1px solid #e6ebf2!important
  }

  .dark-table td:first-child,
  .table-dark td:first-child,
  .table td:first-child {
    padding-left: 1.25rem!important
  }

  .dark-table thead tr,
  .table-dark thead tr {
    background-color: #1f3a55;
    color: #cdd1d6!important
  }

  .dark-table thead th,
  .table-dark thead th {
    border-top: 1px solid #1f3a55!important;
    border-bottom: 1px solid #1f3a55!important
  }

  .dark-table td,
  .table-dark td {
    color: #cdd1d6;
    border-top: none!important;
    border-bottom: 1px solid #1f3a55!important
  }

  .primary-dark,
  .primary-light {
    background: #0f59d1!important
  }

  .bg-body-light {
    background: #f8f9fd!important
  }

  .bg-body-dark {
    background: #{$dark-l-theme}!important
  }

  .bg-block-light {
    background: #fff!important
  }

  .bg-light {
    background: #eee!important
  }

  .bg-block-dark {
    background: #{$dark-theme}!important
  }

  .headings-light {
    color: #373737!important
  }

  .headings-dark {
    color: #fff!important
  }

  .text-body-light {
    color: rgba(87, 87, 87, .85)!important;
    font-weight: 700
  }

  .text-body-dark {
    color: hsla(0, 0%, 100%, .7)!important;
    font-weight: 700
  }

  .text-light {
    color: #{$dark-theme}!important
  }

  .text-dark {
    color: #cdd1d6!important
  }

  .text-primary-light {
    color: #0f59d1!important
  }

  .text-primary-dark {
    color: #86b0f4!important
  }

  .label-muted-light {
    color: #6b7c93!important
  }

  .label-active-light {
    color: #{$dark-theme}!important
  }

  .label-muted-dark {
    color: #97a5b8!important
  }

  .label-active-dark {
    color: #cdd1d6!important
  }

  .text-red {
    color: #de4a5c!important;
    font-weight: 700!important
  }

  .text-link-light {
    color: #0f59d1
  }

  .text-link-dark,
  .text-link-light {
    text-decoration: underline;
    cursor: pointer
  }

  .text-link-dark {
    color: #86b0f4
  }

  .text-link-muted-light {
    color: #1f3a55;
    text-decoration: underline;
    cursor: pointer
  }

  .text-link-muted-dark {
    color: #e6ebf2;
    text-decoration: underline;
    cursor: pointer
  }

  .text-muted-light {
    color: #6b7c93!important
  }

  .text-muted-dark {
    color: #cdd1d6!important
  }

  .block-light-blue-light {
    background-color: #f7f9fc!important;
    border: 1px solid #e6ebf2!important
  }

  .block-light-blue-dark {
    background-color: #1d3144!important;
    border: 1px solid #1d3144!important
  }

  .alert-info-light {
    border: 1px solid #0f59d1!important;
    background: rgba(15, 89, 209, .1);
    border-radius: 8px;
    padding: 1rem;
    color: #0f59d1
  }

  .alert-info-dark {
    border: 1px solid #86b0f4!important;
    background: rgba(31, 58, 85, .5);
    border-radius: 8px;
    padding: 1rem;
    color: #86b0f4
  }

  .alert-error-light {
    background: rgba(222, 74, 92, .1)
  }

  .alert-error-dark,
  .alert-error-light {
    border: 1px solid #de4a5c!important;
    border-radius: 8px;
    padding: .5rem;
    color: #de4a5c
  }

  .alert-error-dark {
    background: rgba(36, 41, 66, .5)
  }

  .alert-warning-light {
    background: #fff8ed
  }

  .alert-warning-dark,
  .alert-warning-light {
    border: 1px solid #f6ab30!important;
    border-radius: 8px;
    padding: .5rem;
    color: #f6ab30
  }

  .alert-warning-dark {
    background: rbga(#423a24, .2)
  }

  .badge-light {
    color: #0f59d1;
    background: #e9f2fd
  }

  .badge-dark {
    color: #000;
    background: #86b0f4;
    opacity: .65
  }

  .percentage-negative {
    color: #de4a5c
  }

  .percentage-positive {
    color: #3ec8c8
  }

  .progress-bar {
    background-color: #33f693
  }

  .cursor {
    cursor: pointer
  }

  .block.block-mode-loading>.block-content,
  .block.block-mode-loading>.block-header,
  .block.block-mode-loading>.nav-tabs {
    opacity: 0
  }

  .block.block-mode-loading:after {
    content: "";
    background: url(assets/circle-notch.4cccc6b0.svg) no-repeat 50% 50%;
    background-size: 24px
  }

  .block.block-shadow {
    -webkit-box-shadow: 0 2px 22px 0 rgba(15, 89, 209, .12), 0 2px 19px 0 rgba(82, 105, 141, .12);
    box-shadow: 0 2px 22px 0 rgba(15, 89, 209, .12), 0 2px 19px 0 rgba(82, 105, 141, .12)
  }

  .block.block-shadow-light {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .04), 0 1px 2px 0 rgba(15, 89, 209, .08);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .04), 0 1px 2px 0 rgba(15, 89, 209, .08)
  }

  .bn-onboard-modal,
  .bn-onboard-modal-content {
    font-family: Arial!important
  }

  .bn-onboard-custom.bn-onboard-modal {
    z-index: 99
  }

  .btn {
    font-weight: 700;
    font-size: 14px
  }

  .btn.block-rounded {
    border-radius: 8px!important
  }

  .btn.btn-outline-gray {
    color: #{$dark-theme}!important
  }

  .btn.btn-outline-gray-dark {
    color: #cdd1d6!important
  }

  .btn.btn-circle-gray {
    color: #{$dark-theme}!important;
    border-color: #d0d7df!important;
    border-radius: 20px!important
  }

  .btn.btn-circle-gray-dark {
    color: #cdd1d6!important;
    border-color: #eee!important;
    border-radius: 20px!important
  }

  .btn.btn-outline-gray-dark:hover {
    color: #{$dark-theme}!important
  }

  .btn.btn-outline-alt-light {
    color: #{$dark-theme}!important;
    border-color: #d0d7df!important;
    background-color: #f8f9fd!important
  }

  .btn.btn-outline-alt-dark {
    color: #eee!important;
    border-color: #eee!important;
    background-color: #{$dark-l-theme}!important
  }

  .btn.btn-outline-alt-light:hover {
    color: #{$dark-theme}!important;
    border-color: #d0d7df!important;
    background-color: #f8f9fd!important
  }

  .btn.btn-outline-alt-dark:hover {
    color: #{$dark-l-theme}!important;
    border-color: #{$dark-l-theme}!important;
    background-color: #eee!important
  }

  .btn.btn-outline-primary {
    background-color: #e9f2fd!important;
    color: #{$dark-theme}!important
  }

  .btn.btn-outline-primary-dark {
    background-color: #{$dark-theme}!important;
    color: #6b7c93!important;
    opacity: .6;
    border: 1px solid #0f59d1!important
  }

  .btn.btn-outline-primary:hover {
    background-color: #e9f2fd!important;
    color: #{$dark-theme}!important
  }

  .btn.btn-outline-primary-dark:hover {
    background-color: #0a316e!important;
    color: #cdd1d6!important
  }

  .btn.btn-muted-white-light {
    color: #6b7c93!important;
    border-color: #d0d7df!important;
    background-color: #fff!important
  }

  .btn.btn-muted-light {
    color: #d0d7df!important;
    border-color: #d0d7df!important;
    background-color: #f8f9fd!important
  }

  .btn.btn-muted-dark {
    background-color: #1f3a55!important
  }

  .btn.btn-muted-alt-dark,
  .btn.btn-muted-dark {
    color: #516175!important;
    border-color: #516175!important
  }

  .btn.btn-muted-alt-dark {
    background-color: #{$dark-l-theme}!important
  }

  .btn.btn-active-light {
    color: #{$dark-theme}!important;
    border-color: #{$dark-theme}!important;
    background-color: #f8f9fd!important
  }

  .btn.btn-active-dark {
    color: #cdd1d6!important;
    border-color: #cdd1d6!important;
    background-color: #{$dark-l-theme}!important
  }

  .btn.btn-active-alt-light {
    color: #fff!important;
    background-color: #244185!important
  }

  .btn.btn-active-alt-dark {
    color: #fff!important;
    background-color: #1f3a55!important
  }

  .btn.btn-primary:focus {
    -webkit-box-shadow: none!important;
    box-shadow: none!important
  }

  .btn.btn-outline-dark {
    color: #eee!important;
    border-color: #eee!important;
    background-color: #{$dark-l-theme}!important
  }

  .btn.btn-outline-light {
    color: #0f59d1!important;
    border-color: #d0d7df!important;
    background-color: #f8f9fd!important
  }

  .btn.btn-outline-light-alt {
    color: #{$dark-theme}!important;
    border-color: #d0d7df!important;
    background-color: #fff!important
  }

  .btn.btn-outline-dark:hover {
    background-color: #eee!important;
    color: #{$dark-l-theme}!important
  }

  .btn.btn-outline-light:hover {
    background-color: #0f59d1!important;
    color: #eee!important;
    border-color: #0f59d1!important
  }

  .btn.btn-outline-light-alt:hover {
    color: #{$dark-theme}!important;
    border-color: #d0d7df!important;
    background-color: #fff!important
  }

  .btn.btn-error {
    color: #fff!important;
    background-color: #de4a5c!important
  }

  .modal .modal-content {
    border-radius: 8px!important
  }

  .form-control-alt-dark,
  .form-control-alt-light {
    height: 48px;
    background-color: transparent!important;
    font-size: 15px
  }

  .form-control-alt-light {
    border: 1px solid #e6ebf2!important
  }

  .form-control-alt-light::-webkit-input-placeholder {
    color: #d0d7df;
    opacity: 1
  }

  .form-control-alt-light::-moz-placeholder {
    color: #d0d7df;
    opacity: 1
  }

  .form-control-alt-light:-ms-input-placeholder {
    color: #d0d7df;
    opacity: 1
  }

  .form-control-alt-light::-ms-input-placeholder {
    color: #d0d7df;
    opacity: 1
  }

  .form-control-alt-light::placeholder {
    color: #d0d7df;
    opacity: 1
  }

  .form-control-alt-dark {
    border: 1px solid #1f3a55!important
  }

  .form-control-alt-dark::-webkit-input-placeholder {
    color: #516175;
    opacity: 1
  }

  .form-control-alt-dark::-moz-placeholder {
    color: #516175;
    opacity: 1
  }

  .form-control-alt-dark:-ms-input-placeholder {
    color: #516175;
    opacity: 1
  }

  .form-control-alt-dark::-ms-input-placeholder {
    color: #516175;
    opacity: 1
  }

  .form-control-alt-dark::placeholder {
    color: #516175;
    opacity: 1
  }

  .custom-tabs .nav-tabs,
  .tabs-dark .nav-tabs,
  .tabs-light .nav-tabs {
    border-bottom: none!important;
    font-size: 14px!important;
    font-weight: 600!important
  }

  .custom-tabs .nav-tabs .nav-link,
  .tabs-dark .nav-tabs .nav-link,
  .tabs-light .nav-tabs .nav-link {
    margin-right: 5px!important;
    margin-left: 20px!important
  }

  .custom-tabs .nav-tabs .nav-item.show .nav-link,
  .custom-tabs .nav-tabs .nav-link.active,
  .tabs-dark .nav-tabs .nav-item.show .nav-link,
  .tabs-dark .nav-tabs .nav-link.active,
  .tabs-light .nav-tabs .nav-item.show .nav-link,
  .tabs-light .nav-tabs .nav-link.active {
    background: none!important;
    border: none!important
  }

  .custom-tabs .nav-tabs .nav-link,
  .tabs-dark .nav-tabs .nav-link,
  .tabs-light .nav-tabs .nav-link {
    color: #6b7c93!important;
    border: none!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 12px;
    margin-bottom: 1px
  }

  .tabs-light .nav-tabs .nav-item.show .nav-link,
  .tabs-light .nav-tabs .nav-link.active {
    color: #0f59d1!important;
    border-bottom: 2px solid #0f59d1!important
  }

  .tabs-dark .nav-tabs .nav-item.show .nav-link,
  .tabs-dark .nav-tabs .nav-link.active {
    color: #fff!important;
    border-bottom: 2px solid #fff!important
  }

  .large-tabs-light .nav-tabs {
    border: #fff;
    font-size: 20px!important;
    font-weight: 600!important
  }

  .large-tabs-light .nav-tabs .nav-link {
    color: #6b7c93;
    background: #e6ebf2;
    border-bottom: 0
  }

  .large-tabs-light .nav-link.active {
    color: #{$dark-theme};
    background: #fff;
    border: #fff;
    padding: 10px 20px
  }

  .large-tabs-dark .nav-tabs {
    border: #{$dark-l-theme};
    font-size: 20px!important;
    font-weight: 600!important
  }

  .large-tabs-dark .nav-tabs .nav-link {
    color: #cdd1d6;
    background: #1f3a55;
    border-bottom: 0
  }

  .large-tabs-dark .nav-link.active {
    color: #cdd1d6;
    background: #{$dark-theme};
    border: #{$dark-theme};
    padding: 10px 20px
  }

  .border-gray {
    border: 1px solid #e6ebf2!important
  }

  .border-gray-dark {
    border: 1px solid #1f3a55!important
  }

  .pagination .page-item,
  .pagination .page-item.active,
  .pagination .page-item:hover,
  .pagination .page-link,
  .pagination .page-link.active,
  .pagination .page-link:hover {
    border: none!important;
    background-color: transparent!important
  }

  .logo-shadow {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    border: 1px solid #e6ebf2
  }

  @media (max-width:768px) {
    .max-search-width-xs {
      width: 120px!important
    }
  }

  .progress {
    height: 4px!important
  }

  .form-control-date {
    height: 35px!important;
    width: 220px!important;
    border: 1px solid transparent!important;
    border-radius: 8px!important;
    padding: .375rem .75rem;
    cursor: pointer
  }

  .form-control-date.date-range-light {
    color: #6b7c93!important;
    background-color: #fff!important;
    border-color: #d0d7df!important
  }

  .form-control-date.date-range-light-active {
    color: #{$dark-theme}!important;
    border-color: #{$dark-theme}!important;
    background-color: #f8f9fd!important
  }

  .form-control-date.date-range-dark {
    color: #516175!important;
    background-color: #1f3a55!important;
    border-color: #516175!important
  }

  .form-control-date.date-range-dark-active {
    color: #cdd1d6!important;
    border-color: #cdd1d6!important;
    background-color: #{$dark-l-theme}!important
  }

  .date-range-picker-light .daterangepicker.dropdown-menu {
    background: #f8f9fd!important
  }

  .date-range-picker-light .calendar-table,
  .date-range-picker-light .drp-calendar {
    background: #f8f9fd!important;
    border-radius: 8px!important;
    border-color: #f8f9fd!important
  }

  .date-range-picker-light thead {
    background: #f8f9fd!important
  }

  .date-range-picker-light thead th {
    background: #f8f9fd!important;
    color: #{$dark-theme}!important
  }

  .date-range-picker-light tbody {
    color: #{$dark-theme}!important
  }

  .date-range-picker-light tbody td,
  .date-range-picker-light tbody tr {
    background: #f8f9fd!important
  }

  .date-range-picker-light tbody th {
    background: #f8f9fd!important;
    color: #{$dark-theme}!important
  }

  .date-range-picker-light tbody .in-range {
    background: #e9f2fd!important;
    color: #0f59d1!important
  }

  .date-range-picker-light tbody .active {
    background: #0f59d1!important;
    color: #fff!important
  }

  .date-range-picker-dark .daterangepicker.dropdown-menu {
    background: #{$dark-l-theme}!important
  }

  .date-range-picker-dark .calendar-table,
  .date-range-picker-dark .drp-calendar {
    background: #{$dark-l-theme}!important;
    border-radius: 8px!important;
    border-color: #{$dark-l-theme}!important
  }

  .date-range-picker-dark thead {
    background: #{$dark-l-theme}!important
  }

  .date-range-picker-dark thead th {
    background: #{$dark-l-theme}!important;
    color: #cdd1d6!important
  }

  .date-range-picker-dark tbody {
    color: #cdd1d6!important
  }

  .date-range-picker-dark tbody td,
  .date-range-picker-dark tbody tr {
    background: #{$dark-l-theme}!important
  }

  .date-range-picker-dark tbody th {
    background: #{$dark-l-theme}!important;
    color: #cdd1d6!important;
    font-weight: 800!important
  }

  .date-range-picker-dark tbody .in-range {
    color: #{$dark-l-theme}!important;
    background: #86b0f4!important;
    opacity: .65!important
  }

  .date-range-picker-dark tbody .active {
    background: #0f59d1!important;
    color: #fff!important
  }

  .dropdown-item-light {
    font-size: 14px!important;
    font-weight: 400!important;
    margin-top: 5px!important
  }

  .dropdown-item-light a {
    color: #6b7c93!important;
    background-color: #fff!important
  }

  .dropdown-item-light a:hover {
    color: #0f59d1!important;
    background-color: #e9f2fd!important
  }

  .dropdown-item-dark {
    font-size: 14px!important;
    font-weight: 400!important;
    margin-top: 5px!important
  }

  .dropdown-item-dark a {
    color: #97a5b8!important;
    background-color: #{$dark-theme}!important
  }

  .dropdown-item-dark a:hover {
    color: #fff!important;
    background-color: #0a316e!important
  }

  .checkbox-dark .custom-control-label:before {
    border-radius: 2px
  }

  .checkbox-dark .custom-control-input:checked~.custom-control-label:before {
    background-color: #{$dark-theme};
    border-color: #fff
  }

  .checkbox-light .custom-control-label:before {
    border-radius: 2px
  }

  .checkbox-light .custom-control-input:checked~.custom-control-label:before {
    background-color: #0f59d1;
    border-color: #6b7c93
  }

  .limited-dropdown .dropdown-menu {
    max-height: 250px;
    overflow-y: auto
  }

  .limited-dropdown-settings .dropdown-menu {
    max-height: 420px;
    width: 300px;
    overflow-y: auto
  }

  .full-dropdown .dropdown-menu {
    width: 100%
  }

  .custom-input-active {
    border: 1px solid #fff!important
  }

  .custom-input-active,
  .custom-input-active-borderless,
  .icon-primary {
    background-color: #0f59d1!important;
    color: #fff!important
  }

  .icon-primary {
    border-radius: 36px!important;
    border: 8px solid #0f59d1!important
  }

  .icon-primary-light {
    background-color: #fff!important;
    border-radius: 6px!important;
    border: 1px solid #0f59d1!important;
    padding: 3px;
    color: #0f59d1!important
  }

  .icon-primary-dark {
    background-color: #{$dark-theme}!important;
    border-radius: 6px!important;
    border: 1px solid #86b0f4!important;
    padding: 3px;
    color: #86b0f4!important
  }

  .seperator-dark {
    margin-bottom: 1rem;
    border: 1px solid #1f3a55
  }

  .seperator-light {
    margin-bottom: 1rem;
    border: 1px solid #e6ebf2
  }

  .full-modal .modal {
    padding: 0!important
  }

  .full-modal .modal-dialog {
    max-width: 100%!important;
    height: 100%!important;
    margin: 0!important
  }

  .full-modal .dialog-centered,
  .full-modal .modal-dialog-scrollable {
    max-height: 100%!important
  }

  .full-modal .modal-content {
    border: 0!important;
    border-radius: 0!important;
    height: 100%!important
  }

  .btn-green {
    color: #fff;
    background-color: #33f693;
  }

  .btn-green:focus{
    box-shadow: 0 0 0 0.2rem #33f693;
    -webkit-box-shadow: 0 0 0 0.2rem #33f693;
    outline: 0;
  }

  .btn-green:hover,
  .btn-green:active{
    color: #fff;
    background-color: #25b86f;
  }

  .snack-bar-light {
    color: #fff!important;
    background-color: #{$dark-theme}!important;
    border-radius: 6px!important
  }

  .snack-bar-dark {
    color: #000!important;
    background-color: #e9f2fd!important;
    border-radius: 6px!important
  }

  .snack-bar-light-error {
    color: #fff!important;
    background-color: red!important;
    border-radius: 6px!important
  }

  .snack-bar-dark-error {
    color: #000!important;
    background-color: red!important;
    border-radius: 6px!important
  }

  .snack-bar-light p {
    color: inherit!important;
  }

  .snack-bar-dark p {
    color: inherit!important;
  }
